import React, { useState } from "react";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";


import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import RHFTextField from "../../components/FormControls/RHFTextField";
import RHFAutoCompleteSelect from "../../components/FormControls/RHFAutoCompleteSelect";
import RHFDatePicker from "../../components/FormControls/RHFDatePicker";
import RHFSwitch from "../../components/FormControls/RHFSwitch";
import RHFButton from "../../components/FormControls/RHFButton";
import RHFFileUpload from "../../components/FormControls/RHFFileUpload";
import RHFCheckbox from "../../components/FormControls/RHFCheckbox";
import DropdownButton from "../../components/FormControls/DropdownButton";
import { Toast } from "components/Common/Toaster";
import AvtarGroup from "components/FormControls/AvtarGroup";
import { avatar1, avatar2, avatar3, avatar4, avatar5 } from "assets/images";
import TextChip from "components/Common/TextChip";
import RHFDropZone from "components/FormControls/RHFDropZone";

const DmControl = () => {
    document.title = "Dm Controls | RiDiscovery";

    const [isActive, setIsActive] = useState(false);
    const [isRemember, setIsRemember] = useState(false);

    const users = [
        { id: 1, name: 'Dipesh Mali', image: avatar1 },
        { id: 2, name: 'Mahesh', image: avatar2 },
        { id: 3, name: 'Foram', image: avatar3 },
        { id: 4, name: 'Pratik', image: avatar4 },
        { id: 5, name: 'Priyal', image: avatar5 },
    ]

    const menuItems = [
        { id: 1, name: 'Surat' },
        { id: 2, name: 'Baroda' },
        { id: 3, name: 'Mumbai' },
        { id: 4, name: 'Pune' },
        { id: 5, name: 'Nashik' }
    ]

    const loginSchema = yup.object().shape({
        email: yup.string().email().max(150).required("Email is required"),
        colors: yup
            .object()
            .shape({ label: yup.string(), value: yup.string() })
            .nullable()
            .required("Select atleast one option"),
        date: yup.date().required("Date is required"),
        userProfile: yup.mixed().required("Profile pic is required"),
        isRemember: yup.bool().required("Field is required"),
    });

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(loginSchema),
    });

    const onLogin = () => {
    };


    const handleSwitchChange = (val) => {
        setIsActive(val);
        setValue("isActive", val);
    };

    const handleCheckboxChange = (val) => {
        setIsRemember(val);
        setValue("isRemember", val);
    };

    const getFileData = () => {
    };
    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/dm-controls", heading: "DM Control" }]

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Dm Control" breadcrumbItem={breadcrumbItems} />

                <form onSubmit={handleSubmit(onLogin)}>
                    <RHFTextField
                        id="email"
                        label="Email"
                        name="email"
                        errorobj={errors}
                        control={control}
                        isController={true}
                    />

                    <RHFAutoCompleteSelect
                        id="colors"
                        label="Colors"
                        name="colors"
                        options={[
                            { value: "ocean", label: "Ocean", color: "#00B8D9" },
                            { value: "blue", label: "Blue", color: "#0052CC" },
                            { value: "purple", label: "Purple", color: "#5243AA" },
                            { value: "red", label: "Red", color: "#FF5630", isFixed: true },
                            { value: "orange", label: "Orange", color: "#FF8B00" },
                            { value: "yellow", label: "Yellow", color: "#FFC400" },
                            { value: "green", label: "Green", color: "#36B37E" },
                            { value: "forest", label: "Forest", color: "#00875A" },
                            { value: "slate", label: "Slate", color: "#253858" },
                            { value: "silver", label: "Silver", color: "#666666" },
                        ]}
                        isMultiple={false}
                        errorobj={errors}
                        control={control}
                        isController={true}
                    // handleOnChange={handleOnChange} // when isController === false
                    />

                    <RHFDatePicker
                        name="date"
                        label="Date"
                        errorobj={errors}
                        control={control}
                        isController={true}
                    />

                    <RHFSwitch
                        name="isActive"
                        label="Is Active"
                        isController={true}
                        checked={isActive}
                        errorobj={errors}
                        control={control}
                        onChange={handleSwitchChange}
                    />

                    <RHFCheckbox
                        name="isRemember"
                        label="Is Remember"
                        checked={isRemember}
                        isController={true}
                        errorobj={errors}
                        control={control}
                        onChange={handleCheckboxChange} // mostly useful when isController === false
                    />

                    <RHFFileUpload
                        name="userProfile"
                        getFileData={getFileData}
                        setValue={setValue}
                        errorobj={errors}
                        isValidate={true}
                    />
                    <br />
                    <br />

                    <RHFButton btnName="Save" type="submit" />

                    <br />
                    <br />

                    <AvtarGroup
                        users={users}
                        max={4}
                    />
                    <br />
                    <br />

                    <TextChip text="Text Chip" />

                    <br />
                    <br />

                    <RHFButton btnName="Toaster" onClick={() => Toast.success('Hello bhai')} />

                    <br />
                    <br />

                    <DropdownButton
                        heading="Dropdown"
                        menuItems={menuItems}
                        handleClick={(item) => alert(item)}
                    />

                    <br />
                    <br />

                    <RHFDropZone
                        // accept="image/png, image/jpg, image/jpeg, image/gif"
                        name="imagefile"
                        label="imagefile"
                        errorobj={errors}
                        control={control}
                    />
                    <br />
                    <br />


                </form>

            </div>
        </React.Fragment >
    );
};

export default DmControl;
