import React, { useState, useEffect } from 'react'
import { AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, CardHeader, Col, FormFeedback, Row, UncontrolledAccordion } from 'reactstrap'
import Breadcrumb from 'components/Common/Breadcrumb';
import RHFButton from 'components/FormControls/RHFButton';
import RHFDropZone from 'components/FormControls/RHFDropZone';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import Editor from './Editor';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import RHFTextField from 'components/FormControls/RHFTextField';
import { addTemplate, editTemplate, getTemplateDetails } from 'helpers/BackendHelper';
import { sortableObject } from 'helpers/util';
import Settings from './Settings';
import DialogBox from 'components/Modals/DialogBox';
import { Toast } from 'components/Common/Toaster';
import { nameRegex, TEMPLATE_PARAMETER_FEILDS } from 'constants/Constant';

import PageLoader from "components/PageLoader";

const TemplateFields = {
    "confidentiality": "Confidentiality",
    "disclaimers": "Disclaimer",
    "documentAuthorization": "Document Authorizations",
    "tableOfContents": "Table of Contents",
    "purpose": "Purpose",
    "scope": "Scope",
    "assesmentInformation": "Assesment Information",
    "summary": "Summary",
    "vulenerabilityIndex": "Vulnerability Index",
    "poc": "Poc",
    "appendices": "Appendices",
    "appendixBUsedTools": "Appendix B.Used tools",
    "checklist": "Checklist"
}

const TemplatesListAdd = () => {
    const history = useHistory();
    const { templateId } = useParams();
    const [fileData, setFileData] = useState(null);
    const [templateDetails, setTemplateDetails] = useState(null);
    const [isModelOpen, setSettingModel] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [isApiCalling, setApiCalling] = useState(false);
    const [sectionErrors, setSectionErrors] = useState({});

    const isEditMode = templateId ? true : false;
    document.title = `${(isEditMode) ? "Edit Reporting-Template" : "Add Reporting-Template"} | RiDiscovery`;
    const templateSchema = yup.object().shape({
        name: yup.string().trim().matches(nameRegex, 'Please enter valid name').required("Template name is required").max(50, "Maximum 50 characters allowed."),
        header: yup.string().trim().matches(nameRegex, 'Invalid Header').required("Header is required").max(50, "Maximum 50 characters allowed."),
        marginLeft: yup.number().typeError("Please enter value in number.").lessThan(1000, 'Please enter between 0 to 999').required("Margin left is required"),
        marginRight: yup.number().typeError("Please enter value in number.").lessThan(1000, 'Please enter between 0 to 999').required("Margin right is required"),
        footer: yup.string().trim().matches(nameRegex, 'Invalid Footer').required("Footer is required").max(50, "Maximum 50 characters allowed."),
        logo: yup.array().min(1, "Template Logo is required"),

        confidentiality: yup.string().required("Confidentiality is required"),
        disclaimers: yup.string().required("Disclaimers is required"),
        documentAuthorization: yup.string().required("Document authorization is required"),

        // tableOfContents: yup.string().required("Table of contents is required"), // will use for only order
        purpose: yup.string().required("Purpose is required"),
        scope: yup.string().required("Scope is required"),
        assesmentInformation: yup.string().required("Assesment information is required"),

        summary: yup.string().required("Summary is required"),
        // vulenerabilityIndex: yup.string().required("Vulenerability index is required"), // will use for only order
        // poc: yup.string().required("Poc is required"),
        appendices: yup.string().required("Appendices is required"),
        appendixBUsedTools: yup.string().required("AppendixBUsed tools is required"),
        checklist: yup.string().required("Checklist is required"),
    });

    const {
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(templateSchema),
    });

    const [list, setList] = useState([
        { id: 1, title: "Confidentiality", name: "confidentiality" },
        { id: 2, title: "Disclaimer", name: "disclaimers" },
        { id: 3, title: "Document Authorizations", name: "documentAuthorization" },
        { id: 4, title: "Table of Contents", name: "tableOfContents" },
        { id: 5, title: "Purpose", name: "purpose" },
        { id: 6, title: "Scope", name: "scope" },
        { id: 7, title: "Assesment Information", name: "assesmentInformation" },
        { id: 8, title: "Summary", name: "summary" },
        { id: 9, title: "Vulnerability Index", name: "vulenerabilityIndex" },
        // { id: 10, title: "Poc", name: "poc" },
        { id: 10, title: "Appendices", name: "appendices" },
        { id: 11, title: "Appendix B.Used tools", name: "appendixBUsedTools" },
        { id: 12, title: "Checklist", name: "checklist" }
    ]);

    useEffect(() => {
        if (templateId) {
            const params = {
                id: templateId
            }
            setPageLoader(true)
            getTemplateDetails({ ...params }).then(resp => {
                setTemplateDetails(resp?.data);
                setPageLoader(false)
            })
        }
    }, [templateId]);

    useEffect(() => {
        if (templateDetails?.id) {
            setValue('name', templateDetails?.name);
            setValue('header', templateDetails?.header);
            setValue('footer', templateDetails?.footer);
            setValue('marginLeft', templateDetails?.marginLeft);
            setValue('marginRight', templateDetails?.marginRight);
            setValue('logo', templateDetails ? [{ signedUrl: templateDetails?.logo, url: templateDetails?.logo }] : null)

            // const obj = sortableObject(JSON.parse(templateDetails?.sequence));
            const obj = sortableObject(templateDetails?.sequence);
            const data = [];
            Object.entries(obj).forEach(([key, value]) => {
                // setValue(key, templateDetails?.[key]);
                data.push({ id: value, title: TemplateFields[key], name: key })
            });
            setList(data);
        }
    }, [templateDetails])


    const setSectionErrorNow = (name, msg) => {
        setSectionErrors(prev => {
            return {
                ...prev,
                [name]: msg
            }
        })
    }

    const getFileData = (files) => {
        setFileData(files)
    }

    const redirectToList = () => {
        history.push('/reporting-template')
    }

    const handleToggle = () => {
        setSettingModel(!isModelOpen)
    }

    const onSubmitTemplate = (data) => {
        setApiCalling(true);
        if (!isApiCalling) {
            const sequence = {};
            list?.map((item, index) => {
                sequence[item?.name] = index + 1;
            });

            const formData = new FormData();
            formData.append('header', data?.header);
            formData.append('marginLeft', data?.marginLeft);
            formData.append('marginRight', data?.marginRight);
            formData.append('name', data?.name);
            formData.append('logo', fileData?.files[0]);
            formData.append('appendices', data?.appendices);
            formData.append('appendixBUsedTools', data?.appendixBUsedTools);
            formData.append('assesmentInformation', data?.assesmentInformation);
            formData.append('checklist', data?.checklist);
            formData.append('confidentiality', data?.confidentiality);
            formData.append('disclaimers', data?.disclaimers);
            formData.append('documentAuthorization', data?.documentAuthorization);
            formData.append('purpose', data?.purpose);
            formData.append('scope', data?.scope);
            formData.append('summary', data?.summary);
            formData.append('tableOfContents', data?.tableOfContents);
            formData.append('vulenerabilityIndex', data?.vulenerabilityIndex);
            formData.append('footer', data?.footer);
            formData.append('sequence', JSON.stringify(sequence));
            // formData.append('sequence', sequence);

            if (isEditMode) {
                formData.append('templateId', templateDetails?.id);

                editTemplate(formData).then(resp => {
                    Toast.success("Template update successfully.")
                    if (resp?.status) {
                        redirectToList();
                        reset();
                        setApiCalling(false);
                    }
                }).catch(() => {
                    setApiCalling(false);
                });
            } else {
                addTemplate(formData).then(resp => {
                    Toast.success("Template add successfully.")
                    if (resp?.status) {
                        redirectToList();
                        reset();
                        setApiCalling(false);
                    }
                }).catch(() => {
                    setApiCalling(false);
                });
            }
        }

    }

    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/reporting-template", heading: "Reporting Templates" },
    { path: `/reporting-template/add`, heading: (!isEditMode) ? "Add Reporting Template" : "Edit Reporting Template" }]

    return (

        <React.Fragment>
            {pageLoader ?
                <PageLoader />
                :
                <div className="page-content">
                    <Breadcrumb title="Template" breadcrumbItem={breadcrumbItems} />
                    <Row>
                        <Col md={9}>
                            <form onSubmit={(e) => e.preventDefault()}>
                                {/* <div className="col d-flex justify-content-between align-items-end mb-3">
                            <div className="me-2"> {isEditMode && <h4>{templateDetails?.name}</h4>}</div>
                            <RHFButton btnName="Setting" icon="settings" onClick={() => handleToggle()} />
                        </div> */}
                                <DragDropContext
                                    onDragEnd={(props) => {
                                        const sourceIndex = props.source.index;
                                        const destinationIndex = props.destination.index;
                                        const listCopy = [...list];
                                        listCopy.splice(destinationIndex, 0, listCopy.splice(sourceIndex, 1)[0]);
                                        setList(listCopy)
                                    }}>
                                    <Card className="mb-3">
                                        <CardBody className='p-2'>
                                            <RHFTextField
                                                id="header"
                                                label="Header"
                                                name="header"
                                                errorobj={errors}
                                                control={control}
                                                isController={true}
                                            />
                                        </CardBody>
                                    </Card>

                                    <Card className="mb-3">
                                        <CardBody className='p-2'>
                                            <Row>
                                                <Col lg={6}>
                                                    <RHFTextField
                                                        id="marginLeft"
                                                        label="Margin Left"
                                                        name="marginLeft"
                                                        errorobj={errors}
                                                        control={control}
                                                        isController={true}
                                                    />
                                                </Col>
                                                <Col lg={6}>
                                                    <RHFTextField
                                                        id="marginRight"
                                                        label="Margin Right"
                                                        name="marginRight"
                                                        errorobj={errors}
                                                        control={control}
                                                        isController={true}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>

                                    <Card className="mb-3">
                                        <CardBody className='p-2'>
                                            <RHFDropZone
                                                name="logo"
                                                label="Select Logo"
                                                errorobj={errors}
                                                control={control}
                                                pocSetvalue={setValue}
                                                getFileData={getFileData}
                                                editPocData={templateDetails ? { images: [{ signedUrl: templateDetails?.logo, url: templateDetails?.logo }] } : null}
                                            />
                                        </CardBody>
                                    </Card>
                                    <Card className="mb-3">
                                        <CardBody className='p-2'>
                                            <RHFTextField
                                                id="name"
                                                label="Template Name"
                                                name="name"
                                                errorobj={errors}
                                                control={control}
                                                isController={true}
                                            />
                                        </CardBody>
                                    </Card>
                                    <UncontrolledAccordion>
                                        <Droppable droppableId="droppable-1">
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                    {list?.map((item, index) => (
                                                        <Card key={item.id} className="mb-3">
                                                            <CardBody className='p-0'>
                                                                <Draggable
                                                                    draggableId={"draggable-" + item.id}
                                                                    index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            className="drag-control-wrapper"
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            style={{
                                                                                ...provided.draggableProps.style,
                                                                                boxShadow: snapshot.isDragging
                                                                                    ? "0 0 .4rem #666"
                                                                                    : "none",
                                                                            }}
                                                                        >
                                                                            <div  {...provided.dragHandleProps}
                                                                                className="drag-handle" >
                                                                                <i className="bx bx-grid-vertical" ></i>
                                                                            </div>
                                                                            <AccordionItem key={item.id}>
                                                                                <AccordionHeader targetId={item.id}>
                                                                                    {item?.title}
                                                                                </AccordionHeader>
                                                                                {!['tableOfContents', 'vulenerabilityIndex'].includes(item?.name) &&
                                                                                    <AccordionBody accordionId={item.id}>
                                                                                        <Editor
                                                                                            sectionData={templateDetails && templateDetails?.[item?.name] || null}
                                                                                            title={item?.title}
                                                                                            name={item?.name}
                                                                                            errorobj={errors}
                                                                                            control={control}
                                                                                            isController={true}
                                                                                            setValue={setValue}
                                                                                            isEditMode={isEditMode}
                                                                                            details={templateDetails && templateDetails}
                                                                                            setSectionErrors={setSectionErrorNow}
                                                                                        />
                                                                                    </AccordionBody>
                                                                                }
                                                                            </AccordionItem>
                                                                            {sectionErrors && sectionErrors?.[item?.name] &&
                                                                                <FormFeedback className='ms-2  pb-1' type="invalid">{sectionErrors[item?.name]}</FormFeedback>
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            </CardBody>
                                                        </Card>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>

                                            )}
                                        </Droppable>
                                    </UncontrolledAccordion>

                                    <Card className="mb-3">
                                        <CardBody className='p-2'>
                                            <RHFTextField
                                                id="footer"
                                                label="Footer"
                                                name="footer"
                                                errorobj={errors}
                                                control={control}
                                                isController={true}
                                            />
                                        </CardBody>
                                    </Card>
                                </DragDropContext>
                                <div className="d-flex justify-content-end">
                                    <RHFButton
                                        className="mx-2"
                                        btnName="Cancel"
                                        outline={true}
                                        onClick={() => history.push('/reporting-template')}
                                    />
                                    <RHFButton
                                        btnName="Save"
                                        onClick={handleSubmit(onSubmitTemplate)}
                                        isLoading={isApiCalling}
                                    />
                                </div>
                            </form>
                        </Col>
                        <Col md={3}>
                            <Card>
                                <CardHeader className='p-2'>
                                    <div className='d-flex justify-content-between'>
                                        <h4 className="card-title mb-0">Template Parameters</h4>
                                    </div>
                                </CardHeader>
                                <CardBody className='p-2'>
                                    <div className="app-detail-wrapper">
                                        {TEMPLATE_PARAMETER_FEILDS?.map((item, index) =>
                                            <div className='app-detail' key={index}>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-text">{item}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        <p className='mb-0 mt-2 text-warning'>{'Please use the parameter as it displayed exactly along with $,{, and.}.'}</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <DialogBox
                        isModelOpen={isModelOpen}
                        handleToggle={handleToggle}
                        modelSize="sm-100"
                        title="Settings"
                        actions={null}
                    >
                        <Settings handleToggle={handleToggle} />
                    </DialogBox>
                </div>
            }
        </React.Fragment>
    )
}

export default TemplatesListAdd