import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { EMAIL_VERIFY, REGISTER_COMPANY, REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed, emailVerifySuccessful, emailVerifyFailed, registerCompanySuccess, registerCompanyFail } from "./actions"

import {
  onBoardCompany,
  registerNewUser,
  verifyUserEmail,
} from "../../../helpers/BackendHelper"
import { Toast } from "components/Common/Toaster"


// Is user register successfull then direct plot user in redux.
function* registerUser({ payload }) {
  try {
    const response = yield call(registerNewUser, payload)
    yield put(registerUserSuccessful(response))
    Toast.success(response.message)
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

/* Email Verification */
function* verifyEmail({ payload }) {
  try {
    const response = yield call(verifyUserEmail, payload)
    yield put(emailVerifySuccessful(response))

  } catch (error) {
    yield put(emailVerifyFailed(error))
  }
}

/** Register OR Onboarding new company */
function* companyOnBoarding({ payload }) {
  try {
    const response = yield call(onBoardCompany, payload)
    yield put(registerCompanySuccess(response))
    Toast.success(response.message)
  } catch (error) {
    yield put(registerCompanyFail(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
  yield takeEvery(EMAIL_VERIFY, verifyEmail)
  yield takeEvery(REGISTER_COMPANY, companyOnBoarding)
}

function* accountSaga() {
  yield all([fork(
    watchUserRegister,
    verifyEmail
  )])
}

export default accountSaga
