import React, { useState } from "react";
import Breadcrumb from "components/Common/Breadcrumb";
import ActionButtons from "components/FormControls/ActionButtons";
import RHFButton from "components/FormControls/RHFButton";
import DialogBox from "components/Modals/DialogBox";
import Table from "components/Tables/Table";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import defaultImage from "../../assets/images/Default-User-Image.jpeg";
import CompanyAddEdit from "./CompanyAddEdit";
import { isModulePermisssion } from "helpers/util";
import { ROLE_PERMISSIONS } from "constants/RolePermissions";
import { COMPANY } from "helpers/Services/Company";

const Company = () => {
  document.title = "Agency | RiDiscovery";
  const [editAgencyData, setEditAgencyData] = useState(null);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isRefresh, setRefresh] = useState(false);

  const handleToggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const columns = [
    {
      name: "Agency Name",
      cell: (row) => (
        <div className="d-flex">
          <img
            src={row?.companyLogo ? row?.companyLogo : defaultImage}
            className="rounded-circle me-2"
            width="35px"
            height="35px"
          />
          <span className="align-self-center">{row?.companyName}</span>
          {/* <span className="m-3">{row?.companyName}</span> */}
        </div>
      ),
      width: "30%",
    },
    {
      id: "type",
      name: "Type",
      cell: (row) => (
        <span>
          {
            ["development_agency_internal", "security_agency_internal"].includes(row?.companyType) ? 'Internal' :
              ["development_agency_external", "security_agency_external"].includes(row?.companyType) ? 'External' : '-'
          }
        </span>
      ),
      isVisible: true,
      width: "15%"
    },
    {
      id: "score",
      name: "Score",
      center: true,
      cell: (row) => (
        <span className="badge-soft-danger badge fs-6">{row?.score > "0" ? Number(row?.score).toFixed(2) : row?.score}</span>
      ),
      isVisible: true,
      width: "15%"
    },
    {
      id: "totalActiveUsers",
      name: "Active Users",
      center: true,
      cell: (row) => (
        <span>{row?.totalActiveUsers}</span>
      ),
      isVisible: true,
      width: "25%"
    },
    {
      name: "Edit",
      isVisible: true,
      omit: !isModulePermisssion(ROLE_PERMISSIONS?.ADD_UPDATE_AGENCY),
      cell: (row) => {
        return (
          <ActionButtons
            edit={
              isModulePermisssion(ROLE_PERMISSIONS?.ADD_UPDATE_AGENCY) &&
              {
                handleClick: () => {
                  handleToggle()
                  setEditAgencyData(row)
                }
              }
            }
          />
        );
      },
      width: "15%"
    },
  ];

  const breadcrumbItems = [{ path: "/", heading: "Home" },
  { path: "/agency", heading: "Agency List" }]

  return (
    <div className="page-content">
      <Breadcrumb title="Agency" breadcrumbItem={breadcrumbItems} />
      <Col xs={6} lg={12}>
        <div className="d-flex justify-content-end mb-3">
          {isModulePermisssion(ROLE_PERMISSIONS?.ADD_UPDATE_AGENCY) &&
            <RHFButton
              btnName="Add"
              icon="plus"
              onClick={() => {
                handleToggle();
                setEditAgencyData(null);
              }}
            />
          }
          <DialogBox
            isModelOpen={isModelOpen}
            handleToggle={handleToggle}
            modelSize="md"
            title={
              editAgencyData === null ? "Add Agency" : "Edit Agency"
            }
            actions={null}
          >
            <CompanyAddEdit
              editAgencyData={editAgencyData}
              handleToggle={handleToggle}
              setRefresh={setRefresh}
            />
          </DialogBox>
        </div>
      </Col>

      {isModulePermisssion(ROLE_PERMISSIONS?.VIEW_AGENCY_LIST) &&
        <Row className="g-3">
          <Col sm="6">
            <Card>
              <CardHeader >
                <h5 className="m-0">Development Agency </h5>
              </CardHeader>
              <CardBody>
                <Table
                  columns={columns}
                  dataURL={COMPANY.listAllCompany}
                  extra={{ type: ["development_agency_internal", "development_agency_external"] }}
                  isRefresh={isRefresh}
                />
              </CardBody></Card>
          </Col>
          <Col sm="6">
            <Card>
              <CardHeader>
                <h5 className="m-0">Security Agency </h5>
              </CardHeader>
              <CardBody>
                <Table
                  columns={columns}
                  dataURL={COMPANY.listAllCompany}
                  extra={{ type: ["security_agency_internal", "security_agency_external"] }}
                  isRefresh={isRefresh}
                />
              </CardBody></Card>
          </Col>
        </Row>
      }
    </div>

  );
};

export default Company;
