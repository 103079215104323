import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form } from "reactstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import * as yup from "yup";
import logo from "../../assets/images/RiDiscovery_Icon.png";
import RHFTextField from "components/FormControls/RHFTextField";
import RHFButton from "components/FormControls/RHFButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from "helpers/BackendHelper";

const ResetPasswordPage = () => {

  document.title = "Reset Password | RiDiscovery";
  const history = useHistory()
  const { token } = useParams();
  const [isApiCalling, setApiCalling] = useState(false);

  const ResetPasswordSchema = yup.object().shape({
    password: yup.string().trim().required("Password is required").matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character required."),
    confirmPassword: yup.string().required("Confirm Password is required").oneOf([yup.ref('password'), null], 'Password and Confirm Password must match'),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(ResetPasswordSchema)
  });

  const resetPasswordd = (values) => {
    const payload = { token: token, password: values?.password };
    setApiCalling(true);
    if (!isApiCalling) {
      resetPassword(payload).then(resp => {
        setApiCalling(false);
        if (resp?.status) {
          history.push('/login')
          Toast.success(resp.message)
          reset();
        }
      }).catch(() => {
        setApiCalling(false);
      });
    }
  };

  return (
    <React.Fragment>
      <div className="auth-page">

        <Row className="g-0">
          <Col lg={4} md={5} className="col-xxl-3">
            <div className="auth-full-page-content d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5 text-center">
                    <span className="d-block auth-logo">
                      <img src={logo} alt="" height="35" />
                      <span className="logo-txt">RiDiscovery</span>
                    </span>
                  </div>
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      <h3 className="mb-0">Reset Password</h3>
                      <p className="text-muted mt-2">Reset Password with RiDiscovery.</p>
                    </div>


                    <Form
                      className="custom-form mt-4"
                      onSubmit={handleSubmit(resetPasswordd)}
                    >
                      <div className="mb-3">
                        <RHFTextField
                          id="password"
                          label="Password"
                          name="password"
                          type="password"
                          errorobj={errors}
                          control={control}
                          isController={true}
                        />
                      </div>
                      <div className="mb-3">
                        <RHFTextField
                          id="confirmPassword"
                          label="Confirm Password"
                          name="confirmPassword"
                          type="password"
                          errorobj={errors}
                          control={control}
                          isController={true}
                        />
                      </div>

                      <Row className="mb-3">
                        <Col>
                          <div className="mt-3 d-grid">
                            <RHFButton btnName="Reset Password" type="submit" isLoading={isApiCalling} />
                          </div>
                        </Col>
                      </Row>

                    </Form>

                    <div className="mt-4 text-center">
                      <p className="text-muted mb-0">Remember It ?
                        <Link to="/tenant/login" className="text-primary fw-semibold"> Login</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

      </div>
    </React.Fragment >
  )
}

ResetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default ResetPasswordPage;