import React, { useEffect } from 'react';
import PageLoader from 'components/PageLoader';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getLoginUserDetail, getPermission } from 'store/actions';

const LandingPage = () => {
    const { user, permissions } = useSelector(state => state?.Login);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            if (!user) {
                dispatch(getLoginUserDetail());
            }
        }
    }, []);

    useEffect(() => {
        if (user?.user?.roleId) {
            const params = {
                slug: user?.user?.roleId
            }
            dispatch(getPermission({ ...params }));
        }
    }, [user]);

    useEffect(() => {
        if (permissions && user?.user?.roleId) {
            if (['developer', 'pentester', 'da_manager'].includes(user?.user?.roleId)) {
                history.push("/applications")
            } else {
                history.push("/dashboard")
            }
        }
    }, [permissions, user?.user?.roleId])

    return (
        !permissions ?
            <PageLoader />
            :
            null
    )
}

export default LandingPage