import React, { useEffect, useRef, useState } from 'react'
import { Card, CardBody, CardText, Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { DateRangePicker } from 'react-date-range'
import { addDays } from 'date-fns';
import moment from 'moment/moment'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import RHFButton from 'components/FormControls/RHFButton';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBu } from 'store/bu/actions';
import DropdownButton from 'components/FormControls/DropdownButton';
import LineChart from 'components/Common/Graphs/LineChart';
import PieChart from 'components/Common/Graphs/PieChart';
import ColumnChart from 'components/Common/Graphs/ColumnChart';
import BarChart from 'components/Common/Graphs/BarChart';
import PageLoader from 'components/PageLoader';
import { getCVETrendsChartData, getOSesSummaryChartData } from 'helpers/BackendHelper';
import TimelineButton from 'components/FormControls/TimelineButton';
;


const AssetSummary = () => {
    const dispatch = useDispatch();
    const ref = useRef();
    const { bussinessUnit } = useSelector(state => state?.bu);
    const [pageLoader, setPageLoader] = useState(false);
    const [filterFields, setFilterData] = useState({
        'buId': null,
        'from': null,
        'to': null
    });
    const [isTimelineModalOpen, setIsTimelineModalOpen] = useState(false);
    const [timelineData, setTimelineData] = useState({})
    const [timeline, setTimeline] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection'
        }
    ]);

    const [osSummaryData, setOSSummaryData] = useState({
        osSummaryPieChartOSNameData: null,
        osSummaryPieChartOSCountData: null,
        osSummaryPieChartOSLineScoreData: null
    })

    const [cveTrendsChartData, setcveTrendsChartData] = useState({
        cveTrendsBarChartSeriesData: null,
        cveTrendsBarChartXAxisData: null
    })

    const setFilterValues = (field) => {
        setFilterData(prev => {
            return {
                ...prev,
                ...field
            }
        })
    }

    useEffect(() => {
        dispatch(getAllBu());
    }, []);

    const handleTimelineChange = () => {
        setFilterData((prevValue) => {
            const prev = {
                ...prevValue,
            };

            const fromDate = moment(timelineData?.startDate).format('YYYY-MM-DD')
            const endDate = moment(timelineData?.endDate).format('YYYY-MM-DD')

            prev['from'] = fromDate;
            prev['to'] = endDate;
            return prev;
        });

        setTimeline([{
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection'
        }])
        setIsTimelineModalOpen(false);
    }

    const toggle = () => {
        setIsTimelineModalOpen(!isTimelineModalOpen);
    }

    const clearAllFilters = () => {
        setFilterData({ 'buId': null, 'from': null, 'to': null });
        setTimeline([{
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection'
        }])
        setIsTimelineModalOpen(false);
    }

    useEffect(() => {
        const payload = filterFields;
        setPageLoader(true);
        Promise.all([
            getOSesSummaryChartData(payload),
            getCVETrendsChartData(payload)
        ]).then(([osSummaryChartResp, cveTrendsChartResp]) => {
            const osSummaryOSName = osSummaryChartResp?.data?.VulnOses?.map(item => item.os)
            const osSummaryOSCount = osSummaryChartResp?.data?.VulnOses?.map(item => item.count).map(Number)
            const osSummaryOSLineScore = osSummaryChartResp?.data?.scoreChart?.map(item => [item.date, item.avgScore]);
            setOSSummaryData({
                osSummaryPieChartOSNameData: osSummaryOSName,
                osSummaryPieChartOSCountData: osSummaryOSCount,
                osSummaryPieChartOSLineScoreData: osSummaryOSLineScore
            })

            const cveTrendsData = cveTrendsChartResp?.data?.map(item => item.count).map(Number)
            const cveTrendsXAxisDetail = cveTrendsChartResp?.data?.map(item => item?.cve)

            setcveTrendsChartData({
                cveTrendsBarChartSeriesData: cveTrendsData,
                cveTrendsBarChartXAxisData: cveTrendsXAxisDetail
            })
            setPageLoader(false);
        }).catch(() => {
            setPageLoader(false);
        })
    }, [filterFields])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setIsTimelineModalOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    return (
        <React.Fragment>
            <Row className="mb-2 g-3">
                <Col className="align-self-center">
                    <h5 className="card-title m-0">OSes Summary</h5>
                </Col>
                <Col>
                    <div className='d-flex justify-content-end'>
                        <div className='me-2'>
                            <RHFButton
                                btnName="Clear Filter"
                                outline={true}
                                icon="filter"
                                onClick={() => clearAllFilters()}
                            />
                        </div>
                        <div className='me-2'>
                            <DropdownButton
                                heading="Select BU"
                                isSearchable={true}
                                menuItems={bussinessUnit ? bussinessUnit?.data : []}
                                handleClick={(item) => setFilterValues({ buId: item?.id })}
                                filterFields={filterFields}
                            />
                        </div>
                        <div ref={ref}>
                            <TimelineButton
                                toggle={toggle}
                                timeline={timeline}
                                setTimeline={setTimeline}
                                setTimelineData={setTimelineData}
                                handleTimelineChange={handleTimelineChange}
                                isTimelineModalOpen={isTimelineModalOpen}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            {pageLoader ?
                <PageLoader />
                :
                <>
                    <Row className="mb-2 g-3">
                        <Col sm="6">
                            <Card className="m-0 h-100">
                                <CardBody>
                                    <div className='d-flex justify-content-center'>
                                        <div>
                                            <CardText className="text-center">Vuln Oses</CardText>
                                            <PieChart
                                                pieSeries={osSummaryData?.osSummaryPieChartOSCountData}
                                                pieColors={["#3A7ABF", "#7ECA9C", "#F38B8B", "#F3A031", "#EF1A04"]}
                                                pieLables={osSummaryData?.osSummaryPieChartOSNameData}
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <Card className="m-0 h-100">
                                <CardBody>
                                    <CardText>Assets</CardText>
                                    <LineChart
                                        height="170"
                                        seriesTitle='Score'
                                        seriesData={osSummaryData?.osSummaryPieChartOSLineScoreData}
                                        lineColor={["#F00806"]} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    {/* SECTION 2 */}
                    <Row className="mb-2 g-3">
                        <Col className="align-self-center">
                            <h5 className="card-title m-0">CVE - Trends</h5>
                        </Col>
                    </Row>
                    <Row className="mb-2 g-3">
                        <Col sm="6">
                            <Card className="m-0 h-100">
                                <CardBody>
                                    <div className='d-flex justify-content-center'>
                                        <div>
                                            <CardText className="text-center">Network CVE Top 10</CardText>
                                            <PieChart
                                                pieSeries={cveTrendsChartData?.cveTrendsBarChartSeriesData}
                                                pieColors={["#F5CA3B", "#F1595E", "#8AC926", "#3D82C4", "#6A4C93", "#4CBCCD", "#9A0B1E", "#5F0F40", "#F17F01"]}
                                                pieLables={cveTrendsChartData?.cveTrendsBarChartXAxisData}
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <Card className="m-0 h-100">
                                <CardBody>
                                    <div>
                                        <CardText>Top 5%</CardText>
                                        <LineChart
                                            height="170"
                                            seriesTitle='Score'
                                            seriesData={osSummaryData?.osSummaryPieChartOSLineScoreData}
                                            lineColor={["#F4B315"]} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    {/* SECTION 3 */}

                    <Row className="mb-2 g-3">
                        <Col sm="6">
                            <div className="mb-2">
                                <div className="align-self-center">
                                    <h5 className="card-title m-0">Supply Chain</h5>
                                </div>
                            </div>
                            <Card className="m-0 h-100">
                                <CardBody>
                                    <div>
                                        <CardText>Vulnerability By Severity</CardText>
                                        <ColumnChart />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <div className="mb-2">
                                <div className="align-self-center">
                                    <h5 className="card-title m-0">CVE Trends</h5>
                                </div>
                            </div>
                            <Card className="m-0 h-100">
                                <CardBody>
                                    <div>
                                        <CardText>Apps - Top 10%</CardText>
                                        <BarChart
                                            seriesData={cveTrendsChartData?.cveTrendsBarChartSeriesData}
                                            xAxisCategoryData={cveTrendsChartData?.cveTrendsBarChartXAxisData}
                                            barColors={["#D62828", "#9A0B1E", "#5F0F40", "#F17F01", "#E07A5E", "#F28482", "#F5BD60", "#84A59D", "#F9D9B7", "#F5CAC3"]}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>
            }
        </React.Fragment>
    )
}

export default AssetSummary
