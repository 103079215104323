
import {
    ADD_USER, GET_ALL_USER, GET_USER_BY_ROLE,
    ACTIVE_DEACTIVE_USER, GET_LOGIN_USER_DETAILS, USER_UPDATE, USER_PROFILE_UPLOAD, GET_USER_TEAM, GET_APP_MEMBER_BY_TYPE, GET_SIGNED_URL, GET_USER_DETAIL, GET_ALL_PENDING_USERS, APPROVE_REJECT_USER
} from "helpers/UrlHelper";

import { API_URL } from "./Services";

const baseURL = API_URL.USER;

export const USER = {
    addUser: {
        method: 'POST',
        url: ADD_USER,
        baseURL
    },
    editUser: {
        method: 'PUT',
        url: USER_UPDATE,
        baseURL
    },
    listAllUser: {
        method: 'POST',
        url: GET_ALL_USER,
        baseURL
    },
    getUserByRole: {
        method: 'POST',
        url: GET_USER_BY_ROLE,
        baseURL
    },
    activeDeactiveUser: {
        method: 'PUT',
        url: ACTIVE_DEACTIVE_USER,
        baseURL
    },
    getLoginUser: {
        method: 'GET',
        url: GET_LOGIN_USER_DETAILS,
        baseURL
    },
    profileUpload: {
        method: 'POST',
        url: USER_PROFILE_UPLOAD,
        baseURL
    },
    getSignedUrl: {
        method: 'POST',
        url: GET_SIGNED_URL,
        baseURL
    },
    getUserTeam: {
        method: 'POST',
        url: GET_USER_TEAM,
        baseURL
    },
    getAppMemberByType: {
        method: 'POST',
        url: GET_APP_MEMBER_BY_TYPE,
        baseURL
    },
    getUserDetail: {
        method: 'GET',
        url: GET_USER_DETAIL,
        baseURL
    },
    listAllPendingUsers: {
        method: 'POST',
        url: GET_ALL_PENDING_USERS,
        baseURL
    },
    approveRejectUser: {
        method: 'PUT',
        url: APPROVE_REJECT_USER,
        baseURL
    }
}