import React from 'react'
import ReactApexChart from 'react-apexcharts'

const CircleChart = (props) => {
    const { seriesData = [], CircleChartColorsCode = [], } = props

    const CircleChartColors = CircleChartColorsCode
    const series = seriesData
    var options = {
        chart: {
            height: 350,
            type: 'radialBar',
            offsetY: -40
        },
        colors: [CircleChartColors[0]],
        plotOptions: {
            radialBar: {
                dataLabels: {
                    show: true,
                    name: {
                        show: false
                    },
                    value: {
                        offsetY: 10,
                        fontSize: '18px',
                        color: 'black',
                        formatter: function (val) {
                            return (val / 10).toFixed(1)
                        }
                    }
                },
                hollow: {
                    margin: 0,
                    size: '30%',
                },
                track: {
                    background: [CircleChartColors[1]],
                    strokeWidth: '100%',
                },
            },
        },
        labels: ['Score'],
        tooltip: {
            enabled: true,
            y: {
                formatter: function (val) {
                    return val.toFixed(1);
                },
            },
        }
    };


    return (
        <ReactApexChart
            options={options}
            series={series}
            type="radialBar"
            height="320"
            className="apex-charts"
        />
    )
}

export default CircleChart
