import React, { Fragment } from "react";
import { FormFeedback, Label } from "reactstrap";
import Select from "react-select";
import { Controller } from "react-hook-form";
import RHFDisableField from "./RHFDisableField";

const RHFAutoCompleteSelect = ({
  id,
  name,
  label,
  placeholder = "Select",
  isController = true,
  control,
  defaultValue = null,
  options,
  errorobj,
  onChange,
  limitTags = -1,
  handleOnChange,
  isRequired = true,
  isClearable = true,
  isSearchable = true,
  isLoading = false,
  isDisabled = false,
  isMultiple = false,
  isTableDropDown = false,
  disabledField = false,
  disabledText = '',
  value,
  inputNote = null,
  ...props
}) => {
  let isError = false;
  let errorMessage = "";
  let multiSelect = false;
  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: ((isError && state.isFocused) || isError)
        ? 'red'
        : base.borderColor,
      '&:hover': {
        borderColor: ((isError && state.isFocused) || isError)
          ? 'red'
          : base.borderColor,
      }
    })
  };

  if (typeof isMultiple === "undefined" || isMultiple) {
    multiSelect = true;
  }
  const disabled = isDisabled === undefined ? false : isDisabled;

  if (errorobj && errorobj[name]) {
    isError = true;
    errorMessage = errorobj[name].message;
  }

  if (!isController) {
    return (
      <Fragment>
        <div className={isTableDropDown ? "d-flex align-items-center" : ""}>
          {label && <Label htmlFor="select-input" className="form-label">
            {label}
          </Label>}
          {!disabledField ?
            <Select
              {...props}
              placeholder={placeholder}
              className={!isTableDropDown ? "react-select-container" : "react-select-container-table"}
              isMulti={multiSelect}
              id={id}
              name={name}
              value={value}
              isClearable={isClearable}
              isSearchable={isSearchable}
              isDisabled={disabled}
              isLoading={isLoading}
              options={options}
              defaultValue={defaultValue}
              classNamePrefix="react-select"
              menuShouldBlockScroll={true}
              menuPosition="fixed"
              // isOptionDisabled={(option) => option.value === 'true_vulnerability'}
              onChange={(data) => {
                if (handleOnChange) {
                  handleOnChange(
                    data?.value,
                    name,
                    id,
                    data
                  ); /* You must pass this function while isController is false -> else you will not get selected values */
                }
              }}
            />
            : <RHFDisableField fieldValue={disabledText}></RHFDisableField>
          }
        </div>
      </Fragment >
    );
  }

  return (
    <>
      <Controller
        id={id || name}
        name={name}
        control={control}
        defaultValue={defaultValue || []}
        render={({ field }) => {
          let filteredOptionList = [...options];
          if (multiSelect && field?.value?.length) {
            const selectedValueArr = field.value.map((result) => result.value);
            filteredOptionList = filteredOptionList.filter(
              (item) => selectedValueArr.indexOf(item.value) === -1
            );
          }
          return (
            <Fragment>
              <Label htmlFor="select-input" className="form-label">
                {label} {isRequired && <span className="text-danger">*</span>}
              </Label>
              {!disabledField ?
                <Select
                  {...field}
                  {...props}
                  options={filteredOptionList}
                  id={id}
                  name={name}
                  placeholder={placeholder}
                  className="react-select-container"
                  isMulti={multiSelect}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  isDisabled={disabled}
                  isLoading={isLoading}
                  defaultValue={defaultValue}
                  classNamePrefix="react-select"
                  onChange={(data, action) => {
                    field.onChange(data);
                    if (handleOnChange) {
                      handleOnChange(data, name, action);
                    }
                  }}
                  styles={customStyles}
                /> :
                <RHFDisableField fieldValue={field.value?.label}></RHFDisableField>
              }
            </Fragment>
          );
        }}
      // className={multiSelect ? "basic-multi-select" : "basic-single"}
      />
      {inputNote && <small>{inputNote}</small>}
      {isError && !disabledField && <FormFeedback type="invalid">{errorMessage}</FormFeedback>}
    </>
  );
};

export default RHFAutoCompleteSelect;
