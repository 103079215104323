import { useEffect, useReducer, Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import PageSection from "components/Pdf/Template/PageSection";
import PocImageSection from "components/Pdf/Template/PocImageSection";
import { TemplateFields } from "constants/Constant";
import { htmlSanitizer, sortableObject } from "helpers/util";
import moment from "moment";
import { getApplicationReport } from "helpers/BackendHelper";
import PageLoader from "components/PageLoader";
import './TemplatePDFStyle.scss';
import { renderToString } from 'react-dom/server'


let lastCount = 0;
const uniqueKey = (prefix = "key") => {
    lastCount++;
    return `${prefix}${lastCount}`;
};
const initialState = {
    isLoading: false,
    content: null,
    sortedList: [],
    error: null,
};

const pdfContentReducer = (state, { type, payload }) => {
    switch (type) {
        case "GET_PDF_CONTENT_PENDING":
            return { ...state, isLoading: true };
        case "GET_PDF_CONTENT_SUCCESS":
            return {
                ...state,
                isLoading: false,
                content: payload,
                reportSections: Object.keys(sortableObject(payload?.template?.sequence)),
            };
        case "GET_PDF_CONTENT_FAILED":
            return { ...state, isLoading: false, error: payload };
        default:
            return state;
    }
};

const TemplatePDF = () => {
    const [pdfContent, dispatchPdfContent] = useReducer(
        pdfContentReducer,
        initialState
    );
    const { applicationId, token } = useParams();

    useEffect(() => {
        if (pdfContent.content === null && !!applicationId) {
            (async () => {
                try {
                    dispatchPdfContent({ type: "GET_PDF_CONTENT_PENDING" });
                    getApplicationReport({ id: applicationId }, token).then(reponse => {
                        dispatchPdfContent({
                            type: "GET_PDF_CONTENT_SUCCESS",
                            payload: reponse,
                        });
                    }).catch(error => {
                        dispatchPdfContent({
                            type: "GET_PDF_CONTENT_FAILED",
                            payload: error.message,
                        });
                    });
                } catch (error) {
                    dispatchPdfContent({
                        type: "GET_PDF_CONTENT_FAILED",
                        payload: error.message,
                    });
                }
            })();
        }
    }, [applicationId, pdfContent.content]);


    // if (!!pdfContent.isLoading) return <PageLoader />;
    if (!!pdfContent.error)
        return <h2 className="text-center mt-5">Something went wrong</h2>;

    function interpolateTemplate(template, args) {
        return Object.entries(args).reduce((result, [arg, val]) => {
            // return result.replace(`$\{${arg}}`, `${val}`);
            return result.replaceAll(`$\{${arg}}`, renderToString(val))
        }, template);
    }

    // const getScopeDoc = (str, data) => {
    //     // inscope
    //     let htmlData = new DOMParser().parseFromString(str, "text/html");

    //     let inScopeTable = htmlData.getElementById("inscope");
    //     let tableTbody = inScopeTable.getElementsByTagName("tbody")[0];
    //     let tableRow = tableTbody.getElementsByTagName("tr");
    //     let row = tableRow[0].outerHTML;
    //     data?.scope?.map((s) => {
    //         const key = Object.keys(s)[0];
    //         const value = s[key];

    //         let rowData = interpolateTemplate(`${row}`, {
    //             scopeKey: key,
    //             scopeValue: value,
    //         });
    //         const existingHtml = tableTbody.innerHTML;
    //         tableTbody.innerHTML = existingHtml.concat(rowData);
    //     });
    //     tableTbody.removeChild(tableRow[0]);

    //     // outofScope
    //     let outOfScopeTable = htmlData.getElementById("outofscope");
    //     let outOfScopeTableTbody = outOfScopeTable.getElementsByTagName("tbody")[0];
    //     let outOfScopeTableRow = outOfScopeTableTbody.getElementsByTagName("tr");
    //     let row2 = outOfScopeTableRow[0].outerHTML;
    //     data?.outOfScope?.map((s) => {
    //         const key = Object.keys(s)[0];
    //         const value = s[key];

    //         let rowData = interpolateTemplate(`${row2}`, {
    //             outofScopeKey: key,
    //             outofScopeValue: value,
    //         });
    //         const existingHtml = outOfScopeTableTbody.innerHTML;
    //         outOfScopeTableTbody.innerHTML = existingHtml.concat(rowData);
    //     });
    //     outOfScopeTableTbody.removeChild(outOfScopeTableRow[0]);
    //     return htmlData.body.outerHTML;
    // };

    const getSeverityCell = (score) => {
        if (score == 0.0) {
            return (
                <td className="infomative text-white fs-5 fw-bold text-center" width="10%">
                    Informative
                </td>
            );
        } else if (score >= 0.1 && score <= 3.9) {
            return (
                <td className="low text-white fs-5 fw-bold text-center" width="10% ">
                    Low
                </td >
            );
        } else if (score >= 4.0 && score < 6.9) {
            return (
                <td className="medium text-dark fs-5 fw-bold text-center" width="10%">
                    Medium
                </td>
            );
        } else if (score >= 7.0 && score < 8.9) {
            return (
                <td className="high text-white fs-5 fw-bold text-center" width="10%">
                    High
                </td>
            );
        } else if (score >= 9.0 && score <= 10.0) {
            return (
                <td className="critical text-white fs-5 fw-bold text-center" width="10%">
                    Critical
                </td>
            );
        }


    };
    // text-white fs-5 fw-bold
    const getPosition = () => { };

    const getSectionName = (key, i) => {
        switch (key) {
            case "tableOfContents":
                return (
                    <li className="pageLink">
                        <a href="#tableOfContents">
                            {i + 1}. Table of Contents {getPosition("tableOfContents")}
                        </a>
                    </li>
                );
            case "purpose":
                return (
                    <li className="pageLink">
                        <a href="#purpose">
                            {i + 1}. Purpose {getPosition("purpose")}
                        </a>
                    </li>
                );
            case "confidentiality":
                return (
                    <li className="pageLink">
                        <a href="#confidentiality">
                            {i + 1}. Confidentiality {getPosition("confidentiality")}
                        </a>
                    </li>
                );
            case "disclaimers":
                return (
                    <li className="pageLink">
                        <a href="#disclaimers">
                            {i + 1}. Disclaimers {getPosition("disclaimers")}
                        </a>
                    </li>
                );
            case "documentAuthorization":
                return (
                    <li className="pageLink">
                        <a href="#documentAuthorization">
                            {i + 1}. Document Authorization{" "}
                            {getPosition("documentAuthorization")}
                        </a>
                    </li>
                );
            case "assesmentInformation":
                return (
                    <li className="pageLink">
                        <a href="#assesmentInformation">
                            {i + 1}. Assessment Information{" "}
                            {getPosition("assesmentInformation")}
                        </a>
                    </li>
                );
            case "scope":
                return (
                    <li className="pageLink">
                        <a href="#scope">
                            {i + 1}. Scope {getPosition("scope")}
                        </a>
                    </li>
                );
            case "vulenerabilityIndex":
                return (
                    <li className="pageLink">
                        <a href="#vulenerabilityIndex">
                            {i + 1}. Vulenerability Index {getPosition("vulenerabilityIndex")}
                        </a>
                    </li>
                );
            case "appendices":
                return (
                    <li className="pageLink">
                        <a href="#appendices">
                            {i + 1}. Appendices {getPosition("appendices")}
                        </a>
                    </li>
                );
            case "appendixBUsedTools":
                return (
                    <li className="pageLink">
                        <a href="#appendixBUsedTools">
                            {i + 1}. Appendices B Used Tools{" "}
                            {getPosition("appendixBUsedTools")}
                        </a>
                    </li>
                );
            default:
                break;
        }
    };

    const renderInScopeData = () => {
        return <table className="table table-bordered border-dark">
            <tbody id='inScope'>
                <tr>
                    <th
                        className="infomative text-white fs-5"
                        colSpan={2}
                    >
                        In Scope
                    </th>
                </tr>
                {pdfContent?.content?.kickoff?.scopeDetails?.map((data, i) => (
                    <tr key={i}>
                        <td width="50%">{Object.keys(data)[0]}</td>
                        <td width="50%">{Object.values(data)[0]}</td>
                    </tr>
                ))}
            </tbody>
        </table >
    }

    const rendeOutOfScopeData = () => {
        return <table className="table table-bordered border-dark">
            <tbody id='inScope'>
                <tr>
                    <th
                        className="infomative text-white fs-5"
                        colSpan={2}
                    >
                        Out Of Scope
                    </th>
                </tr>
                {pdfContent?.content?.kickoff?.outOfScope?.map((data, i) => (
                    <tr key={i}>
                        <td width="50%">{Object.keys(data)[0]}</td>
                        <td width="50%">{Object.values(data)[0]}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    }

    const rendeAppDetails = () => {
        return <table className="table table-bordered border-dark">
            <tbody id='inScope'>
                <tr>
                    <th
                        className="infomative text-white fs-5"
                        colSpan={2}
                    >
                        Application Details
                    </th>
                </tr>
                {pdfContent?.content?.kickoff?.applicationDetails?.map((data, i) => (
                    <tr key={i}>
                        <td width="50%">{Object.keys(data)[0]}</td>
                        <td width="50%">{Object.values(data)[0]}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    }

    const rendeServerDetails = () => {
        return <table className="table table-bordered border-dark">
            <tbody id='inScope'>
                <tr>
                    <th
                        className="infomative text-white fs-5"
                        colSpan={2}
                    >
                        Server Details
                    </th>
                </tr>
                {pdfContent?.content?.kickoff?.serverDetails?.map((data, i) => (
                    <tr key={i}>
                        <td width="50%">{Object.keys(data)[0]}</td>
                        <td width="50%">{Object.values(data)[0]}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    }

    const rendeUserDetails = () => {
        return <table className="table table-bordered border-dark">
            <tbody id='inScope'>
                <tr>
                    <th
                        className="infomative text-white fs-5"
                        colSpan={2}
                    >
                        User Details
                    </th>
                </tr>
                {pdfContent?.content?.kickoff?.userDetails?.map((data, i) => (
                    <tr key={i}>
                        <td width="50%">{Object.keys(data)[0]}</td>
                        <td width="50%">{Object.values(data)[0]}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    }

    return (
        !pdfContent.isLoading &&
        pdfContent.content !== null && (
            <div
                style={{
                    width: "210mm",
                }}
                id="application-pdf"
                className="application-pdf d-flex mx-auto"
            >
                <div style={{ height: "100%" }}>
                    <div style={{ minHeight: "297mm", backgroundColor: '#fff' }} className="d-flex align-items-center justify-content-center">
                        <div className=" text-center">
                            <img
                                className="mt-4"
                                src={pdfContent?.content?.template.logo}
                                alt="logo"
                                height="220"
                            />
                            <h3 className="display-4 mt-3 text-center">Web Application</h3>
                            <h3 className="display-4 mt-3 text-center">Security</h3>
                            <h3 className="display-4 mt-3 text-center">Assessment</h3>
                        </div>
                    </div>
                    {/* <div className="page-header d-flex flex-row justify-content-between align-items-center py-2" style={{ width: '210mm' }}>
                        <h5>{pdfContent?.content?.template?.header}</h5>
                        <img style={{ width: '70px' }} src={pdfContent?.content?.template?.logo} />
                    </div>
*/}
                    <div className="page-footer" style={{ width: '210mm' }}>
                        <h5>{pdfContent?.content?.template?.footer}</h5>
                    </div>
                    <div className="table-breaked">
                        <table className="table border-dark v-table">
                            <thead>
                                <tr className="no-border">
                                    <td className="no-border">
                                        <div className="page-header d-flex flex-row justify-content-between align-items-center py-2" style={{ width: '210mm' }}>
                                            <h5>{pdfContent?.content?.template?.header}</h5>
                                            <img style={{ width: '70px' }} src={pdfContent?.content?.template?.logo} />
                                        </div>
                                    </td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr className="no-border">
                                    <td className="no-border">
                                        <div> {pdfContent?.content !== null &&
                                            pdfContent.reportSections.map((reportSections, i) => {
                                                return (
                                                    <Fragment key={`${reportSections}`}>
                                                        {
                                                            ["tableOfContents", "scope", "purpose", "confidentiality", "disclaimers", "documentAuthorization",
                                                                "assesmentInformation", "appendices", "appendixBUsedTools", "vulenerabilityIndex"].includes(reportSections) &&
                                                            i !== 0 && <div style={{ pageBreakAfter: "always" }}> </div>
                                                        }
                                                        {reportSections === "tableOfContents" && (
                                                            <>
                                                                <PageSection
                                                                    title={TemplateFields[reportSections]}
                                                                    pageId={reportSections}
                                                                    index={i}
                                                                    marginLeft={pdfContent?.content?.template?.marginLeft}
                                                                    marginRight={pdfContent?.content?.template?.marginRight}
                                                                >
                                                                    <div>
                                                                        <ol>
                                                                            {pdfContent.reportSections.map((section, i) => {
                                                                                return getSectionName(section, i);
                                                                            })}
                                                                        </ol>
                                                                    </div>
                                                                </PageSection>
                                                            </>
                                                        )}
                                                        {reportSections === "scope" && (
                                                            <>
                                                                <PageSection
                                                                    title={TemplateFields[reportSections]}
                                                                    pageId={reportSections}
                                                                    index={i}
                                                                    marginLeft={pdfContent?.content?.template?.marginLeft}
                                                                    marginRight={pdfContent?.content?.template?.marginRight}
                                                                >
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: htmlSanitizer(interpolateTemplate(
                                                                                pdfContent.content.template[reportSections], {
                                                                                inScope: pdfContent?.content?.kickoff?.scopeDetails?.length > 0 ? renderInScopeData() : null,
                                                                                outOfScope: pdfContent?.content?.kickoff?.outOfScope?.length > 0 ? rendeOutOfScopeData() : null,
                                                                                serverDetails: pdfContent?.content?.kickoff?.applicationDetails?.length > 0 ? rendeAppDetails() : null,
                                                                                applicationDetails: pdfContent?.content?.kickoff?.serverDetails?.length > 0 ? rendeServerDetails() : null,
                                                                                userDetails: pdfContent?.content?.kickoff?.userDetails?.length > 0 ? rendeUserDetails() : null,
                                                                                applicationName: pdfContent?.content?.application?.applicationName,
                                                                            }))
                                                                        }} >
                                                                    </div>
                                                                </PageSection>

                                                            </>
                                                        )
                                                        }
                                                        {reportSections === "purpose" && (
                                                            <>
                                                                <PageSection
                                                                    title={TemplateFields[reportSections]}
                                                                    pageId={reportSections}
                                                                    index={i}
                                                                    marginLeft={pdfContent?.content?.template?.marginLeft}
                                                                    marginRight={pdfContent?.content?.template?.marginRight}
                                                                >
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: htmlSanitizer(interpolateTemplate(
                                                                                pdfContent.content.template[reportSections],
                                                                                {
                                                                                    applicationName: pdfContent?.content?.application
                                                                                        ?.applicationName,
                                                                                    assessmentType:
                                                                                        pdfContent?.content?.application
                                                                                            ?.assessmentType,
                                                                                    environment:
                                                                                        pdfContent?.content?.kickoff?.environment,
                                                                                    applicationType:
                                                                                        pdfContent?.content?.application
                                                                                            ?.applicationType,
                                                                                    reportDate: moment(new Date()).format("YYYY-MM-DD"),
                                                                                    publishDate: moment(new Date()).format("YYYY-MM-DD"),
                                                                                    assessmentId:
                                                                                        pdfContent?.content?.application?.penId,
                                                                                    reportId: `RPT_${pdfContent?.content?.application?.penId}`,

                                                                                    lastModifyBy: pdfContent?.content?.application?.lastModifyBy,
                                                                                    lastModifyDate: moment(pdfContent?.content?.application?.updatedAt).format("YYYY-MM-DD"),
                                                                                    lastReviewDate: moment(pdfContent?.content?.application?.updatedAt).format("YYYY-MM-DD")
                                                                                }
                                                                            )),
                                                                        }}
                                                                    />
                                                                </PageSection>

                                                            </>
                                                        )}
                                                        {reportSections === "confidentiality" && (
                                                            <>
                                                                <PageSection
                                                                    title={TemplateFields[reportSections]}
                                                                    pageId={reportSections}
                                                                    index={i}
                                                                    marginLeft={pdfContent?.content?.template?.marginLeft}
                                                                    marginRight={pdfContent?.content?.template?.marginRight}
                                                                >
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: htmlSanitizer(interpolateTemplate(
                                                                                pdfContent.content.template[reportSections],
                                                                                {
                                                                                    applicationName: pdfContent?.content?.application
                                                                                        ?.applicationName,
                                                                                    assessmentType:
                                                                                        pdfContent?.content?.application
                                                                                            ?.assessmentType,
                                                                                    environment:
                                                                                        pdfContent?.content?.kickoff?.environment,
                                                                                    applicationType:
                                                                                        pdfContent?.content?.application
                                                                                            ?.applicationType,
                                                                                    reportDate: moment(new Date()).format("YYYY-MM-DD"),
                                                                                    publishDate: moment(new Date()).format("YYYY-MM-DD"),
                                                                                    assessmentId:
                                                                                        pdfContent?.content?.application?.penId,
                                                                                    reportId: `RPT_${pdfContent?.content?.application?.penId}`,

                                                                                    lastModifyBy: pdfContent?.content?.application?.lastModifyBy,
                                                                                    lastModifyDate: moment(pdfContent?.content?.application?.updatedAt).format("YYYY-MM-DD"),
                                                                                    lastReviewDate: moment(pdfContent?.content?.application?.updatedAt).format("YYYY-MM-DD")
                                                                                }
                                                                            )),
                                                                        }}
                                                                    />
                                                                </PageSection>

                                                            </>
                                                        )}
                                                        {reportSections === "disclaimers" && (
                                                            <>
                                                                <PageSection
                                                                    title={TemplateFields[reportSections]}
                                                                    pageId={reportSections}
                                                                    index={i}
                                                                    marginLeft={pdfContent?.content?.template?.marginLeft}
                                                                    marginRight={pdfContent?.content?.template?.marginRight}
                                                                >
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: htmlSanitizer(interpolateTemplate(
                                                                                pdfContent.content.template[reportSections],
                                                                                {
                                                                                    applicationName: pdfContent?.content?.application
                                                                                        ?.applicationName,
                                                                                    assessmentType:
                                                                                        pdfContent?.content?.application
                                                                                            ?.assessmentType,
                                                                                    environment:
                                                                                        pdfContent?.content?.kickoff?.environment,
                                                                                    applicationType:
                                                                                        pdfContent?.content?.application
                                                                                            ?.applicationType,
                                                                                    reportDate: moment(new Date()).format("YYYY-MM-DD"),
                                                                                    publishDate: moment(new Date()).format("YYYY-MM-DD"),
                                                                                    assessmentId:
                                                                                        pdfContent?.content?.application?.penId,
                                                                                    reportId: `RPT_${pdfContent?.content?.application?.penId}`,

                                                                                    lastModifyBy: pdfContent?.content?.application?.lastModifyBy,
                                                                                    lastModifyDate: moment(pdfContent?.content?.application?.updatedAt).format("YYYY-MM-DD"),
                                                                                    lastReviewDate: moment(pdfContent?.content?.application?.updatedAt).format("YYYY-MM-DD")
                                                                                }
                                                                            )),
                                                                        }}
                                                                    />
                                                                </PageSection>

                                                            </>
                                                        )}
                                                        {reportSections === "documentAuthorization" && (
                                                            <>
                                                                <PageSection
                                                                    title={TemplateFields[reportSections]}
                                                                    pageId={reportSections}
                                                                    index={i}
                                                                    marginLeft={pdfContent?.content?.template?.marginLeft}
                                                                    marginRight={pdfContent?.content?.template?.marginRight}
                                                                >
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: htmlSanitizer(interpolateTemplate(
                                                                                pdfContent.content.template[reportSections],
                                                                                {
                                                                                    applicationName: pdfContent?.content?.application
                                                                                        ?.applicationName,
                                                                                    assessmentType:
                                                                                        pdfContent?.content?.application
                                                                                            ?.assessmentType,
                                                                                    environment:
                                                                                        pdfContent?.content?.kickoff?.environment,
                                                                                    applicationType:
                                                                                        pdfContent?.content?.application
                                                                                            ?.applicationType,
                                                                                    reportDate: moment(new Date()).format("YYYY-MM-DD"),
                                                                                    publishDate: moment(new Date()).format("YYYY-MM-DD"),
                                                                                    assessmentId:
                                                                                        pdfContent?.content?.application?.penId,
                                                                                    reportId: `RPT_${pdfContent?.content?.application?.penId}`,

                                                                                    lastModifyBy: pdfContent?.content?.application?.lastModifyBy,
                                                                                    lastModifyDate: moment(pdfContent?.content?.application?.updatedAt).format("YYYY-MM-DD"),
                                                                                    lastReviewDate: moment(pdfContent?.content?.application?.updatedAt).format("YYYY-MM-DD"),

                                                                                }
                                                                            )),
                                                                        }}
                                                                    />
                                                                </PageSection>

                                                            </>
                                                        )}
                                                        {reportSections === "assesmentInformation" && (
                                                            <>
                                                                <PageSection
                                                                    title={TemplateFields[reportSections]}
                                                                    pageId={reportSections}
                                                                    index={i}
                                                                    marginLeft={pdfContent?.content?.template?.marginLeft}
                                                                    marginRight={pdfContent?.content?.template?.marginRight}
                                                                >

                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: htmlSanitizer(interpolateTemplate(
                                                                                pdfContent.content.template[reportSections],
                                                                                {
                                                                                    applicationName: pdfContent?.content?.application
                                                                                        ?.applicationName,
                                                                                    assessmentType:
                                                                                        pdfContent?.content?.application
                                                                                            ?.assessmentType,
                                                                                    environment:
                                                                                        pdfContent?.content?.kickoff?.environment,
                                                                                    applicationType:
                                                                                        pdfContent?.content?.application
                                                                                            ?.applicationType,
                                                                                    reportDate: moment(new Date()).format("YYYY-MM-DD"),
                                                                                    publishDate: moment(new Date()).format("YYYY-MM-DD"),
                                                                                    assessmentId:
                                                                                        pdfContent?.content?.application?.penId,
                                                                                    reportId: `RPT_${pdfContent?.content?.application?.penId}`,

                                                                                    lastModifyBy: pdfContent?.content?.application?.lastModifyBy,
                                                                                    lastModifyDate: moment(pdfContent?.content?.application?.updatedAt).format("YYYY-MM-DD"),
                                                                                    lastReviewDate: moment(pdfContent?.content?.application?.updatedAt).format("YYYY-MM-DD")

                                                                                }
                                                                            )),
                                                                        }}
                                                                    />
                                                                </PageSection>

                                                            </>
                                                        )}
                                                        {reportSections === "appendices" && (
                                                            <>
                                                                <PageSection
                                                                    title={TemplateFields[reportSections]}
                                                                    pageId={reportSections}
                                                                    index={i}
                                                                    marginLeft={pdfContent?.content?.template?.marginLeft}
                                                                    marginRight={pdfContent?.content?.template?.marginRight}
                                                                >
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: htmlSanitizer(interpolateTemplate(
                                                                                pdfContent.content.template[reportSections],
                                                                                {
                                                                                    scope: pdfContent?.template?.appendices,

                                                                                    applicationName: pdfContent?.content?.application
                                                                                        ?.applicationName,
                                                                                    assessmentType:
                                                                                        pdfContent?.content?.application
                                                                                            ?.assessmentType,
                                                                                    environment:
                                                                                        pdfContent?.content?.kickoff?.environment,
                                                                                    applicationType:
                                                                                        pdfContent?.content?.application
                                                                                            ?.applicationType,
                                                                                    reportDate: moment(new Date()).format("YYYY-MM-DD"),
                                                                                    publishDate: moment(new Date()).format("YYYY-MM-DD"),
                                                                                    assessmentId:
                                                                                        pdfContent?.content?.application?.penId,
                                                                                    reportId: `RPT_${pdfContent?.content?.application?.penId}`,

                                                                                    lastModifyBy: pdfContent?.content?.application?.lastModifyBy,
                                                                                    lastModifyDate: moment(pdfContent?.content?.application?.updatedAt).format("YYYY-MM-DD"),
                                                                                    lastReviewDate: moment(pdfContent?.content?.application?.updatedAt).format("YYYY-MM-DD")
                                                                                }
                                                                            )),
                                                                        }}
                                                                    />
                                                                </PageSection>

                                                            </>
                                                        )}
                                                        {reportSections === "appendixBUsedTools" && (
                                                            <>
                                                                <PageSection
                                                                    title={TemplateFields[reportSections]}
                                                                    pageId={reportSections}
                                                                    index={i}
                                                                    marginLeft={pdfContent?.content?.template?.marginLeft}
                                                                    marginRight={pdfContent?.content?.template?.marginRight}
                                                                >
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: htmlSanitizer(interpolateTemplate(
                                                                                pdfContent.content.template[reportSections],
                                                                                {
                                                                                    scope: pdfContent?.template?.appendixBUsedTools,

                                                                                    applicationName: pdfContent?.content?.application
                                                                                        ?.applicationName,
                                                                                    assessmentType:
                                                                                        pdfContent?.content?.application
                                                                                            ?.assessmentType,
                                                                                    environment:
                                                                                        pdfContent?.content?.kickoff?.environment,
                                                                                    applicationType:
                                                                                        pdfContent?.content?.application
                                                                                            ?.applicationType,
                                                                                    reportDate: moment(new Date()).format("YYYY-MM-DD"),
                                                                                    publishDate: moment(new Date()).format("YYYY-MM-DD"),
                                                                                    assessmentId:
                                                                                        pdfContent?.content?.application?.penId,
                                                                                    reportId: `RPT_${pdfContent?.content?.application?.penId}`,

                                                                                    lastModifyBy: pdfContent?.content?.application?.lastModifyBy,
                                                                                    lastModifyDate: moment(pdfContent?.content?.application?.updatedAt).format("YYYY-MM-DD"),
                                                                                    lastReviewDate: moment(pdfContent?.content?.application?.updatedAt).format("YYYY-MM-DD")
                                                                                }
                                                                            )),
                                                                        }}
                                                                    />
                                                                </PageSection>

                                                            </>
                                                        )}
                                                        {reportSections === "vulenerabilityIndex" && (
                                                            <>
                                                                <PageSection
                                                                    title={TemplateFields[reportSections]}
                                                                    pageId={reportSections}
                                                                    index={i}
                                                                    marginLeft={pdfContent?.content?.template?.marginLeft}
                                                                    marginRight={pdfContent?.content?.template?.marginRight}
                                                                >

                                                                    <div>
                                                                        <table className="table table-bordered border-dark">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className="infomative text-white fs-5 text-center" scope="col">VID</th>
                                                                                    <th className="infomative text-white fs-5" scope="col">Vulnerability Name</th>
                                                                                    <th className="infomative text-white fs-5" scope="col">Severity</th>
                                                                                    <th className="infomative text-white fs-5" scope="col">Status</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {pdfContent?.content?.findings?.sort((a, b) => b.cvssScore - a.cvssScore)?.map(
                                                                                    (finding, i) => {
                                                                                        return (
                                                                                            <tr key={i}>
                                                                                                <td className="fs-5 text-center" width="10%">V{i + 1}</td>
                                                                                                <td className="fs-5" width="70%">
                                                                                                    {finding.findingTitle}
                                                                                                </td>
                                                                                                {getSeverityCell(finding?.cvssScore)}
                                                                                                <td
                                                                                                    // width="10%"
                                                                                                    className="text-capitalize critical text-white fs-5 fw-bold text-center"
                                                                                                >
                                                                                                    {finding.findingStatus}
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </PageSection>
                                                                {pdfContent?.content?.findings?.sort((a, b) => b.cvssScore - a.cvssScore)?.map((finding, j) => {
                                                                    return (
                                                                        <>
                                                                            <div style={{ pageBreakAfter: "always" }}></div>
                                                                            <PageSection
                                                                                title={"Vulenerability Tables"}
                                                                                pageId={reportSections}
                                                                                index={i}
                                                                                hasTitle={j == 0}
                                                                                marginLeft={pdfContent?.content?.template?.marginLeft}
                                                                                marginRight={
                                                                                    pdfContent?.content?.template?.marginRight
                                                                                }
                                                                            >
                                                                                <table className="table border-dark v-table">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <th
                                                                                                className="infomative text-white fs-5"
                                                                                                width="25%"
                                                                                            >
                                                                                                Vulnerability Name
                                                                                            </th>
                                                                                            <td className="fs-5 fw-bold" width="75%" colSpan={3}>
                                                                                                {finding.findingTitle}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th
                                                                                                className="infomative text-white fs-5"
                                                                                                width="25%"
                                                                                            >
                                                                                                Vulnerability ID
                                                                                            </th>
                                                                                            <td className="fs-5 text-center fw-bold" width="25%">V{j + 1}</td>
                                                                                            <th
                                                                                                className="infomative text-white fs-5"
                                                                                                width="25%"
                                                                                            >
                                                                                                CVSS v3.1 Scoring
                                                                                            </th>
                                                                                            <td
                                                                                                width="25%"
                                                                                                className="text-capitalize fs-5 text-center"
                                                                                            >
                                                                                                {finding.cvssScore}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th
                                                                                                className="infomative text-white fs-5"
                                                                                                width="25%"
                                                                                            >
                                                                                                OWASP ID
                                                                                            </th>
                                                                                            <td className="fs-5 text-center" width="25%">
                                                                                                {finding?.owasps.map(({ cweId }) => cweId).join(" ")}
                                                                                            </td>
                                                                                            <th
                                                                                                width="25%"
                                                                                                className="infomative text-white fs-5"
                                                                                            >
                                                                                                Severity
                                                                                            </th>

                                                                                            {getSeverityCell(finding.cvssScore)}
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th
                                                                                                className="infomative text-white fs-5"
                                                                                                width="25%"
                                                                                            >
                                                                                                Method{" "}
                                                                                            </th>
                                                                                            <td className="fs-5 text-center" width="25%">{finding?.method?.toUpperCase()}</td>
                                                                                            <th
                                                                                                className="infomative text-white fs-5"
                                                                                                width="25%"
                                                                                            >
                                                                                                OWASP Category
                                                                                            </th>
                                                                                            <td
                                                                                                width="25%"
                                                                                                className="text-capitalize fs-5 text-center"
                                                                                            >
                                                                                                {finding?.owasps?.map(o => o?.name)?.filter(Boolean)?.join(',')}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th
                                                                                                className="infomative text-white fs-5"
                                                                                                width="25%"
                                                                                            >
                                                                                                Infected URL(s)/URI(s)
                                                                                            </th>
                                                                                            <td className="fs-5" width="75%" colSpan={3}>
                                                                                                {finding?.infectedURL?.map((url) => url).join(", ")}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th
                                                                                                className="infomative text-white fs-5"
                                                                                                width="25%"
                                                                                            >
                                                                                                Infected Parameters
                                                                                            </th>
                                                                                            <td className="fs-5" width="75%" colSpan={3}>
                                                                                                {finding?.pocs.map(({ parameters }) => parameters).join(", ")}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th
                                                                                                className="infomative text-white fs-5"
                                                                                                width="25%"
                                                                                            >
                                                                                                Payload(s)
                                                                                            </th>
                                                                                            <td className="fs-5" width="75%" colSpan={3}>
                                                                                                {finding?.pocs.map(({ payload }) => payload).join(", ")}

                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th
                                                                                                className="infomative text-white fs-5"
                                                                                                width="25%"
                                                                                            >
                                                                                                Description
                                                                                            </th>
                                                                                            <td
                                                                                                className="fs-5"
                                                                                                width="75%"
                                                                                                colSpan={3}
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html: htmlSanitizer(finding.description),
                                                                                                }}
                                                                                            ></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th
                                                                                                className="infomative text-white fs-5"
                                                                                                width="25%"
                                                                                            >
                                                                                                Impact
                                                                                            </th>
                                                                                            <td
                                                                                                className="fs-5"
                                                                                                width="75%"
                                                                                                colSpan={3}
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html: htmlSanitizer(finding.impect),
                                                                                                }}
                                                                                            ></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </PageSection>
                                                                            <div style={{ pageBreakAfter: "always" }}></div>
                                                                            <PageSection
                                                                                title={"Vulenerability Tables"}
                                                                                pageId={reportSections}
                                                                                index={i}
                                                                                marginLeft={pdfContent?.content?.template?.marginLeft}
                                                                                marginRight={
                                                                                    pdfContent?.content?.template?.marginRight
                                                                                }
                                                                                hasTitle={false}
                                                                            >
                                                                                <h4 className="fs-5 fw-bold text-dark">
                                                                                    Proof of concept:
                                                                                </h4>
                                                                                <PocImageSection
                                                                                    key={uniqueKey("pocSection")}
                                                                                    description={finding.description}
                                                                                    pocs={finding.pocs}
                                                                                />
                                                                                <div style={{ pageBreakAfter: "always" }}></div>
                                                                                <div>
                                                                                    <table className="table border-dark v-table">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <th
                                                                                                    className="infomative text-white fs-5" colSpan={2}
                                                                                                >
                                                                                                    Remediation
                                                                                                </th>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td
                                                                                                    className="fs-5"
                                                                                                    width="75%"
                                                                                                    colSpan={3}
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html: htmlSanitizer(finding?.remediation),
                                                                                                    }}
                                                                                                >
                                                                                                </td>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <th
                                                                                                    className="infomative text-white fs-5" colSpan={2}
                                                                                                >
                                                                                                    Reference(s)
                                                                                                </th>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td
                                                                                                    className="fs-5"
                                                                                                    width="75%"
                                                                                                    colSpan={3}
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html: htmlSanitizer(finding?.reference),
                                                                                                    }}
                                                                                                ></td>
                                                                                            </tr>

                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </PageSection>
                                                                        </>
                                                                    );
                                                                })}
                                                            </>
                                                        )
                                                        }
                                                    </Fragment>
                                                );
                                            })}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div >
        )
    );
};

export default TemplatePDF;
