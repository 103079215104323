
export const ADD_USER = "ADD_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"

export const USER_UPDATE = "USER_UPDATE"
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS"
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL"

export const GET_ALL_USER = "GET_ALL_USER"
export const GET_ALL_USER_SUCCESS = "GET_ALL_USER_SUCCESS"
export const GET_ALL_USER_FAIL = "GET_ALL_USER_FAIL"

export const ACTIVE_DEACTIVE_USER = "ACTIVE_DEACTIVE_USER"
export const ACTIVE_DEACTIVE_USER_SUCCESS = "ACTIVE_DEACTIVE_USER_SUCCESS"
export const ACTIVE_DEACTIVE_USER_FAIL = "ACTIVE_DEACTIVE_USER_FAIL"

export const USER_PROFILE_UPLOAD = "USER_PROFILE_UPLOAD"
export const USER_PROFILE_UPLOAD_SUCCESS = "USER_PROFILE_UPLOAD_SUCCESS"
export const USER_PROFILE_UPLOAD_FAIL = "USER_PROFILE_UPLOAD_FAIL"

export const GET_SIGNED_URL = "GET_SIGNED_URL"
export const GET_SIGNED_URL_SUCCESS = "GET_SIGNED_URL_SUCCESS"
export const GET_SIGNED_URL_FAIL = "GET_SIGNED_URL_FAIL"