import ActionButtons from 'components/FormControls/ActionButtons';
import RHFButton from 'components/FormControls/RHFButton';
import RHFSwitch from 'components/FormControls/RHFSwitch';
import DialogBox from 'components/Modals/DialogBox';
import Table from 'components/Tables/Table';
import { activeDeactivebu } from 'helpers/BackendHelper';
import { APPLICATION } from 'helpers/Services/Application';
import React, { useCallback, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import BuAddEdit from './BuAddEdit';
import { Toast } from 'components/Common/Toaster';
import { isModulePermisssion } from 'helpers/util';
import { ROLE_PERMISSIONS } from 'constants/RolePermissions';
import RHFTextField from 'components/FormControls/RHFTextField';

const Bu = () => {
    document.title = "Business Unit | RiDiscovery";
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [editBuData, setEditBuData] = useState(null);
    const [isRefresh, setRefresh] = useState(false);
    const [filterFields, setFilterFields] = useState({
        'search': null,
    });

    const setFilterValues = (field) => {
        setFilterFields(prev => {
            return {
                ...prev,
                ...field
            }
        })
    }

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };

    const optimizedFn = useCallback(debounce((val) => setFilterValues({ search: val })), []);


    const handleToggle = () => {
        setIsModelOpen(!isModelOpen);
    };

    const handleSwitchChange = (value, row) => {
        const payload = {
            id: row?.id,
            isActive: !row?.isActive
        }
        activeDeactivebu({ ...payload }).then(resp => {
            if (resp?.status === 1) {
                Toast.success(resp?.message, !row?.isActive ? 0 : 1)
            }
            setRefresh(prevValue => !prevValue)
        })
    };

    const editHandler = (obj) => {
        handleToggle();
        setEditBuData(obj);

    };

    const handleActionClick = (payload, actionType) => {
        const actionMapper = {
            edit: editHandler,
        };

        if (actionMapper[actionType]) {
            actionMapper[actionType](payload);
        }
    };


    const columns = [
        {
            name: "Name",
            cell: (row) => <span>{row?.name}</span>,
            width: "20%",
            minWidth: "120px"
        },
        {
            name: "BU Code",
            cell: (row) => <span>{row?.buCode}</span>,
            width: "23%",
            minWidth: "120px"
        },
        {
            name: "Address",
            cell: (row) => <span>{row?.address}</span>,
            width: "23%",
            minWidth: "120px"
        },
        {
            name: "Status",
            width: "23%",
            minWidth: "120px",
            omit: !isModulePermisssion(ROLE_PERMISSIONS.BU_ACTIVE_DEACTIVE),
            cell: (row) => (
                <RHFSwitch
                    name="activated" // isActive
                    isController={false}
                    checked={row?.isActive}
                    rowData={row}
                    onChange={(val, row) => handleSwitchChange(val, row)}
                />
            ),
        },
        {
            name: "Actions",
            minWidth: "120px",
            omit: !isModulePermisssion(ROLE_PERMISSIONS.UPDATE_BU),
            cell: (row) => {
                return (
                    <ActionButtons
                        edit={
                            isModulePermisssion(ROLE_PERMISSIONS.UPDATE_BU) &&
                            {
                                handleClick: () => handleActionClick(row, 'edit'),
                            }
                        }
                    />
                );
            },
        }
    ];

    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/manage-bu", heading: "Business Unit" }]

    return (
        <div className="page-content">
            <Breadcrumbs title="Business Unit" breadcrumbItem={breadcrumbItems} />
            <Card>
                <CardHeader>
                    <Row>
                        <Col md="3">
                            <RHFTextField
                                id="search"
                                name="search"
                                placeholder="Search here"
                                isController={false}
                                handleOnChange={optimizedFn}
                            />
                        </Col>
                        <Col>
                            <div className="col d-flex justify-content-end">
                                {isModulePermisssion(ROLE_PERMISSIONS.ADD_BU) &&
                                    <RHFButton
                                        btnName="Add"
                                        icon="plus"
                                        onClick={() => {
                                            handleToggle()
                                            setEditBuData(null);
                                        }}
                                    />
                                }
                                <DialogBox
                                    isModelOpen={isModelOpen}
                                    handleToggle={handleToggle}
                                    editBuData={editBuData}
                                    setRefresh={setRefresh}
                                    title={
                                        editBuData === null ? "Add Business Unit" : "Edit Business Unit"
                                    }
                                >
                                    <BuAddEdit
                                        handleToggle={handleToggle}
                                        editBuData={editBuData}
                                        setRefresh={setRefresh}
                                    />
                                </DialogBox>
                            </div>
                        </Col>
                    </Row>
                </CardHeader>

                <CardBody className="table-responsive">
                    <Table
                        columns={columns}
                        dataURL={APPLICATION.listAllBu}
                        isRefresh={isRefresh}
                        filter={filterFields}
                    />
                </CardBody>
            </Card>

        </div >

    )
}

export default Bu
