import { ADD_KICK_OFF, ADD_KICK_OFF_FAIL, ADD_KICK_OFF_SUCCESS, UPDATE_KICK_OFF, UPDATE_KICK_OFF_FAIL, UPDATE_KICK_OFF_SUCCESS } from "./actionTypes"


export const addKickoff = (payload) => {
    return {
        type: ADD_KICK_OFF,
        payload,
    }
}

export const addKickoffSuccess = (data) => {
    return {
        type: ADD_KICK_OFF_SUCCESS,
        payload: data
    }
}

export const addKickoffFail = (data) => {
    return {
        type: ADD_KICK_OFF_FAIL,
        payload: data
    }
}

export const updateKickoff = (payload) => {
    return {
        type: UPDATE_KICK_OFF,
        payload,
    }
}

export const updateKickoffSuccess = (data) => {
    return {
        type: UPDATE_KICK_OFF_SUCCESS,
        payload: data
    }
}

export const updateKickoffFail = (data) => {
    return {
        type: UPDATE_KICK_OFF_FAIL,
        payload: data
    }
}
