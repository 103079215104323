import {
    ADD_BU,
    ADD_BU_SUCCESS,
    ADD_BU_FAIL,

    UPDATE_BU,
    UPDATE_BU_SUCCESS,
    UPDATE_BU_FAIL,

    GET_ALL_BU,
    GET_ALL_BU_SUCCESS,
    GET_ALL_BU_FAIL,

    ACTIVE_DEACTIVE_BU,
    ACTIVE_DEACTIVE_BU_SUCCESS,
    ACTIVE_DEACTIVE_BU_FAIL,

} from "./actionTypes"

const initialState = {
    isLoading: false,
    isError: false,
    bussinessUnit: [],
}

const bu = (state = initialState, action) => {
    switch (action.type) {
        case ADD_BU:
            state = {
                ...state,
                isLoading: true,
            }
            break
        case ADD_BU_SUCCESS:
            state = {
                ...state,
                isLoading: false,
            }
            break
        case ADD_BU_FAIL:
            state = {
                ...state,
                isLoading: false,
                isError: true
            }
            break

        case UPDATE_BU:
            state = {
                ...state,
                isLoading: true,
            }
            break
        case UPDATE_BU_SUCCESS:
            state = {
                ...state,
                isLoading: false,
            }
            break
        case UPDATE_BU_FAIL:
            state = {
                ...state,
                isLoading: false,
                isError: true
            }
            break

        case ACTIVE_DEACTIVE_BU:
            state = {
                ...state,
                isLoading: true,
            }
            break
        case ACTIVE_DEACTIVE_BU_SUCCESS:
            state = {
                ...state,
                isLoading: false,
            }
            break
        case ACTIVE_DEACTIVE_BU_FAIL:
            state = {
                ...state,
                isLoading: false,
                isError: true
            }
            break
        case GET_ALL_BU:
            state = {
                ...state,
                isLoading: true,
            }
            break
        case GET_ALL_BU_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                bussinessUnit: action?.payload
            }
            break
        case GET_ALL_BU_FAIL:
            state = {
                ...state,
                isLoading: false,
                bussinessUnit: [],
                isError: true
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export default bu;
