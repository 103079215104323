import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import RHFAutoCompleteSelect from "components/FormControls/RHFAutoCompleteSelect";
import RHFButton from "components/FormControls/RHFButton";
import RHFFileUpload from "components/FormControls/RHFFileUpload";
import RHFTextField from "components/FormControls/RHFTextField";
import { Col, Row } from "reactstrap";
import * as yup from "yup";
import RHFInputTags from 'components/FormControls/RHFInputTags';
import defaultImage from "../../assets/images/Default-User-Image.jpeg";
import { htmlTagRegex, nameRegex, urlRegex } from 'constants/Constant';
import { addCompany, editCompany } from 'helpers/BackendHelper';
import { Toast } from 'components/Common/Toaster';


const CategoryData = [
  {
    value: "development_agency_internal",
    label: "Development Agency - Internal",
  },
  {
    value: "security_agency_internal",
    label: "Security Agency - Internal",
  },
  {
    value: "development_agency_external",
    label: "Development Agency - External",
  },
  {
    value: "security_agency_external",
    label: "Security Agency - External",
  },
];

const CompanyAddEdit = (props) => {
  const { editAgencyData, handleToggle, setRefresh } = props;
  const isEditMode = editAgencyData ? true : false;
  const [isApiCalling, setApiCalling] = useState(false);

  const userSchema = yup.object().shape({
    companyName: yup.string().trim().matches(nameRegex, "Please enter valid Comapany Name").required("Agency Name is required"),
    domain: yup.array().typeError("Domain is required").required("Domain is required").of(yup.string().test("domain-name", "Please enter valid Domain Name",
      function (value) {
        let isValidHtml = htmlTagRegex.test(value);
        let isValidUrl = urlRegex.test(value);
        if (isValidUrl && isValidHtml) {
          return true;
        } else {
          return false;
        }
      }))
      .min(1, "Domain is required"),
    companyType:
      !isEditMode &&
      yup
        .object()
        .shape({ label: yup.string(), value: yup.string() })
        .nullable()
        .required("Select atleast one option"),
  });

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(userSchema),
  });

  const getFileData = (file) => {
    setValue("companyLogo", file?.file)
  }

  useEffect(() => {
    if (isEditMode) {
      const domain = [];
      editAgencyData?.domains?.map(o => domain.push(o?.companyDomain))

      setValue('companyLogo', editAgencyData?.companyLogo)
      setValue('companyName', editAgencyData?.companyName)
      setValue('domain', domain)
      setValue('companyType', CategoryData?.find(o => o.value === editAgencyData?.companyType))

    }
    else {
      setValue(null)
    }
  }, [editAgencyData]);

  const onSubmit = (data) => {
    setApiCalling(true);
    if (!isApiCalling) {
      const formData = new FormData();
      formData.append('companyLogo', data?.companyLogo);
      formData.append('companyName', data?.companyName);
      formData.append('companyType', data?.companyType?.value);
      formData.append('domain', JSON.stringify((data?.domain)));
      isEditMode && formData.append('companyId', editAgencyData?.id)
      const payload = formData
      if (isEditMode) {
        editCompany(payload).then(resp => {
          Toast.success(resp?.message)
          setRefresh(prevValue => !prevValue)
          reset();
          handleToggle();
          setApiCalling(false);
        }).catch(() => {
          setApiCalling(false);
        });
      } else {
        addCompany(payload).then(resp => {
          Toast.success(resp?.message)
          setRefresh(prevValue => !prevValue)
          reset();
          handleToggle();
          setApiCalling(false);
        }).catch(() => {
          setApiCalling(false);
        });
      }
    }
  };


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex mb-3 justify-content-center">
        <div className="">
          <RHFFileUpload
            name="companyLogo"
            defaultImage={editAgencyData?.companyLogo ? editAgencyData?.companyLogo : defaultImage}
            errorobj={errors}
            setValue={setValue}
            getFileData={getFileData}
            setError={setError}
            clearErrors={clearErrors}
            isValidate={true}
            control={control}
          />
        </div>
      </div>

      <Row className="mb-3 mt-5">
        <Col sm="12">
          <RHFTextField
            id="companyName"
            label="Agency Name"
            name="companyName"
            errorobj={errors}
            control={control}
            isController={true}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col sm="12">
          <RHFInputTags
            id="domain"
            label="Domain"
            name="domain"
            errorobj={errors}
            control={control}
            isController={true}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col sm="12">
          <RHFAutoCompleteSelect
            id="companyType"
            label="Category"
            name="companyType"
            options={CategoryData}
            setValue={setValue}
            isMultiple={false}
            errorobj={errors}
            control={control}
            isController={true}
          />
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <RHFButton
          btnName="Cancel"
          outline={true}
          onClick={handleToggle}
          className="mx-2"

        />
        <RHFButton
          btnName="Submit"
          type="submit"
          isLoading={isApiCalling}
        />
      </div>
    </form>
  );
};

export default CompanyAddEdit;
