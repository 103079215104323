import React from 'react'
import { DateRangePicker } from 'react-date-range'
import { Button, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const TimelineButton = (props) => {
    const {
        toggle = () => { },
        isTimelineModalOpen,
        timeline,
        setTimeline,
        setTimelineData,
        handleTimelineChange
    } = props

    return (
        <Dropdown isOpen={isTimelineModalOpen} toggle={() => { }} >
            <DropdownToggle caret={true} onClick={toggle} color='primary'>
                Timeline
            </DropdownToggle>
            <DropdownMenu end persist={true}>
                <div className="shadow d-inline-block">
                    <DateRangePicker
                        editableDateInputs={true}
                        onChange={item => {
                            setTimeline([item?.selection])
                            setTimelineData(item?.selection)
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={timeline}
                        direction="vertical"
                        calendarFocus="forward"
                    />
                    <div className="text-right position-relative rdr-buttons-position mt-2 mr-3 d-flex justify-content-end">
                        <Button
                            // style={{ float: "right" }}
                            color="primary"
                            outline="false"
                            className="rounded-5 px-4 me-3 mb-3"
                            size="sm"
                            onClick={() => handleTimelineChange()}>
                            Apply
                        </Button>
                    </div>
                </div>
            </DropdownMenu>
        </Dropdown>
    )
}

export default TimelineButton
