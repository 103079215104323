import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef } from "react";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import images

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { isModulePermisssion } from "helpers/util";
import { ROLE_PERMISSIONS } from "constants/RolePermissions";

const SidebarContent = (props) => {
  const ref = useRef();
  const pathName = props.location.pathname

  const splitLocation = pathName.split("/")
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          {location?.pathname === '/onboard-company' ?
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className={splitLocation[1] === "onboard-company" ? "mm-active" : ""}>
                <Link to="/onboard-company" >
                  <FeatherIcon icon="home" />
                  <span>{props.t("Register Company")}</span>
                </Link>
              </li>
            </ul>
            :
            <ul className="metismenu list-unstyled" id="side-menu">
              {isModulePermisssion(ROLE_PERMISSIONS?.MENU_DASHBOARD) &&
                <li className={splitLocation[1] === "dashboard" ? "mm-active" :
                  splitLocation[1] === "asset-summary" ? "mm-active" : ""}>
                  <Link to="/dashboard" >
                    <FeatherIcon icon="pie-chart" />
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
              }
              {isModulePermisssion(ROLE_PERMISSIONS?.MENU_ASSESSMENT) &&
                <li className={splitLocation[1] === "applications" ? "mm-active" :
                  splitLocation[1] === "application" ? "mm-active" :
                    splitLocation[1] === "networks" ? "mm-active" :
                      splitLocation[1] === "network" ? "mm-active" : ""}>
                  <Link to={isModulePermisssion(ROLE_PERMISSIONS.APPLICATION) ? "/applications" : "/networks"}>
                    <FeatherIcon icon="grid" />
                    <span>{props.t("Assessment")}</span>
                  </Link>
                </li>}

              {isModulePermisssion(ROLE_PERMISSIONS?.MENU_ROLE_MANAGEMENT) &&
                <li className={splitLocation[1] === "user-list" ? "mm-active" : ""}>
                  <Link to="/user-list">
                    <FeatherIcon icon="users" />
                    <span>{props.t("Role Management")}</span>
                  </Link>
                </li>
              }
              {isModulePermisssion(ROLE_PERMISSIONS?.MENU_CERTIFICATE) &&
                <li className={splitLocation[1] === "project-list" ? "mm-active" : ""}>
                  <Link to="/project-list">
                    <FeatherIcon icon="file-text" />
                    <span>{props.t("Certificates")}</span>
                  </Link>
                </li>
              }

              {isModulePermisssion(ROLE_PERMISSIONS.AGENCY) &&
                <li className={splitLocation[1] === "agency" ? "mm-active" : ""} >
                  <Link to="/agency">
                    <FeatherIcon icon="home" />
                    <span>{props.t("Agency")}</span>
                  </Link>
                </li>
              }

              {isModulePermisssion(ROLE_PERMISSIONS?.MENU_BU) &&
                <li className={splitLocation[1] === "manage-bu" ? "mm-active" : ""}>
                  <Link to="/manage-bu">
                    <FeatherIcon icon="package" />
                    <span>{props.t("Business Unit")}</span>
                  </Link>
                </li>
              }
              {isModulePermisssion(ROLE_PERMISSIONS?.MENU_TEMPLATES) &&
                <li className={splitLocation[1] === "reporting-template" ? "mm-active" :
                  splitLocation[1] === "certificate-template" ? "mm-active" : ""}>
                  <Link to="/reporting-template">
                    <FeatherIcon icon="file" />
                    <span>{props.t("Templates")}</span>
                  </Link>
                </li>}
              {isModulePermisssion(ROLE_PERMISSIONS?.MENU_SUBMISSIONS) &&
                <li className={splitLocation[1] === "submissions" ? "mm-active" : ""}>
                  <Link to="/submissions">
                    <FeatherIcon icon="check-square" />
                    <span>{props.t("Submissions")}</span>
                  </Link>
                </li>}
              {isModulePermisssion(ROLE_PERMISSIONS?.APPROVE_REJECT_USER) &&
                <li className={splitLocation[1] === "approve-reject-users-list" ? "mm-active" : ""}>
                  <Link to="/approve-reject-users-list">
                    <FeatherIcon icon="user-check" />
                    <span>{props.t("Approve/Reject Users")}</span>
                  </Link>
                </li>}
            </ul>
          }
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
