import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useParams, useHistory } from "react-router-dom";

// Formik Validation
import * as yup from "yup";

// import images
import logo from "../../assets/images/RiDiscovery_Icon.png";
import RHFTextField from "components/FormControls/RHFTextField";
import RHFButton from "components/FormControls/RHFButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import RHFSwitch from "components/FormControls/RHFSwitch";
import { generateQRCode } from "store/auth/twoFA/actions";
import { resetPassword } from "helpers/BackendHelper";
import { Toast } from "components/Common/Toaster";


const SetPasswordPage = () => {

  //meta title
  document.title = "Set Password | RiDiscovery";

  const dispatch = useDispatch()
  const history = useHistory()
  const { token } = useParams();
  const { qrCode } = useSelector(state => state?.TwoFA);

  const [isActive, setIsActive] = useState(false);
  const [isApiCalling, setApiCalling] = useState(false);

  const ResetPasswordSchema = yup.object().shape({
    password: yup.string().trim()
      .required("Password is required").matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character required."),
    confirmPassword: yup.string().required("Confirm Password is required")
      .oneOf([yup.ref('password'), null], 'Password and Confirm Password must match'),
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(ResetPasswordSchema)
  });

  const setPasswordd = (values) => {
    setApiCalling(true);
    if (!isApiCalling) {
      const payload = {
        password: values?.password,
        token: token,
        twoFAEnabled: values?.twoFAEnabled || false,
        secret: qrCode?.secret || null,
      };
      resetPassword(payload).then(resp => {
        setApiCalling(false);
        if (resp?.status) {
          history.push('/login')
          Toast.success(response.message)
          reset();
        }
      }).catch(() => {
        setApiCalling(false);
      });
    }
  };


  const handleSwitchChange = (val) => {
    setIsActive(val);
    setValue('twoFAEnabled', val);
    dispatch(generateQRCode({ token: token }))
  }

  return (
    <React.Fragment>
      <div className="auth-page">

        <Row className="g-0">
          <Col lg={4} md={5} className="col-xxl-3">
            <div className="auth-full-page-content d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5 text-center">
                    <span className="d-block auth-logo">
                      <img src={logo} alt="" height="35" />
                      <span className="logo-txt">RiDiscovery</span>
                    </span>
                  </div>
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      <h3 className="mb-0">Set New Password</h3>
                      <p className="text-muted mt-2">Set Password with RiDiscovery.</p>
                    </div>


                    <Form
                      className="custom-form mt-4"
                      onSubmit={handleSubmit(setPasswordd)}
                    >
                      <div className="mb-3">
                        <RHFTextField
                          id="password"
                          label="Password"
                          name="password"
                          type="password"
                          errorobj={errors}
                          control={control}
                          isController={true}
                        />
                      </div>
                      <div className="mb-3">
                        <RHFTextField
                          id="confirmPassword"
                          label="Confirm Password"
                          name="confirmPassword"
                          type="password"
                          errorobj={errors}
                          control={control}
                          isController={true}
                        />
                      </div>
                      <div className="mb-3">
                        <RHFSwitch
                          name="twoFAEnabled"
                          label="Allow two factor verification ?"
                          checked={isActive}
                          isController={true}
                          errorobj={errors}
                          control={control}
                          onChange={handleSwitchChange}
                        />
                      </div>

                      {
                        isActive && qrCode?.QRCode &&
                        <div className="mb-3 text-center">
                          <img alt='qr-code' src={qrCode?.QRCode} width="150" height="150" />
                        </div>
                      }

                      <Row className="mb-3">
                        <Col>
                          <div className="mt-3 d-grid">
                            <RHFButton btnName="Set Password" type="submit" isLoading={isApiCalling} />
                          </div>
                        </Col>
                      </Row>

                    </Form>

                    <div className="mt-5 text-center">
                      <p className="text-muted mb-0">Remember It ?
                        <Link to="/login" className="text-primary fw-semibold"> Login</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

      </div>
    </React.Fragment >
  )
}

SetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default SetPasswordPage;