import {
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,

  ACTIVE_DEACTIVE_USER,
  ACTIVE_DEACTIVE_USER_SUCCESS,
  ACTIVE_DEACTIVE_USER_FAIL,

  GET_ALL_USER,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_FAIL,

  USER_UPDATE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_PROFILE_UPLOAD,
  USER_PROFILE_UPLOAD_SUCCESS,
  USER_PROFILE_UPLOAD_FAIL,
  GET_SIGNED_URL,
  GET_SIGNED_URL_SUCCESS,
  GET_SIGNED_URL_FAIL,

} from "./actionTypes"

const initialState = {
  isLoading: false,
  isError: false,
  users: [],
  profile: null,
  getProfile: null
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER:
      state = {
        ...state,
        isLoading: true,
      }
      break
    case ADD_USER_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        users: action?.payload
      }
      break
    case ADD_USER_FAIL:
      state = {
        ...state,
        isLoading: false,
        isError: true
      }
      break

    case USER_UPDATE:
      state = {
        ...state,
        isLoading: true,
      }
      break
    case USER_UPDATE_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        users: action?.payload
      }
      break
    case USER_UPDATE_FAIL:
      state = {
        ...state,
        isLoading: false,
        isError: true
      }
      break

    case ACTIVE_DEACTIVE_USER:
      state = {
        ...state,
        isLoading: true,
      }
      break
    case ACTIVE_DEACTIVE_USER_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      }
      break
    case ACTIVE_DEACTIVE_USER_FAIL:
      state = {
        ...state,
        isLoading: false,
        isError: true
      }
      break

    case GET_ALL_USER:
      state = {
        ...state,
        isLoading: true,
      }
      break
    case GET_ALL_USER_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        users: action?.payload
      }
      break
    case GET_ALL_USER_FAIL:
      state = {
        ...state,
        isLoading: false,
        users: [],
        isError: true
      }
      break

    case USER_PROFILE_UPLOAD:
      state = {
        ...state,
        isLoading: true,
      }
      break
    case USER_PROFILE_UPLOAD_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        profile: action?.payload
      }
      break
    case USER_PROFILE_UPLOAD_FAIL:
      state = {
        ...state,
        isLoading: false,
        isError: true
      }
      break

    case GET_SIGNED_URL:
      state = {
        ...state,
        isLoading: true,
      }
      break
    case GET_SIGNED_URL_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        getProfile: action?.payload
      }
      break
    case GET_SIGNED_URL_FAIL:
      state = {
        ...state,
        isLoading: false,
        isError: true
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default user;
