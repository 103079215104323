import React, { useEffect, useRef, useState } from "react";
//import Breadcrumbs
import { Button, Card, CardBody, CardText, Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import RadialChart from "components/Common/Graphs/RadialChart";
import LineChart from "components/Common/Graphs/LineChart";
import DropdownButton from "components/FormControls/DropdownButton";
import DonutChart from "components/Common/Graphs/DonutChart";
import BarChart from "components/Common/Graphs/BarChart";
import { getAllBu } from "store/bu/actions";
import { useDispatch, useSelector } from "react-redux";
import { getBuRiskSummaryLineChartData, getCWETrendsBarChartData, getOverViewChartData, getOwaspApiBarChartData, getOwaspMobileBarChartData, getOwaspWebBarChartData } from "helpers/BackendHelper";
import PageLoader from "components/PageLoader";
import ColumnChart from "components/Common/Graphs/ColumnChart";
import RHFButton from "components/FormControls/RHFButton";
import { DateRangePicker } from "react-date-range"
import { addDays } from 'date-fns';
import moment from 'moment/moment'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import CircleChart from "components/Common/Graphs/CircleChart";
import { FINDING_SCORE_COLORS, DONUT_DASHBOARD_COLORS } from "constants/Constant";
import TimelineButton from "components/FormControls/TimelineButton";
;

const RiskSummary = () => {
    const dispatch = useDispatch();
    const ref = useRef();
    const { bussinessUnit } = useSelector(state => state?.bu);
    const [pageLoader, setPageLoader] = useState(false);
    const [filterFields, setFilterData] = useState({
        'buId': null,
        'from': null,
        'to': null
    });
    const [timelineData, setTimelineData] = useState({})
    const [isTimelineModalOpen, setIsTimelineModalOpen] = useState(false);
    const [timeline, setTimeline] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection'
        }
    ]);

    //For Chart

    const [buRiskSummaryChartData, setBuRiskSummaryRadialChartScores] = useState({
        applicationRadialChartScore: null,
        networkRadialChartScore: null,
        buRiskSummaryLineChartScoretrends: null
    })
    const [cweTrendsBarChartData, setCweTrendsBarChartData] = useState({
        cweTrendsBarChartSeriesData: null,
        cweTrendsBarChartXAxisData: null,
        cweTrendsBarChartNameData: null
    })
    const [overviewChartData, setOverviewRadialChartScores] = useState({
        applicationOverviewRadialChartScore: null,
        networkOverviewRadialChartScore: null,
        overviewLineChartScoretrends: null
    })
    const [owaspWebData, setOwaspWebData] = useState({
        owaspWebBarChartCountData: null,
        owaspWebBarChartXaxisData: null,
        owaspWebBarChartNameData: null,
        owaspWebCircleChartScoreData: null,
        owaspWebDountChartAssetCountKeyName: null,
        owaspWebDonutChartAssetCount: null,
        owaspWebDonuutChartAssetCountTotal: null
    })
    const [owaspApiData, setOwaspApiData] = useState({
        owaspApiBarChartCountData: null,
        owaspApiBarChartNameData: null,
        owaspApiBarChartXaxisData: null,
        owaspApiCircleChartScoreData: null,
        owaspApiDountChartAssetCountKeyName: null,
        owaspApiDonutChartAssetCount: null,
        owaspApiDonuutChartAssetCountTotal: null
    })
    const [owaspMobileData, setOwaspMobileData] = useState({
        owaspMobileBarChartCountData: null,
        owaspMobileBarChartNameData: null,
        owaspMobileBarChartXaxisData: null,
        owaspMobileCircleChartScoreData: null,
        owaspMobileDountChartAssetCountKeyName: null,
        owaspMobileDonutChartAssetCount: null,
        owaspMobileDonuutChartAssetCountTotal: null
    })

    const setFilterValues = (field) => {
        setFilterData(prev => {
            return {
                ...prev,
                ...field
            }
        })
    }

    useEffect(() => {
        dispatch(getAllBu());
    }, []);

    const handleTimelineChange = () => {
        // timeline
        setFilterData((prevValue) => {
            const prev = {
                ...prevValue,
            };

            const fromDate = moment(timelineData?.startDate).format('YYYY-MM-DD')
            const endDate = moment(timelineData?.endDate).format('YYYY-MM-DD')

            prev['from'] = fromDate;
            prev['to'] = endDate;
            return prev;
        });

        setTimeline([{
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection'
        }])
        setIsTimelineModalOpen(false);
    }

    const toggle = () => {
        setIsTimelineModalOpen(!isTimelineModalOpen);
    }

    const clearAllFilters = () => {
        setFilterData({ 'buId': null, 'from': null, 'to': null });
        setTimeline([{
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection'
        }])
        setIsTimelineModalOpen(false);
    }

    useEffect(() => {
        const payload = filterFields;
        const overviewPayload = {
            "from": filterFields?.from,
            "to": filterFields?.to
        }
        setPageLoader(true);
        Promise.all([
            getBuRiskSummaryLineChartData(payload),
            getCWETrendsBarChartData(payload),
            getOverViewChartData(overviewPayload),
            getOwaspWebBarChartData(payload),
            getOwaspApiBarChartData(payload),
            getOwaspMobileBarChartData(payload)
        ]).then(([buRiskSummaryLineChartResp, CWETrendsBarChartResp, overviewResp, owaspWebLineChartResp, owaspApiLineChartResp, owaspMobileLineChartResp]) => {

            // Overview

            const overviewData = overviewResp?.data?.map(item => [item.createdOn, item.avgScore]);
            setOverviewRadialChartScores({
                applicationOverviewRadialChartScore: overviewResp?.applicationScore,
                networkOverviewRadialChartScore: overviewResp?.networkScore,
                overviewLineChartScoretrends: overviewData
            })

            //BU Summary
            const buRiskSummarydata = buRiskSummaryLineChartResp?.data?.map(item => [item.createdOn, item.avgScore]);
            setBuRiskSummaryRadialChartScores({
                applicationRadialChartScore: buRiskSummaryLineChartResp?.applicationScore,
                networkRadialChartScore: buRiskSummaryLineChartResp?.networkScore,
                buRiskSummaryLineChartScoretrends: buRiskSummarydata
            })

            // OWASP WEB
            const owaspWebData = owaspWebLineChartResp?.data?.map(item => item.count);
            const owaspxaxisData = owaspWebData?.map((item, index) => (index + 1).toString()).reverse()
            const owaspWebName = owaspWebLineChartResp?.data?.map(item => item.name);
            const owaspWebAssetsCount = Object.values(owaspWebLineChartResp?.appAssets).slice(0, -1)
            const owaspWebAssetsCountKeyName = Object.keys(owaspWebLineChartResp?.appAssets).slice(0, -1)
            setOwaspWebData({
                owaspWebBarChartCountData: owaspWebData,
                owaspWebBarChartNameData: owaspWebName,
                owaspWebBarChartXaxisData: owaspxaxisData,
                owaspWebCircleChartScoreData: owaspWebLineChartResp?.score,
                owaspWebDonutChartAssetCount: owaspWebAssetsCount,
                owaspWebDountChartAssetCountKeyName: owaspWebAssetsCountKeyName,
                owaspWebDonuutChartAssetCountTotal: owaspWebLineChartResp?.appAssets?.total
            })

            // OWASP API
            const owaspApiData = owaspApiLineChartResp?.data?.map(item => item.count);
            const owaspApiXaxisData = owaspApiData?.map((item, index) => (index + 1).toString()).reverse()
            const owaspApiName = owaspApiLineChartResp?.data?.map(item => item.name);
            const owaspApiAssetsCount = Object.values(owaspApiLineChartResp?.appAssets).slice(0, -1)
            const owaspApiAssetsCountKeyName = Object.keys(owaspApiLineChartResp?.appAssets).slice(0, -1)
            setOwaspApiData({
                owaspApiBarChartCountData: owaspApiData,
                owaspApiBarChartNameData: owaspApiName,
                owaspApiBarChartXaxisData: owaspApiXaxisData,
                owaspApiCircleChartScoreData: owaspApiLineChartResp?.score,
                owaspApiDonutChartAssetCount: owaspApiAssetsCount,
                owaspApiDountChartAssetCountKeyName: owaspApiAssetsCountKeyName,
                owaspApiDonuutChartAssetCountTotal: owaspApiLineChartResp?.appAssets?.total
            })

            // OWASP MOBILE
            const owaspMobileData = owaspMobileLineChartResp?.data?.map(item => item.count);
            const owaspMobileXaxisData = owaspMobileData?.map((item, index) => (index + 1).toString()).reverse()
            const owaspMobileName = owaspMobileLineChartResp?.data?.map(item => item.name);
            const owaspMobileAssetsCount = Object.values(owaspMobileLineChartResp?.appAssets).slice(0, -1)
            const owaspMobileAssetsCountKeyName = Object.keys(owaspMobileLineChartResp?.appAssets).slice(0, -1)
            setOwaspMobileData({
                owaspMobileBarChartCountData: owaspMobileData,
                owaspMobileBarChartNameData: owaspMobileName,
                owaspMobileBarChartXaxisData: owaspMobileXaxisData,
                owaspMobileCircleChartScoreData: owaspMobileLineChartResp?.score,
                owaspMobileDonutChartAssetCount: owaspMobileAssetsCount,
                owaspMobileDountChartAssetCountKeyName: owaspMobileAssetsCountKeyName,
                owaspMobileDonuutChartAssetCountTotal: owaspMobileLineChartResp?.appAssets?.total
            })

            //CWE Trends 
            const cweTrendsData = CWETrendsBarChartResp?.data?.map(item => item.count)
            const cweTrendsXAxisDetail = CWETrendsBarChartResp?.data?.map(item => `CWE-${item?.cwe}`)
            const cweTrendsName = CWETrendsBarChartResp?.data?.map(item => item.name);
            setCweTrendsBarChartData({
                cweTrendsBarChartSeriesData: cweTrendsData,
                cweTrendsBarChartXAxisData: cweTrendsXAxisDetail,
                cweTrendsBarChartNameData: cweTrendsName
            })

            setPageLoader(false);
        }).catch(() => {
            setPageLoader(false);
        })
    }, [filterFields])


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setIsTimelineModalOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    return (
        <React.Fragment>
            <Row className="mb-2 g-3">
                <Col className="align-self-center">
                    <h5 className="card-title m-0">Overview</h5>
                </Col>
                <Col>
                    <div className='d-flex justify-content-end'>
                        <div className='me-2'>
                            <RHFButton
                                btnName="Clear Filter"
                                outline={true}
                                icon="filter"
                                onClick={() => clearAllFilters()}
                            />
                        </div>
                        <div ref={ref}>
                            <TimelineButton
                                toggle={toggle}
                                timeline={timeline}
                                setTimeline={setTimeline}
                                setTimelineData={setTimelineData}
                                handleTimelineChange={handleTimelineChange}
                                isTimelineModalOpen={isTimelineModalOpen}
                            />
                        </div>
                    </div>
                </Col>
            </Row >
            {
                pageLoader ?
                    <PageLoader />
                    :
                    <>
                        <Row className="mb-2 g-3">
                            <Col sm="6">
                                <Card className="m-0 h-100">
                                    <CardBody>
                                        <div className="d-flex justify-content-around">
                                            <div>
                                                <CardText className="text-center">Apps</CardText>
                                                <RadialChart
                                                    radialchartColorsCode={[FINDING_SCORE_COLORS[(+(overviewChartData?.applicationOverviewRadialChartScore) + 0.1).toFixed(1)]]}
                                                    seriesData={[(10 - overviewChartData?.applicationOverviewRadialChartScore) * 10]} />
                                            </div>
                                            <div>
                                                <CardText className="text-center">Assets</CardText>
                                                <RadialChart
                                                    radialchartColorsCode={[FINDING_SCORE_COLORS[(+(overviewChartData?.networkOverviewRadialChartScore) + 0.1).toFixed(1)]]}
                                                    seriesData={[(10 - overviewChartData?.networkOverviewRadialChartScore) * 10]} />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card className="m-0 h-100">
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <CardText>Scores Trends</CardText>
                                                <LineChart
                                                    height="170"
                                                    seriesTitle='Score'
                                                    seriesData={overviewChartData?.overviewLineChartScoretrends}
                                                    lineColor={["#EF1A04"]} />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {/* SECTION 2 */}

                        <Row className="mb-2 g-3">
                            <Col className="align-self-center">
                                <h5 className="card-title m-0">BU - Risk Summary</h5>
                            </Col>
                            <Col>
                                <div className='d-flex justify-content-end'>
                                    <DropdownButton
                                        heading="Select BU"
                                        isSearchable={true}
                                        menuItems={bussinessUnit ? bussinessUnit?.data : []}
                                        handleClick={(item) => setFilterValues({ buId: item?.id })}
                                        filterFields={filterFields}
                                    />
                                </div>
                            </Col>

                        </Row>

                        <Row className="mb-2 g-3">
                            <Col sm="6">
                                <Card className="m-0 h-100">
                                    <CardBody>
                                        <div className="d-flex justify-content-around">
                                            <div>
                                                <CardText className="text-center">Apps</CardText>
                                                <RadialChart
                                                    radialchartColorsCode={[FINDING_SCORE_COLORS[(+(buRiskSummaryChartData?.applicationRadialChartScore) + 0.1).toFixed(1)]]}
                                                    seriesData={[(10 - buRiskSummaryChartData?.applicationRadialChartScore) * 10]}
                                                // seriesData={[80]}
                                                />
                                            </div>
                                            <div>
                                                <CardText className="text-center">Assets</CardText>
                                                <RadialChart
                                                    radialchartColorsCode={[FINDING_SCORE_COLORS[(+(buRiskSummaryChartData?.networkRadialChartScore) + 0.1).toFixed(1)]]}
                                                    seriesData={[(10 - buRiskSummaryChartData?.networkRadialChartScore) * 10]} />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card className="m-0 h-100">
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <CardText>Scores Trends</CardText>
                                                <LineChart
                                                    height="170"
                                                    seriesTitle='Avg. Finding'
                                                    seriesData={buRiskSummaryChartData?.buRiskSummaryLineChartScoretrends}
                                                    lineColor={["#F4B315"]}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {/* SECTION 3 */}

                        <Row className="mb-2 g-3">
                            <Col className="align-self-center">
                                <h5 className="card-title m-0">OWASP - Web</h5>
                            </Col>
                        </Row>
                        <Row className="mb-2 g-3">
                            <Col sm="6">
                                <Card className="m-0 h-100">
                                    <CardBody>
                                        <div className="d-flex justify-content-around p-0">
                                            <div>
                                                <CardText className="text-center">Score</CardText>
                                                <CircleChart
                                                    seriesData={[owaspWebData?.owaspWebCircleChartScoreData * 10]}
                                                    CircleChartColorsCode={["#EF1A04", "#FBDEDE"]}
                                                />
                                            </div>
                                            <div>
                                                <CardText className="text-center">Assets Count</CardText>
                                                <DonutChart
                                                    optionSeriesData={owaspWebData?.owaspWebDonutChartAssetCount}
                                                    donutColors={owaspMobileData?.owaspMobileDountChartAssetCountKeyName?.map(key => DONUT_DASHBOARD_COLORS[key])}
                                                    donutLabels={["Pending", "In Progress", "Resolved"]}
                                                    DonutChartTotal={owaspWebData?.owaspWebDonuutChartAssetCountTotal}
                                                    height="260px"
                                                />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card className="m-0 h-100">
                                    <CardBody>
                                        <div>
                                            <CardText>OWASP Top 10 Web</CardText>
                                            <BarChart
                                                seriesData={owaspWebData?.owaspWebBarChartCountData}
                                                xAxisCategoryData={owaspWebData?.owaspWebBarChartXaxisData}
                                                nameLables={owaspWebData?.owaspWebBarChartNameData}
                                                barColors={["#F1595E", "#F5CA3B", "#8AC926", "#3D82C4", "#6A4C93", "#4CBCCD", "#8093F1", "#B388EB", "#BA181B", "#E5383B"]}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>


                        {/* SECTION 4 */}

                        <Row className="mb-2 g-3">
                            <Col className="align-self-center">
                                <h5 className="card-title m-0">OWASP - API</h5>
                            </Col>
                        </Row>
                        <Row className="mb-2 g-3">
                            <Col sm="6">
                                <Card className="m-0 h-100">
                                    <CardBody>
                                        <div className="d-flex justify-content-around">
                                            <div>
                                                <CardText className="text-center">Score</CardText>
                                                <CircleChart
                                                    seriesData={[owaspApiData?.owaspApiCircleChartScoreData * 10]}
                                                    CircleChartColorsCode={["#F05002", "#F9D0C4"]}
                                                // nameLables={owaspApiData?.owaspApiBarChartNameData}
                                                />
                                            </div>
                                            <div>
                                                <CardText className="text-center">Assets Count</CardText>
                                                <DonutChart
                                                    optionSeriesData={owaspApiData?.owaspApiDonutChartAssetCount}
                                                    donutColors={owaspMobileData?.owaspMobileDountChartAssetCountKeyName?.map(key => DONUT_DASHBOARD_COLORS[key])}
                                                    donutLabels={["Pending", "In Progress", "Resolved"]}
                                                    DonutChartTotal={owaspApiData?.owaspApiDonuutChartAssetCountTotal}
                                                    height="260px"
                                                />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card className="m-0 h-100">
                                    <CardBody>
                                        <div>
                                            <CardText>OWASP Top 10 APIs</CardText>
                                            <BarChart
                                                seriesData={owaspApiData?.owaspApiBarChartCountData}
                                                xAxisCategoryData={owaspApiData?.owaspApiBarChartXaxisData}
                                                nameLables={owaspApiData?.owaspApiBarChartNameData}
                                                barColors={["#E55759", "#4BD39A", "#7A61BA", "#D8B655", "#90DCD0", "#F39C5B", "#E55759", "#4BD39A", "#7A61BA", "#4598D7"]}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {/* SECTION 5 */}

                        <Row className="mb-2 g-3">
                            <Col className="align-self-center">
                                <h5 className="card-title m-0">OWASP - Mobile</h5>
                            </Col>
                        </Row>
                        <Row className="mb-2 g-3">
                            <Col sm="6">
                                <Card className="m-0 h-100">
                                    <CardBody>
                                        <div className="d-flex justify-content-around">
                                            <div>
                                                <CardText className="text-center">Score</CardText>
                                                <CircleChart
                                                    seriesData={[owaspMobileData?.owaspMobileCircleChartScoreData * 10]}
                                                    CircleChartColorsCode={["#F5CC07", "#FEFCED"]}
                                                />
                                            </div>
                                            <div>
                                                <CardText className="text-center">Assets Count</CardText>
                                                <DonutChart
                                                    optionSeriesData={owaspMobileData?.owaspMobileDonutChartAssetCount}
                                                    donutColors={owaspMobileData?.owaspMobileDountChartAssetCountKeyName?.map(key => DONUT_DASHBOARD_COLORS[key])}
                                                    donutLabels={["Pending", "In Progress", "Resolved"]}
                                                    DonutChartTotal={owaspMobileData?.owaspMobileDonuutChartAssetCountTotal}
                                                    height="260px"
                                                />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card className="m-0 h-100">
                                    <CardBody>
                                        <div>
                                            <CardText>OWASP Top 10 Mobile</CardText>
                                            <BarChart
                                                seriesData={owaspMobileData?.owaspMobileBarChartCountData}
                                                xAxisCategoryData={owaspMobileData?.owaspMobileBarChartXaxisData}
                                                nameLables={owaspMobileData?.owaspMobileBarChartNameData}
                                                barColors={["#B25068", "#774360", "#4C3A51", "#712B74", "#C74B50", "#D49B54", "#1C4663", "#0D293A", "#864879", "#EF1A04"]}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {/* SECTION 6 */}

                        <Row className="mb-2 g-3">
                            <Col sm="6">
                                <div className="mb-2">
                                    <div className="align-self-center">
                                        <h5 className="card-title m-0">Chainning Attack</h5>
                                    </div>
                                </div>
                                <Card className="m-0 h-100">
                                    <CardBody>
                                        <Row className="d-flex justify-content-between">
                                            <Col>
                                                <ColumnChart />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <div className="mb-2">
                                    <Col className="align-self-center">
                                        <h5 className="card-title m-0">CWE Trends</h5>
                                    </Col>
                                </div>
                                <Card className="m-0 h-100">
                                    <CardBody>
                                        <div>
                                            <CardText>Apps - Top 10%</CardText>
                                            <BarChart
                                                seriesData={cweTrendsBarChartData?.cweTrendsBarChartSeriesData}
                                                xAxisCategoryData={cweTrendsBarChartData?.cweTrendsBarChartXAxisData}
                                                nameLables={cweTrendsBarChartData?.cweTrendsBarChartNameData}
                                                barColors={["#EF1A01", "#E5446D", "#5B8E7D", "#D95D39", "#49C7A2", "#F28854", "#F8D985", "#BCE975", "#6EB2DD", "#B5A2CF"]}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
            }
        </React.Fragment >
    )
}

export default RiskSummary
