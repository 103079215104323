import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { FormFeedback, Input, Label } from 'reactstrap';
import RHFDisableField from './RHFDisableField';
import moment from 'moment/moment'

const RHFDatePicker = ({ isController = false, ...props }) => {
  const {
    name,
    label,
    errorobj,
    control,
    value = null,
    handleOnChange,
    defaultValue = '',
    isRequired = true,
    disabled = false,
    onChange,
    inputFormat = 'DD/MM/YYYY',
    disabledField = false,
    ...extraProps
  } = props;

  if (!isController) {
    return (
      <Fragment>
        <div >
          <Label htmlFor="example-date-input" className="form-label">{label}</Label>
          {!disabledField ?
            <Input
              className="form-control text-uppercase"
              id="example-date-input"
              type="date"
              {...extraProps}
              disabled={disabled}
              defaultValue={defaultValue}
              onChange={(data) => {
                if (handleOnChange) {
                  handleOnChange(
                    data.target.value,
                    name
                  ); /* You must pass this function while isController is false -> else you will not get selected values */
                }
              }}
            />
            : <RHFDisableField fieldValue={''}></RHFDisableField>
          }
        </div>
      </Fragment>
    );
  }
  let isError = false;
  let errorMessage = '';
  let someValue = '';
  if (errorobj && Object.prototype.hasOwnProperty.call(errorobj, name)) {
    isError = true;
    errorMessage = errorobj[name].message;
  }
  if (defaultValue !== undefined) {
    someValue = defaultValue;
  }
  return <Controller
    name={name}
    control={control}
    defaultValue={someValue}
    render={({ field }) => (
      <Fragment>
        <Label htmlFor="example-date-input" className="form-label">{label} {isRequired && <span className="text-danger">*</span>}</Label>
        {!disabledField ?
          <Fragment>
            <Input
              className='text-uppercase'
              {...field}
              {...extraProps}
              type="date"
              invalid={isError}
              disabled={disabled}
              onChange={(_,) => {
                if (_?.target?.type === 'date') {
                  field.onChange(_.target.value)
                  return !onChange ? field.onChange(_.target.value) : onChange(_);
                }
              }}
            />
            {isError && <FormFeedback type="invalid">{errorMessage}</FormFeedback>}
          </Fragment>
          : <RHFDisableField fieldValue={moment(field.value).format('DD/MM/YYYY')}></RHFDisableField>
        }
      </Fragment>
    )}
  />
}

export default RHFDatePicker;
