import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Breadcrumb from 'components/Common/Breadcrumb';
import Table from 'components/Tables/Table';
import RHFTextField from 'components/FormControls/RHFTextField';
import AvtarGroup from 'components/FormControls/AvtarGroup';
import ActionButtons from 'components/FormControls/ActionButtons';
import FilterByStatus from 'components/Common/FilterByStatus';
import RHFButton from 'components/FormControls/RHFButton';
import DropdownButton from 'components/FormControls/DropdownButton';
import ManageColumns from 'components/Common/ManageColumns';
import { ROLE_PERMISSIONS } from 'constants/RolePermissions';
import { applicationScore, isModulePermisssion, TitleCase } from 'helpers/util';
import { APPLICATION } from 'helpers/Services/Application';
import DialogBox from 'components/Modals/DialogBox';
import { getAllBu } from 'store/bu/actions';
import { APPLICATION_STATUS, FINDING_SCORE_COLORS, CVSS, CVSS_TEXT_COLOR } from 'constants/Constant';
import TextChip from 'components/Common/TextChip';
import { deleteApplication, getUserListByRole } from 'helpers/BackendHelper';
import { Toast } from 'components/Common/Toaster';


const Application = () => {
    document.title = "Application | RiDiscovery";

    const [customActiveTab, setcustomActiveTab] = useState(1);
    const [filterColumns, setFilterColumns] = useState([]);
    const [columnOptions, setColumnOption] = useState([]);
    const [filterFields, setFilterFields] = useState({
        'search': null,
        'status': null,
        'buId': null,
    });
    const [isRefresh, setRefresh] = useState(false);
    const [activeRow, setActiveRow] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [allUsers, setAllUsers] = useState(null);
    const [isApiCalling, setApiCalling] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();
    const { bussinessUnit } = useSelector(state => state?.bu);

    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/applications", heading: "Application" }]

    useEffect(() => {
        const columnFilter = [...columns];
        setColumnOption([...columns]);
        setFilterColumns(columnFilter.filter(o => o.isVisible && o));
    }, [allUsers])


    useEffect(() => {
        dispatch(getAllBu());
    }, []);

    // const applicationFilteredColumn = JSON.parse(localStorage.getItem('applicationColumn'))

    useEffect(() => {

        if (isModulePermisssion(ROLE_PERMISSIONS.ASSIGN_REMOVE_TEAM)) {
            getUserListByRole().then((roleResp) => {
            const members = roleResp?.data?.reduce(function (r, a) {
                r[a?.roleId] = r[a?.roleId] || [];
                r[a?.roleId].push({ user_name: `${a?.firstName} ${a?.lastName}`, ...a });
                return r;
            }, Object.create(null));
            setAllUsers(members);
        });
        } 
    }, []);

    const getFilteredValues = (cols) => {
        setColumnOption(cols);
        setFilterColumns(cols.filter(o => o.isVisible && o));
        // const columnName = cols.filter(o => o.isVisible && o).map((o) => {
        //     return { name: o?.name }
        // })

        // localStorage.setItem('applicationColumn', JSON.stringify(cols.filter(o => o.isVisible && o)))
    }

    const handleToggle = () => {
        setIsModelOpen(!isModelOpen);
    };

    const toggleCustom = (tab, val) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
            setFilterValues({ status: val });
        }
    };

    const previewHandler = (obj) => {
        history.push({ pathname: `/application/${obj?.id}/overview`, state: { objData: obj } })
    };

    const addeHandler = (obj) => {
        history.push({ pathname: `/application/${obj?.id}/add-finding`, state: { appData: obj } })
    };

    const deleteHandler = (obj) => {
        setActiveRow(obj);
        handleToggle(true);
    };

    const handleDelete = () => {
        setApiCalling(true)
        if (!isApiCalling) {
            const payload = {
                "isDeleted": true,
                "applicationId": activeRow?.id
            }
            deleteApplication(payload).then(resp => {
                setApiCalling(false);
                Toast.success(resp?.message)
                handleToggle();
                setRefresh(prevValue => !prevValue)
            }).catch(() => {
                setApiCalling(false);
            });
        }
    }

    const editHandler = (row) => {
        history.push({ pathname: `/application/${row?.id}/edit-application`, state: { rowData: row } })
    };

    const setFilterValues = (field) => {
        setFilterFields(prev => {
            return {
                ...prev,
                ...field
            }
        })
    }

    const clearAllFilters = () => {
        setFilterValues({ 'search': null, 'status': null, 'buId': null });
        setcustomActiveTab(1);
    }

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };


    const optimizedFn = useCallback(debounce((val) => setFilterValues({ search: val })), []);

    const handleActionClick = (payload, actionType) => {
        const actionMapper = {
            preview: previewHandler,
            add: addeHandler,
            edit: editHandler,
            delete: deleteHandler
        };

        if (actionMapper[actionType]) {
            actionMapper[actionType](payload);
        }
    };

    const columns = React.useMemo(() => [
        {
            id: 'penId', //  @DM  - its required when sorting is true @DM
            name: "Pen Id",
            cell: (row) =>

                <div className="table-cell-click" onClick={() => previewHandler(row)}>
                    <span>{row?.penId ? row?.penId : "N/A"}</span>
                </div>,
            isVisible: true,
            width: "100px"
        },
        {
            id: 'name',
            name: "Application Name",
            cell: (row,) =>
                <div className="table-cell-click" onClick={() => previewHandler(row)}>
                    <span>{row?.applicationName}</span>
                </div>,
            isVisible: true,
            minWidth: "130px",
        },
        {
            id: 'type',
            name: "Type",
            width: '100px',
            cell: (row,) =>
                <div className="table-cell-click" onClick={() => previewHandler(row)}>
                    <span>{TitleCase(row?.applicationType)}</span>
                </div>,
            isVisible: true,
            minWidth: "130px",
        },
        {
            id: 'score',
            name: "Score",
            width: '100px',
            cell: (row) =>
                <div className="table-cell-click" onClick={() => previewHandler(row)}>
                    <TextChip
                        text={row?.applicationScore}
                        color={FINDING_SCORE_COLORS[(+row?.applicationScore).toFixed(1)] || "#E9E8EF"}
                        // color={CVSS[applicationScore(+row?.applicationScore)]}
                        // color={CVSS[applicationScore(+row?.applicationScore)]}
                        textColor={CVSS_TEXT_COLOR[applicationScore(+row?.applicationScore)]}
                    />
                </div>,
            isVisible: true,

        },
        {
            id: 'assignedPentester',
            name: "Pentester",
            width: "150px",
            minWidth: "190px",
            isVisible: true,
            cell: (row) => {
                return (
                    <AvtarGroup
                        // allUsers={allUsers}
                        assignedUsers={row?.securityTeam?.map(o => ({ ...o.user, isAssigned: true }))}
                        max={3}
                        type="pentester"
                        appId={row?.id}
                        popUpId={`${row?.id}_pentester`}
                        requestType="application"
                        assignRemovePermission={isModulePermisssion(ROLE_PERMISSIONS.ASSIGN_REMOVE_TEAM_P) && ['open', 'scheduled', 'in_progress'].includes(row?.applicationStatus)}
                    />
                )
            }
        },
        {
            id: 'assignedDeveloper',
            name: "Developer",
            width: "150px",
            minWidth: "190px",
            isVisible: true,
            cell: (row) => {
                return (
                    <AvtarGroup
                        // allUsers={allUsers}
                        assignedUsers={row?.developmentTeam?.map(o => ({ ...o.user, isAssigned: true }))}
                        max={3}
                        type="developer"
                        appId={row?.id}
                        popUpId={`${row?.id}_developer`}
                        requestType="application"
                        assignRemovePermission={isModulePermisssion(ROLE_PERMISSIONS.ASSIGN_REMOVE_TEAM_D) && ['open', 'scheduled', 'in_progress'].includes(row?.applicationStatus)}
                    />
                )
            }
        },
        {
            id: 'securityManager',
            name: "Security Manager",
            width: "150px",
            minWidth: "190px",
            isVisible: false,
            cell: (row) => {
                return (
                    <AvtarGroup
                        allUsers={allUsers}
                        assignedUsers={row?.securityManager?.map(o => ({ ...o.user, isAssigned: true }))}
                        max={3}
                        type="sm_manager"
                        appId={row?.id}
                        popUpId={`${row?.id}_sm_manager`}
                        requestType="application"
                        assignRemovePermission={isModulePermisssion(ROLE_PERMISSIONS.ASSIGN_REMOVE_TEAM) && ['open', 'scheduled', 'in_progress'].includes(row?.applicationStatus)}
                    />
                )
            }
        },
        {
            id: 'developmentManager',
            name: "Development Manager",
            width: "150px",
            minWidth: "190px",
            isVisible: false,
            cell: (row) => {
                return (
                    <AvtarGroup
                        allUsers={allUsers}
                        assignedUsers={row?.developmentManager?.map(o => ({ ...o.user, isAssigned: true }))}
                        max={3}
                        type="da_manager"
                        appId={row?.id}
                        popUpId={`${row?.id}_da_manager`}
                        requestType="application"
                        assignRemovePermission={isModulePermisssion(ROLE_PERMISSIONS.ASSIGN_REMOVE_TEAM) && ['open', 'scheduled', 'in_progress'].includes(row?.applicationStatus)}
                    />
                )
            }
        },
        {
            id: 'status',
            name: "Status",
            cell: (row) =>
                <TextChip
                    text={row?.applicationStatus && APPLICATION_STATUS[row?.applicationStatus] || "N/A"}
                    color="#E9E8EF"
                />,
            isVisible: true,
            width: '140px'
        },
        {
            name: "Actions",
            isVisible: true,
            width: "130px",
            cell: (row) => {
                return (
                    <ActionButtons
                        preview={{
                            handleClick: () => handleActionClick(row, 'preview',),
                            isPermission: ROLE_PERMISSIONS?.APPLICATION_OVERVIEW
                        }}
                        add={
                            isModulePermisssion(ROLE_PERMISSIONS?.ADD_FINDING) && ['open', 'scheduled', 'in_progress'].includes(row?.applicationStatus) &&
                            {
                                tooltip: 'Add Finding',
                                handleClick: () => handleActionClick(row, 'add'),
                                // isPermission: ROLE_PERMISSIONS?.ADD_FINDING
                            }}
                        edit={
                            isModulePermisssion(ROLE_PERMISSIONS?.UPDATE_APPLICATION) && ['open', 'scheduled', 'in_progress'].includes(row?.applicationStatus) &&
                            {
                                handleClick: () => handleActionClick(row, 'edit'),
                                // isPermission: ROLE_PERMISSIONS?.UPDATE_APPLICATION,
                            }}
                        delete={
                            isModulePermisssion(ROLE_PERMISSIONS?.DELETE_APPLICATION) && ['open', 'scheduled', 'in_progress'].includes(row?.applicationStatus) &&
                            {
                                handleClick: () => handleActionClick(row, 'delete'),
                                // isPermission: ROLE_PERMISSIONS?.DELETE_APPLICATION,
                            }
                        }
                    />
                );
            },
        }
    ], [allUsers])

    return (
        <React.Fragment>
            <Breadcrumb title="Application" breadcrumbItem={breadcrumbItems} />
            <Row>
                <Col lg={7} xs={12}>
                    {isModulePermisssion(ROLE_PERMISSIONS?.APPLICATION_FILTER_AND_SEARCH) &&
                        <FilterByStatus
                            statusList={[
                                { name: 'All', value: null, icon: 'disc' },
                                { name: 'Open', value: 'open', icon: 'target' },
                                { name: 'Scheduled', value: 'scheduled', icon: 'clock' },
                                { name: 'In progress', value: 'in_progress', icon: 'loader' },
                                // { name: 'Action Required', value: 'action_required', icon: 'alert-circle' },
                                { name: 'In Review', value: 'in_review', icon: 'file-text' },
                                { name: 'Completed', value: 'completed', icon: 'check-circle' },
                            ]}
                            toggleCustom={toggleCustom}
                            customActiveTab={customActiveTab}
                        />}
                </Col>
                <Col lg={5} xs={12}>
                    <div className='d-flex justify-content-end'>
                        <div className='me-2'>
                            <RHFButton
                                btnName="Clear Filter"
                                outline={true}
                                icon="filter"
                                onClick={() => clearAllFilters()}
                            />
                        </div>
                        <div className='me-2'>
                            <ManageColumns
                                // allColumns={columns}
                                allColumns={columnOptions}
                                // getCols={getCols}
                                getFilteredValues={getFilteredValues}
                            />
                        </div>
                        <div>
                            <DropdownButton
                                heading="Select BU"
                                isSearchable={true}
                                menuItems={bussinessUnit ? bussinessUnit?.data : []}
                                handleClick={(item) => {
                                    setFilterValues({ buId: item?.id })
                                }}
                                filterFields={filterFields}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Card className='mt-3'>
                <CardHeader>
                    <Row>
                        <Col xs={6} lg={3}>
                            {isModulePermisssion(ROLE_PERMISSIONS?.APPLICATION_FILTER_AND_SEARCH) &&
                                <RHFTextField
                                    id="search"
                                    name="search"
                                    placeholder="Search here"
                                    isController={false}
                                    handleOnChange={optimizedFn}
                                />
                            }
                        </Col>
                        <Col xs={6} lg={9}>
                            {isModulePermisssion(ROLE_PERMISSIONS?.ADD_APPLICATION) &&
                                <div className="d-flex justify-content-end">
                                    <RHFButton
                                        btnName="Add"
                                        icon="plus"
                                        onClick={() => {
                                            history.push('/application/add')
                                        }}
                                    />
                                </div>}
                        </Col>
                    </Row>
                </CardHeader>
                {isModulePermisssion(ROLE_PERMISSIONS?.APPLICATION_LIST) &&
                    <CardBody>
                        <Table
                            columns={filterColumns}
                            dataURL={filterColumns?.length > 0 && APPLICATION.listAllApplication}
                            isRefresh={isRefresh}
                            filter={filterFields}
                        />
                    </CardBody>
                }
                {/* <iframe src="https://www.w3schools.com/" title="W3Schools Free Online Web Tutorials">
                </iframe> */}
            </Card>


            <DialogBox
                isModelOpen={isModelOpen}
                handleToggle={handleToggle}
                modelSize="sm-100"
                title="Confirmation"
                actions={
                    <>
                        <RHFButton
                            btnName="Cancel"
                            outline={true}
                            onClick={handleToggle}
                        />
                        <RHFButton
                            btnName="Okay"
                            onClick={handleDelete}
                            isLoading={isApiCalling}
                        />
                    </>
                }
            >
                <div className="text-center">
                    <i className="mdi mdi-alert-circle-outline" style={{ fontSize: "5em", color: "orange" }} />
                    <h5>Are you sure?</h5>
                    <p className="fs-5">You won't be able to revert this!</p>
                </div>
            </DialogBox>


        </React.Fragment>
    )
}

export default Application;