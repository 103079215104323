import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap';
import PageLoader from 'components/PageLoader';
import RHFButton from 'components/FormControls/RHFButton';
import Breadcrumb from "components/Common/Breadcrumb";
import { getAllSubscriptions, getCurrentSubscription, updateSubscriptionplan } from 'helpers/BackendHelper';
import DonutChart from 'components/Common/Graphs/DonutChart';
import SubscriptionPlanCard from 'components/Common/SubscriptionPlanCard';
import DialogBox from 'components/Modals/DialogBox';
import RHFAutoCompleteSelect from 'components/FormControls/RHFAutoCompleteSelect';
import { Toast } from 'components/Common/Toaster';

const MySubscription = () => {
    const [currentPlanDetails, setCurrentPlan] = useState(null)
    const [allSubscriptionsList, setAllSubscriptions] = useState(null)
    const [allSubscriptionsListCopy, setAllSubscriptionsCopy] = useState(null)
    const [pageLoader, setPageLoader] = useState(false);
    const [isApiCalling, setApiCalling] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [planType, setSelectedPlanType] = useState(null);
    const [newPlan, setNewPlan] = useState(null);

    useEffect(() => {
        setPageLoader(true)
        getCurrentSubscription().then(resp => {
            if (resp.status) {
                setCurrentPlan(resp?.data)
            }
        }).finally(() => {
            setPageLoader(false)
        });
    }, []);

    useEffect(() => {
        getAllSubscriptions().then(resp => {
            if (resp.status) {
                setAllSubscriptions(resp?.data)
            }
        })
    }, []);

    useEffect(() => {
        if (currentPlanDetails) {
            const allSubscriptionsListCopy = allSubscriptionsList?.map((plan) => {
                if (plan?.id === currentPlanDetails?.parentPlanId) {
                    plan.isActive = true;
                    plan.type = currentPlanDetails?.type;
                } else {
                    plan.isActive = false;
                }
                return plan;
            });
            setAllSubscriptionsCopy(allSubscriptionsListCopy);
        }
    }, [currentPlanDetails, allSubscriptionsList])

    const handleNewPlan = (plan) => {
        handleToggle();
        setNewPlan(plan)
    }

    const handleToggle = () => {
        setIsModelOpen(!isModelOpen);
    };

    const changeMyPlan = () => {
        setApiCalling(true);
        const payload = {
            "planId": newPlan?.id,
            "type": planType
        }
        updateSubscriptionplan(payload).then(resp => {
            if (resp?.status) {
                Toast.success(resp?.message)
                handleToggle();
            }
        }).finally(() => {
            setApiCalling(false);
        })
    }

    const handlePlanType = (val) => {
        setSelectedPlanType(val)
    }

    const breadcrumbItems = [
        { path: "/", heading: "Home" },
        { path: "/my-subscription", heading: "My Subscription" }
    ]

    return (
        <React.Fragment>
            {pageLoader ? <PageLoader /> :
                <div className="page-content">
                    <Breadcrumb title="My Subscription" breadcrumbItem={breadcrumbItems} />
                    <Card>
                        <CardBody>
                            <Row className='mb-3'>
                                <Col lg={4}>
                                    <p className='text-center fw-bold fs-5'>Users</p>
                                    <DonutChart
                                        optionSeriesData={[currentPlanDetails?.current?.users, currentPlanDetails?.total_users - currentPlanDetails?.current?.users]}
                                        donutColors={["#04009a", "#04009ab3"]}
                                        donutLabels={["Current Users", "Remaining Users"]}
                                        DonutChartTotal={currentPlanDetails?.total_users}
                                        height="300"
                               
                               />
                                </Col>
                                <Col lg={4}>
                                    <p className='text-center fw-bold fs-5'>Applications</p>
                                    <DonutChart
                                        optionSeriesData={[currentPlanDetails?.current?.apps, currentPlanDetails?.total_applications - currentPlanDetails?.current?.apps]}
                                        donutColors={["#ff7f5c", "#ff7f5cb3"]}
                                        donutLabels={["Current Apps", "Remaining Apps"]}
                                        DonutChartTotal={currentPlanDetails?.total_applications}
                                        height="300"
                                    />
                                </Col>
                                <Col lg={4}>
                                    <p className='text-center fw-bold fs-5'>Networks</p>
                                    <DonutChart
                                        optionSeriesData={[currentPlanDetails?.current?.networks, currentPlanDetails?.total_applications - currentPlanDetails?.current?.networks]}
                                        donutColors={["#5d8bf4", "#5d8bf4b3"]}
                                        donutLabels={["Current Networks", "Remaining Networks"]}
                                        DonutChartTotal={currentPlanDetails?.total_applications}
                                        height="300"
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Row className='mb-0 plan-detail-wrapper g-3'>
                        {allSubscriptionsListCopy?.map((plan, key) => (
                            <Col lg={3} sm={6} key={key}>
                                <SubscriptionPlanCard
                                    planDeatils={plan}
                                    handleToggle={() => handleNewPlan(plan)}
                                />
                            </Col>
                        ))}
                    </Row>

                    <DialogBox
                        isModelOpen={isModelOpen}
                        handleToggle={handleToggle}
                        modelSize="sm-100"
                        title="Confirmation"
                        actions={
                            <>
                                <RHFButton
                                    btnName="Cancel"
                                    outline={true}
                                    onClick={handleToggle}
                                />
                                {
                                    planType &&
                                    <RHFButton
                                        btnName="Okay"
                                        onClick={changeMyPlan}
                                        isLoading={isApiCalling}
                                    />
                                }
                            </>
                        }
                    >
                        <div>
                            <div className="text-center">
                                <i className="mdi mdi-alert-circle-outline" style={{ fontSize: "5em", color: "orange" }} />
                                <h5>Are you sure?</h5>
                                <p className="fs-5">Do you want to switch to new plan?</p>
                            </div>
                            <br />
                            <RHFAutoCompleteSelect
                                id="planType"
                                label="Plan Type"
                                name="planType"
                                options={[{ label: 'Monthly', value: 'monthly' }, { label: 'Yearly', value: 'yearly' }]}
                                isMultiple={false}
                                isController={false}
                                handleOnChange={handlePlanType}
                            />
                        </div>
                    </DialogBox>
                </div >
            }
        </React.Fragment >
    )
}

export default MySubscription;
