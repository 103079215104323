import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardText, Col, Row } from 'reactstrap'
import Breadcrumb from 'components/Common/Breadcrumb'
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup";
import { addComment, approveRejectException, getNetworkFinding, getNetworkFindingLogs } from 'helpers/BackendHelper';
import RHFButton from 'components/FormControls/RHFButton';
import RHFTextField from 'components/FormControls/RHFTextField';
import RHFCheckbox from 'components/FormControls/RHFCheckbox';
import { applicationScore, htmlSanitizer, isModulePermisssion, renderTimestamp } from 'helpers/util';
import { ROLE_PERMISSIONS } from 'constants/RolePermissions';
import defaultImage from "assets/images/Default-User-Image.jpeg";
import { Toast } from 'components/Common/Toaster';
import PageLoader from 'components/PageLoader';
import { CVSS, CVSS_TEXT_COLOR, FINDING_SCORE_COLORS, NETWORK_ASSET_TYPE, NETWORK_FINDING_LOGS, NETWORK_FINDING_TYPES } from 'constants/Constant';
import moment from 'moment';
import TextChip from 'components/Common/TextChip';

const NetworkFindingOverview = () => {
    document.title = "Finding-Overview | RiDiscovery";
    const { findingId, networkId } = useParams();
    const [isException, setException] = useState(false);
    const [commentList, setCommentList] = useState([]);
    const [findingDetails, setFindingDetails] = useState(null);
    const [pageLoader, setPageLoader] = useState(false);
    const [isApiCalling, setApiCalling] = useState(false);
    const [findingLogs, setFindingLogs] = useState([]);

    const isApproveRejectPermission = isModulePermisssion(ROLE_PERMISSIONS.NETWORK_APPROVE_REJECT_EXCEPTIONS);
    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/networks", heading: "Network" },
    { path: `/network/${networkId}/network-overview`, heading: `Network Overview` },
    { path: `/network/${networkId}/finding/${findingId}/overview`, heading: "Finding Overview" }]


    const commentSchema = yup.object().shape({
        comment: yup.string().trim().max(200, 'Comment should be less then 200 charecters').required('Comment is required')
    });

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(commentSchema),
    });


    const apiCalls = () => {
        const params = {
            id: findingId
        }
        setPageLoader(true)
        getNetworkFinding({ ...params }).then(resp => {
            setFindingDetails(resp?.data);
            setCommentList(resp?.data?.comments.sort((a, b) => Number(b.isException) - Number(a.isException)));
            setPageLoader(false)
        })
    }

    useEffect(() => {
        apiCalls()
    }, []);

    useEffect(() => {
        if (findingId) {
            getNetworkFindingLogs({ id: findingId }).then(resp => {
                setFindingLogs(resp?.data);
            })
        }
    }, [findingId])

    const handleSwitchChange = (val) => {
        setException(val)
    };

    const onSubmit = (values) => {
        setApiCalling(true)
        if (!isApiCalling) {
            const data = {
                comment: values?.comment,
                isException,
                "findingId": findingId,
                "findingType": "network"
            }
            addComment(data).then(resp => {
                setApiCalling(false);
                // setCommentList(prevData => ([resp?.data, ...prevData]))
                let newCommentList = [resp?.data, ...commentList]
                let sortedCommentsExceptionWise = newCommentList.sort((a, b) => Number(b.isException) - Number(a.isException))
                setCommentList(sortedCommentsExceptionWise)
                getNetworkFindingLogs({ id: findingId }).then(response => {
                    setFindingLogs(response?.data);
                })
                Toast.success(resp?.message)
                reset()
            }).catch(() => {
                setApiCalling(false);
            });
        }
    }

    const takeAction = ({ obj, action }) => {
        const payload = {
            "commentId": obj?.id,
            "isException": obj?.isException,
            "status": action,
            "findingId": findingId
        }
        approveRejectException(payload).then(resp => {
            Toast.success(resp?.message);
            apiCalls();
        });
    }

    const renderText = (log) => {
        let finalText = '';
        if ([NETWORK_FINDING_LOGS.update_assetType]?.includes(log?.activityName)) {
            finalText = <span>has <b>updated</b> asset type.</span>
        }
        else if ([NETWORK_FINDING_LOGS.update_findingType]?.includes(log?.activityName)) {
            finalText = <span>has <b>updated</b> finding type.</span>
        }
        else if ([NETWORK_FINDING_LOGS.update_severity]?.includes(log?.activityName)) {
            finalText = <span>has <b>updated</b> severity.</span>
        }
        else if ([NETWORK_FINDING_LOGS.add_comment]?.includes(log?.activityName)) {
            finalText = <span>has added<b> comment.</b></span>
        }
        else if ([NETWORK_FINDING_LOGS.exception_approved]?.includes(log?.activityName)) {
            finalText = <span>has<b> Approved Exeption.</b></span>
        }
        else if ([NETWORK_FINDING_LOGS.exception_rejected]?.includes(log?.activityName)) {
            finalText = <span>has<b> Rejected Exeption.</b></span>
        }
        return finalText;
    }

    return (
        <React.Fragment>
            {pageLoader ?
                <PageLoader />
                :
                <div className="page-content">
                    <Breadcrumb title="Finding" breadcrumbItem={breadcrumbItems} />
                    <Row className='g-3'>
                        <Col lg={9}>
                            <Card>
                                <CardBody>
                                    {findingDetails?.findingType &&
                                        <Card className="mb-3 border-0">
                                            <CardHeader className="py-2 rounded-0 card-header-bg">
                                                <h5 className="card-title m-0">Finding Type</h5>
                                            </CardHeader>
                                            <CardBody className="card-body p-2 mx-2">
                                                <CardText> {NETWORK_FINDING_TYPES.find(o => o.value === findingDetails?.findingType)?.label} </CardText>
                                            </CardBody>
                                        </Card>
                                    }
                                    {findingDetails?.businessUnit &&
                                        <Card className="mb-3 border-0">
                                            <CardHeader className="py-2 rounded-0 card-header-bg">
                                                <h5 className="card-title m-0">Business Unit</h5>
                                            </CardHeader>
                                            <CardBody className="card-body p-2 mx-2">
                                                <CardText dangerouslySetInnerHTML={{ __html: htmlSanitizer(findingDetails?.businessUnit) }} />
                                            </CardBody>
                                        </Card>
                                    }
                                    {findingDetails?.payload &&
                                        <Card className="mb-3 border-0">
                                            <CardHeader className="py-2 rounded-0 card-header-bg">
                                                <h5 className="card-title m-0">Payload</h5>
                                            </CardHeader>
                                            <CardBody className="card-body p-2 mx-2">
                                                <CardText dangerouslySetInnerHTML={{ __html: htmlSanitizer(findingDetails?.payload) }} />
                                            </CardBody>
                                        </Card>
                                    }
                                    {findingDetails?.server &&
                                        <Card className="mb-3 border-0">
                                            <CardHeader className="py-2 rounded-0 card-header-bg">
                                                <h5 className="card-title m-0">Server</h5>
                                            </CardHeader>
                                            <CardBody className="card-body p-2 mx-2">
                                                <CardText dangerouslySetInnerHTML={{ __html: htmlSanitizer(findingDetails?.server) }} />
                                            </CardBody>
                                        </Card>
                                    }
                                    {findingDetails?.threatLevel &&
                                        <Card className="mb-3 border-0">
                                            <CardHeader className="py-2 rounded-0 card-header-bg">
                                                <h5 className="card-title m-0">Threat Level</h5>
                                            </CardHeader>
                                            <CardBody className="card-body p-2 mx-2">
                                                <CardText dangerouslySetInnerHTML={{ __html: htmlSanitizer(findingDetails?.threatLevel) }} />
                                            </CardBody>
                                        </Card>
                                    }
                                    {findingDetails?.port &&
                                        <Card className="mb-3 border-0">
                                            <CardHeader className="py-2 rounded-0 card-header-bg">
                                                <h5 className="card-title m-0">Port</h5>
                                            </CardHeader>
                                            <CardBody className="card-body p-2 mx-2">
                                                <CardText dangerouslySetInnerHTML={{ __html: htmlSanitizer(findingDetails?.port) }} />
                                            </CardBody>
                                        </Card>
                                    }
                                </CardBody>
                            </Card>
                            {commentList?.length > 0 ?
                                <Card>
                                    <CardHeader tag="h6">
                                        Comments
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <div className='comments'>
                                                    {commentList?.map(comment => (
                                                        <div className="d-flex justify-content-between align-items-center" key={comment?.id}>
                                                            <div className="comment text-justify">
                                                                <div className='d-flex align-items-baseline'>
                                                                    <div>
                                                                        <img src={comment?.user?.profile ? comment?.user?.profile : defaultImage} alt="" className="rounded-circle" width="50" height="50" />
                                                                    </div>
                                                                    <h6 className='ms-2'>{`${comment?.user?.firstName} ${comment?.user?.lastName}`}</h6>
                                                                    <span className='ms-2'>{renderTimestamp(comment?.createdAt)}</span>
                                                                    {(comment?.isException && comment?.status === "pending") && <span className="ms-2 badge bg-danger">Exception</span>}
                                                                    {(comment?.isException && comment?.status === "approved") && <span className="ms-2 badge bg-success">Approved</span>}
                                                                    {(comment?.isException && comment?.status === "rejected") && <span className="ms-2 badge bg-warning">Rejected</span>}
                                                                </div>
                                                                <p className='fs-6'>{comment?.comment}</p>
                                                            </div>
                                                            {(isApproveRejectPermission && comment?.isException && comment?.status === 'pending') &&
                                                                <div className="comment-action">
                                                                    <RHFButton
                                                                        btnName="Approve"
                                                                        className="mx-1"
                                                                        outline={true}
                                                                        onClick={() => takeAction({ obj: comment, action: 'approved' })}
                                                                    />
                                                                    <RHFButton
                                                                        btnName="Reject"
                                                                        className="mx-1"
                                                                        outline={true}
                                                                        onClick={() => takeAction({ obj: comment, action: 'rejected' })}
                                                                    />
                                                                    {/* <RHFButton
                                                                    btnName="Correction"
                                                                    type="submit"
                                                                    className="mx-2"
                                                                    outline={true}
                                                                /> */}
                                                                </div>
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <hr></hr> */}

                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <Row className="mb-3">
                                                <Col>
                                                    <RHFTextField
                                                        id="comment"
                                                        label="Comment"
                                                        name="comment"
                                                        multiline={true}
                                                        rows={3}
                                                        type="textarea"
                                                        errorobj={errors}
                                                        control={control}
                                                        isController={true}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                {isModulePermisssion(ROLE_PERMISSIONS.NETWORK_GENERATE_EXCEPTIONS) &&
                                                    <Col>
                                                        <div className="form-check">
                                                            <RHFCheckbox
                                                                name="exception"
                                                                label="Raise Exception ?"
                                                                checked={isException}
                                                                isController={false}
                                                                onChange={handleSwitchChange}
                                                            />
                                                        </div>
                                                    </Col>
                                                }
                                                <Col>
                                                    <div className='d-flex justify-content-end'>
                                                        <RHFButton
                                                            btnName="Save"
                                                            type="submit"
                                                            isLoading={isApiCalling}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </form>
                                    </CardBody>
                                </Card>
                                :
                                <Card>
                                    <CardBody>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <Row className="mb-3">
                                                <Col>
                                                    <RHFTextField
                                                        id="comment"
                                                        label="Comment"
                                                        name="comment"
                                                        multiline={true}
                                                        rows={3}
                                                        type="textarea"
                                                        errorobj={errors}
                                                        control={control}
                                                        isController={true}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                {isModulePermisssion(ROLE_PERMISSIONS.NETWORK_GENERATE_EXCEPTIONS) &&
                                                    <Col>
                                                        <div className="form-check">
                                                            <RHFCheckbox
                                                                name="exception"
                                                                label="Raise Exception ?"
                                                                checked={isException}
                                                                isController={false}
                                                                onChange={handleSwitchChange}
                                                            />
                                                        </div>
                                                    </Col>
                                                }
                                                {isModulePermisssion(ROLE_PERMISSIONS.ADD_COMMENTS_ON_FINDING) &&
                                                    <Col>
                                                        <div className='d-flex justify-content-end'>
                                                            <RHFButton
                                                                btnName="Save"
                                                                type="submit"
                                                                isLoading={isApiCalling}
                                                            />
                                                        </div>
                                                    </Col>
                                                }
                                            </Row>
                                        </form>
                                    </CardBody>
                                </Card>
                            }
                            {/** Logs section */}
                            {findingLogs?.length > 0 &&
                                <Card>
                                    <CardHeader tag="h6">
                                        Finding Logs
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <div className='audit-logs'>
                                                    {findingLogs?.map(log => (
                                                        <div className="logs-wrapper" key={log?.id}>
                                                            <div className='log-avatar'>
                                                                <img src={log?.createdByUser?.profile ? log?.createdByUser?.profile : defaultImage} alt="" className="rounded-circle" width="50" height="50" />
                                                            </div>
                                                            <div className='log-details-wrapper'>
                                                                <div className='log-details'>
                                                                    <h6 className='m-0 p-0'>
                                                                        {`${log?.createdByUser?.firstName} ${log?.createdByUser?.lastName}`}
                                                                    </h6>
                                                                    <span className='ms-1'>
                                                                        {renderText(log)}
                                                                    </span>

                                                                    <span className='ms-auto log-date-time'>{moment(log?.createdAt).format('Do MMM YYYY h:mm a')}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            }


                        </Col>
                        <Col lg={3}>
                            <Card>
                                <CardBody >
                                    <div className="app-detail-wrapper">
                                        {findingDetails?.findingStatus &&
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-title-finding">Status</span>
                                                        <span className="value-text">{findingDetails?.findingStatus}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                        {findingDetails?.assetName &&
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-title-finding">Asset Name</span>
                                                        <span className="value-text">{findingDetails?.assetName}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                        {findingDetails?.assetType &&
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-title-finding">Asset Type</span>
                                                        <span className="value-text">{NETWORK_ASSET_TYPE.find(o => o.value === findingDetails?.assetType)?.label}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                        {findingDetails?.ipAddress &&
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-title-finding">IP Address</span>
                                                        <span className="value-text">{findingDetails?.ipAddress}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                        {findingDetails?.os &&
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-title-finding">OS</span>
                                                        <span className="value-text">{findingDetails?.os}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                        {findingDetails?.severity &&
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-title-finding">Severity</span>
                                                        <div className='value-text' >
                                                            <div className="mb-1">
                                                                <TextChip
                                                                    text={applicationScore(+findingDetails?.severity)}
                                                                    color={findingDetails?.cvss ? CVSS[findingDetails?.cvssName] : FINDING_SCORE_COLORS[(+findingDetails?.severity).toFixed(1)]}
                                                                    textColor={findingDetails?.cvss ? CVSS_TEXT_COLOR[findingDetails?.cvssName] : CVSS_TEXT_COLOR[applicationScore(+findingDetails?.severity)]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </p>
                                                    <p className='value-row'>
                                                        <span className="value-title-finding">Score</span>
                                                        <span className="value-text mb-1">
                                                            <TextChip
                                                                text={findingDetails?.severityScore}
                                                                color={findingDetails?.cvss ? CVSS[findingDetails?.cvssName] : FINDING_SCORE_COLORS[(+findingDetails?.severityScore).toFixed(1)]}
                                                                textColor={findingDetails?.cvss ? CVSS_TEXT_COLOR[findingDetails?.cvssName] : CVSS_TEXT_COLOR[applicationScore(+findingDetails?.severityScore)]}
                                                            />
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                        {findingDetails?.cve &&
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-title-finding">CVE</span>
                                                        <span className="value-text badge badge-soft-primary bg-soft-primary text-reset p-1">{findingDetails?.cve}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                        {findingDetails?.cwe &&
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-title-finding">CWE</span>
                                                        <span className="value-text badge badge-soft-primary bg-soft-primary text-reset p-1">{findingDetails?.cwe}</span>
                                                        <span className='value-text'>Improper Input Validation</span>
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div >
            }
        </React.Fragment >
    )
}

export default NetworkFindingOverview
