import {
    ADD_KICK_OFF,
    ADD_KICK_OFF_FAIL,
    ADD_KICK_OFF_SUCCESS,
    UPDATE_KICK_OFF,
    UPDATE_KICK_OFF_FAIL,
    UPDATE_KICK_OFF_SUCCESS,
} from "./actionTypes"


const initialState = {
    isLoading: false,
    isError: false,
    kickoff: [],

}

const Kickoff = (state = initialState, action) => {
    switch (action.type) {
        case ADD_KICK_OFF:
            state = {
                ...state,
                isLoading: true,
            }
            break
        case ADD_KICK_OFF_SUCCESS:
            state = {
                ...state,
                isLoading: false,
            }
            break
        case ADD_KICK_OFF_FAIL:
            state = {
                ...state,
                isLoading: false,
                isError: true
            }
            break

        case UPDATE_KICK_OFF:
            state = {
                ...state,
                isLoading: true,
            }
            break
        case UPDATE_KICK_OFF_SUCCESS:
            state = {
                ...state,
                isLoading: false,
            }
            break
        case UPDATE_KICK_OFF_FAIL:
            state = {
                ...state,
                isLoading: false,
                isError: true
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export default Kickoff;
