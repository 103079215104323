import React from 'react';
import { htmlSanitizer } from 'helpers/util';
let lastCount = 0;

const uniqueKey = (prefix = 'key') => {
    lastCount++;
    return `${prefix}${lastCount}`;
}

export default function PocImageSection({ description, pocs }) {
    return (<>
        <p dangerouslySetInnerHTML={{
            __html: htmlSanitizer(description)
        }} />
        {pocs?.map((data, i) => {
            return <div key={uniqueKey('index')} className="fs-5">


                Step {i + 1} :           <span dangerouslySetInnerHTML={{
                    __html: htmlSanitizer(data.description)
                }} />

                <div className='row'>
                    {data['images'].map((document) => {
                        return (<div key={uniqueKey("image")} className="col-md-12 col-xs-12 mb-2">
                            <img src={document} className="img img-responsive img-thumbnail " style={{ /* width: "100%",  */maxHeight: "390px" }} />
                        </div>)
                    })}
                </div>
            </div>
        })}
    </>)
}