import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
// Import Routes all
import { userRoutes, authRoutes, globalUserRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";

import { getLoginUserDetail, getPermission } from "store/actions";
import { isModulePermisssion } from "helpers/util";
import PageLoader from "components/PageLoader";
import { validateTanent } from "helpers/BackendHelper";
import { ENV_CONFIG } from "constants/Constant";


const App = () => {

  const { user } = useSelector(state => state?.Login)
  const dispatch = useDispatch();
  const [isTenantChecked, setIsTenantChecked] = useState(false)

  const tenantName = location?.host?.split('.')[0];
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setTimeout(() => {
        setIsTenantChecked(true);
      }, 1500)
    } else {
      validateTanent({ "tenantName": tenantName }).then(resp => {
        if (resp.status) {
          setIsTenantChecked(true);
        } else {
          setIsTenantChecked(false);
          location.href = 'https://app.ridiscovery.com/';
        }
      }).catch(() => {
        setIsTenantChecked(false);
      })
    }

    if (localStorage.getItem("authUser")) {
      if (!user) {
        dispatch(getLoginUserDetail());
      }
    }
  }, []);

  useEffect(() => {
    if (location?.pathname !== '/verify-otp') {
      if (user?.user) {
        const params = {
          slug: user?.user?.roleId
        }
        dispatch(getPermission({ ...params }));
      }
    }
  }, [user?.user?.roleId])

  return (
    <React.Fragment>
      {isTenantChecked ?
        <Router>
          <Switch>
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                // permission={true}
                permission={route?.allowAlways ? route.allowAlways : !localStorage.getItem("authUser")}
                exact
              />
            ))}
            {
              (location?.port === ENV_CONFIG.PORT2 || (tenantName !== ENV_CONFIG.APP_TENANT && location?.port !== ENV_CONFIG.PORT1)) ?
                userRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={VerticalLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    permission={route?.isRouteAccessible && isModulePermisssion(route?.isRouteAccessible)}
                    showEmptyLayout={route?.showEmptyLayout}
                    exact
                  />
                ))
                :
                globalUserRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={VerticalLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    // permission={true}
                    permission={route?.allowAlways ? route.allowAlways : !localStorage.getItem("authUser")}
                    exact
                  />
                ))
            }
          </Switch>
        </Router>
        : <PageLoader />
      }
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)