import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Breadcrumb from 'components/Common/Breadcrumb';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import RHFDatePicker from 'components/FormControls/RHFDatePicker';
import RHFButton from 'components/FormControls/RHFButton';
import RHFTextField from 'components/FormControls/RHFTextField';
import { today, SUPPORTED_FORMATS, nameRegex, CERTIFICATE_PARAMETER_FEILD } from 'constants/Constant';
import { addCertificateTemplate, editCertificateTemplate, getCertificateTemplateDetails } from 'helpers/BackendHelper';
import RHFFileUpload from 'components/FormControls/RHFFileUpload';
import avatar from "assets/images/Default-User-Image.jpeg";
import { Toast } from 'components/Common/Toaster';
import PageLoader from 'components/PageLoader';
import RHFDropZone from 'components/FormControls/RHFDropZone';
import RHFCKEditor from 'components/FormControls/RHFCKEditor';
import moment from 'moment';

const CertificateTemplateAddEdit = () => {

    const [certificateDetails, setCertificateDetails] = useState(null);
    const { templateId } = useParams();
    let history = useHistory()
    const isEditMode = templateId;
    const [pageLoader, setPageLoader] = useState(false);
    const [isApiCalling, setApiCalling] = useState(false);
    const [fileData, setSignatureData] = useState(null);
    document.title = `${(isEditMode) ? "Edit Certificate-Template" : "Add Certificate-Template"} | RiDiscovery`;

    const certificateSchema = yup.object().shape({
        logoOfMainCompany: yup.mixed().required("Main company logo  is required").test("type", "Invalid file formate.", function (value) {
            if (!value) {
                return false
            } else if (typeof value === "string") {
                if (value?.toLowerCase().includes("jpeg") || value?.toLowerCase().includes("jpg") || value?.toLowerCase().includes("png") || value?.toLowerCase().includes("bmp") || value?.toLowerCase().includes("svg")) {
                    return true
                }
            }
            else if (SUPPORTED_FORMATS.includes(value.type)) {
                return true
            } else {
                return false
            }
        }),
        logoOfSa: yup.mixed().required("Agency logo is required").test("type", "Invalid file formate.", function (value) {
            if (!value) {
                return false
            } else if (typeof value === "string") {
                if (value?.toLowerCase().includes("jpeg") || value?.toLowerCase().includes("jpg") || value?.toLowerCase().includes("png") || value?.toLowerCase().includes("bmp") || value?.toLowerCase().includes("svg")) {
                    return true
                }
            }
            else if (SUPPORTED_FORMATS.includes(value.type)) {
                return true
            } else {
                return false
            }
        }),


        title: yup
            .string().trim().matches(nameRegex, 'Please enter valid name')
            .required("Cetificate Name is required"),
        signature: yup.array().min(1, "Signature is required"),

        content: yup.string().required("Content is required"),
        auditorsRemark: yup.string().required("Auditors Remark is required"),
        observations: yup.string().required("Observations is required"),
        conclusion: yup.string().required("Conclusion is required"),
        standards: yup.string().required("Standards is required"),
        appendixA: yup.string().required("Appendix A is required"),
        appendixB: yup.string().required("Appendix B is required"),

        date: !isEditMode ? yup.date().typeError("Date is required").min(today, 'Date cannot be in the past') : yup.date().typeError("Date is required")
    });

    const {
        handleSubmit,
        control,
        setValue,
        reset,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(certificateSchema),
    });

    useEffect(() => {
        if (isEditMode) {
            const params = {
                id: templateId
            }
            setPageLoader(true)
            getCertificateTemplateDetails({ ...params }).then(resp => {
                setCertificateDetails(resp?.data);
                setPageLoader(false)
            })
        }
    }, [templateId]);


    const onSubmit = (data) => {
        setApiCalling(true);
        if (!isApiCalling) {
            const formData = new FormData();
            formData.append('logoOfMainCompany', data?.logoOfMainCompany);
            formData.append('logoOfSa', data?.logoOfSa);

            formData.append('title', data?.title);
            formData.append('content', data?.content);

            formData.append('auditorsRemark', data?.auditorsRemark);
            formData.append('observations', data?.observations);
            formData.append('conclusion', data?.conclusion);
            formData.append('standards', data?.standards);
            formData.append('appendixA', data?.appendixA);
            formData.append('appendixB', data?.appendixB);
            formData.append('benchmarks', data?.benchmarks);

            formData.append('date', moment(data?.date).format('YYYY-MM-DD'));
            formData.append('signature', fileData?.files[0]);

            if (isEditMode) {
                formData.append('certificateId', templateId);
                editCertificateTemplate(formData).then(resp => {
                    setApiCalling(false);
                    Toast.success(resp?.message)
                    history.push({ pathname: '/certificate-template' })
                    reset();
                }).catch(() => {
                    setApiCalling(false);
                });
            } else {
                addCertificateTemplate(formData).then(resp => {
                    setApiCalling(false);
                    Toast.success(resp?.message)
                    history.push({ pathname: '/certificate-template' })
                    reset();
                }).catch(() => {
                    setApiCalling(false);
                });
            }
        }
    };

    useEffect(() => {
        if (isEditMode) {
            setValue('logoOfMainCompany', certificateDetails?.logoOfMainCompany)
            setValue('logoOfSa', certificateDetails?.logoOfSa)

            setValue('title', certificateDetails?.title)

            setValue('content', certificateDetails?.content)
            setValue('auditorsRemark', certificateDetails?.auditorsRemark)
            setValue('standards', certificateDetails?.standards)
            setValue('observations', certificateDetails?.observations)
            setValue('conclusion', certificateDetails?.conclusion)
            setValue('appendixA', certificateDetails?.appendixA)
            setValue('appendixB', certificateDetails?.appendixB)
            setValue('benchmarks', certificateDetails?.benchmarks)


            setValue('date', certificateDetails?.date)
            setValue('signature', certificateDetails ? [certificateDetails?.signature] : null)

        }
        else {
            setValue(null)
        }
    }, [templateId, certificateDetails]);


    const getFileData = (fileData) => {
        if (fileData?.fieldName === 'logoOfMainCompany') {
            setValue("logoOfMainCompany", fileData?.file)
        } else {
            setValue("logoOfSa", fileData?.file)
        }
    };

    const getFileDataSignature = (files) => {
        setSignatureData(files)
    }

    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/certificate-template", heading: "Certificate Template" },
    { path: `/certificate-template/add`, heading: (!isEditMode) ? "Add Certificate" : "Edit Certificate" }]

    return (
        <React.Fragment>
            {pageLoader ?
                <PageLoader />
                :
                <div className="page-content">
                    <Breadcrumb title="Certificate Template" breadcrumbItem={breadcrumbItems} />
                    <Row>
                        <Col md={9}>
                            <Card>
                                <CardBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <Row className="mb-3">
                                            <Col sm="6">
                                                <Label>Main Company Logo</Label>
                                                <RHFFileUpload
                                                    name="logoOfMainCompany"
                                                    id="logoOfMainCompany"
                                                    defaultImage={certificateDetails ? certificateDetails?.logoOfMainCompany : avatar}
                                                    errorobj={errors}
                                                    setValue={setValue}
                                                    getFileData={getFileData}
                                                    setError={setError}
                                                    clearErrors={clearErrors}
                                                    isValidate={true}
                                                    // showEditIcon={false}
                                                    control={control}
                                                />
                                            </Col>
                                            <Col sm="6">
                                                <Label>Security Agency Logo</Label>
                                                <RHFFileUpload
                                                    name="logoOfSa"
                                                    id="logoOfSa"
                                                    defaultImage={certificateDetails ? certificateDetails?.logoOfSa : avatar}
                                                    errorobj={errors}
                                                    setValue={setValue}
                                                    getFileData={getFileData}
                                                    setError={setError}
                                                    clearErrors={clearErrors}
                                                    isValidate={true}
                                                    control={control}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3 mt-5">
                                            <Col sm="12">
                                                <RHFTextField
                                                    id="title"
                                                    label="Certificate Name"
                                                    name="title"
                                                    errorobj={errors}
                                                    control={control}
                                                    isController={true}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col sm="12">
                                                <RHFDropZone
                                                    name="signature"
                                                    label="Signature"
                                                    errorobj={errors}
                                                    control={control}
                                                    pocSetvalue={setValue}
                                                    getFileData={getFileDataSignature}
                                                    editPocData={certificateDetails ? { images: [certificateDetails?.signature] } : null}
                                                />

                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col sm="12">
                                                <RHFCKEditor
                                                    id="content"
                                                    label="Content"
                                                    name="content"
                                                    errorobj={errors}
                                                    control={control}
                                                    isController={true}
                                                    setValue={setValue}
                                                    details={certificateDetails && certificateDetails}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col sm="12">
                                                <RHFCKEditor
                                                    id="auditorsRemark"
                                                    label="Auditors Remark"
                                                    name="auditorsRemark"
                                                    errorobj={errors}
                                                    control={control}
                                                    isController={true}
                                                    setValue={setValue}
                                                    details={certificateDetails && certificateDetails}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col sm="12">
                                                <RHFCKEditor
                                                    id="observations"
                                                    label="Observations"
                                                    name="observations"
                                                    errorobj={errors}
                                                    control={control}
                                                    isController={true}
                                                    setValue={setValue}
                                                    details={certificateDetails && certificateDetails}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col sm="12">
                                                <RHFCKEditor
                                                    id="conclusion"
                                                    label="Conclusion"
                                                    name="conclusion"
                                                    errorobj={errors}
                                                    control={control}
                                                    isController={true}
                                                    setValue={setValue}
                                                    details={certificateDetails && certificateDetails}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col sm="12">
                                                <RHFCKEditor
                                                    id="standards"
                                                    label="Standards"
                                                    name="standards"
                                                    errorobj={errors}
                                                    control={control}
                                                    isController={true}
                                                    setValue={setValue}
                                                    details={certificateDetails && certificateDetails}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col sm="12">
                                                <RHFCKEditor
                                                    id="appendixA"
                                                    label="Appendix A"
                                                    name="appendixA"
                                                    errorobj={errors}
                                                    control={control}
                                                    isController={true}
                                                    setValue={setValue}
                                                    details={certificateDetails && certificateDetails}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col sm="12">
                                                <RHFCKEditor
                                                    id="appendixB"
                                                    label="Appendix B"
                                                    name="appendixB"
                                                    errorobj={errors}
                                                    control={control}
                                                    isController={true}
                                                    setValue={setValue}
                                                    details={certificateDetails && certificateDetails}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col sm="12">
                                                <RHFCKEditor
                                                    id="benchmarks"
                                                    label="Benchmarks"
                                                    name="benchmarks"
                                                    errorobj={errors}
                                                    control={control}
                                                    isController={true}
                                                    setValue={setValue}
                                                    details={certificateDetails && certificateDetails}
                                                    isRequired={false}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col sm="12">
                                                <RHFDatePicker
                                                    name="date"
                                                    label="Date"
                                                    errorobj={errors}
                                                    control={control}
                                                    isController={true}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <div className="d-flex justify-content-end">
                                                <RHFButton
                                                    btnName="Cancel"
                                                    outline={true}
                                                    onClick={() => history.push('/certificate-template')}
                                                    className="mx-2"
                                                />
                                                <RHFButton
                                                    btnName="Save"
                                                    type="submit"
                                                    isLoading={isApiCalling}
                                                />
                                            </div>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card>
                                <CardHeader className='p-2'>
                                    <div className='d-flex justify-content-between'>
                                        <h4 className="card-title mb-0">Certificate Parameters</h4>
                                    </div>
                                </CardHeader>
                                <CardBody className='p-2'>
                                    <div className="app-detail-wrapper">
                                        {CERTIFICATE_PARAMETER_FEILD?.map((item, index) =>
                                            <div className='app-detail' key={index}>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-text">{item}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        <p className='mb-0 mt-2 text-warning'>{'Please use the parameter as it displayed exactly along with $,{, and.}.'}</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            }
        </React.Fragment>
    )
}

export default CertificateTemplateAddEdit;
