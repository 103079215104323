import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import RHFTextField from "../../components/FormControls/RHFTextField";
import Table from "../../components/Tables/Table";
import ActionButtons from "components/FormControls/ActionButtons";
import { dmDebounce, isModulePermisssion } from "helpers/util";
import { ROLE_PERMISSIONS } from "constants/RolePermissions";
import { APPLICATION } from "helpers/Services/Application";
import DropdownButton from "components/FormControls/DropdownButton";
import { getApplicationCertificateDownload, listAllBu } from "helpers/BackendHelper";
import RHFButton from "components/FormControls/RHFButton";

const ProjectList = () => {
  document.title = "Certificate| RiDiscovery";

  const [filterColumns, setFilterColumns] = useState([]);
  const [filterFields, setFilterFields] = useState({
    'search': null,
    'buId': null,
  });

  const [buList, setBuList] = useState([]);

  useEffect(() => {
    const columnFilter = [...columns];
    setFilterColumns(columnFilter.filter(o => o.isVisible && o));
  }, [])

  useEffect(() => {
    listAllBu().then(resp => {
      setBuList(resp?.data);
    })
  }, []);

  const clearAllFilters = () => {
    setFilterValues({ 'search': null, 'buId': null });
  }

  const downloadFile = (dataurl, filename) => {
    const link = document.createElement("a");
    link.href = dataurl;
    link.download = filename;
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const downloadCertificate = async (data) => {
    const params = {
      id: data?.id
    }
    getApplicationCertificateDownload({ ...params }).then(resp => {
      downloadFile(URL.createObjectURL(resp), `${data?.application?.penId} - Certificate.pdf`);
    })
  }

  const setFilterValues = (field) => {
    setFilterFields(prev => {
      return {
        ...prev,
        ...field
      }
    })
  }

  const optimizedFn = useCallback(dmDebounce((val) => setFilterValues({ search: val })), []);

  const handleActionClick = (payload, actionType) => {
    const actionMapper = {
      download: downloadCertificate
    };

    if (actionMapper[actionType]) {
      actionMapper[actionType](payload);
    }
  };

  const columns = [
    {
      name: "Project Name",
      cell: (row) => <span>{row?.application?.applicationName}</span>,
      isVisible: true,
      width: "70%"
    },
    {
      name: "Pen ID",
      cell: (row) => <span>{row?.application?.penId}</span>,
      isVisible: true,
      width: "15%"
    },
    {
      name: "Action",
      isVisible: true,
      width: "15%",
      cell: (row) => {
        return (
          <ActionButtons
            download={{
              handleClick: () => handleActionClick(row, 'download'),
            }}
          />
        );
      },
      omit: !isModulePermisssion(ROLE_PERMISSIONS?.DOWNLOAD_CERTIFICATE),
    }
  ];

  const breadcrumbItems = [{ path: "/", heading: "Home" },
  { path: "/project-list", heading: "Project List" }]

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Certificate" breadcrumbItem={breadcrumbItems} />
        <Card>
          <CardHeader>
            <Row>
              {isModulePermisssion(ROLE_PERMISSIONS?.CERTIFICATE_LIST) &&
                <Col md="3">
                  <RHFTextField
                    id="search"
                    name="search"
                    placeholder="Search here"
                    isController={false}
                    handleOnChange={optimizedFn}
                  />
                </Col>
              }
              <Col md="9">
                <div className='d-flex justify-content-end'>
                  <div className='me-2'>
                    <RHFButton
                      btnName="Clear Filter"
                      outline={true}
                      icon="filter"
                      onClick={() => clearAllFilters()}
                    />
                  </div>
                  <div className='me-2'>
                    <DropdownButton
                      heading="Select BU"
                      isSearchable={true}
                      menuItems={buList.length > 0 ? buList : []}
                      handleClick={(item) => {
                        setFilterValues({ buId: item?.id })
                      }}
                      filterFields={filterFields}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </CardHeader>
          {isModulePermisssion(ROLE_PERMISSIONS?.CERTIFICATE_LIST) &&
            <CardBody>
              <Table
                columns={filterColumns}
                dataURL={filterColumns?.length > 0 && APPLICATION.getApplicationCertificateList}
                filter={filterFields}
              />
            </CardBody>
          }
        </Card>

      </div>
    </React.Fragment>
  );
};

export default ProjectList;
