import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { addApplication, deleteApplication, editApplication, listAllApplication, } from "helpers/BackendHelper"
import { ADD_APPLICATION, UPDATE_APPLICATION, GET_ALL_APPLICATION, DELETE_APPLICATION } from "./actionTypes"
import { addApplicationFail, addApplicationSuccess, deleteApplicationFail, deleteApplicationSuccess, getAllApplicationFail, getAllApplicationSuccess, updateApplicationFail, updateApplicationSuccess } from "./actions"
import { Toast } from "components/Common/Toaster"


/* Add New Application */
function* addNewApplication({ payload: { data, history } }) {
  try {
    const response = yield call(addApplication, data)
    if (response?.status) {
      const appID = response?.data?.id
      history.push({ pathname: `/application/${appID}/add/kickoff`, state: { objData: response?.data } })
    }
    yield put(addApplicationSuccess(response))
    Toast.success(response.message)
  } catch (error) {
    yield put(addApplicationFail(error))
  }
}

/* Update Application */
function* updateApplication({ payload }) {
  try {
    const response = yield call(editApplication, payload)
    yield put(updateApplicationSuccess(response))
    Toast.success(response.message)
  } catch (error) {
    yield put(updateApplicationFail(error))
  }
}


/* Delete Application */
function* removeApplication({ payload }) {
  try {
    const response = yield call(deleteApplication, payload)
    yield put(deleteApplicationSuccess(response))
    Toast.success(response.message)
  } catch (error) {
    yield put(deleteApplicationFail(error))
  }
}

/* Get All Application */
function* getAllApplications({ payload }) {
  try {
    const response = yield call(listAllApplication, payload)
    yield put(getAllApplicationSuccess(response))
  } catch (error) {
    yield put(getAllApplicationFail(error))
  }
}

export function* watchApplications() {
  yield takeEvery(ADD_APPLICATION, addNewApplication)
  yield takeEvery(UPDATE_APPLICATION, updateApplication)
  yield takeEvery(DELETE_APPLICATION, removeApplication)
  yield takeEvery(GET_ALL_APPLICATION, getAllApplications)
}

function* applicationSaga() {
  yield all([fork(watchApplications)])
}

export default applicationSaga;
