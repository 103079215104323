export const BASE_API_URL = process.env.NODE_ENV === 'development' ? 'http://192.168.1.235' : 'https://api.ridiscovery.com';

/** Development */
const API_URL_DEV = {
    'AUTH': `${BASE_API_URL}:5001`,
    'COMPANY': `${BASE_API_URL}:5002`,
    'ROLE': `${BASE_API_URL}:5003`,
    'USER': `${BASE_API_URL}:5004`,
    'APPLICATION': `${BASE_API_URL}:5005`,
    'KICKOFF': `${BASE_API_URL}:5006`,
    'FINDING': `${BASE_API_URL}:5007`,
    'NETWORK': `${BASE_API_URL}:5008`,
    'TEMPLATE': `${BASE_API_URL}:5009`,
    'CERTIFICATE_TEMPLATE': `${BASE_API_URL}:5010`,
    'SUBSCRIPTION': `${BASE_API_URL}:5011`
}

/** Productions */
const API_URL_PROD = {
    'AUTH': `${BASE_API_URL}`,
    'COMPANY': `${BASE_API_URL}`,
    'ROLE': `${BASE_API_URL}`,
    'USER': `${BASE_API_URL}`,
    'APPLICATION': `${BASE_API_URL}`,
    'KICKOFF': `${BASE_API_URL}`,
    'NETWORK': `${BASE_API_URL}`,
    'FINDING': `${BASE_API_URL}`,
    'TEMPLATE': `${BASE_API_URL}`,
    'CERTIFICATE_TEMPLATE': `${BASE_API_URL}`,
    'SUBSCRIPTION': `${BASE_API_URL}`
}

export const API_URL = process.env.NODE_ENV === 'development' ? API_URL_DEV : API_URL_PROD
