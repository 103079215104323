import { GET_CURRENT_SUBSCRIPTION, GET_SUBSCRIPTION_LIST, UPDATE_SUBSCRIPTION_PLAN } from "helpers/UrlHelper";
import { API_URL } from "./Services";

const baseURL = API_URL.SUBSCRIPTION;

export const SUBSCRIPTION = {
    getAllSubscriptions: {
        method: 'POST',
        url: GET_SUBSCRIPTION_LIST,
        baseURL
    },
    getCurrentSubscription: {
        method: 'GET',
        url: GET_CURRENT_SUBSCRIPTION,
        baseURL
    },
    updateSubscriptionplan: {
        method: 'PUT',
        url: UPDATE_SUBSCRIPTION_PLAN,
        baseURL
    },
}