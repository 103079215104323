import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import RHFTextField from 'components/FormControls/RHFTextField';
import RHFButton from 'components/FormControls/RHFButton';
import RHFCheckbox from 'components/FormControls/RHFCheckbox';


const ManageColumns = (props) => {
    const { allColumns = [], getFilteredValues, ...args } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [columnList, setColumnList] = useState(allColumns);
    const ref = useRef();

    const handleSearch = (val) => {
        setColumnList(allColumns.filter(o => o?.name.toLowerCase()?.indexOf(val.toLowerCase()) > -1))
    }

    const toggle = () => {
        setDropdownOpen((prevState) => !prevState);
        setColumnList(allColumns)
    };

    const handleChange = (id, isChecked) => {
        const filterCols = columnList.map(o => o.id === id ? ({ ...o, isVisible: isChecked }) : o)
        setColumnList(filterCols)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setDropdownOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    const handleApply = () => {
        allColumns.map((t1, i) => {
            columnList.map(t2 => {
                if (t1.id === t2.id) {
                    allColumns[i] = t2
                }
            })
        })
        getFilteredValues(allColumns);
        setDropdownOpen(false);
    }

    return (

        <div ref={ref}>
            <Dropdown isOpen={dropdownOpen} toggle={() => { }}>
                <DropdownToggle caret color='primary' onClick={toggle} >Custom Column</DropdownToggle>
                <DropdownMenu {...args} className='p-2'>
                    <RHFTextField
                        isController={false}
                        name="Search"
                        placeholder="Search"
                        className="form-control-sm"
                        handleOnChange={handleSearch}
                    />
                    <div className='column-list py-1'>
                        {columnList?.map((col, colIndex) => {
                            return (
                                <>
                                    <DropdownItem key={colIndex} className='dropdown-item m-0 p-1'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <span className='font-size-12'>{col?.name}</span>
                                            <RHFCheckbox
                                                name="chk"
                                                value={col?.isVisible}
                                                defaultChecked={col?.isVisible}
                                                isController={false}
                                                className='align-items-center'
                                                onChange={() => handleChange(col?.id, !col?.isVisible)}
                                            />
                                        </div>
                                    </DropdownItem>
                                    <DropdownItem divider className='m-0' />
                                </>
                            )
                        })}
                    </div>
                    {columnList?.length > 0 &&
                        <div className='d-flex justify-content-end'>
                            <RHFButton
                                btnName="Apply"
                                onClick={handleApply}
                                className="btn-sm"
                            />
                        </div>
                    }
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

export default ManageColumns;