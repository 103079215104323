import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// users
import defaultImage from "../../../assets/images/Default-User-Image.jpeg";
import { getLoginUserDetail } from "store/actions";
import { isModulePermisssion } from "helpers/util";
import { ROLE_PERMISSIONS } from "constants/RolePermissions";

const ProfileMenu = (props) => {

  const { user } = useSelector(state => state.Login);
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const [username, setusername] = useState("Company");
  const [profileImage, setProfileImage] = useState(user?.user?.profile)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (!user) {
        dispatch(getLoginUserDetail());
      }
    }
  }, []);

  useEffect(() => {
    if (user) {
      const usename = `${user?.user?.firstName} ${user?.user?.lastName}`;
      const profileImg = user?.user?.profile;
      setusername(usename || 'Company');
      setProfileImage(profileImg)
    }
  }, [user])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profileImage || defaultImage}
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link Link to="/profile">
            <DropdownItem>
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {props.t("Profile")}
            </DropdownItem>
          </Link>
          {isModulePermisssion(ROLE_PERMISSIONS.UPDATE_COMPANY_DETAILS) &&
            <>
              <div className="dropdown-divider" />
              <Link Link to="/company-profile">
                <DropdownItem>
                  <i className="bx bx-user font-size-16 align-middle me-1" />
                  {props.t("Company Profile")}
                </DropdownItem>
              </Link>
            </>
          }
          {isModulePermisssion(ROLE_PERMISSIONS.UPDATE_COMPANY_DETAILS) &&
            <>
              <div className="dropdown-divider" />
              <Link Link to="/my-subscription">
                <DropdownItem>
                  <i className="bx bx-user font-size-16 align-middle me-1" />
                  {props.t("My Subscription")}
                </DropdownItem>
              </Link>
            </>
          }
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default connect(mapStatetoProps, {})(withTranslation()(ProfileMenu));
