import React from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import RHFTextField from 'components/FormControls/RHFTextField';
import RHFButton from 'components/FormControls/RHFButton';
import { Row, Col } from 'reactstrap';

const Settings = (props) => {
    const { handleToggle } = props;

    const settingsSchema = yup.object().shape({
        left: yup.number().typeError("Please enter value in number.").lessThan(1000, 'Please enter between 0 to 999').required("Margin left is required"),
        right: yup.number().typeError("Please enter value in number.").lessThan(1000, 'Please enter between 0 to 999').required("Margin right is required")
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(settingsSchema),
    });

    const submitSettings = () => {
        // setApiCalling(true)
        // Make this state to false once API call is done
    }

    return (
        <div>
            <form onSubmit={handleSubmit(submitSettings)}>
                <Row className="mb-3">
                    <Col sm="6">
                        <RHFTextField
                            id="left"
                            label="Left"
                            name="left"
                            errorobj={errors}
                            control={control}
                            isController={true}
                        />
                    </Col>
                    <Col sm="6">
                        <RHFTextField
                            id="right"
                            label="Right"
                            name="right"
                            errorobj={errors}
                            control={control}
                            isController={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <div className="d-flex justify-content-end">
                        <RHFButton
                            className="mx-2"
                            btnName="Cancel"
                            outline={true}
                            onClick={() => handleToggle()}
                        />
                        <RHFButton
                            btnName="Submit"
                            type="submit"

                        />
                    </div>
                </Row>
            </form>
        </div>
    )
}

export default Settings
