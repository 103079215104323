import React, { useState, useEffect, useCallback } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Table from 'components/Tables/Table';
import RHFTextField from 'components/FormControls/RHFTextField';
import ManageColumns from 'components/Common/ManageColumns';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { NETWORK } from 'helpers/Services/Network';
import RHFAutoCompleteSelect from 'components/FormControls/RHFAutoCompleteSelect';
import { NETWORK_ASSET_TYPE, NETWORK_SEVERITY, NETWORK_FINDING_TYPES, FINDING_SEVERITY, CVSS, CVSS_TEXT_COLOR, COMPANY_SCORE_COLORS, FINDING_SCORE_COLORS } from 'constants/Constant';
import { editNetworkFindingDropdownData, getNetworkFindingStatus } from 'helpers/BackendHelper';
import { Toast } from 'components/Common/Toaster';
import { applicationScore, isModulePermisssion } from 'helpers/util';
import { ROLE_PERMISSIONS } from 'constants/RolePermissions';
import TextChip from 'components/Common/TextChip';

const Finding = ({ networkData }) => {
    // document.title = "Finding-Overwiew | RiDiscovery";
    const history = useHistory()
    const { networkId } = useParams()
    const [filterColumns, setFilterColumns] = useState([]);
    const [columnOptions, setColumnOption] = useState([]);
    const [pageLoader, setPageLoader] = useState(false);
    const [findingStatusDropdown, setFindingStatusDropdown] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [filterFields, setFilterFields] = useState({
        'networkId': networkId,
        'search': null
    });

    const [editFindingData, setEditFindingData] = useState({
        "findingType": "",
        "severity": null,
        "assetType": "",
        "findingId": null
    })
    useEffect(() => {
        const columnFilter = [...columns];
        setColumnOption([...columns]);
        setFilterColumns(columnFilter.filter(o => o.isVisible && o));
    }, [findingStatusDropdown])

    const getFilteredValues = (cols) => {
        setColumnOption(cols);
        setFilterColumns(cols.filter(o => o.isVisible && o));
    }

    const getCols = (cols) => {
        setColumnOption(cols);
    }

    const setFilterValues = (field) => {
        setFilterFields(prev => {
            return {
                ...prev,
                ...field
            }
        })
    }
    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };

    const optimizedFn = useCallback(debounce((val) => setFilterValues({ search: val })), []);

    const handleAutoComplete = (data, name, id, obj) => {
        setSelectedValue(obj);
        const value = data;
        setEditFindingData(() => {
            const prev = {
                findingId: id
            };
            if (value === undefined || value === "") {
                delete prev[name];
            } else {
                prev[name] = value;
            }
            return prev;
        });
    }

    useEffect(() => {
        setPageLoader(true);
        getNetworkFindingStatus().then(resp => {
            const finalArray = resp?.data?.map(item => ({ label: item?.name, value: item?.slug }));
            setFindingStatusDropdown(finalArray);
            setPageLoader(false);
        }).catch(() => {
            setPageLoader(false);
        })
    }, []);

    useEffect(() => {
        if (editFindingData?.findingId) {
            editNetworkFindingDropdownData({ ...editFindingData, networkId }).then(resp => {
                if (editFindingData?.findingId !== null) {
                    Toast.success(resp?.message)
                }
            }).finally(() => {

            });
        }
    }, [editFindingData]);

    const previewHandler = (obj) => {
        history.push({ pathname: `/network/${networkId}/finding/${obj?.id}/overview` })
    };


    const columns = [
        {
            id: 'ip', //  @DM  - its required when sorting is true @DM
            name: "IP",
            cell: (row) =>
                <div className="table-cell-click"
                    onClick={() => previewHandler(row)}>
                    <span>{row?.ipAddress ? row?.ipAddress : "N/A"}</span>
                </div>,
            isVisible: true,
        },
        {
            id: 'cve',
            name: "CVE",
            cell: (row) => <span>{row?.cve ? row?.cve : "N/A"}</span>,
            isVisible: true,
        },
        {
            id: 'os',
            name: "OS",
            cell: (row) => <span>{row?.os ? row?.os : "N/A"}</span>,
            isVisible: true,
        },
        {
            id: 'server',
            name: "Server",
            cell: (row) => <span>{row?.server ? row?.server : "N/A"}</span>,
            isVisible: true,
        },
        {
            id: 'findingType',
            name: "Type",
            isVisible: true,
            classNames: "d-flex align-items-center",
            cell: (row) => {
                if (networkData?.networkStatus === "completed") {
                    return (
                        <TextChip
                            text={NETWORK_FINDING_TYPES.find(o => o.value === row?.findingType)?.label || "N/A"}
                            // text={row?.findingType}
                            color='#2D31FA'
                            textColor='#fff'
                        />
                    )
                } else {
                    if (isModulePermisssion(ROLE_PERMISSIONS.CHANGE_SELECT_FINDING_STATUS)) {
                        const possibleStatus = findingStatusDropdown?.map(statusOption => statusOption.value);
                        if (row?.findingType === 'N/A' || row?.findingType === 'open' || possibleStatus.indexOf(row?.findingType) > -1) {
                            return (
                                <RHFAutoCompleteSelect
                                    id={row?.id}
                                    name="findingType"
                                    options={findingStatusDropdown && findingStatusDropdown}
                                    isMultiple={false}
                                    isClearable={false}
                                    isSearchable={false}
                                    isController={false}
                                    handleOnChange={handleAutoComplete}
                                    defaultValue={findingStatusDropdown && findingStatusDropdown?.find(o => o.value === row?.findingType)}
                                    isTableDropDown={true}
                                />
                            )
                        } else {
                            return (
                                <TextChip
                                    text={NETWORK_FINDING_TYPES.find(o => o.value === row?.findingType)?.label}
                                    // text={row?.findingType}
                                    color='#2D31FA'
                                    textColor='#fff'
                                />
                            )
                        }
                    } else {
                        return row?.findingType !== "open" ?
                            <TextChip
                                text={findingStatusDropdown.find(o => o.value === row?.findingType)?.label}
                                color='#2D31FA'
                                textColor='#fff'
                            /> : <TextChip
                                text={row?.findingType}
                                color='#2D31FA'
                                textColor='#fff'
                            />
                    }
                }
            },
        },
        {
            id: 'assetType',
            name: "Asset Type",
            isVisible: true,
            cell: (row) => {
                if (networkData?.networkStatus === "completed") {
                    return (
                        <TextChip
                            text={NETWORK_ASSET_TYPE.find(o => o.value === row?.assetType)?.label || "N/A"}
                            // text={row?.findingType}
                            color='#2D31FA'
                            textColor='#fff'
                        />
                    )
                } else {
                    return (
                        isModulePermisssion(ROLE_PERMISSIONS.CHANGE_SELECT_ASSET_TYPE) ?
                            <RHFAutoCompleteSelect
                                id={row?.id}
                                name="assetType"
                                options={NETWORK_ASSET_TYPE}
                                isMultiple={false}
                                isClearable={false}
                                isSearchable={false}
                                isController={false}
                                defaultValue={NETWORK_ASSET_TYPE.find(o => o.value === row?.assetType)}
                                handleOnChange={handleAutoComplete}
                                isTableDropDown={true}
                            /> :

                            row?.assetType ?
                                <TextChip
                                    text={NETWORK_ASSET_TYPE.find(o => o.value === row?.assetType)?.label}
                                    color='#2D31FA'
                                    textColor='#fff'
                                /> : '-'
                    )

                }
            }
        },
        {
            id: 'port',
            name: "Port",
            cell: (row) => <span>{row?.port ? row?.port : "N/A"}</span>,
            isVisible: true,
        },
        {
            id: 'severity',
            name: "Severity",
            isVisible: true,
            cell: (row) => {

                if (editFindingData?.findingType === "false_positive" || row?.findingType === "false_positive") {
                    return "N/A"
                } else {
                    if (networkData?.networkStatus === "completed") {
                        return (
                            <TextChip
                                text={applicationScore(+row?.severity)}
                                color={FINDING_SCORE_COLORS[(+row?.severity).toFixed(1)] || "#E9E8EF"}
                                textColor={CVSS_TEXT_COLOR[applicationScore(+row?.severity)]}
                            />
                        )
                    }
                    else {
                        return (
                            isModulePermisssion(ROLE_PERMISSIONS.CHANGE_SELECT_SEVERITY) ?
                                <RHFAutoCompleteSelect
                                    id={row?.id}
                                    name="severity"
                                    options={NETWORK_SEVERITY}
                                    isMultiple={false}
                                    isClearable={false}
                                    isSearchable={false}
                                    isController={false}
                                    defaultValue={row?.severity === null ? row?.severity : NETWORK_SEVERITY.find(o => o.value === +row?.severity)}
                                    handleOnChange={handleAutoComplete}
                                    isTableDropDown={true}
                                /> :
                                row?.severity ?
                                    <TextChip
                                        text={applicationScore(+row?.severity)}
                                        color='#2D31FA'
                                        textColor='#fff'
                                    /> : '-'
                        )
                    }
                }
            }
        },
    ];

    return (
        <React.Fragment>
            <h5 className='mt-2 mb-2'>Finding</h5>
            <Card>
                <CardHeader>
                    <Row>
                        <Col xs={6} lg={3}>
                            <RHFTextField
                                id="search"
                                name="search"
                                placeholder="Search here"
                                isController={false}
                                handleOnChange={optimizedFn}
                            />
                        </Col>
                        <Col xs={6} lg={9}>
                            <div className="d-flex justify-content-end">
                                <div className='me-2'>
                                    <ManageColumns
                                        allColumns={columnOptions}
                                        getCols={getCols}
                                        getFilteredValues={getFilteredValues}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    {pageLoader ?
                        <div className='d-flex justify-content-center my-4 gap-1'>
                            <div className="spinner-grow text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div> :
                        <Table
                            columns={filterColumns}
                            dataURL={filterColumns?.length > 0 ? NETWORK.listAllNetworkFinding : ""}
                            // isRefresh={isRefresh}
                            filter={filterFields}
                        />}
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default Finding
