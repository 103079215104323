import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isModulePermisssion } from 'helpers/util';
import { ROLE_PERMISSIONS } from 'constants/RolePermissions';
import RiskSummary from "./RiskSummary";
import AssetSummary from "./AssetSummary";
import Tabs from "components/Tab/Tabs";

// const breadcrumbItems = [{ path: "/", heading: "Home" }]

const Dashboard = () => {
  document.title = "Dashboard | RiDiscovery";

  const navLinkData = [
    {
      tabNo: "dashboard",
      tabName: "Risk Summary",
      tabPath: "/dashboard",
      isVisible: isModulePermisssion(ROLE_PERMISSIONS.RISK_SUMMARY) ? true : false,
    },
    {
      tabNo: "asset-summary",
      tabName: "Asset Summary",
      tabPath: "/asset-summary",
      isVisible: isModulePermisssion(ROLE_PERMISSIONS.ASSET_SUMMARY) ? true : false,
    }
  ]
  const { permissions } = useSelector(state => state.Login);
  let location = useLocation()
  const history = useHistory()
  let tabValue = location.pathname
  const [filterColumns, setFilterColumns] = useState([]);
  const [activeTab, setactiveTab] = useState('');


  let columnFilter = [...navLinkData];

  useEffect(() => {
    const filteredTabs = columnFilter.filter(o => o.isVisible && o);
    setFilterColumns(filteredTabs);
    setactiveTab(filteredTabs.length > 0 ? filteredTabs[0]?.tabNo : '')
  }, [permissions])


  const toggle = (tab) => {
    setactiveTab(tab);
    history.push(`/${tab}`)

  };
  return (
    <div className="page-content1">
      <div className='page-title-box'>
        <Tabs
          navLinkData={filterColumns}
          activeTab={activeTab}
          setactiveTab={setactiveTab}
          toggle={toggle}
        >
          {tabValue === "/dashboard" && <RiskSummary />}
          {tabValue === "/asset-summary" && <AssetSummary />}
        </Tabs>
      </div>
    </div>
  );
};

export default Dashboard;