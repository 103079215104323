import React, { useState, useCallback } from "react";
import RHFButton from "components/FormControls/RHFButton";
import RHFTextField from "components/FormControls/RHFTextField";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Table from "../../components/Tables/Table";
import ActionButtons from "components/FormControls/ActionButtons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { TEMPLATE } from "helpers/Services/Template";
import { dmDebounce, isModulePermisssion } from "helpers/util";
import DialogBox from "components/Modals/DialogBox";
import { deleteTemplate, templateDefaultUser } from "helpers/BackendHelper";
import { ROLE_PERMISSIONS } from "constants/RolePermissions";
import RHFSwitch from "components/FormControls/RHFSwitch";
import { Toast } from "components/Common/Toaster";

const ReportingTemplates = () => {
    const history = useHistory();

    const [filterFields, setFilterFields] = useState({
        'search': null,
    });
    const [isRefresh, setRefresh] = useState(false);
    const [activeRow, setActiveRow] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [isApiCalling, setApiCalling] = useState(false);

    const setFilterValues = (field) => {
        setFilterFields(prev => {
            return {
                ...prev,
                ...field
            }
        })
    }

    const optimizedFn = useCallback(dmDebounce((val) => setFilterValues({ search: val })), []);

    const handleToggle = () => { setIsModelOpen(!isModelOpen); };

    const deleteHandler = (obj) => {
        setActiveRow(obj);
        handleToggle(true);
    };

    const handleDelete = () => {
        setApiCalling(true)
        if (!isApiCalling) {
            const payload = {
                "isDeleted": true,
                "templateId": activeRow?.id
            }
            deleteTemplate(payload).then(() => {
                Toast.success("Template delete successfully.")
                setRefresh(prevValue => !prevValue)
                setApiCalling(false);
                handleToggle();
            }).catch(() => {
                setApiCalling(false);
            });
        }
    }

    const editHandler = (row) => {
        history.push({ pathname: `/reporting-template/edit/${row?.id}` })
    };


    const handleActionClick = (payload, actionType) => {
        const actionMapper = {
            edit: editHandler,
            delete: deleteHandler
        };
        if (actionMapper[actionType]) {
            actionMapper[actionType](payload);
        }
    };

    const handleSwitchChange = (value, row) => {
        const payload = {
            templateId: row?.id,
            isDefault: !row?.isDefault
        }
        templateDefaultUser(payload).then((resp) => {
            Toast.success(resp?.message)
            setRefresh(prevValue => !prevValue)
        })
    };

    const columns = [
        {
            name: "Template Name",
            cell: (row) => <span>{row?.name}</span>,
            width: "45%",
            minWidth: "130px"
        },
        {
            name: "Make as Default",
            omit: !isModulePermisssion(ROLE_PERMISSIONS.MAKE_DEFAULT_TEMPLATE),
            cell: (row) => (
                <RHFSwitch
                    name="default" // isActive
                    isController={false}
                    checked={row?.isDefault}
                    rowData={row}
                    onChange={(val, row) => handleSwitchChange(val, row)}
                />
            ),
            width: "45%",
            minWidth: "130px"
        },
        {
            name: "Actions",
            omit: !isModulePermisssion(ROLE_PERMISSIONS?.UPDATE_REPORTING_TEMPLATE) || !isModulePermisssion(ROLE_PERMISSIONS?.DELETE_REPORTING_TEMPLATE),
            cell: (row) => {
                return (
                    <>
                        {!row?.isAdmin ? <ActionButtons
                            edit={{
                                handleClick: () => handleActionClick(row, 'edit'),
                                isPermission: ROLE_PERMISSIONS?.UPDATE_REPORTING_TEMPLATE
                            }}
                            delete={{
                                handleClick: () => handleActionClick(row, 'delete'),
                                isPermission: ROLE_PERMISSIONS?.DELETE_REPORTING_TEMPLATE,
                            }}
                        /> : null}
                    </>
                );
            },
        },
    ];


    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/reporting-template", heading: "Reporting Templates" }]


    document.title = "Reporting Template | RiDiscovery";
    return (
        <React.Fragment>
            <Breadcrumbs title="Reporting Template" breadcrumbItem={breadcrumbItems} />
            <Card className="card-h-100">
                <CardHeader>
                    <Row>
                        <Col md="3">
                            <RHFTextField
                                id="search"
                                name="search"
                                placeholder="Search here"
                                isController={false}
                                handleOnChange={optimizedFn}
                            />
                        </Col>
                        <Col>
                            <div className="col d-flex justify-content-end align-items-end">
                                {isModulePermisssion(ROLE_PERMISSIONS?.ADD_REPORTING_TEMPLATE) &&
                                    <RHFButton btnName="Add Template" icon="plus" onClick={() => {
                                        history.push(`/reporting-template/add`)
                                    }} />
                                }
                            </div>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Table
                        columns={columns}
                        dataURL={TEMPLATE.listAllTemplates}
                        isRefresh={isRefresh}
                        filter={filterFields}
                    />
                </CardBody>
            </Card>
            <DialogBox
                isModelOpen={isModelOpen}
                handleToggle={handleToggle}
                modelSize="sm-100"
                title="Confirmation"
                actions={
                    <>
                        <RHFButton
                            btnName="Cancel"
                            outline={true}
                            onClick={handleToggle}
                        />
                        <RHFButton
                            btnName="Okay"
                            onClick={handleDelete}
                            isLoading={isApiCalling}
                        />
                    </>
                }
            >
                <div className="text-center">
                    <i className="mdi mdi-alert-circle-outline" style={{ fontSize: "5em", color: "orange" }} />
                    <h5>Are you sure?</h5>
                    <p className="fs-5">You won't be able to revert this!</p>
                </div>
            </DialogBox>
        </React.Fragment>
    );
}

export default ReportingTemplates