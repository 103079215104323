
export const ADD_AGENCY = "ADD_AGENCY"
export const ADD_AGENCY_SUCCESS = "ADD_AGENCY_SUCCESS"
export const ADD_AGENCY_FAIL = "ADD_AGENCY_FAIL"

export const UPDATE_AGENCY = "UPDATE_AGENCY"
export const UPDATE_AGENCY_SUCCESS = "UPDATE_AGENCY_SUCCESS"
export const UPDATE_AGENCY_FAIL = "UPDATE_AGENCY_FAIL"

export const GET_ALL_AGENCY = "GET_ALL_AGENCY"
export const GET_ALL_AGENCY_SUCCESS = "GET_ALL_AGENCY_SUCCESS"
export const GET_ALL_AGENCY_FAIL = "GET_ALL_AGENCY_FAIL"

export const GET_ALL_DEV_AGENCY = "GET_ALL_DEV_AGENCY"
export const GET_ALL_DEV_AGENCY_SUCCESS = "GET_ALL_DEV_AGENCY_SUCCESS"
export const GET_ALL_DEV_AGENCY_FAIL = "GET_ALL_DEV_AGENCY_FAIL"

export const GET_ALL_SECURITY_AGENCY = "GET_ALL_SECURITY_AGENCY"
export const GET_ALL_SECURITY_AGENCY_SUCCESS = "GET_ALL_SECURITY_AGENCY_SUCCESS"
export const GET_ALL_SECURITY_AGENCY_FAIL = "GET_ALL_SECURITY_AGENCY_FAIL"