import React, { useState, useRef } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { addNetworkFinding } from 'helpers/BackendHelper';
import { Toast } from 'components/Common/Toaster';
import RHFButton from 'components/FormControls/RHFButton';
import RHFUpload from 'components/FormControls/RHFUpload';
import RHFAutoCompleteSelect from 'components/FormControls/RHFAutoCompleteSelect';
import { ROLE_PERMISSIONS } from 'constants/RolePermissions';
import { isModulePermisssion } from 'helpers/util';

const UploadNetworkDetails = () => {
    const { networkId } = useParams();
    const history = useHistory();
    const fileRef = useRef(null);
    const fileRef2 = useRef(null);

    const RESULT_TYPE = [
        (isModulePermisssion(ROLE_PERMISSIONS?.IMPORT_NMAP_RESULTS) && {
            value: "Nmap",
            label: "Nmap"
        }),
        (isModulePermisssion(ROLE_PERMISSIONS?.IMPORT_NESSUS_RESULTS) && {
            value: "Nessus",
            label: "Nessus"
        }),
    ]

    const filteredResult = RESULT_TYPE.filter(Boolean);

    const [selectedOption, setSelectedOption] = useState(RESULT_TYPE)
    const [flag, setFlag] = useState(false)
    const [isApiCalling, setApiCalling] = useState(false);

    const networkSchema = yup.object().shape({
        fileType: yup
            .object()
            .shape({ label: yup.string(), value: yup.string() })
            .nullable()
            .required("Select atleast one option"),
        nmap:
            isModulePermisssion(ROLE_PERMISSIONS?.IMPORT_NMAP_RESULTS) &&
            flag && selectedOption?.value === "Nmap" &&
            yup.mixed()
                .required("Nmap is required").test(
                    "fileFormat",
                    "Unsupported File Format",
                    value => value && ["text/xml"].includes(value.type)
                ),
        nessus:
            isModulePermisssion(ROLE_PERMISSIONS?.IMPORT_NESSUS_RESULTS) &&
            flag && selectedOption?.value === "Nessus" &&
            yup.mixed()
                .required("Nessus is required").test(
                    "fileFormat",
                    "Unsupported File Format",
                    value => value && ["text/csv"].includes(value.type)
                )
    })

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(networkSchema),
    })

    const getFileData = () => { };

    const handleOnChange = (selectedValue) => {
        setFlag(true)
        setSelectedOption(selectedValue)
    }

    const onSubmit = (data) => {
        setApiCalling(true);
        if (!isApiCalling) {
            const formData = new FormData();
            formData.append('networkId', networkId);
            formData.append('nmap', data?.nmap);
            formData.append('nessus', data?.nessus);
            if (formData) {
                addNetworkFinding(formData).then(resp => {
                    Toast.success(resp?.message)
                    setApiCalling(false);
                    history.push('/networks')
                }).catch(err => {
                    Toast.error(err?.response?.message)
                    setApiCalling(false);
                });
            }
        }
    };


    return (
        <>
            {(isModulePermisssion(ROLE_PERMISSIONS?.IMPORT_NMAP_RESULTS) || isModulePermisssion(ROLE_PERMISSIONS?.IMPORT_NESSUS_RESULTS)) &&
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xs={12}>
                                <div className="fs-6 fw-bold">
                                    Upload Network File
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <Row className="mb-3 d-flex justify-content-center">
                                <Col xs="6" className=''>
                                    <RHFAutoCompleteSelect
                                        id="fileType"
                                        label="Upload Nmap / Nessus"
                                        name="fileType"
                                        options={filteredResult}
                                        isMultiple={false}
                                        errorobj={errors}
                                        control={control}
                                        isController={true}
                                        isRequired={false}
                                        handleOnChange={handleOnChange}
                                    />
                                </Col>
                            </Row>
                            {
                                flag && selectedOption?.value === "Nmap" && <Row className="mb-3 d-flex justify-content-center">
                                    <Col xs="6" className='' >
                                        <RHFUpload
                                            inputRef={fileRef}
                                            name="nmap"
                                            id="nmap"
                                            label="Import Nmap Results"
                                            getFileData={getFileData}
                                            setValue={setValue}
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            isValidate={true} />

                                    </Col>
                                </Row>
                            }
                            {
                                flag && selectedOption?.value === "Nessus" &&
                                <Row className="mb-3 d-flex justify-content-center">
                                    <Col xs="6" className='' >
                                        <RHFUpload
                                            inputRef={fileRef2}
                                            name="nessus"
                                            id="nessus"
                                            label="Import Nessus Results"
                                            getFileData={getFileData}
                                            setValue={setValue}
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            isValidate={true}
                                        />
                                    </Col>
                                </Row>
                            }
                            <Row className="mb-3 d-flex justify-content-center">
                                <Col xs="6" className='d-flex justify-content-end' >
                                    <RHFButton
                                        btnName="Save"
                                        type="submit"
                                        isLoading={isApiCalling}
                                    />
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>}
        </>
    )
}

export default UploadNetworkDetails;