import {
    ADD_NETWORK, ADD_NETWORK_FAIL, ADD_NETWORK_SUCCESS,
    GET_ALL_NETWORK, GET_ALL_NETWORK_FAIL, GET_ALL_NETWORK_SUCCESS
} from "./actionTypes"

const initialState = {
    isLoading: false,
    isError: false,
    networks: [],
}

const network = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NETWORK:
            state = {
                ...state,
                isLoading: true,
            }
            break
        case ADD_NETWORK_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                networks: action?.payload
            }
            break
        case ADD_NETWORK_FAIL:
            state = {
                ...state,
                isLoading: false,
                isError: true,
            }
            break


        case GET_ALL_NETWORK:
            state = {
                ...state,
                isLoading: true,
            }
            break
        case GET_ALL_NETWORK_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                networks: action?.payload
            }
            break
        case GET_ALL_NETWORK_FAIL:
            state = {
                ...state,
                isLoading: false,
                networks: [],
                isError: true
            }
            break


        default:
            state = { ...state }
            break
    }
    return state
}

export default network;
