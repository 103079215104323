export const ROLE_PERMISSIONS = {
    "MENU_SIGN_IN": "menu_sign_in",
    "LOGIN": "login",
    "2FA_AUTHENTICATION": "2fa_authentication",
    "FORGOT_PASSWORD": "forgot_password",
    "MENU_DASHBOARD": "menu_dashboard",
    "RISK_SUMMARY": "risk_summary",
    "ASSET_SUMMARY": "asset_summary",
    "ACTION_PLAN": "action_plan",
    "PRIORITIZATION": "prioritization",
    "FINDING_PRIORITIZATION_REPORT": "finding_prioritization_report",
    "MENU_ASSESSMENT": "menu_assessment",
    "APPLICATION": "application",
    "APPLICATION_FILTER_AND_SEARCH": "application_filter_and_search",
    "APPLICATION_LIST": "application_list",
    "ADD_APPLICATION": "add_application",
    "UPDATE_APPLICATION": "update_application",
    "DELETE_APPLICATION": "delete_application",
    "ADD_KICK_OFF_DOC": "add_kick_off_doc",
    "UPDATE_KICK_OFF_DOC": "update_kick_off_doc",
    "APPLICATION_OVERVIEW": "application_overview",
    "CHANGE_APPLICATION_STATUS": "change_application_status",
    "ASSIGN_REMOVE_TEAM": "assign_remove_team",
    "ASSIGN_REMOVE_TEAM_D": "assign_remove_team_d",
    "ASSIGN_REMOVE_TEAM_P": "assign_remove_team_p",
    "ADD_FINDING": "add_finding",
    "UPDATE_FINDING": "update_finding",
    "VIEW_FINDING": "view_finding",
    "ADD_COMMENTS_ON_FINDING": "add_comments_on_finding",
    "FINDING_REVIEW": "finding_review",
    "CHANGE_FINDING_STATUS": "change_finding_status",
    "DELETE_FINDING": "delete_finding",
    "APPLICATION_REVIEW": "application_review",
    "APPROVE_REJECT_CORRECTION_FINDING": "approve_reject_correction_finding",
    "UPDATE_FINDING_IN_CORRECTION": "update_finding_in_correction",
    "APPLICATION_GENERATE_EXCEPTIONS": "application_generate_exceptions",
    "APPLICATION_APPROVE_REJECT_EXCEPTIONS": "application_approve_reject_exceptions",
    "APPLICATION_LOG_VIEW": "application_log_view",
    "APPLICATION_REPORT_DOWNLOAD": "application_report_download",
    "NETWORK": "network",
    "NETWORK_LIST": "network_list",
    "ADD_NETWORK": "add_network",
    "CHANGE_NETWORK_STATUS": "change_network_status",
    "IMPORT_NMAP_RESULTS": "import_nmap_results",
    "IMPORT_NESSUS_RESULTS": "import_nessus_results",
    "NETWORK_ASSIGN_REMOVE_TEAM": "network_assign_remove_team",
    "NETWORK_ASSIGN_REMOVE_TEAM_P": "network_assign_remove_team_p",
    "NETWORK_ASSIGN_REMOVE_TEAM_D": "network_assign_remove_team_d",
    "NETWORK_OVERVIEW": "network_overview",
    "VIEW_NETWORK_FINDINGS": "view_network_findings",
    "UPDATE_NETWORK_FINDINGS": "update_network_findings",
    "CHANGE_SELECT_FINDING_STATUS": "change_select_finding_status",
    "CHANGE_SELECT_ASSET_TYPE": "change_select_asset_type",
    "CHANGE_SELECT_SEVERITY": "change_select_severity",
    "NETWORK_GENERATE_EXCEPTIONS": "network_generate_exceptions",
    "NETWORK_APPROVE_REJECT_EXCEPTIONS": "network_approve_reject_exceptions",
    "DELETE_NETWORK_FINDING": "delete_network_finding",
    "NETWORK_LOG_VIEW": "network_log_view",
    "MENU_SUBMISSIONS": "menu_submissions",
    "ALL_SUBMISSIONS": "all_submissions",
    "TEAM_SUBMISSIONS": "team_submissions",
    "ACCEPTED": "accepted",
    "REJECTED": "rejected",
    "CORRECTIONS": "corrections",
    "MENU_CERTIFICATE": "menu_certificate",
    "CERTIFICATE_LIST": "certificate_list",
    "DOWNLOAD_CERTIFICATE": "download_certificate",
    "CERTIFICATE_VIEW_LOGS": "certificate_view_logs",
    "MENU_ROLE_MANAGEMENT": "menu_role_management",
    "VIEW_USERS_COMPANY_LIST": "view_users_company_list",
    "VIEW_USERS_AGENCY_LIST": "view_users_agency_list",
    'ADD_USER_AGENCY_DROPDOWN': 'add_user_agency_dropdown',
    "AGENCY_DROPDOWN": "agency_dropdown",
    "ADD_UPDATE_CSM": "add_update_csm",
    "ADD_UPDATE_SM": "add_update_sm",
    "ADD_UPDATE_DM": "add_update_dm",
    "ADD_UPDATE_P": "add_update_p",
    "ADD_UPDATE_D": "add_update_d",
    "ADD_UPDATE_E": "add_update_e",
    "DEACTIVATE_USER": "deactivate_user",
    "APPROVE_REJECT_USER": "approve_reject_user",
    "ROLE_VIEW_LOGS": "role_view_logs",
    "MENU_COMPANY": "menu_company",
    "COMPANY_PROFILE": "company_profile",
    "VIEW_COMPANY_DETAILS": "view_company_details",
    "UPDATE_COMPANY_DETAILS": "update_company_details",
    "AGENCY": "agency",
    "ADD_UPDATE_AGENCY": "add_update_agency",
    "VIEW_AGENCY_LIST": "view_agency_list",
    "VIEW_AGENCY_OVERVIEW": "view_agency_overview",
    "USERS": "users",
    "VIEW_INTERNAL_TEAM_DETAILS_LIST": "view_internal_team_details_list",
    "ADD_INTERNAL_TEAM": "add_internal_team",
    "UPDATE_INTERNAL_TEAM": "update_internal_team",
    "DEACTIVATE_INTERNAL_TEAM": "deactivate_internal_team",
    "VIEW_DA_TEAM_DETAILS_LIST": "view_da_team_details_list",
    "ADD_DA_TEAM": "add_da_team",
    "UPDATE_DA_TEAM": "update_da_team",
    "DEACTIVATE_DA_TEAM": "deactivate_da_team",
    "VIEW_SA_TEAM_DETAILS_LIST": "view_sa_team_details_list",
    "ADD_SA_TEAM": "add_sa_team",
    "UPDATE_SA_TEAM": "update_sa_team",
    "DEACTIVATE_SA_TEAM": "deactivate_sa_team",
    "APPROVE_REJECT_TEAM_MEMBER_INVITE": "approve_reject_team_member_invite",
    "USERS_VIEW_LOGS": "users_view_logs",
    "MENU_TEMPLATES": "menu_templates",
    "REPORTING_TEMPLATES": "reporting_templates",
    "REPORTING_TEMPLATE_LIST": "reporting_template_list",
    "VIEW_REPORTING_TEMPLATE": "view_reporting_template",
    "ADD_REPORTING_TEMPLATE": "add_reporting_template",
    "UPDATE_REPORTING_TEMPLATE": "update_reporting_template",
    "DELETE_REPORTING_TEMPLATE": "delete_reporting_template",
    "CERTIFICATE_TEMPLATES": "certificate_templates",
    "LIST_OF_CERTIFICATE": "list_of_certificate",
    "VIEW_CERTIFICATE_TEMPLATE": "view_certificate_template",
    "ADD_CERTIFICATE_TEMPLATE": "add_certificate_template",
    "UPDATE_CERTIFICATE_TEMPLATE": "update_certificate_template",
    "DELETE_CERTIFICATE_TEMPLATE": "delete_certificate_template",
    "CERTIFICATE_TEMPLATES_VIEW_LOGS": "certificate_templates_view_logs",
    "MENU_SETTINGS": "menu_settings",
    "UPDATE_PROFILE": "update_profile",
    "CHANGE_PASSWORD": "change_password",
    "UPDATE_2FA_MECHANISM": "update_2fa_mechanism",
    "MENU_BU": 'menu_bu',
    "ADD_BU": 'bu_add',
    "UPDATE_BU": 'bu_update',
    "DELETE_BU": 'bu_delete',
    "LIST_BU": 'list_bu',
    "BU_ACTIVE_DEACTIVE": 'bu_active_deactive',
    "MAKE_DEFAULT_TEMPLATE": 'make_default_template',
    "MAKE_DEFAULT_CERTIFICATE": 'make_default_certificate',
}