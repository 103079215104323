import React from 'react'
import RHFButton from 'components/FormControls/RHFButton';
import { Card, CardBody } from 'reactstrap';
import TextChip from '../TextChip';
import { TitleCase } from 'helpers/util';

const SubscriptionPlanCard = (props) => {
    const { planDeatils, handleToggle } = props;
    return (
        <Card className={planDeatils?.isActive ? "bg-primary mb-xl-0 h-100 plan-detail" : "mb-xl-0 h-100 plan-detail"}>
            <CardBody>
                <div className="d-flex flex-column h-100">
                    {planDeatils?.isActive && (
                        <div className="pricing-badge">
                            <span className="badge">Current Plan</span>
                        </div>
                    )}
                    <h5 className={planDeatils?.isActive ? "font-size-16 text-white" : "font-size-16"}>
                        {planDeatils?.name}
                        {planDeatils?.isActive &&
                            <span className="ms-2">
                                <TextChip text={TitleCase(planDeatils?.type)} color="#FF7F5C" />
                            </span>
                        }
                    </h5>
                    <h3 className={planDeatils?.isActive ? "mt-3 text-white" : "mt-3"}>
                        ₹{planDeatils?.monthly_price}{" "}
                        <span className={planDeatils?.isActive ? "text-white font-size-16 fw-medium" : "text-muted font-size-16 fw-medium"}>
                            / Month
                        </span>
                    </h3>
                    <h1 className={planDeatils?.isActive ? "mt-3 text-white" : "mt-3"}>
                        ₹{planDeatils?.annual_price}{" "}
                        <span className={planDeatils?.isActive ? "text-white font-size-16 fw-medium" : "text-muted font-size-16 fw-medium"}>
                            / Year
                        </span>
                    </h1>
                    <p className={planDeatils?.isActive ? "text-white-50 mt-3 font-size-15 plan-description" : "text-muted-50 mt-3 font-size-15 plan-description"}>
                        {planDeatils?.description}
                    </p>
                    <div className={planDeatils?.isActive ? "mt-4 pt-2 text-white" : "mt-4 pt-2 text-muted"}>
                        <p className="mb-3 font-size-15">
                            <i className={planDeatils?.isActive ? "mdi mdi-check-circle text-light font-size-18 me-2" : "mdi mdi-check-circle text-secondary font-size-18 me-2"}></i>
                            Total Users : {planDeatils?.total_users}
                        </p>
                        <p className="mb-3 font-size-15">
                            <i className={planDeatils?.isActive ? "mdi mdi-check-circle text-light font-size-18 me-2" : "mdi mdi-check-circle text-secondary font-size-18 me-2"}></i>
                            Total Applications : {planDeatils?.total_applications}
                        </p>
                        <p className="mb-3 font-size-15">
                            <i className={planDeatils?.isActive ? "mdi mdi-check-circle text-light font-size-18 me-2" : "mdi mdi-check-circle text-secondary font-size-18 me-2"}></i>
                            Total Networks : {planDeatils?.total_networks}
                        </p>
                    </div>

                    {!planDeatils?.isActive &&
                        <div className="mt-auto pt-2 text-center">
                            <RHFButton
                                btnName="Choose Plan"
                                icon=""
                                onClick={() => {
                                    handleToggle()
                                }}
                            />
                        </div>
                    }
                </div>
            </CardBody>
        </Card >
    )
}

export default SubscriptionPlanCard;