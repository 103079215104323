
import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { Card, CardBody, CardHeader, FormFeedback, Input, Label } from 'reactstrap';

const RHFCheckBoxGroup = ({ isController = false, ...props }) => {
    const {
        label,
        name,
        checkBoxData,
        errorobj,
        control,
        autoFocus = false,
        disabled = false,
        onChange,
        checked = false,
        autoCheckedValues,
        ...extraProps
    } = props;
    if (!isController) {
        return (
            <Fragment>
                <div className="mb-0">
                    <Label htmlFor="example-checkbox-input" className="form-label">{label}</Label>
                    <Input type="checkbox" onChange={(e) => onChange(e.target.checked)} id="example-checkbox-input"  {...extraProps} />
                </div>
            </Fragment>
        );
    }
    let isError = false;
    let errorMessage = '';

    if (errorobj && Object.prototype.hasOwnProperty.call(errorobj, name)) {
        isError = true;
        errorMessage = errorobj[name].message;
    }

    return <Controller
        name={name}
        control={control}
        render={({ field }) => (
            <Fragment>
                <Card className={`${errorMessage ? "border-danger" : null} mb-0`} style={{ height: "155px", }}>
                    <CardHeader className='d-flex justify-content-between my-1 py-1 border-0'>
                        {label && <Label htmlFor="example-checkbox-input" className="form-label">
                            {label}
                        </Label>}
                    </CardHeader>
                    <CardBody className='mx-2 p-0' style={{ height: "110px" }}>
                        {checkBoxData.map((item, index) =>
                            <div className="form-check" key={index}>
                                <Label htmlFor={item.label} className="form-label" style={{ cursor: "pointer" }}>{item.label}</Label>
                                <Input
                                    {...field}
                                    {...extraProps}
                                    id={item.label}
                                    type="checkbox"
                                    value={item.value}
                                    defaultChecked={autoCheckedValues?.includes(item.value)}
                                    autoFocus={autoFocus}
                                    invalid={isError}
                                    disabled={disabled}
                                    onChange={(_,) => {
                                        return !onChange ? field.onChange(_.target.checked) : onChange(_);
                                    }}
                                />
                            </div>
                        )}
                    </CardBody>
                </Card>
                {isError && <FormFeedback type="invalid">{errorMessage}</FormFeedback>}
            </Fragment>
        )}
    />
}

export default RHFCheckBoxGroup;
