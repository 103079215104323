import {
  ADD_APPLICATION,
  ADD_APPLICATION_SUCCESS,
  ADD_APPLICATION_FAIL,

  UPDATE_APPLICATION,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION_FAIL,

  DELETE_APPLICATION,
  DELETE_APPLICATION_SUCCESS,
  DELETE_APPLICATION_FAIL,

  GET_ALL_APPLICATION,
  GET_ALL_APPLICATION_SUCCESS,
  GET_ALL_APPLICATION_FAIL,


} from "./actionTypes"

const initialState = {
  isLoading: false,
  isError: false,
  applications: [],
}

const application = (state = initialState, action) => {
  switch (action.type) {
    case ADD_APPLICATION:
      state = {
        ...state,
        isLoading: true,
      }
      break
    case ADD_APPLICATION_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        applications: action?.payload
      }
      break
    case ADD_APPLICATION_FAIL:
      state = {
        ...state,
        isLoading: false,
        isError: true,

      }
      break

    case UPDATE_APPLICATION:
      state = {
        ...state,
        isLoading: true,
      }
      break
    case UPDATE_APPLICATION_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      }
      break
    case UPDATE_APPLICATION_FAIL:
      state = {
        ...state,
        isLoading: false,
        isError: true
      }
      break

    case DELETE_APPLICATION:
      state = {
        ...state,
        isLoading: true,
      }
      break
    case DELETE_APPLICATION_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      }
      break
    case DELETE_APPLICATION_FAIL:
      state = {
        ...state,
        isLoading: false,
        isError: true
      }
      break

    case GET_ALL_APPLICATION:
      state = {
        ...state,
        isLoading: true,
      }
      break
    case GET_ALL_APPLICATION_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        applications: action?.payload
      }
      break
    case GET_ALL_APPLICATION_FAIL:
      state = {
        ...state,
        isLoading: false,
        applications: [],
        isError: true
      }
      break


    default:
      state = { ...state }
      break
  }
  return state
}

export default application;
