import React, { useEffect, useState } from 'react'
import Breadcrumb from 'components/Common/Breadcrumb';
import { Card, CardBody, CardHeader, Col, Row, } from 'reactstrap'
import FeatherIcon from 'feather-icons-react';
import AvtarGroup from 'components/FormControls/AvtarGroup';
import { NavLink, useHistory } from 'react-router-dom';
import Finding from '../Finding'
import { isModulePermisssion } from 'helpers/util';
import { ROLE_PERMISSIONS } from 'constants/RolePermissions';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getApplicationDetails, getKickoffDetail, getApplicationScoreLineChartData, getApplicationSubmissionDonutChartData, getApplicationLogs, generateApplicationReport, listAllCertificateTemplate, generateCertificate } from 'helpers/BackendHelper';
import LineChart from 'components/Common/Graphs/LineChart';
import DonutChart from 'components/Common/Graphs/DonutChart';
import { APPLICATION_LOGS, APPLICATION_STATUS, DONUT_SEVERITY_COLORS, DONUT_SEVERITY_LABELS } from 'constants/Constant';
import PageLoader from 'components/PageLoader';
import defaultImage from "assets/images/Default-User-Image.jpeg";
import moment from 'moment';
import RHFButton from 'components/FormControls/RHFButton';
import DialogBox from 'components/Modals/DialogBox';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import RHFAutoCompleteSelect from 'components/FormControls/RHFAutoCompleteSelect';
import { Toast } from 'components/Common/Toaster';

const Overview = () => {
    document.title = "Application-Overview | RiDiscovery";
    const history = useHistory()
    const [applicationData, setApplicationData] = useState([])
    const [kickoffData, setKickoffData] = useState([])
    const [applicationLogs, setApplicationLogs] = useState([])
    const [appDropdownStatus, setAppDropdownStatus] = useState(null)
    const { applicationId } = useParams()
    const [submissionDonutChartData, setSubmissionDonutChartData] = useState([])
    const [certificateTemplateList, setCertificateTemplateList] = useState([])
    const [DonutChartTotalSubmissions, setDonutChartTotalSubmissions] = useState(null)
    const [scoreLineChartData, setScoreLineChartData] = useState([])
    const [pageLoader, setPageLoader] = useState(false);
    const [isRecallApplicationDetailAPI, setRecallApplicationDetailAPI] = useState(false)
    const [isDownloadAPICalling, setDownloadAPICalling] = useState(false)
    const [isGenerateCertificateAPICalling, setGenerateCertificateAPICalling] = useState(false)
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [assignPentesters, setAssignPentesters] = useState([]);
    const [assignDevelopers, setAssignDevelopers] = useState([]);


    const certificateSchema = yup.object().shape({
        certificateList: yup
            .object()
            .shape({ label: yup.string(), value: yup.string() })
            .nullable()
            .required("Select atleast one option"),
    });

    const {
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(certificateSchema),
    });



    useEffect(() => {
        if (applicationId) {
            const payload = { applicationId: applicationId }
            setPageLoader(true);
            Promise.all([
                getApplicationSubmissionDonutChartData(payload),
                getApplicationScoreLineChartData(payload)
            ]).then(([DonutChartResp, lineChartResp]) => {
                const DonutChartData = Object.values(DonutChartResp?.data);
                DonutChartData.forEach((field) => DonutChartResp?.data[field]);
                setSubmissionDonutChartData(DonutChartData)
                setDonutChartTotalSubmissions(DonutChartResp?.submmisions)
                const data = lineChartResp?.data?.map(item => [item.createdOn, item.avgScore]);
                setScoreLineChartData(data)
                setPageLoader(false);
            })
        }
    }, [])
    const { user } = useSelector(state => state?.Login);

    const renderApplicationStatus = () => {
        const roleId = user?.user?.roleId;
        const isVisible = ['da_manager', 'sm_manager'].includes(roleId);
        const dropdownArr = [];
        if (isVisible) {
            if (roleId === 'sm_manager') {
                if (['open', 'scheduled'].includes(applicationData?.applicationStatus)) {
                    // dropdownArr.push({ label: 'In Progress', value: 'in_progress' });
                    dropdownArr.push({ name: 'In Progress', id: 'in_progress' });
                } else if (applicationData?.applicationStatus === 'in_progress') {
                    // dropdownArr.push({ label: 'In Review', value: 'in_review' });
                    dropdownArr.push({ name: 'In Review', id: 'in_review' });
                }
            } else {
                if (applicationData?.applicationStatus === 'in_review') {
                    // dropdownArr.push({ label: 'Completed', value: 'completed' });
                    dropdownArr.push({ name: 'Completed', id: 'completed' });
                }
            }
        }
        return { isVisible, dropdownArr, applicationStatus: applicationData?.applicationStatus }
    }

    const setRefreshAPI = () => {
        setRecallApplicationDetailAPI(true);
    }

    useEffect(() => {
        const params = {
            id: applicationId
        }
        if (applicationId) {
            getApplicationDetails({ ...params }).then(resp => {
                setApplicationData(resp?.data);
                if (resp.status) {
                    setApplicationData(resp?.data);
                } else {
                    history.goBack();
                }
            })
        }
    }, [isRecallApplicationDetailAPI]);

    useEffect(() => {
        if (applicationId) {
            const params = { id: applicationId }
            getKickoffDetail({ ...params }).then(resp => {
                setKickoffData(resp?.data);
            })

            getApplicationLogs({ ...params }).then(resp => {
                setApplicationLogs(resp?.data);
            })
        }
    }, [])


    useEffect(() => {
        const { isVisible, dropdownArr, applicationStatus } = renderApplicationStatus();
        setAppDropdownStatus({ isVisible, dropdownArr, id: applicationId, selected: APPLICATION_STATUS[applicationStatus] });
    }, [applicationId, applicationData])

    const updateKickOff = isModulePermisssion(ROLE_PERMISSIONS?.UPDATE_KICK_OFF_DOC)

    const renderText = (log) => {
        let finalText = '';

        if ([APPLICATION_LOGS.create, APPLICATION_LOGS.update]?.includes(log?.activityName)) {
            finalText = <span>has <b>{log?.activityName}d</b> application.</span>
        } else if ([APPLICATION_LOGS.d_assign, APPLICATION_LOGS.p_assign, APPLICATION_LOGS.dm_assign, APPLICATION_LOGS.sm_assign]?.includes(log?.activityName)) {
            finalText = <span>has assigned <b>{log?.user?.firstName} {log?.user?.lastName}</b> into application.</span>
        } else if ([APPLICATION_LOGS.d_unassign, APPLICATION_LOGS.p_unassign, APPLICATION_LOGS.dm_unassign, APPLICATION_LOGS.sm_unassign]?.includes(log?.activityName)) {
            finalText = <span>has unassigned <b>{log?.user?.firstName} {log?.user?.lastName}</b> from application.</span>
        } else if ([APPLICATION_LOGS.update_status]?.includes(log?.activityName)) {
            finalText = <span>has changed the application status.</span>
        } else if ([APPLICATION_LOGS.download_report]?.includes(log?.activityName)) {
            finalText = <span>has <b>downloaded</b> application report.</span>
        } else if ([APPLICATION_LOGS.download_certificate]?.includes(log?.activityName)) {
            finalText = <span>has <b>downloaded</b> application certificate.</span>
        } else if ([APPLICATION_LOGS.generate_certificate]?.includes(log?.activityName)) {
            finalText = <span>has <b>generated</b> application certificate.</span>
        }

        return finalText;
    }

    const downloadFile = (dataurl, filename) => {
        const link = document.createElement("a");
        link.href = dataurl;
        link.download = filename;

        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const downloadAppReport = () => {
        setDownloadAPICalling(true);
        generateApplicationReport({ applicationId }).then(resp => {
            setDownloadAPICalling(false);
            downloadFile(URL.createObjectURL(resp), `${applicationData?.penId} - Report.pdf`);
        }).catch(() => {
            setDownloadAPICalling(false);
        })
    }

    const handleToggle = () => {
        setIsModelOpen(!isModelOpen);
        reset();
    };

    const showCertificateList = () => {
        setIsModelOpen(true);
        listAllCertificateTemplate().then(resp => {
            setCertificateTemplateList(resp?.data?.map(item => ({ label: item?.title, value: item?.id })));
        })
    }

    const onSubmit = (data) => {
        setGenerateCertificateAPICalling(true);
        const payload = {
            "applicationId": applicationId,
            "certificateId": data?.certificateList?.value
        }
        generateCertificate(payload).then(resp => {
            if (resp?.status) {
                Toast.success('Certificate has been generated successfully');
                handleToggle();
                reset();
            }
            setGenerateCertificateAPICalling(false);
        }).catch(() => {
            setGenerateCertificateAPICalling(false);
        })
    }

    const getAssignedPentesters = (data) => {
        setAssignPentesters(data);
    }
    const getAssignedDevelopers = (data) => {
        setAssignDevelopers(data);
    }

    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/applications", heading: "Application" },
    { path: `/application/${applicationId}/overview`, heading: `Application Overview` }]


    return (
        <React.Fragment>
            {pageLoader ?
                <PageLoader />
                :
                <div className="page-content">
                    <Breadcrumb
                        // badgeTitle={APPLICATION_STATUS[applicationData?.applicationStatus]}
                        title={`${applicationData?.applicationName || ''} ${applicationData?.penId ? `(${applicationData?.penId})` : ''}`}
                        breadcrumbItem={breadcrumbItems}
                        dropdown={appDropdownStatus}
                        setRefreshAPI={setRefreshAPI}
                    />
                    <Row className='g-3'>
                        <Col lg={9}>
                            {applicationData && applicationData?.totalFindings !== 0 ?
                                <Row className='g-3'>
                                    <Col lg={5}>
                                        <Card className="m-0 h-100">
                                            <CardHeader>
                                                <h4 className="card-title mb-0">Finding by Severity</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <DonutChart
                                                    optionSeriesData={submissionDonutChartData}
                                                    DonutChartTotal={DonutChartTotalSubmissions}
                                                    donutColors={DONUT_SEVERITY_COLORS}
                                                    donutLabels={DONUT_SEVERITY_LABELS}
                                                    height="300"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={7}>
                                        <Card className="m-0 h-100">
                                            <CardHeader>
                                                <h4 className="card-title mb-0">Finding Timeline</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <LineChart
                                                    height="300"
                                                    seriesTitle='Score Board'
                                                    seriesData={scoreLineChartData}
                                                    lineColor={["#3957d5"]}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row> :
                                null}
                            {/* <Finding editApplicationData={editApplicationDetails} /> */}
                            <Finding applicationData={applicationData} />


                            {/** Logs section */}
                            {applicationLogs?.length > 0 &&
                                <Card>
                                    <CardHeader tag="h6">
                                        Applications Logs
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <div className='audit-logs'>
                                                    {applicationLogs?.map(log => (
                                                        <div className="logs-wrapper" key={log?.id}>
                                                            <div className='log-avatar'>
                                                                <img src={log?.createdByUser?.profile ? log?.createdByUser?.profile : defaultImage} alt="" className="rounded-circle" width="50" height="50" />
                                                            </div>
                                                            <div className='log-details-wrapper'>
                                                                <div className='log-details'>
                                                                    <h6 className='m-0 p-0'>
                                                                        {`${log?.createdByUser?.firstName} ${log?.createdByUser?.lastName}`}
                                                                    </h6>
                                                                    <span className='ms-1'>
                                                                        {renderText(log)}
                                                                    </span>

                                                                    <span className='ms-auto log-date-time'>{moment(log?.createdAt).format('Do MMM YYYY h:mm a')}</span>
                                                                </div>
                                                                {log?.activityName === APPLICATION_LOGS.update_status &&
                                                                    <div className='log-status'>
                                                                        <p className='fs-6 mb-0'>
                                                                            <span className='prev-status'>{APPLICATION_STATUS[log?.prevValue]}</span>
                                                                            <span className=''>  <FeatherIcon
                                                                                icon="arrow-right"
                                                                                size="22"
                                                                                className="actionBtn"
                                                                            /> </span>
                                                                            <span className='new-status fw-bold text-primary'>{APPLICATION_STATUS[log?.newValue]}</span>
                                                                        </p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            }

                        </Col>
                        <Col lg={3}>
                            <Card>
                                <CardHeader>
                                    <div className='d-flex justify-content-between'>
                                        <h4 className="card-title mb-0">Kick-off Details</h4>
                                        {updateKickOff && ['open', 'scheduled', 'in_progress'].includes(applicationData?.applicationStatus) && <FeatherIcon
                                            icon="edit-3"
                                            size="22"
                                            className="actionBtn"
                                            onClick={() => {
                                                if (kickoffData?.id) {
                                                    history.push({
                                                        pathname: `/application/${applicationId}/edit-kickoff/${kickoffData?.id}`,
                                                        state: { data: kickoffData }
                                                    })
                                                }
                                                else {
                                                    history.push({
                                                        pathname: `/application/${applicationId}/add/kickoff`, state: { objData: applicationData },
                                                    })
                                                }
                                            }}
                                        />}
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div className="app-detail-wrapper">

                                        {['oam_manager', 'sm_manager'].includes(user?.user?.roleId) && ['in_review', 'completed'].includes(applicationData?.applicationStatus) &&
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <RHFButton
                                                            btnName="Download Application Report"
                                                            icon="download"
                                                            iconPlacement="start"
                                                            onClick={() => downloadAppReport()}
                                                            isLoading={isDownloadAPICalling}
                                                        />
                                                    </p></div>
                                            </div>
                                        }

                                        {['sm_manager'].includes(user?.user?.roleId) && ['completed'].includes(applicationData?.applicationStatus) &&
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <RHFButton
                                                            btnName="Generate Application Certificate"
                                                            icon="upload"
                                                            iconPlacement="start"
                                                            onClick={() => showCertificateList()}
                                                        // isLoading={isDownloadAPICalling}
                                                        />
                                                    </p></div>
                                            </div>
                                        }

                                        {applicationData?.startDate && <div className='app-detail'>
                                            <div className='value-wrapper'>
                                                <p className='value-row'>
                                                    <span className="value-title">Start Date :</span>
                                                    <span className="value-text">{applicationData?.startDate}</span>
                                                </p>
                                            </div>
                                        </div>}
                                        {/* <div className='app-detail'>
                                <div className='value-wrapper'>
                                    <p className='value-row'>
                                        <span className="value-title">Scope :</span>
                                        <span className="value-text">
                                            <NavLink
                                                to={{ pathname: editApplicationDetails?.scope }} target="_blank"
                                            >
                                                {editApplicationDetails?.scope}
                                            </NavLink></span>
                                    </p>
                                </div>
                            </div> */}

                                        {/* <RHFDatePicker
                                    name="endDate"
                                    label="End Date"
                                    defaultValue={editApplicationDetails?.endDate}
                                    isController={false}
                                    disabled={true}
                                /> */}
                                        <div className='app-detail'>
                                            <div className='value-wrapper'>
                                                <p className='value-row'>
                                                    <span className="value-title">Pentesters</span>
                                                    <AvtarGroup
                                                        // assignedUsers={applicationData?.securityTeam && applicationData?.securityTeam?.map(o => ({ ...o.user, isAssigned: true })) || []}
                                                        assignedUsers={assignPentesters?.length > 0 ? assignPentesters : applicationData?.securityTeam && applicationData?.securityTeam?.map(o => ({ ...o.user, isAssigned: true })) || []}
                                                        max={3}
                                                        type="pentester"
                                                        appId={applicationId}
                                                        popUpId={`${applicationId}_pentester`}
                                                        requestType="application"
                                                        assignRemovePermission={isModulePermisssion(ROLE_PERMISSIONS.ASSIGN_REMOVE_TEAM_P) && ['open', 'scheduled', 'in_progress'].includes(applicationData?.applicationStatus)}

                                                        isDetailPage={true}
                                                        getAssignedUsers={getAssignedPentesters}
                                                    />
                                                </p></div>
                                        </div>

                                        <div className='app-detail'>
                                            <div className='value-wrapper'>
                                                <p className='value-row'>
                                                    <span className="value-title">Developers</span>
                                                    <AvtarGroup
                                                        // assignedUsers={applicationData?.developmentTeam && applicationData?.developmentTeam?.map(o => ({ ...o.user, isAssigned: true }))}
                                                        assignedUsers={assignDevelopers?.length > 0 ? assignDevelopers : applicationData?.developmentTeam && applicationData?.developmentTeam?.map(o => ({ ...o.user, isAssigned: true }))}
                                                        max={3}
                                                        type="developer"
                                                        appId={applicationId}
                                                        popUpId={`${applicationId}_developer`}
                                                        requestType="application"
                                                        assignRemovePermission={isModulePermisssion(ROLE_PERMISSIONS.ASSIGN_REMOVE_TEAM_D) && ['open', 'scheduled', 'in_progress'].includes(applicationData?.applicationStatus)}

                                                        isDetailPage={true}
                                                        getAssignedUsers={getAssignedDevelopers}
                                                    />
                                                </p></div>
                                        </div>
                                        {kickoffData?.fqdn && <div className='app-detail'>
                                            <div className='value-wrapper'>
                                                <p className='value-row'>
                                                    <span className="value-title">FQDN :</span>
                                                    <span className="value-text">{kickoffData?.fqdn}</span>
                                                </p>
                                            </div>
                                        </div>}
                                        {kickoffData?.walkthrough && <div className='app-detail'>
                                            <div className='value-wrapper'>
                                                <p className='value-row'>
                                                    <span className="value-title">Walkthrough :</span>
                                                    <span className="value-text">
                                                        <NavLink
                                                            to={{ pathname: kickoffData?.walkthrough }} target="_blank"
                                                        >
                                                            {kickoffData?.walkthrough}
                                                        </NavLink>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>}
                                        {kickoffData?.environment && <div className='app-detail'>
                                            <div className='value-wrapper'>
                                                <p className='value-row'>
                                                    <span className="value-title">Environment :</span>
                                                    <span className="value-text">{kickoffData?.environment}</span>
                                                </p>
                                            </div>
                                        </div>}
                                        {kickoffData?.assessmentType && <div className='app-detail'>
                                            <div className='value-wrapper'>
                                                <p className='value-row'>
                                                    <span className="value-title">Assessment Type :</span>
                                                    <span className="value-text">{kickoffData?.assessmentType}</span>
                                                </p>
                                            </div>
                                        </div>}
                                        {kickoffData?.ipAddress?.length > 0 && <div className='app-detail'>
                                            <div className='value-wrapper'>
                                                <p className='value-row'>
                                                    <span className="value-title">Ip Address :</span>
                                                    <span className="value-text">{kickoffData?.ipAddress}</span>
                                                </p>
                                            </div>
                                        </div>}
                                        {kickoffData?.URI && <div className='app-detail'>
                                            <div className='value-wrapper'>
                                                <p className='value-row'>
                                                    <span className="value-title">URI :</span>
                                                    <span className="value-text"> <NavLink
                                                        to={{ pathname: kickoffData?.URI }} target="_blank"
                                                    >
                                                        {kickoffData?.URI}
                                                    </NavLink></span>
                                                </p>
                                            </div>
                                        </div>}
                                        {kickoffData?.checkList && <div className='app-detail'>
                                            <div className='value-wrapper'>
                                                <p className='value-row'>
                                                    <span className="value-title">Check List :</span>
                                                    {kickoffData?.checkList && kickoffData?.checkList.map((item) => {
                                                        return (
                                                            <span className="value-text d-block me-2" key={item} >{item}</span>
                                                        )
                                                    })
                                                    }
                                                </p>
                                            </div>
                                        </div>}
                                        {kickoffData?.scopeDetails?.length > 0 && <div className='app-detail'>
                                            <h4 className="card-title">Scope Details : </h4>
                                            <div className='value-wrapper'>
                                                {kickoffData?.scopeDetails && kickoffData?.scopeDetails.map((item, index) =>
                                                    Object.entries(item).map(([key, value]) => {
                                                        return (
                                                            <p className='value-row' key={index}>
                                                                <span className="value-title">{key}</span>
                                                                <span className="value-text">{value}</span>
                                                            </p>
                                                        )
                                                    })
                                                )}
                                            </div>
                                        </div>}
                                        {kickoffData?.applicationDetails?.length > 0 && <div className='app-detail'>
                                            <h4 className="card-title">Application Details : </h4>
                                            <div className='value-wrapper'>
                                                {kickoffData?.applicationDetails && kickoffData?.applicationDetails.map((item, index) =>
                                                    Object.entries(item).map(([key, value]) => {
                                                        return (
                                                            <p className='value-row' key={index}>
                                                                <span className="value-title">{key}</span>
                                                                <span className="value-text">{value}</span>
                                                            </p>
                                                        )
                                                    })
                                                )}
                                            </div>
                                        </div>}
                                        {kickoffData?.outOfScope?.length > 0 && <div className='app-detail'>
                                            <h4 className="card-title">Out Of Scope : </h4>
                                            <div className='value-wrapper'>
                                                {kickoffData?.outOfScope && kickoffData?.outOfScope.map((item, index) =>
                                                    Object.entries(item).map(([key, value]) => {
                                                        return (
                                                            <p className='value-row' key={index}>
                                                                <span className="value-title">{key}</span>
                                                                <span className="value-text">{value}</span>
                                                            </p>
                                                        )
                                                    })
                                                )}
                                            </div>
                                        </div>}

                                        {kickoffData?.userDetails?.length > 0 && <div className='app-detail'>
                                            <h4 className="card-title">User Details : </h4>
                                            <div className='value-wrapper'>
                                                {kickoffData?.userDetails && kickoffData?.userDetails.map((item, index) =>
                                                    Object.entries(item).map(([key, value]) => {
                                                        return (
                                                            <p className='value-row' key={index}>
                                                                <span className="value-title">{key}</span>
                                                                <span className="value-text">{value}</span>
                                                            </p>
                                                        )
                                                    })
                                                )}
                                            </div>
                                        </div>}
                                        {kickoffData?.serverDetails?.length > 0 && <div className='app-detail'>
                                            <h4 className="card-title">Server Details : </h4>
                                            <div className='value-wrapper'>
                                                {kickoffData?.serverDetails && kickoffData?.serverDetails.map((item, index) =>
                                                    Object.entries(item).map(([key, value]) => {
                                                        return (
                                                            <p className='value-row' key={index}>
                                                                <span className="value-title">{key}</span>
                                                                <span className="value-text">{value}</span>
                                                            </p>
                                                        )
                                                    })
                                                )}
                                            </div>
                                        </div>}
                                    </div>
                                </CardBody>
                            </Card >
                        </Col >
                    </Row >

                    <DialogBox
                        isModelOpen={isModelOpen}
                        handleToggle={handleToggle}
                        modelSize="md"
                        title="Available Certificates"
                        actions={null}
                    >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row className="mb-3">
                                <Col sm="12">
                                    <RHFAutoCompleteSelect
                                        id="certificateList"
                                        label="Choose Certificate"
                                        placeholder='Select'
                                        name="certificateList"
                                        options={certificateTemplateList}
                                        setValue={setValue}
                                        isMultiple={false}
                                        errorobj={errors}
                                        control={control}
                                        isController={true}
                                    />
                                </Col>
                            </Row>
                            <div className="d-flex justify-content-end">
                                <RHFButton
                                    btnName="Cancel"
                                    outline={true}
                                    onClick={handleToggle}
                                    className="mx-2"

                                />
                                <RHFButton
                                    btnName="Submit"
                                    type="submit"
                                    isLoading={isGenerateCertificateAPICalling}
                                />
                            </div>
                        </form>
                    </DialogBox>

                </div>
            }
        </React.Fragment >
    )
}

export default Overview