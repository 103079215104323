import React from 'react'
import ReactApexChart from 'react-apexcharts'

const RadialChart = (props) => {
    const { radialchartColorsCode = [], seriesData = [] } = props

    const radialchartColors = radialchartColorsCode
    const series = seriesData
    const options = {
        chart: {
            height: 270,
            type: 'radialBar',
            offsetY: -10
        },
        plotOptions: {
            radialBar: {
                startAngle: -130,
                endAngle: 130,
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        offsetY: 10,
                        fontSize: '18px',
                        color: undefined,
                        formatter: function (val) {
                            return (val / 10).toFixed(1)
                        }
                    },
                },

                hollow: {
                    margin: 0,
                    size: "50%",
                },
                track: {
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        blur: 4,
                        opacity: 0.15
                    }
                },
            }
        },
        noData: {
            text: "None found.",
        },
        tooltip: {
            enabled: false,
            y: {
                formatter: function (val) {
                    return (val / 10).toFixed(1)
                },
            },
        },
        colors: [radialchartColors[0]],
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                gradientToColors: [radialchartColors[1]],
                shadeIntensity: 0.15,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [20, 60]
            },
        },
        stroke: {
            dashArray: 2,
        },
        legend: {
            show: false
        },
        // labels: ['Series A'],
    };

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="radialBar"
            height="263"
            className="apex-charts"
        />
    )
}

export default RadialChart
