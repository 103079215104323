import React, { useEffect, useState } from 'react';
import { CKEditor } from 'ckeditor4-react';
import { Controller } from "react-hook-form";
import { FormFeedback, Label } from 'reactstrap';

const RHFCKEditor = (props) => {
    const { name, errorobj, control, setValue, details, label, isRequired = true } = props

    const [showEditor, setloadEditor] = useState(false);
    let isError = false;
    let errorMessage = "";

    if (errorobj && Object.prototype.hasOwnProperty.call(errorobj, name)) {
        isError = true;
        errorMessage = errorobj[name]?.message;
    }

    useEffect(() => {
        if (details) {
            setValue(`${name}`, details?.[name] || '');
        }
    }, [details, name])

    useEffect(() => {
        setTimeout(() => {
            setloadEditor(true)
        }, [1000])
    }, [])

    return (
        <div>
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    return (
                        <React.Fragment>
                            {showEditor &&
                                <>
                                    {label && <Label htmlFor="example-text-input" className="form-label">
                                        {label} {isRequired && <span className="text-danger">*</span>}
                                    </Label>
                                    }
                                    <CKEditor
                                        {...field}
                                        name={field?.name}
                                        onChange={(event) => field.onChange(event?.editor?.getData())}
                                        editorUrl="https://cdn.ckeditor.com/4.20.1/full/ckeditor.js"
                                        initData={field?.value}
                                        type="classic"
                                        onInstanceReady={() => { }}
                                    />
                                    {isError && <FormFeedback type="invalid">{errorMessage}</FormFeedback>}
                                </>
                            }
                        </React.Fragment>
                    )
                }}
            />
        </div>
    )
}

export default RHFCKEditor;