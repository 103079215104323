import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormFeedback,
    Row,
} from 'reactstrap';
import * as yup from "yup";
import Breadcrumb from 'components/Common/Breadcrumb';
import RHFAutoCompleteSelect from 'components/FormControls/RHFAutoCompleteSelect';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import RHFTextField from 'components/FormControls/RHFTextField';
import RHFTextEditor from 'components/FormControls/RHFTextEditor';
import RHFButton from 'components/FormControls/RHFButton';
import DialogBox from 'components/Modals/DialogBox';
import PocStepsAddEdit from './PocStepsAddEdit';
import FeatherIcon from "feather-icons-react";
import { FINDING_SEVERITY, FINDING_STATUS, FINDING_STATUS_OVERVIEW, urlRegex } from 'constants/Constant';
import RHFInputTags from 'components/FormControls/RHFInputTags';
import { addComment, addFinding, deletePoc, editFinding, getAllCVEListFromCWE, getAllCWEList, getAllOWASP, getApplicationDetails, getCVEDetails, getFinding, getKickoffDetail, getOWASPDetails } from 'helpers/BackendHelper';
import { htmlSanitizer } from 'helpers/util';
import CvssCalculator from './CvssCalculator';
import { Toast } from 'components/Common/Toaster';
import PageLoader from 'components/PageLoader';
import ImageViewer from 'components/ImageViewer'
import ReadOnlyPoc from './ReadOnlyPoc';
import EditablePoc from './EditablePoc';


const FindingAddEdit = () => {
    const history = useHistory()
    const { applicationId, findingId } = useParams()
    const [pageLoader, setPageLoader] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [isException, setException] = useState(false);
    const [formData, setFormData] = useState(null);
    const [pocStepData, setPocStepData] = useState([])
    const [editPocData, setEditPocData] = useState(null);
    const [formStatus, setFormStatus] = useState(true);
    const [isDeleteModelOpen, setDeleteIsModelOpen] = useState(false);
    const [isOpenViewer, setImageViewer] = useState(false);
    const [activePoc, setActivePoc] = useState(null);
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [kickoffData, setKickoffData] = useState([])
    const [cweList, setCWEList] = useState([]);
    const [cveList, setCVEList] = useState([]);
    const [owaspList, setOWASPList] = useState([]);
    const [severityValue, setSeverityValue] = useState(null);
    const [severitytyData, setSeverityData] = useState(null);
    const [isApiCalling, setApiCalling] = useState(false);
    const [isSaveAsDraft, setSaveAsDraft] = useState(false);
    const [appData, setAppData] = useState()
    const [findingDetails, setFindingDetails] = useState(null);
    const isEditMode = findingId
    const [deletePOCdata, setDeletePOCdata] = useState(null)
    const [activeCWE, setSelectedCWE] = useState(null)
    const [activeCVE, setSelectedCVE] = useState(null)
    const [cveDetails, setCVEDetails] = useState(null)
    const [activeOWASP, setSelectedOWASP] = useState(null)
    const [owaspDetails, setOWASPDetails] = useState(null)

    document.title = `${(isEditMode) ? "Edit Finding" : "Add Finding"} | RiDiscovery`;

    const findingSchema = yup.object().shape({
        title: !formStatus && yup
            .string().trim()
            .required("Title is required").typeError("Title is required"),
        cwe: !formStatus && yup
            .object()
            .shape({ label: yup.string(), value: yup.string() })
            .required("Select atleast one option")
            .typeError("Select atleast one option"),
        severity: !formStatus && yup
            .object()
            .shape({ label: yup.string(), value: yup.string() })
            .required("Select atleast one option")
            .typeError("Select atleast one option"),

        method: !formStatus && yup
            .string().trim()
            .required("Method is required").typeError("Method is required"),

        infectedURL: !formStatus && yup.array().nullable().of(yup.string().trim()
            .matches(urlRegex, 'Enter Correct Infected URL')).min(1, "Infected URL is required"),

        pocs: !formStatus && yup.array().min(1, 'Pocs are required'),
        description: !formStatus && yup
            .string().trim().test('description', 'Description is required', function (value) {
                if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes("<img")) {
                    return false;
                }
                return true;
            })
            .required("Description is required").typeError("Description is required"),
        impect: !formStatus && yup
            .string().trim().test('impect', "Impact is required", function (value) {
                if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes("<img")) {
                    return false;
                }
                return true;
            })
            .required("Impact is required").typeError("Impact is required"),
        remediation: !formStatus && yup
            .string().trim().test('remediation', "Remediation is required", function (value) {
                if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes("<img")) {
                    return false;
                }
                return true;
            })
            .required("Remediation is required").typeError("Remediation is required"),

        comment: yup.string().trim().max(200, 'Comment should be less then 200 charecters')
    });

    useEffect(() => {
        if (isEditMode) {
            const params = {
                findingId: findingId
            }
            // setPageLoader(true)
            getFinding({ ...params }).then(resp => {
                setFindingDetails(resp?.data);
                // setPageLoader(false)
            })
        }
    }, []);

    useEffect(() => {
        const params = {
            id: applicationId
        }
        if (applicationId) {
            getApplicationDetails({ ...params }).then(resp => {
                if (resp.status) {
                    setAppData(resp?.data);
                }
            })
        }
    }, []);

    const {
        handleSubmit: findingHandleSubmit,
        control: findingControl,
        setValue: findingSetValue,
        reset: findingReset,
        formState: { errors: findingErrors },
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(findingSchema),
    });

    const handleToggle = () => {
        setIsModelOpen(!isModelOpen);
    };
    const handleDeleteToggle = () => {
        setDeleteIsModelOpen(!isDeleteModelOpen)
    }

    const toggle = () => {
        setImageViewer(!isOpenViewer);
    }

    useEffect(() => {
        setPageLoader(true)
        const params = { id: applicationId }
        Promise.all([
            getKickoffDetail({ ...params }),
            getAllCWEList(),
            getAllOWASP()
        ]).then(([kickoffData, cweData, owaspData]) => {
            setKickoffData(kickoffData?.data);
            setCWEList(cweData?.data?.map(data => ({ label: data?.name, value: +data?.CWEID })))
            // setOWASPList(owaspData?.data?.map(data => ({ label: data?.name, value: +data?.CWEID, description: data?.description, reference: data?.reference, impect: data?.impect, remediation: data?.remediation })))
            setOWASPList(owaspData?.data?.map(data => ({ label: data?.name, value: data?.cweId, id: data?.id })))
            setPageLoader(false)
        }).catch(() => {
            setPageLoader(false)
        });
    }, [])

    useEffect(() => {
        if (activeCWE || findingDetails) {
            const payload = {
                cweId: isEditMode && !activeCWE ? findingDetails?.cwes[0]?.cweId : activeCWE?.value,
                populateFields: ["id", "name"]
            }
            getAllCVEListFromCWE(payload).then(resp => {
                setCVEList(resp?.data?.map(data => ({ label: data?.name, value: data?.name, id: data?.id })))
            })
        }
    }, [activeCWE, findingDetails])

    useEffect(() => {
        if (activeCVE) {
            const params = {
                id: activeCVE?.id,
            }
            getCVEDetails({ ...params }).then(resp => {
                setCVEDetails(resp?.data);
            })
        }
    }, [activeCVE])

    useEffect(() => {
        if (activeOWASP) {
            const params = {
                id: activeOWASP?.id,
            }
            getOWASPDetails({ ...params }).then(resp => {
                setOWASPDetails(resp?.data);
            })
        }
    }, [activeOWASP])

    useEffect(() => {
        if (cveDetails) {
            findingSetValue('description', cveDetails?.description);
            findingSetValue('impect', cveDetails?.impect);
            findingSetValue('remediation', cveDetails?.remediation);
            findingSetValue('reference', cveDetails?.reference);
        }
        else if (owaspDetails) {
            findingSetValue('description', owaspDetails?.description);
            findingSetValue('impect', owaspDetails?.impect);
            findingSetValue('remediation', owaspDetails?.remediation);
            findingSetValue('reference', owaspDetails?.reference);
        }
    }, [cveDetails, owaspDetails]);

    useEffect(() => {
        if (formData) {
            setPocStepData(prevState => [...prevState, formData]);
        }
    }, [formData])

    useEffect(() => {
        findingSetValue('pocs', pocStepData);
    }, [pocStepData])

    useEffect(() => {
        if (findingDetails) {
            setPocStepData(findingDetails?.['pocs']);
        }
    }, [findingDetails])

    const findingOnSubmit = async (data) => {
        if (!formStatus) {
            setApiCalling(true)
        } else {
            setSaveAsDraft(true);
        }

        if (!formStatus && !isApiCalling || formStatus && !isSaveAsDraft) {
            const { comment, description, cve, cwe, status, impect, owasp, pocs, reference, remediation, severity, title, infectedURL, method } = data
            const copyPocs = await pocs?.map(o => ({ ...o, images: o?.images?.map(x => x.url) }));
            const payload = {
                applicationId: applicationId,
                findingStatus: status?.value,
                isEditable: formStatus,
                title,

                cwe: cwe ? [{ cweId: cwe?.value, name: cwe?.label }] : [],
                // cve: data?.cve?.map(o => ({ cveId: o.value, name: o.label })),
                cve: cve ? [{ cveId: cve?.value, name: cve?.label }] : [],
                // owasp: data?.owasp?.map(o => ({ cweId: +o.value, name: o.label })),
                owasp: owasp ? [{ cweId: owasp?.value, name: owasp?.label }] : [],

                severity: severity?.value === undefined ? null : severity?.value === null ? null : severity?.value,
                method,
                infectedURL,
                description,
                impect,
                remediation,
                reference,
                pocs: copyPocs,
                "cvss": severitytyData ? severitytyData?.cvss : '',
                "cvssScore": severitytyData ? severitytyData?.score : '',
                "cvssName": severitytyData ? severitytyData?.name : '',
            }

            if (isEditMode) {
                editFinding({ findingId: findingId, ...payload }).then(resp => {
                    Toast.success(resp?.message)
                    history.push({ pathname: `/application/${applicationId}/overview`, state: { objData: appData } })
                    findingReset();
                    setApiCalling(false);
                    setSaveAsDraft(false);
                }).catch(() => {
                    setApiCalling(false);
                    setSaveAsDraft(false);
                });
            } else {
                addFinding(payload).then(resp => {
                    Toast.success(resp?.message)
                    history.push({ pathname: `/application/${applicationId}/overview`, state: { objData: appData } })
                    findingReset();
                    setApiCalling(false);
                    setSaveAsDraft(false);
                }).catch(() => {
                    setApiCalling(false);
                    setSaveAsDraft(false);
                });
            }

            if (comment?.length > 0) {
                const data = {
                    comment,
                    isException,
                    "findingId": findingId
                }
                addComment(data).then(resp => {
                    Toast.success(resp?.message)
                    findingReset();
                }).catch(() => {
                });
            }
            setSeverityValue(null);
        }
    };

    const deleteStep = () => {
        const pocdata = deletePOCdata
        const deleted = pocStepData?.filter((item) => item?.id !== pocdata?.id);
        setPocStepData(deleted);
        if (findingId) {
            deletePoc({ pocId: pocdata?.id, findingId: findingId }).then((resp) => {
                Toast.success(resp?.message)
                handleDeleteToggle()
            }).catch(() => {
                handleDeleteToggle()
            })
        }
        handleDeleteToggle()
    }

    const updateStep = (pocdata) => {
        setEditPocData(pocdata)
    }

    const handleOnChange = (data) => {
        setSeverityValue(data?.value);
    };

    const handleCWE = (data) => {
        setSelectedCWE(data)
    };

    const handleCVE = (data) => {
        setSelectedCVE(data)
    };

    const handleOWASP = (data) => {
        setSelectedOWASP(data)
    };

    const setCvssData = (data) => {
        setSeverityData(data);
    }

    useEffect(() => {
        if (isEditMode && !activeCWE) {
            const findingStatus = appData?.assessmentType === 'initial' ? FINDING_STATUS[0] : FINDING_STATUS?.find(o => o.value === findingDetails?.findingStatus);
            // findingSetValue('status', findingStatus);
            findingSetValue('findingStatus', findingStatus);
            findingSetValue('title', findingDetails?.findingTitle);
            // findingSetValue('cwe', cweList?.find(o => o.value === +findingDetails?.cwes));
            findingSetValue('cwe', cweList?.find((data) => findingDetails?.cwes?.map(o => o.cweId).includes(data.value)) || []);

            // findingSetValue('cve', cveList?.filter((data) => findingDetails?.cves?.map(o => o.cveId).includes(data.value)));
            findingSetValue('cve', cveList?.find((data) => findingDetails?.cves?.map(o => o.cveId).includes(data.value)) || []);

            // findingSetValue('owasp', cweList?.filter((data) => findingDetails?.owasps?.map(o => +o.cweId).includes(+data.value)));
            findingSetValue('owasp', owaspList?.find((data) => findingDetails?.owasps?.map(o => o.cweId).includes(data.value)) || []);

            findingSetValue('severity', FINDING_SEVERITY?.find(o => o.value === +findingDetails?.severity) || null);
            findingSetValue('method', findingDetails?.method);
            findingSetValue('infectedURL', findingDetails?.infectedURL);
            // findingSetValue('description', findingDetails?.description);
            findingSetValue('description', htmlSanitizer(findingDetails?.description));
            findingSetValue('impect', htmlSanitizer(findingDetails?.impect));
            findingSetValue('remediation', htmlSanitizer(findingDetails?.remediation));
            findingSetValue('reference', htmlSanitizer(findingDetails?.reference));
            findingSetValue('correctionDescription', findingDetails?.correctionDescription);

            setSeverityValue(+findingDetails?.severity);
        }
        else {
            findingSetValue(null)
        }
    }, [findingDetails, cweList, cveList]);

    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/applications", heading: "Application" },
    { path: `/application/${applicationId}/overview`, heading: `Application Overview` },
    { path: `/application/${applicationId}/add-finding`, heading: (!isEditMode) ? "New Finding" : `Edit Finding` }]

    return (
        <React.Fragment>
            {pageLoader ?
                <PageLoader />
                :
                <div className="page-content">
                    <Breadcrumb title="Finding" breadcrumbItem={breadcrumbItems} />
                    <form onSubmit={findingHandleSubmit(findingOnSubmit)}>
                        <Card>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col sm="6">
                                        <RHFTextField
                                            id="title"
                                            label="Title"
                                            name="title"
                                            errorobj={findingErrors}
                                            control={findingControl}
                                            isController={true}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <RHFAutoCompleteSelect
                                            id="cwe"
                                            label="CWE"
                                            name="cwe"
                                            options={cweList && cweList}
                                            isMultiple={false}
                                            errorobj={findingErrors}
                                            control={findingControl}
                                            handleOnChange={handleCWE}
                                            isController={true}
                                        />
                                    </Col>
                                </Row>

                                {
                                    (kickoffData?.checkList?.includes("CVE") || kickoffData?.checkList?.includes("OWASP")) &&
                                    <Row className="mb-3">
                                        {kickoffData?.checkList?.includes("CVE") &&
                                            <Col sm={kickoffData?.checkList?.includes("OWASP") ? "6" : "12"}>
                                                <RHFAutoCompleteSelect
                                                    id="cve"
                                                    label="CVE"
                                                    name="cve"
                                                    // options={activeCWE ? cveList && cveList : []}
                                                    options={activeCWE || findingDetails?.cves ? cveList && cveList : []}
                                                    isMultiple={false}
                                                    errorobj={findingErrors}
                                                    control={findingControl}
                                                    isController={true}
                                                    isRequired={false}
                                                    handleOnChange={handleCVE}
                                                />
                                            </Col>}
                                        {kickoffData?.checkList?.includes("OWASP") &&
                                            <Col sm={kickoffData?.checkList?.includes("CVE") ? "6" : "12"}>
                                                <RHFAutoCompleteSelect
                                                    id="owasp"
                                                    label="OWASP"
                                                    name="owasp"
                                                    options={owaspList && owaspList}
                                                    isMultiple={false}
                                                    errorobj={findingErrors}
                                                    control={findingControl}
                                                    isController={true}
                                                    isRequired={false}
                                                    handleOnChange={handleOWASP}
                                                />
                                            </Col>
                                        }
                                    </Row>
                                }
                                <Row className="mb-3">
                                    <Col sm="6">
                                        <RHFAutoCompleteSelect
                                            id="severity"
                                            label="Severity"
                                            name="severity"
                                            options={FINDING_SEVERITY}
                                            isMultiple={false}
                                            errorobj={findingErrors}
                                            control={findingControl}
                                            isController={true}
                                            handleOnChange={handleOnChange}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <RHFTextField
                                            id="method"
                                            label="Method"
                                            name="method"
                                            errorobj={findingErrors}
                                            control={findingControl}
                                            isController={true}
                                        />
                                    </Col>
                                </Row>
                                {severityValue === 10 &&
                                    <CvssCalculator getCvssData={setCvssData} defualtCvss={findingDetails ? findingDetails?.cvss : ''} />}
                                <Row className="mb-3">
                                    <Col sm="12">
                                        <RHFInputTags
                                            id="infectedURL"
                                            label="Infected URL"
                                            name="infectedURL"
                                            placeholder="Add infected URL"
                                            errorobj={findingErrors}
                                            control={findingControl}
                                            isController={true}
                                        />
                                    </Col>
                                </Row>

                                {FINDING_STATUS_OVERVIEW[2].value === findingDetails?.status &&
                                    <Row className="mb-3">
                                        <Col sm="12">
                                            <RHFTextField
                                                id="correctionDescription"
                                                label="Correction Description"
                                                name="correctionDescription"
                                                errorobj={findingErrors}
                                                control={findingControl}
                                                isController={true}
                                                isRequired={false}
                                                disabledField={true}
                                            />
                                        </Col>
                                    </Row>
                                }

                                <Row className="mb-3">
                                    <Col sm="12">
                                        <RHFTextEditor
                                            id="description"
                                            label="Description"
                                            name="description"
                                            errorobj={findingErrors}
                                            control={findingControl}
                                            isController={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col sm="12">
                                        <Card style={{ borderColor: "#ccc" }}>
                                            <CardHeader className="py-2 rounded-0 card-header-bg d-flex align-items-center mb-2">
                                                <h5 className="card-title m-0">Proof of Concept <span className='text-danger'>*</span></h5>
                                                {pocStepData?.length < 1 && <Button type='button' color="primary" size='sm' className="ms-auto" onClick={() => {
                                                    handleToggle();
                                                    setFormData(null);
                                                    setEditPocData(null);
                                                }}>
                                                    + Add New Step
                                                </Button>}
                                            </CardHeader>
                                            <CardBody>

                                                {pocStepData?.map((pocdata, index) => (
                                                    <Card className='border-0' key={index} >
                                                        {/* <div key={index} className='poc-detail-wrapper my-2'> */}
                                                        <CardHeader className="py-2 rounded-0 card-header-bg d-flex align-items-center " style={{ height: "38px" }}>
                                                            <h6 className='card-title m-0'>
                                                                Step {index + 1}:</h6>
                                                            {(editPocData?.id !== pocdata?.id) ?
                                                                <div className='ms-auto'>
                                                                    <FeatherIcon
                                                                        className="cursor-pointer ms-2" size="20" icon="trash-2"
                                                                        onClick={() => {
                                                                            handleDeleteToggle()
                                                                            setDeletePOCdata(pocdata)
                                                                        }}
                                                                    />
                                                                    <FeatherIcon
                                                                        className="cursor-pointer ms-2" size="20" icon="edit"
                                                                        onClick={() => { updateStep(pocdata) }}
                                                                    />
                                                                </div> : ""}
                                                        </CardHeader>
                                                        <CardBody>
                                                            {(editPocData?.id === pocdata?.id) ?
                                                                <EditablePoc
                                                                    pocStepData={pocStepData}
                                                                    setPocStepData={setPocStepData}
                                                                    editPocData={editPocData}
                                                                    setEditPocData={setEditPocData}
                                                                /> :
                                                                <ReadOnlyPoc
                                                                    handleToggle={handleToggle}
                                                                    pocdata={pocdata}
                                                                    setActivePoc={setActivePoc}
                                                                    setImageViewer={setImageViewer}
                                                                    setActiveImageIndex={setActiveImageIndex}
                                                                />}
                                                            {pocStepData[pocStepData?.length - 1]?.id === pocdata?.id &&
                                                                <div className="d-flex justify-content-end">
                                                                    <Button type='button' color="primary" size='sm' className="ms-auto" onClick={() => {
                                                                        handleToggle();
                                                                        setFormData(null);
                                                                        setEditPocData(null);
                                                                    }}>
                                                                        + Add New Step
                                                                    </Button>
                                                                </div>

                                                            }
                                                        </CardBody>
                                                    </Card>
                                                )
                                                )}

                                                {isModelOpen &&
                                                    <Card className='border-0 '>
                                                        <CardHeader className="py-2 rounded-0 card-header-bg d-flex align-items-center" style={{ height: "38px" }}>
                                                            <h6 className="card-title m-0">POC Form <span className='text-danger'>*</span></h6>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <PocStepsAddEdit
                                                                handleToggle={handleToggle}
                                                                setFormData={setFormData}
                                                                pocStepData={pocStepData}
                                                                setPocStepData={setPocStepData}
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                }
                                            </CardBody>
                                        </Card>
                                        <FormFeedback type="invalid">{findingErrors?.pocs?.message}</FormFeedback>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col sm="12">
                                        <RHFTextEditor
                                            name="impect"
                                            id="impect"
                                            errorobj={findingErrors}
                                            control={findingControl}
                                            label="Impact"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col sm="12">
                                        <RHFTextEditor
                                            name="remediation"
                                            id="remediation"
                                            errorobj={findingErrors}
                                            control={findingControl}
                                            label="Remediation"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col sm="12">
                                        <RHFTextEditor
                                            name="reference"
                                            id="reference"
                                            errorobj={findingErrors}
                                            control={findingControl}
                                            label="Reference"
                                            isRequired={false}
                                        />
                                    </Col>
                                </Row>

                                <div className="d-flex justify-content-end">
                                    <RHFButton
                                        className="mx-2"
                                        btnName="Cancel"
                                        outline={true}
                                        onClick={() => history.push(`/application/${applicationId}/overview`)}
                                    />
                                    {findingDetails?.findingStatus !== "close" && ['open', 'scheduled', 'in_progress', 'action_required'].includes(appData?.applicationStatus) &&
                                        <RHFButton
                                            className="mx-2"
                                            btnName="Save As Draft"
                                            onClick={() => setFormStatus(true)}
                                            type="submit"
                                            isLoading={isSaveAsDraft}
                                        />}


                                    {findingDetails?.findingStatus !== "close" && ['open', 'scheduled', 'in_progress', 'action_required'].includes(appData?.applicationStatus) &&
                                        <RHFButton
                                            btnName="Save"
                                            onClick={() => setFormStatus(false)}
                                            type="submit"
                                            isLoading={isApiCalling}
                                        />}
                                </div>
                            </CardBody>
                        </Card>
                    </form>
                    {/* <DialogBox
                        isModelOpen={isModelOpen}
                        handleToggle={handleToggle}
                        modelSize="sm-20"
                        title="Add New Steps"
                        actions={null}
                    >
                        <PocStepsAddEdit
                            handleToggle={handleToggle}
                            setFormData={setFormData}
                            pocStepData={pocStepData}
                            setPocStepData={setPocStepData}
                            editPocData={editPocData}
                        />
                    </DialogBox> */}
                    <DialogBox
                        isModelOpen={isDeleteModelOpen}
                        handleToggle={handleDeleteToggle}
                        modelSize="sm-100"
                        title="Confirmation"
                        actions={
                            <>
                                <RHFButton
                                    btnName="Cancel"
                                    outline={true}
                                    onClick={handleDeleteToggle}
                                />
                                <RHFButton
                                    btnName="Okay"
                                    onClick={deleteStep}
                                    isLoading={isApiCalling}
                                />
                            </>
                        }
                    >
                        <div className="text-center">
                            <i className="mdi mdi-alert-circle-outline" style={{ fontSize: "5em", color: "orange" }} />
                            <h5>Are you sure?</h5>
                            <p className="fs-5">You won't be able to revert this!</p>
                        </div>
                    </DialogBox>
                    {isOpenViewer && <ImageViewer
                        images={activePoc?.images?.map(a => a?.url)}
                        isOpenViewer={isOpenViewer}
                        activeImageIndex={activeImageIndex}
                        toggle={toggle}
                    />}
                </div >
            }
        </React.Fragment >
    )
}

export default FindingAddEdit;