import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Breadcrumb from 'components/Common/Breadcrumb';
import { Card, CardBody, Col, Row } from 'reactstrap';
import RHFAutoCompleteSelect from 'components/FormControls/RHFAutoCompleteSelect';
import RHFButton from 'components/FormControls/RHFButton';
import RHFTextField from 'components/FormControls/RHFTextField';
import { kickoffcheckBoxData } from 'constants/MockData';
import RHFMultipleValue from 'components/FormControls/RHFMultipleValue';
import RHFInputTags from 'components/FormControls/RHFInputTags';
import RHFCheckBoxGroup from 'components/FormControls/RHFCheckBoxGroup';

import { APPLICATION_ASSESSMENT_TYPE, ipAddressRegex, nameRegex, urlRegex } from 'constants/Constant';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { addKickoff, editKickoff, getApplicationDetails, getKickoffDetail } from 'helpers/BackendHelper';
import { Toast } from 'components/Common/Toaster';
import PageLoader from 'components/PageLoader';

const KickoffAddEdit = () => {

    let history = useHistory()
    const { applicationId, kickoffId } = useParams()
    const isEditMode = kickoffId
    const [appData, setAppData] = useState()
    const [kickoffData, setKickoffData] = useState([])
    const [selectedCheckBox, setselectedCheckBox] = useState([])
    const [pageLoader, setPageLoader] = useState(false);
    const [isApiCalling, setApiCalling] = useState(false);
    const [isKickOffAutoFetch, setKickOffAutoFetch] = useState(false);

    document.title = `${(isEditMode) ? "Edit Kick-off Doc" : "Add Kick-off Doc"} | RiDiscovery`;
    const kickOffDocSchema = yup.object().shape({
        fqdn: yup.string().trim().transform((curr, orig) => orig === '' ? null : curr).nullable().matches(urlRegex, 'Please enter valid FQDN'),
        walkthrough: yup.string().trim().transform((curr, orig) => orig === '' ? null : curr).nullable().matches(urlRegex, 'Invalid walkthrough'),
        environment: yup.string().trim().transform((curr, orig) => orig === '' ? null : curr).nullable().matches(nameRegex, 'Please enter valid name.'),
        assessmentType: yup
            .object()
            .shape({ label: yup.string(), value: yup.string() })
            .nullable()
            .required("Select atleast one option"),
        ipAddress: yup.array().nullable().of(yup.string()
            .matches(ipAddressRegex, 'Invalid IP address')),
        URI: yup.string().trim().transform((curr, orig) => orig === '' ? null : curr).nullable().matches(urlRegex, 'Enter correct uri'),
        checkList: yup.array().min(1, "Select atleast one option").test("CWE", "CWE is required", function (value) {
            if (value) {
                return value.includes("CWE")
            } else {
                return true
            }
        }).required("Select atleast one option"),

        scopeDetails: yup.array().of(
            yup.object().shape({
                key: yup.string().trim().matches(nameRegex, 'Please enter valid key').required("Required"),
                value: yup.string().trim().matches(nameRegex, 'Please enter valid value').required("Required"),
            })
        ),
        applicationDetails: yup.array().of(
            yup.object().shape({
                key: yup.string().trim().matches(nameRegex, 'Please enter valid key').required("Required"),
                value: yup.string().trim().matches(nameRegex, 'Please enter valid value').required("Required"),
            })
        ),
        outOfScope: yup.array().of(
            yup.object().shape({
                key: yup.string().trim().matches(nameRegex, 'Please enter valid key').required("Required"),
                value: yup.string().trim().matches(nameRegex, 'Please enter valid value').required("Required"),
            })
        ),
        userDetails: yup.array().of(
            yup.object().shape({
                key: yup.string().trim().matches(nameRegex, 'Please enter valid key').required("Required"),
                value: yup.string().trim().matches(nameRegex, 'Please enter valid value').required("Required"),
            })
        ),
        serverDetails: yup.array().of(
            yup.object().shape({
                key: yup.string().trim().matches(nameRegex, 'Please enter valid key').required("Required"),
                value: yup.string().trim().matches(nameRegex, 'Please enter valid value').required("Required"),
            })
        ),
    });

    useEffect(() => {
        if (applicationId) {
            const params = { id: applicationId }
            setPageLoader(true)
            getKickoffDetail({ ...params }).then(resp => {
                setKickoffData(resp?.data);
                setPageLoader(false)
            });

            getApplicationDetails({ id: applicationId }).then(resp => {
                setAppData(resp?.data);
                if (['reassessment', 'revalidation'].includes(resp?.data?.assessmentType)) {
                    setKickOffAutoFetch(true);
                    if (!isEditMode) {
                        getKickoffDetail({ id: resp?.data?.parentPenId }).then(resp => {
                            setKickoffData(resp?.data);
                            setPageLoader(false);
                        });
                    }
                }
            })
        }
    }, [])

    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(kickOffDocSchema),
    });

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        if (checked && value) {
            setselectedCheckBox([...(selectedCheckBox || []), value]);
        } else {
            setselectedCheckBox(selectedCheckBox.filter((e) => e !== value));
        }
    };

    useEffect(() => {
        setValue('checkList', selectedCheckBox);
    }, [selectedCheckBox])

    const onSubmitKickOff = (data) => {
        setApiCalling(true);
        if (!isApiCalling) {
            const payload = {
                ...data,
                applicationId: applicationId,
                assessmentType: data?.assessmentType?.value,
                scopeDetails: Object.assign([...(data?.scopeDetails || []).map(item => ({ [item.key]: item.value }))]),
                applicationDetails: Object.assign([...(data?.applicationDetails || []).map(item => ({ [item.key]: item.value }))]),
                outOfScope: Object.assign([...(data?.outOfScope || []).map(item => ({ [item.key]: item.value }))]),
                userDetails: Object.assign([...(data?.userDetails || []).map(item => ({ [item.key]: item.value }))]),
                serverDetails: Object.assign([...(data?.serverDetails || []).map(item => ({ [item.key]: item.value }))]),
            }
            if (isEditMode) {
                editKickoff({ ...payload, kickOffDocId: kickoffId }).then(resp => {
                    Toast.success(resp?.message)
                    reset();
                    setApiCalling(false);
                    history.push(`/application/${applicationId}/overview`)
                }).catch(() => {
                    setApiCalling(false);
                });
            } else {
                addKickoff(payload).then(resp => {
                    Toast.success(resp?.message)
                    reset();
                    setApiCalling(false);
                    history.push(`/applications`)
                }).catch(() => {
                    setApiCalling(false);
                });
            }
        }
    };

    useEffect(() => {
        if (isEditMode || isKickOffAutoFetch) {
            setselectedCheckBox((kickoffData?.checkList || []))
        }
        if (isEditMode || isKickOffAutoFetch) {
            const editPayload = {
                ...kickoffData,
                assessmentType: APPLICATION_ASSESSMENT_TYPE?.find(o => o.value === kickoffData?.assessmentType),
                scopeDetails: kickoffData?.scopeDetails && kickoffData?.scopeDetails.map((item) =>
                    Object.assign({}, ...Object.entries(item).map(([key, value]) => ({ key, value })))),
                applicationDetails: kickoffData?.applicationDetails && kickoffData?.applicationDetails.map((item) =>
                    Object.assign({}, ...Object.entries(item).map(([key, value]) => ({ key, value })))),
                outOfScope: kickoffData?.outOfScope && kickoffData?.outOfScope.map((item) =>
                    Object.assign({}, ...Object.entries(item).map(([key, value]) => ({ key, value })))),
                userDetails: kickoffData?.userDetails && kickoffData?.userDetails.map((item) =>
                    Object.assign({}, ...Object.entries(item).map(([key, value]) => ({ key, value })))),
                serverDetails: kickoffData?.serverDetails && kickoffData?.serverDetails.map((item) =>
                    Object.assign({}, ...Object.entries(item).map(([key, value]) => ({ key, value })))),
            }

            const formFields = Object.keys(editPayload);
            formFields.forEach((field) => {
                setValue(field, editPayload[field]);
            });
        }
        else {
            setValue(null)
        }
    }, [kickoffData]);

    useEffect(() => {
        if (appData) {
            setValue('assessmentType', APPLICATION_ASSESSMENT_TYPE?.find(o => o.value === appData?.assessmentType))
        }
    }, [appData])

    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/applications", heading: "Application" },
    { path: `/application/${applicationId}/overview`, heading: "Application Overview" },
    { path: `/application/${applicationId}/add/kickoff`, heading: (!isEditMode) ? "Add Kickoff" : ` Edit Kickoff` }]

    return (
        <React.Fragment>
            {pageLoader ?
                <PageLoader />
                :
                <div className="page-content">
                    <Breadcrumb title="Kick-off Doc" breadcrumbItem={breadcrumbItems} />
                    <Card>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmitKickOff)}>
                                <Row className="mb-3">
                                    <Col sm="6">
                                        <RHFTextField
                                            id="fqdn"
                                            label="FQDN"
                                            name="fqdn"
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            isRequired={false}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <RHFTextField
                                            id="walkthrough"
                                            label="Walkthrough URL"
                                            name="walkthrough"
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            isRequired={false}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col sm="6">
                                        <RHFTextField
                                            id="environment"
                                            label="Environment"
                                            name="environment"
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            isRequired={false}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <RHFAutoCompleteSelect
                                            id="assessmentType"
                                            label="Assessment Type"
                                            name="assessmentType"
                                            options={APPLICATION_ASSESSMENT_TYPE}
                                            setValue={setValue}
                                            isMultiple={false}
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            disabledField={true}
                                            isRequired={false}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col sm="6">
                                        <RHFInputTags
                                            id="ipAddress"
                                            label="IP Address"
                                            name="ipAddress"
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            isRequired={false}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <RHFTextField
                                            id="URI"
                                            label="URI"
                                            name="URI"
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            isRequired={false}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col sm="6">
                                        <RHFCheckBoxGroup
                                            name="checkList"
                                            label="Check List"
                                            checkBoxData={kickoffcheckBoxData}
                                            autoCheckedValues={isEditMode ? kickoffData?.checkList : null}
                                            control={control}
                                            isController={true}
                                            errorobj={errors}
                                            onChange={handleCheckboxChange}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <RHFMultipleValue
                                            label="Scope Details"
                                            name="scopeDetails"
                                            id="scopeDetails"
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            getValues={getValues}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col sm="6">
                                        <RHFMultipleValue
                                            label="Application Details"
                                            name="applicationDetails"
                                            id="applicationDetails"
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            getValues={getValues}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <RHFMultipleValue
                                            label="Out Of Scope"
                                            name="outOfScope"
                                            id="outOfScope"
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            getValues={getValues}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col sm="6">
                                        <RHFMultipleValue
                                            label="User Details"
                                            name="userDetails"
                                            id="userDetails"
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            getValues={getValues}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <RHFMultipleValue
                                            label="Server Details"
                                            name="serverDetails"
                                            id="serverDetails"
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            getValues={getValues}
                                        />
                                    </Col>
                                </Row>
                                <div className="d-flex justify-content-end">
                                    <RHFButton
                                        className="mx-2"
                                        btnName="Cancel"
                                        outline={true}
                                        onClick={() => history.push('/applications')}
                                    />
                                    <RHFButton
                                        btnName="Save"
                                        type="submit"
                                        isLoading={isApiCalling}
                                    />
                                </div>
                            </form>
                        </CardBody>
                    </Card>

                </div >
            }
        </React.Fragment >
    )
}

export default KickoffAddEdit
