import React, { useState } from "react";
import PropTypes from "prop-types";

import { Card, CardBody, Col, Form, Row } from "reactstrap";
import { useHistory, withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import companyDefaultLogo from "assets/images/Default-User-Image.jpeg";

import RHFTextField from "components/FormControls/RHFTextField";
import RHFButton from "components/FormControls/RHFButton";
import RHFFileUpload from "components/FormControls/RHFFileUpload";
import RHFInputTags from "components/FormControls/RHFInputTags";

import { alphaRegex, htmlTagRegex, nameRegex, SUPPORTED_FORMATS, urlRegex } from "constants/Constant";
import Breadcrumb from "components/Common/Breadcrumb";
import { onBoardCompany } from "helpers/BackendHelper";
import { Toast } from "components/Common/Toaster";


const RegisterCompany = () => {
  document.title = "Register Company | RiDiscovery"
  let history = useHistory();
  const [isApiCalling, setApiCalling] = useState(false);
  const [newCompany, setNewCompany] = useState({});
  const companySchema = yup.object().shape({
    companyLogo: yup.mixed().required("Company logo is required.").test("type", "Invalid file formate.", function (value) {
      if (!value) {
        return false
      } else if (typeof value === "string") {
        if (value?.toLowerCase().includes("jpeg") || value?.toLowerCase().includes("jpg") || value?.toLowerCase().includes("png") || value?.toLowerCase().includes("bmp") || value?.toLowerCase().includes("svg")) {
          return true
        }
      }
      else if (SUPPORTED_FORMATS.includes(value.type)) {
        return true
      } else {
        return false
      }
    }),
    companyName: yup.string().trim().matches(nameRegex, "Please enter valid Comapany Name").required("Company Name is required"),
    tenantName: yup.string().matches(alphaRegex, "Please enter valid Tenant Name").required("Tenant name is required"),
    domain: yup.array().typeError("Domain is required").required("Domain is required").of(yup.string().test("domain-name", "Please enter valid Domain Name",
      function (value) {
        let isValidHtml = htmlTagRegex.test(value);
        let isValidUrl = urlRegex.test(value);
        if (isValidUrl && isValidHtml) {
          return true;
        } else {
          return false;
        }
      }))
      .min(1, "Domain is required"),
  });

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(companySchema),
  });

  const getFileData = (file) => {
    setValue("companyLogo", file?.file)
  }

  const onAddCompany = (values) => {
    setApiCalling(true);
    if (!isApiCalling) {
      const formData = new FormData();
      formData.append('companyLogo', values?.companyLogo);
      formData.append('companyName', values?.companyName);
      formData.append('tenantName', values?.tenantName);
      formData.append('domain', JSON.stringify((values?.domain)));
      const payload = formData;
      onBoardCompany(payload).then(resp => {
        setApiCalling(false);
        if (resp.status) {
          reset();
          setNewCompany(resp)
          Toast.success(resp.message);
          history.push('/logout');
        }
      }).catch(() => {
        setApiCalling(false);
      });
    }
  };

  document.title = "Register Company | RiDiscovery";

  const breadcrumbItems = []

  return (
    // !user?.user?.tenantName ?
    <React.Fragment>
      <div className="page-content">

        <Breadcrumb title="Register Company" breadcrumbItem={breadcrumbItems} />

        {!newCompany?.status ?
          <Card>
            <CardBody>
              <Form
                className="custom-form mt-4 pt-2"
                onSubmit={handleSubmit(onAddCompany)}
              >
                <Row>
                  <Col md={4}>

                    <div className="d-flex justify-content-center">
                      <RHFFileUpload
                        control={control}
                        name="companyLogo"
                        defaultImage={companyDefaultLogo}
                        getFileData={getFileData}
                        setValue={setValue}
                        setError={setError}
                        clearErrors={clearErrors}
                        errorobj={errors}
                        isValidate={true}
                      />
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="mb-3">
                      <RHFTextField
                        id="companyName"
                        label="Company Name"
                        name="companyName"
                        errorobj={errors}
                        control={control}
                        isController={true}
                      />
                    </div>

                    <div className="mb-3">
                      <RHFTextField
                        id="tenantName"
                        label="Tenant Name"
                        name="tenantName"
                        errorobj={errors}
                        control={control}
                        isController={true}
                      />
                    </div>
                    <div className="mb-3">
                      <RHFInputTags
                        id="domain"
                        label="Domain"
                        name="domain"
                        errorobj={errors}
                        control={control}
                        isController={true}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="d-flex justify-content-end">
                    <RHFButton btnName="Submit" type="submit" isLoading={isApiCalling} />
                  </Col>
                </Row>

              </Form>
            </CardBody>
          </Card>
          :
          <Card>
            <CardBody>
              <p className="d-flex justify-content-center fw-normal fs-5 text">
                Thank yor for onboarding with RiDiscovery. Your tanent is creating once it will done we, will notify you via email.
                Thank you for your patience.
              </p>
            </CardBody>
          </Card>
        }


      </div>
    </React.Fragment >
    // :
    // history.goBack()

  );
};

export default withRouter(RegisterCompany);

RegisterCompany.propTypes = {
  history: PropTypes.object,
};
