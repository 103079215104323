import React, { useEffect, useState, useCallback } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Breadcrumb from 'components/Common/Breadcrumb';
import Table from 'components/Tables/Table';
import RHFTextField from 'components/FormControls/RHFTextField';
import AvtarGroup from 'components/FormControls/AvtarGroup';
import FilterByStatus from 'components/Common/FilterByStatus';
import RHFButton from 'components/FormControls/RHFButton';
import DialogBox from 'components/Modals/DialogBox';
import NetworkAddEdit from './NetworkAddEdit';
import { applicationScore, isModulePermisssion } from 'helpers/util';
import { ROLE_PERMISSIONS } from 'constants/RolePermissions';
import { NETWORK } from 'helpers/Services/Network';
import DropdownButton from 'components/FormControls/DropdownButton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import ManageColumns from 'components/Common/ManageColumns';
import { getAllBu } from 'store/bu/actions';
import { getUserListByRole } from 'helpers/BackendHelper';
import { APPLICATION_STATUS, CVSS, CVSS_TEXT_COLOR, FINDING_SCORE_COLORS } from 'constants/Constant';
import TextChip from 'components/Common/TextChip';

const Network = () => {
    document.title = "Network | RiDiscovery";
    const [customActiveTab, setcustomActiveTab] = useState(1);
    const [filterColumns, setFilterColumns] = useState([]);
    const [columnOptions, setColumnOption] = useState([]);;
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [isRefresh, setRefresh] = useState(false);
    const [allUsers, setAllUsers] = useState(null);
    const [filterFields, setFilterFields] = useState({
        'search': null,
        'status': null,
        'buId': null,
    });
    const { bussinessUnit } = useSelector(state => state?.bu);
    const history = useHistory()
    const dispatch = useDispatch();

    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/networks", heading: "Network" }]


    useEffect(() => {
        if (isModulePermisssion(ROLE_PERMISSIONS.NETWORK_ASSIGN_REMOVE_TEAM)) {
            getUserListByRole().then((roleResp) => {
            const members = roleResp?.data?.reduce(function (r, a) {
                r[a?.roleId] = r[a?.roleId] || [];
                r[a?.roleId].push({ user_name: `${a?.firstName} ${a?.lastName}`, ...a });
                return r;
            }, Object.create(null));
            setAllUsers(members);
        });
        }
    }, []);

    useEffect(() => {
        dispatch(getAllBu());
    }, []);

    useEffect(() => {
        const columnFilter = [...columns];
        setColumnOption([...columns]);
        setFilterColumns(columnFilter.filter(o => o.isVisible && o));
    }, [allUsers])

    const getFilteredValues = (cols) => {
        setColumnOption(cols);
        setFilterColumns(cols.filter(o => o.isVisible && o));
    }

    const getCols = (cols) => {
        setColumnOption(cols);
    }

    const setFilterValues = (field) => {
        setFilterFields(prev => {
            return {
                ...prev,
                ...field
            }
        })
    }
    const handleToggle = () => {
        setIsModelOpen(!isModelOpen);
    };


    const toggleCustom = (tab, val) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
            setFilterValues({ status: val });
        }
    };

    const clearAllFilters = () => {
        setFilterValues({ 'search': null, 'status': null, 'buId': null });
        setcustomActiveTab(1);
    }

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };

    const optimizedFn = useCallback(debounce((val) => setFilterValues({ search: val })), []);

    const previewHandler = (obj) => {
        history.push({ pathname: `/network/${obj?.id}/network-overview`, state: { objData: obj } });
    };


    const columns = [
        {
            id: 'name',
            name: "Network Name",
            cell: (row) =>
                <div className={isModulePermisssion(ROLE_PERMISSIONS.NETWORK_OVERVIEW) && "table-cell-click"} onClick={() => isModulePermisssion(ROLE_PERMISSIONS.NETWORK_OVERVIEW) && previewHandler(row)}>
                    <span>{row?.title ? row?.title : "N/A"}</span>
                </div>,
            isVisible: true,
            minWidth: "130px",
        },
        {
            id: "type",
            name: "Type",
            cell: (row) =>
                <div className={isModulePermisssion(ROLE_PERMISSIONS.NETWORK_OVERVIEW) && "table-cell-click"} onClick={() => isModulePermisssion(ROLE_PERMISSIONS.NETWORK_OVERVIEW) && previewHandler(row)}>
                    <span>{row?.quarterType ? row?.quarterType : "N/A"}</span>
                </div>,
            isVisible: true,
            width: "100px",
        },
        {
            id: "source",
            name: "Source",
            cell: (row) =>
                <div className={isModulePermisssion(ROLE_PERMISSIONS.NETWORK_OVERVIEW) && "table-cell-click"} onClick={() => isModulePermisssion(ROLE_PERMISSIONS.NETWORK_OVERVIEW) && previewHandler(row)}>
                    <span>{row?.source ? row?.source : "N/A"}</span>
                </div>,
            isVisible: true,
            width: "110px",
        },
        {
            id: "buTag",
            name: "BU Tag",
            cell: (row) =>
                <div className={isModulePermisssion(ROLE_PERMISSIONS.NETWORK_OVERVIEW) && "table-cell-click"} onClick={() => isModulePermisssion(ROLE_PERMISSIONS.NETWORK_OVERVIEW) && previewHandler(row)}>
                    {row?.Bunits ? row?.Bunits.map((bu, index) =>
                        <span key={index} className='badge badge-soft-primary bg-soft-primary p-1 mx-1'>
                            {bu?.business_unit?.buCode}
                        </span>
                    ) : "N/A"}
                </div>,
            isVisible: true,
            minWidth: "130px",
            maxWidth: "150px"
        },
        {
            id: "score",
            name: "Score",
            cell: (row) =>
                <div className={isModulePermisssion(ROLE_PERMISSIONS.NETWORK_OVERVIEW) && "table-cell-click"} onClick={() => isModulePermisssion(ROLE_PERMISSIONS.NETWORK_OVERVIEW) && previewHandler(row)}>
                    <TextChip
                        text={row?.networkScore}
                        color={FINDING_SCORE_COLORS[(+row?.networkScore).toFixed(1)] || "#E9E8EF"}
                        textColor={CVSS_TEXT_COLOR[applicationScore(+row?.networkScore)]}
                    />
                </div>,

            isVisible: true,
            width: '110px',
        },
        {
            id: "startDate",
            name: "Start Date",
            cell: (row) => <span>{row?.startDate ? row?.startDate : "N/A"}</span>,
            isVisible: false,
            width: '120px',
        },
        {
            id: "enddate",
            name: "End Date",
            cell: (row) => <span>{row?.endDate ? row?.endDate : "N/A"}</span>,
            isVisible: false,
            width: '120px',
        },
        {
            id: 'assignedPentester',
            name: "Pentester",
            width: "150px",
            isVisible: true,
            cell: (row) => {
                return (
                    <AvtarGroup
                        // allUsers={allUsers}
                        assignedUsers={row?.securityTeam?.map(o => ({ ...o.user, isAssigned: true }))}
                        max={3}
                        type="pentester"
                        appId={row?.id}
                        popUpId={`${row?.id}_pentester`}
                        requestType="network"
                        assignRemovePermission={isModulePermisssion(ROLE_PERMISSIONS.NETWORK_ASSIGN_REMOVE_TEAM_P)}
                    />
                )
            }
        },
        {
            id: 'assignedDeveloper',
            name: "Developer",
            width: "150px",
            isVisible: true,
            cell: (row) => {
                return (
                    <AvtarGroup
                        // allUsers={allUsers}
                        assignedUsers={row?.developmentTeam?.map(o => ({ ...o.user, isAssigned: true }))}
                        max={3}
                        type="developer"
                        appId={row?.id}
                        popUpId={`${row?.id}_developer`}
                        requestType="network"
                        assignRemovePermission={isModulePermisssion(ROLE_PERMISSIONS.NETWORK_ASSIGN_REMOVE_TEAM_D)}
                    />
                )
            }

        },
        {
            id: 'securityManager',
            name: "Security Manager",
            width: "150px",
            isVisible: false,
            cell: (row) => {
                return (
                    <AvtarGroup
                        allUsers={allUsers}
                        assignedUsers={row?.securityManager?.map(o => ({ ...o.user, isAssigned: true }))}
                        max={3}
                        type="sm_manager"
                        appId={row?.id}
                        popUpId={`${row?.id}_sm_manager`}
                        requestType="network"
                        assignRemovePermission={isModulePermisssion(ROLE_PERMISSIONS.NETWORK_ASSIGN_REMOVE_TEAM)}
                    />
                )
            }
        },
        {
            id: 'developmentManager',
            name: "Development Manager",
            width: "150px",
            isVisible: false,
            cell: (row) => {
                return (
                    <AvtarGroup
                        allUsers={allUsers}
                        assignedUsers={row?.developmentManager?.map(o => ({ ...o.user, isAssigned: true }))}
                        max={3}
                        type="da_manager"
                        appId={row?.id}
                        popUpId={`${row?.id}_da_manager`}
                        requestType="network"
                        assignRemovePermission={isModulePermisssion(ROLE_PERMISSIONS.NETWORK_ASSIGN_REMOVE_TEAM)}
                    />
                )
            }
        },
        {
            id: "networkStatus",
            name: "Status",
            cell: (row) =>
                <TextChip
                    text={row?.networkStatus && APPLICATION_STATUS[row?.networkStatus] || "N/A"}
                    color="#E9E8EF"
                />,
            isVisible: true,
            width: '150px',
        },

    ]



    return (
        <React.Fragment>
            <Breadcrumb title="Network" breadcrumbItem={breadcrumbItems} />
            <Row>
                <Col lg={7} xs={12}>
                    <FilterByStatus
                        statusList={[
                            { name: 'All', value: null, icon: 'disc' },
                            { name: 'In progress', value: 'in_progress', icon: 'loader' },
                            { name: 'Action Required', value: 'action_required', icon: 'alert-circle' },
                            { name: 'Close', value: 'completed', icon: 'check-circle' },
                        ]}
                        toggleCustom={toggleCustom}
                        customActiveTab={customActiveTab}
                    />
                </Col>
                <Col lg={5} xs={12}>
                    <div className='d-flex justify-content-end'>
                        <div className='me-2'>
                            <RHFButton
                                btnName="Clear Filter"
                                outline={true}
                                icon="filter"
                                onClick={() => clearAllFilters()}
                            />
                        </div>
                        <div className='me-2'>
                            <ManageColumns
                                allColumns={columnOptions}
                                getCols={getCols}
                                getFilteredValues={getFilteredValues}
                            />
                        </div>
                        <div>
                            <DropdownButton
                                heading="Select BU"
                                isSearchable={true}
                                menuItems={bussinessUnit ? bussinessUnit?.data : []}
                                handleClick={(item) => setFilterValues({ buId: item?.id })}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Card className='mt-3'>
                <CardHeader>
                    <Row>
                        <Col xs={6} lg={3}>
                            <RHFTextField
                                id="search"
                                name="search"
                                placeholder="Search here"
                                isController={false}
                                handleOnChange={optimizedFn}
                            />
                        </Col>
                        <Col xs={6} lg={9}>
                            {isModulePermisssion(ROLE_PERMISSIONS?.ADD_NETWORK) &&
                                <div className="d-flex justify-content-end">
                                    <RHFButton
                                        btnName="Add"
                                        icon="plus"
                                        onClick={() => {
                                            handleToggle()
                                        }}
                                    />
                                    <DialogBox
                                        isModelOpen={isModelOpen}
                                        handleToggle={handleToggle}
                                        modelSize="sm-100"
                                        title="New Network"
                                        actions={null}
                                    >
                                        <NetworkAddEdit
                                            handleToggle={handleToggle}
                                            setRefresh={setRefresh}
                                        />
                                    </DialogBox>
                                </div>}
                        </Col>
                    </Row>
                </CardHeader>
                {isModulePermisssion(ROLE_PERMISSIONS?.NETWORK_LIST) &&
                    <CardBody>
                        <Table
                            columns={filterColumns}
                            dataURL={filterColumns?.length > 0 && NETWORK.listAllNetwork}
                            isRefresh={isRefresh}
                            filter={filterFields}
                        // onRowClicked={onRowClicked}
                        />
                    </CardBody>
                }
            </Card>
        </React.Fragment>
    )
}

export default Network;