import React from 'react'
import ReactApexChart from 'react-apexcharts'

const PieChart = (props) => {

    const { pieSeries = [], pieColors = [], pieLables = [] } = props
    const PIE_CHART_SERIES = pieSeries
    const PIE_CHART_OPTIONS = {
        chart: {
            width: 380,
            type: 'pie',
        },
        colors: pieColors,
        labels: pieLables,
        stroke: { width: 0 },
        tooltip: { enabled: true },
        legend: {
            show: true,
            position: "right",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "10px",
            offsetX: 0,
            offsetY: -10,
        },
        plotOptions: {
            // pie: {
            //     size: 200
            // }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val.toFixed(1)
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    show: true,
                    position: 'bottom'
                }
            }
        }]
    }


    return (
        <ReactApexChart
            options={PIE_CHART_OPTIONS}
            series={PIE_CHART_SERIES}
            type="pie"
            width={400}
        />
    )
}

export default PieChart
