import React, { Fragment } from 'react'
import { Controller } from 'react-hook-form';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FormFeedback, Label } from 'reactstrap';

const RHFTextEditor = ({ isController = true, ...props }) => {

    const {
        onTextEditorChange,
        name,
        errorobj,
        control,
        defaultValue,
        handleOnChange,
        label = "label",
        isRequired = true,
    } = props

    const modules = {
        toolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            [
                { align: "" },
                { align: "center" },
                { align: "right" },
                { align: "justify" }
            ]
        ]
    };

    // Formats objects for setting up the Quill editor
    const formats = [
        "bold",
        "italic",
        "underline",
        "align",
        "list",
        "bullet"
    ];

    if (!isController) {
        return (
            <Fragment>
                {label && <Label htmlFor="example-text-input" className="form-label">
                    {label}
                </Label>
                }
                <ReactQuill
                    {...props}
                    theme="snow"
                    onChange={(data) => {
                        if (handleOnChange) {
                            handleOnChange(
                                data?.target?.value,
                                name
                            ); /* You must pass this function while isController is false -> else you will not get selected values */
                        }
                    }}
                    modules={modules}
                    formats={formats}
                />
            </Fragment>
        );
    }

    let isError = false;
    let errorMessage = '';
    let someValue = "";

    if (errorobj && Object.prototype.hasOwnProperty.call(errorobj, name)) {
        isError = true;
        errorMessage = errorobj[name].message;
    }
    if (defaultValue !== undefined) {
        someValue = defaultValue;
    }

    return (
        <Controller
            name={name}
            control={control}
            id={name}
            defaultValue={someValue}
            render={({ field }) => (
                <Fragment>
                    {label && <Label htmlFor="example-text-input" className="form-label">
                        {label} {isRequired && <span className="text-danger">*</span>}
                    </Label>
                    }
                    <ReactQuill
                        {...field}
                        {...props}
                        theme="snow"
                        onChange={(e) => field.onChange(e)}
                        modules={modules}
                        formats={formats}
                    />
                    {isError && (
                        <FormFeedback type="invalid">{errorMessage}</FormFeedback>
                    )}
                </Fragment>
            )}
        />

    )
}

export default RHFTextEditor
