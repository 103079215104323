import React from 'react';
import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";

// Authentication related pages
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

//
import ApplicationNetwork from "pages/ApplicationNetwork";
import Overview from "pages/ApplicationNetwork/Application/Overview";
import CompanyHierarchy from "pages/Company";
import UserDetails from "pages/UserList/UserDetails";
import userProfile from "../pages/Authentication/UserProfile";
import dmControls from "../pages/Dashboard/dm-controls-usecases";
import ProjectList from "../pages/Certificate/Index";
import TemplatesList from "../pages/TemplatesList/Index";
import EmailVerification from "pages/Authentication/EmailVerification";
import ResetPassword from "pages/Authentication/ResetPassword";
import UserList from "../pages/UserList/Index";
import FindingAddEdit from "pages/ApplicationNetwork/Application/Finding/FindingAddEdit";
import SetPassword from 'pages/Authentication/SetPassword';
import VerifyOTP from 'pages/Authentication/VerifyOTP';
import RegisterCompany from 'pages/Authentication/RegisterCompany';
import TanentLogin from 'pages/Authentication/TanentLogin';
import KickoffAddEdit from 'pages/ApplicationNetwork/Application/KickoffAddEdit';
import ApplicationAddEdit from 'pages/ApplicationNetwork/Application/ApplicationAddEdit';
import Bu from 'pages/Bu';
import TemplatesListAdd from 'pages/TemplatesList/TemplatesListAdd';
import NetworkOverview from 'pages/ApplicationNetwork/Network/Overview';

import FindingOverview from 'pages/ApplicationNetwork/Application/Finding/Overview';
import NetworkFindingOverview from 'pages/ApplicationNetwork/Network/Finding/Overview';
import CertificateTemplateAddEdit from 'pages/TemplatesList/Certificate/CertificateTemplateAddEdit';
import { ROLE_PERMISSIONS } from 'constants/RolePermissions';
import Submission from 'pages/Submissions';
import CompanyProfile from 'pages/Authentication/CompanyProfile';
import MySubscription from 'pages/Subscriptions/MySubscription';
import ApproveRejectUsers from 'pages/ApproveRejectUsers';
import LandingPage from 'pages/LangingPage';
import TemplatePDF from 'pages/Pdf/TemplatePDF';
import CertificatePDF from 'pages/Pdf/CertificatePDF';

const userRoutes = [
  //dashboard
  { path: "/9rD4t2gsbEY2ycOnK1oLb0325HTFFTZIm6UnelCoiEgcFvfvN0", component: LandingPage, showEmptyLayout: true },
  { path: "/dashboard", component: Dashboard, isRouteAccessible: ROLE_PERMISSIONS.RISK_SUMMARY },
  { path: "/asset-summary", component: Dashboard, isRouteAccessible: ROLE_PERMISSIONS.ASSET_SUMMARY },

  //application/Network
  { path: "/applications", component: ApplicationNetwork, isRouteAccessible: ROLE_PERMISSIONS.APPLICATION },
  { path: "/application/add", component: ApplicationAddEdit, isRouteAccessible: ROLE_PERMISSIONS.ADD_APPLICATION },
  { path: "/application/:applicationId/edit-application", component: ApplicationAddEdit, isRouteAccessible: ROLE_PERMISSIONS.UPDATE_APPLICATION },
  { path: "/application/:applicationId/add/kickoff", component: KickoffAddEdit, isRouteAccessible: ROLE_PERMISSIONS.ADD_KICK_OFF_DOC },
  { path: "/application/:applicationId/edit-kickoff/:kickoffId", component: KickoffAddEdit, isRouteAccessible: ROLE_PERMISSIONS.UPDATE_KICK_OFF_DOC },
  { path: "/application/:applicationId/overview", component: Overview, isRouteAccessible: ROLE_PERMISSIONS.APPLICATION_OVERVIEW },

  { path: "/application/:applicationId/finding/:findingId/overview", component: FindingOverview, isRouteAccessible: ROLE_PERMISSIONS.VIEW_FINDING },
  { path: "/application/:applicationId/add-finding", component: FindingAddEdit, isRouteAccessible: ROLE_PERMISSIONS.ADD_FINDING },
  { path: "/application/:applicationId/edit-finding/:findingId", component: FindingAddEdit, isRouteAccessible: ROLE_PERMISSIONS.UPDATE_FINDING },

  //network
  { path: "/network/:networkId/network-overview", component: NetworkOverview, isRouteAccessible: ROLE_PERMISSIONS.NETWORK_OVERVIEW },
  { path: "/networks", component: ApplicationNetwork, isRouteAccessible: ROLE_PERMISSIONS.NETWORK },
  { path: "/network/:networkId/finding/:findingId/overview", component: NetworkFindingOverview, isRouteAccessible: ROLE_PERMISSIONS.VIEW_NETWORK_FINDINGS },

  //bu
  { path: '/manage-bu', component: Bu, isRouteAccessible: ROLE_PERMISSIONS.MENU_BU },

  //user
  { path: "/user-list", component: UserList, isRouteAccessible: ROLE_PERMISSIONS.USERS },
  { path: "/user-list/user/:userId", component: UserDetails },

  //ApproveRejectUsers

  { path: "/approve-reject-users-list", component: ApproveRejectUsers, isRouteAccessible: ROLE_PERMISSIONS.APPROVE_REJECT_USER },

  //project
  { path: "/project-list", component: ProjectList, isRouteAccessible: ROLE_PERMISSIONS.CERTIFICATE_LIST },

  // template
  { path: "/reporting-template", component: TemplatesList, isRouteAccessible: ROLE_PERMISSIONS.REPORTING_TEMPLATE_LIST },
  { path: "/reporting-template/add", component: TemplatesListAdd, isRouteAccessible: ROLE_PERMISSIONS.ADD_REPORTING_TEMPLATE },
  { path: "/reporting-template/edit/:templateId", component: TemplatesListAdd, isRouteAccessible: ROLE_PERMISSIONS.UPDATE_REPORTING_TEMPLATE },

  { path: "/certificate-template", component: TemplatesList, isRouteAccessible: ROLE_PERMISSIONS.CERTIFICATE_TEMPLATES },
  { path: "/certificate-template/add", component: CertificateTemplateAddEdit, isRouteAccessible: ROLE_PERMISSIONS.ADD_CERTIFICATE_TEMPLATE },
  { path: "/certificate-template/edit/:templateId", component: CertificateTemplateAddEdit, isRouteAccessible: ROLE_PERMISSIONS.UPDATE_CERTIFICATE_TEMPLATE },

  { path: "/submissions", component: Submission, isRouteAccessible: ROLE_PERMISSIONS.MENU_SUBMISSIONS },

  // { path: "/application/viewreport/:appId", component: TemplatePDF },
  // { path: "/certificate/viewreport/:applicationId/:certificateId/:token", component: CertificatePDF },


  //profile
  { path: "/profile", component: userProfile, isRouteAccessible: ROLE_PERMISSIONS.UPDATE_PROFILE },
  { path: "/company-profile", component: CompanyProfile, isRouteAccessible: ROLE_PERMISSIONS.UPDATE_COMPANY_DETAILS },
  { path: "/my-subscription", component: MySubscription, isRouteAccessible: ROLE_PERMISSIONS.UPDATE_COMPANY_DETAILS },

  { path: "/dm-controls", component: dmControls },

  //Company
  { path: "/agency", component: CompanyHierarchy, isRouteAccessible: ROLE_PERMISSIONS.AGENCY },

  // this route should be at the end of all other routes
  { path: "*", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const globalUserRoutes = [
  { path: "/onboard-company", component: RegisterCompany, allowAlways: true },
  { path: "*", exact: true, component: () => <Redirect to="/onboard-company" />, allowAlways: true },
]


const authRoutes = [
  //authencation page
  { path: "/logout", component: Logout, allowAlways: true },
  { path: "/login", component: Login },
  { path: "/tenant/login", component: TanentLogin },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/verify-otp/:token", component: VerifyOTP },
  { path: "/set-password/:token", component: SetPassword },
  { path: "/reset-password/:token", component: ResetPassword },
  { path: "/register", component: Register },
  { path: "/email-verification/:token", component: EmailVerification },
  { path: "/application/viewreport/:applicationId/:token", component: TemplatePDF, allowAlways: true },
  { path: "/certificate/viewreport/:applicationId/:certificateId/:token", component: CertificatePDF, allowAlways: true }
];

export { userRoutes, authRoutes, globalUserRoutes };
