import React from 'react'
import ReactApexChart from 'react-apexcharts'

const ColumnChart = () => {
    const series = [{
        name: 'PRODUCT A',
        data: [44, 55, 41, 67, 22, 43]
    }, {
        name: 'PRODUCT B',
        data: [13, 23, 20, 8, 13, 27]
    }, {
        name: 'PRODUCT C',
        data: [11, 17, 15, 15, 21, 14]
    }, {
        name: 'PRODUCT D',
        data: [21, 7, 25, 13, 22, 8]
    }]

    const options = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: false,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                }
            },
            zoom: {
                enabled: true
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        xaxis: {
            type: 'datetime',
            categories: ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT',
                '01/05/2011 GMT', '01/06/2011 GMT'
            ],
        },
        // legend: {

        //     showForSingleSeries: true,
        //     customLegendItems: ['Actual', 'Expected'],
        //     markers: {
        //         fillColors: ['#00E396', '#775DD0']
        //     }
        // }
        legend: {
            show: false,
            position: 'right',
            offsetY: 40
        },
        fill: {
            opacity: 1
        }
    }

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height="223"
        />
    )
}

export default ColumnChart
