import React, { useEffect, useState } from 'react'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import RHFButton from "components/FormControls/RHFButton";
import { Col, Row } from 'reactstrap';
import RHFTextField from 'components/FormControls/RHFTextField';
import { htmlTagRegex, nameRegex } from 'constants/Constant';
import { addBu, editBu } from 'helpers/BackendHelper';
import { Toast } from 'components/Common/Toaster';

const BuAddEdit = (props) => {
    const [isApiCalling, setApiCalling] = useState(false);

    const { handleToggle, editBuData, setRefresh } = props
    const isEditMode = editBuData ? true : false;

    const buSchema = yup.object().shape({
        name: yup.string().trim().matches(nameRegex, 'Please enter valid name').required("Name is required"),
        buCode: yup.string().trim().matches(nameRegex, 'Please enter valid code').required("BU Code is required"),
        address: yup.string().trim()
            .matches(htmlTagRegex, 'Please enter valid address').required('Address is required').min(10).max(200),
    });

    const {
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(buSchema),
    });

    useEffect(() => {
        if (isEditMode) {
            const formFields = Object.keys(editBuData);
            formFields.forEach((field) => {
                setValue(field, editBuData[field]);
            });
        }
        else {
            setValue(null)
        }
    }, [editBuData]);


    const onSubmit = (data) => {
        setApiCalling(true);
        if (!isApiCalling) {
            const payload = {
                ...data,
            }
            if (isEditMode) {
                editBu({ ...payload, id: editBuData?.id }).then(resp => {
                    Toast.success(resp?.message)
                    setRefresh(prevValue => !prevValue)
                    reset();
                    handleToggle();
                    setApiCalling(false);
                }).catch(() => {
                    setApiCalling(false);
                });
            } else {
                addBu(payload).then(resp => {
                    Toast.success(resp?.message)
                    setRefresh(prevValue => !prevValue)
                    reset();
                    handleToggle();
                    setApiCalling(false);
                }).catch(() => {
                    setApiCalling(false);
                });
            }
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
                <Col sm="12">
                    <RHFTextField
                        id="name"
                        label="Name"
                        name="name"
                        errorobj={errors}
                        control={control}
                        isController={true}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm="12">
                    <RHFTextField
                        id="buCode"
                        label="BU Code"
                        name="buCode"
                        errorobj={errors}
                        control={control}
                        isController={true}
                        disabledField={isEditMode ? true : false}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm="12">
                    <RHFTextField
                        id="address"
                        label="Address"
                        name="address"
                        errorobj={errors}
                        control={control}
                        isController={true}
                        type="textarea"
                        multiline={true}
                        rows={3}
                    />
                </Col>
            </Row>
            <div className="d-flex justify-content-end">
                <RHFButton
                    className="mx-2"
                    btnName="Cancel"
                    outline={true}
                    onClick={handleToggle}

                />
                <RHFButton
                    btnName="Submit"
                    type="submit"
                    isLoading={isApiCalling}
                />
            </div>
        </form>
    )
}

export default BuAddEdit
