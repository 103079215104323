import { useEffect, useReducer, Fragment } from "react";
import { useParams } from "react-router-dom";
import PageSection from "components/Pdf/Template/PageSection";
import moment from "moment";
import { getApplicationCertificateReport } from "helpers/BackendHelper";
import { renderToString } from 'react-dom/server'
import { htmlSanitizer } from "helpers/util";
import './TemplatePDFStyle.scss';


let lastCount = 0;
const uniqueKey = (prefix = "key") => {
    lastCount++;
    return `${prefix}${lastCount}`;
};
const initialState = {
    isLoading: false,
    content: null,
    sortedList: [],
    error: null,
};

const pdfContentReducer = (state, { type, payload }) => {
    switch (type) {
        case "GET_PDF_CONTENT_PENDING":
            return { ...state, isLoading: true };
        case "GET_PDF_CONTENT_SUCCESS":
            return {
                ...state,
                isLoading: false,
                content: payload,
                reportSections: [],
            };
        case "GET_PDF_CONTENT_FAILED":
            return { ...state, isLoading: false, error: payload };
        default:
            return state;
    }
};

const CertificatePDF = () => {
    const [pdfContent, dispatchPdfContent] = useReducer(
        pdfContentReducer,
        initialState
    );

    const { applicationId, certificateId, token } = useParams();

    useEffect(() => {
        if (pdfContent.content === null && !!applicationId) {
            (async () => {
                try {
                    dispatchPdfContent({ type: "GET_PDF_CONTENT_PENDING" });
                    getApplicationCertificateReport({ applicationId: applicationId, certificateId: certificateId }, token).then(response => {
                        dispatchPdfContent({
                            type: "GET_PDF_CONTENT_SUCCESS",
                            payload: response,
                        });
                    }).catch(error => {
                        dispatchPdfContent({
                            type: "GET_PDF_CONTENT_FAILED",
                            payload: error.message,
                        });
                    });
                } catch (error) {
                    dispatchPdfContent({
                        type: "GET_PDF_CONTENT_FAILED",
                        payload: error.message,
                    });
                }
            })();
        }
    }, [applicationId, pdfContent.content]);


    // if (!!pdfContent.isLoading) return <PageLoader />;
    if (!!pdfContent.error)
        return <h2 className="text-center mt-5">Something went wrong</h2>;

    function interpolateTemplate(template, args) {
        return Object.entries(args).reduce((result, [arg, val]) => {
            return result.replace(`$\{${arg}}`, renderToString(val));
        }, template);
    }

    const getSeverityCell = (score) => {
        if (score == 0.0) {
            return (
                <td className="infomative text-white" width="10%">
                    Informative
                </td>
            );
        } else if (score >= 0.1 && score <= 3.9) {
            return (
                <td className="low text-white" width="10%">
                    Low
                </td>
            );
        } else if (score >= 4.0 && score < 6.9) {
            return (
                <td className="medium text-dark" width="10%">
                    Medium
                </td>
            );
        } else if (score >= 7.0 && score < 8.9) {
            return (
                <td className="high text-white" width="10%">
                    High
                </td>
            );
        } else if (score >= 9.0 && score <= 10.0) {
            return (
                <td className="critical text-white" width="10%">
                    Critical
                </td>
            );
        }


    };

    const appVersionsFields = () => {
        const field = pdfContent?.content?.allversions?.map((item, i) => (<th scope="col" key={i}>{`V1.${i + 1}`}</th>))
        return field;
    }

    const appVersionsStatus = (statusArr) => {
        const field = statusArr?.map((status, i) => (
            <td
                width="10%"
                className={`text-capitalize ${[null, 'open'].includes(status) ? 'critical' : 'low'} text-white`}
                key={i}
            >
                {status ? status : 'Open'}
            </td>
        ))
        return field;
    }

    const renderAppendixAData = () => {
        return <>
            <div>
                <p className="fs-6 fw-bold">
                    Below table represents all the vulnerabilities and its status throughout the
                    VAPT DevSecOps cycle for <span className="fs-5">{pdfContent?.content?.application?.applicationName}</span> (v1.0.0).
                </p>
                <table className="table table-bordered border-dark">
                    <thead>
                        <tr>
                            <th scope="col">SR No</th>
                            <th scope="col">Vulnerability Name</th>
                            <th scope="col">Severity</th>
                            <th scope="col">Initial Status</th>
                            {appVersionsFields()}
                        </tr>
                    </thead>
                    <tbody>
                        {pdfContent?.content?.findings?.map(
                            (finding, i) => {
                                return (
                                    <tr key={i}>
                                        <td width="10%">V{i + 1}</td>
                                        <td width="70%">
                                            {finding.findingName}
                                        </td>
                                        {getSeverityCell(finding.cvssScore)}
                                        <td
                                            width="10%"
                                            className="text-capitalize critical text-white"
                                        >
                                            Open
                                        </td>
                                        {appVersionsStatus(finding?.status)}
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                </table>
            </div>

        </>
    }

    return (
        !pdfContent.isLoading &&
        pdfContent.content !== null && (
            <div
                style={{
                    width: "210mm",
                }}
                id="certificate-pdf"
                className="certificate-pdf application-pdf d-flex mx-auto"
            >
                <div style={{ height: "100%" }}>
                    {/* <div style={{ height: "200px" }}>
                        <div className="d-flex align-items-center justify-content-center flex-column h-100">
                            <p className="fs-6 fw-bold ms-auto">Issued Date: {moment().format('DD-MMM-YYYY')}</p>
                            <h3 className="fs-1 mt-2 text-center text-decoration-underline">SAFE TO HOST - CLEARANCE CERTIFICATE</h3>
                            <h3 className="fs-2 mt-3 text-center">To Whomsoever It May Concern:</h3>
                        </div>
                    </div> */}

                    {/* <div className="page-header-certificate ">
                        <div className="d-flex justify-content-between align-items-center px-4">
                            <div>
                                <img src={pdfContent?.content?.certificate?.logoOfMainCompany} height="50" className="pl-2" />
                            </div>
                            <div className="pr-2" >
                                <h3 className="m-0">Certbar Solution PVT. LMT.</h3>
                            </div>
                        </div>
                        <br />
                        <div className="ost-multi-header"></div>
                    </div> */}
                    <div className="cert-footer">
                        <div className="ost-multi-header"></div>
                    </div>
                    <div className="table-breaked">
                        <table className="table border-dark v-table">
                            <thead>
                                <tr className="no-border t-data">
                                    <td className="no-border t-data">
                                        <div className="page-header-certificate">
                                            <div className="d-flex justify-content-between align-items-center px-4">
                                                <div>
                                                    <img src={pdfContent?.content?.certificate?.logoOfMainCompany} height="50" className="pl-2" />
                                                </div>
                                                <div className="pr-2" >
                                                    <h3 className="m-0">Certbar Solution PVT. LMT.</h3>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="ost-multi-header"></div>
                                        </div>
                                    </td>

                                </tr>
                            </thead>
                            <tbody>
                                {pdfContent?.content !== null &&
                                    <Fragment>
                                        <PageSection
                                            pageHeader={false}
                                            isReport={false}
                                            title="Content"
                                            pageId="1"
                                            header={<div style={{ height: "200px" }}>
                                                <div className="d-flex align-items-center justify-content-center flex-column h-100">
                                                    <p className="fs-6 fw-bold ms-auto">Issued Date: {moment().format('DD-MMM-YYYY')}</p>
                                                    <h3 className="fs-1 mt-2 text-center text-decoration-underline">SAFE TO HOST - CLEARANCE CERTIFICATE</h3>
                                                    <h3 className="fs-2 mt-3 text-center">To Whomsoever It May Concern:</h3>
                                                </div>
                                            </div>}
                                        >
                                            {/* <h3 className="text-capitalize" id={1}>Content</h3> */}
                                            {/* <hr /> */}
                                            <div className="textalign"
                                                dangerouslySetInnerHTML={{
                                                    __html: htmlSanitizer(interpolateTemplate(
                                                        pdfContent?.content?.certificate?.content,
                                                        {
                                                            applicationName: `${pdfContent?.content?.application?.applicationName} V1.0.0`,
                                                            assessmentType: pdfContent?.content?.application?.assessmentType,
                                                            applicationOwner: `${pdfContent?.content?.application?.createdByUser?.firstName} ${pdfContent?.content?.application?.createdByUser?.lastName}`,
                                                            siteURL: pdfContent?.content?.kickoff?.URI,
                                                            testSiteURL: pdfContent?.content?.kickoff?.URI,
                                                            certificateNo: btoa(`${pdfContent?.content?.certificate?.id} ${new Date().getTime()}`),
                                                        }
                                                    )),
                                                }}
                                            />
                                        </PageSection>
                                        <div style={{ pageBreakAfter: "always" }}> </div>

                                        <PageSection
                                            isReport={false}

                                            title="Auditors Remark"
                                            pageId="2"
                                        >
                                            <div className="textalign"
                                                dangerouslySetInnerHTML={{
                                                    __html: htmlSanitizer(interpolateTemplate(
                                                        pdfContent?.content?.certificate?.auditorsRemark,
                                                        {
                                                            scope: pdfContent?.content?.certificate?.auditorsRemark,
                                                        }
                                                    )),
                                                }}
                                            />

                                        </PageSection>
                                        {/* <div style={{ pageBreakAfter: "always" }}> </div> */}

                                        <PageSection
                                            isReport={false}
                                            title="Observations"
                                            pageId="3"
                                        >
                                            <div className="textalign"
                                                dangerouslySetInnerHTML={{
                                                    __html: htmlSanitizer(interpolateTemplate(
                                                        pdfContent?.content?.certificate?.observations,
                                                        {
                                                            scope: pdfContent?.content?.certificate?.observations,
                                                        }
                                                    )),
                                                }}
                                            />

                                        </PageSection>
                                        <div style={{ pageBreakAfter: "always" }}> </div>

                                        <PageSection
                                            isReport={false}
                                            title="Conclusion"
                                            pageId="4"
                                        >
                                            <div className="textalign"
                                                dangerouslySetInnerHTML={{
                                                    __html: htmlSanitizer(interpolateTemplate(
                                                        pdfContent?.content?.certificate?.conclusion,
                                                        {
                                                            applicationName: `${pdfContent?.content?.application?.applicationName} V1.0.0`,
                                                            assessmentType: pdfContent?.content?.application?.assessmentType,
                                                            applicationOwner: `${pdfContent?.content?.application?.createdByUser?.firstName} ${pdfContent?.content?.application?.createdByUser?.lastName}`,
                                                            siteURL: pdfContent?.content?.kickoff?.URI,
                                                            testSiteURL: pdfContent?.content?.kickoff?.URI,
                                                            certificateNo: btoa(`${pdfContent?.content?.certificate?.id} ${new Date().getTime()}`),

                                                        }
                                                    )),
                                                }}
                                            />

                                        </PageSection>
                                        {/* <div style={{ pageBreakAfter: "always" }}> </div> */}

                                        <PageSection
                                            isReport={false}
                                            title="Standards"
                                            pageId="5"
                                        >

                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: htmlSanitizer(interpolateTemplate(
                                                        pdfContent?.content?.certificate?.standards,
                                                        {
                                                            scope: pdfContent?.content?.certificate?.standards,
                                                        }
                                                    )),
                                                }}
                                            />
                                        </PageSection>
                                        <div style={{ pageBreakAfter: "always" }}> </div>

                                        <PageSection
                                            isReport={false}
                                            title={'Appendix A'}
                                            pageId="6"
                                        >

                                            <div className="textalign"
                                                dangerouslySetInnerHTML={{
                                                    __html: htmlSanitizer(interpolateTemplate(
                                                        pdfContent?.content?.certificate?.appendixA,
                                                        {
                                                            appendixA: pdfContent?.content?.findings?.length > 0 ? renderAppendixAData() : null,
                                                        }
                                                    )),
                                                }}
                                            />
                                        </PageSection>
                                        <div style={{ pageBreakAfter: "always" }}> </div>

                                        <PageSection
                                            isReport={false}
                                            title="Appendix B"
                                            pageId="7"
                                        >
                                            <div className="textalign"
                                                dangerouslySetInnerHTML={{
                                                    __html: htmlSanitizer(interpolateTemplate(
                                                        pdfContent?.content?.certificate?.appendixB,
                                                        {
                                                            scope: pdfContent?.content?.certificate?.appendixB,
                                                        }
                                                    )),
                                                }}
                                            />
                                        </PageSection>
                                        <div style={{ pageBreakAfter: "always" }}> </div>

                                        <PageSection
                                            isReport={false}
                                            title="Benchmarks"
                                            pageId="8"
                                        >
                                            <div className="textalign"
                                                dangerouslySetInnerHTML={{
                                                    __html: htmlSanitizer(interpolateTemplate(
                                                        pdfContent?.content?.certificate?.benchmarks,
                                                        {
                                                            scope: pdfContent?.content?.certificate?.benchmarks,
                                                        }
                                                    )),
                                                }}
                                            />
                                        </PageSection>
                                    </Fragment>
                                }
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        )
    );
};

export default CertificatePDF;
