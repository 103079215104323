import RHFTextField from 'components/FormControls/RHFTextField'
import React from 'react'
import { Col, Row } from 'reactstrap'

const ReadOnlyPoc = (props) => {
    const { pocdata, setActivePoc, setImageViewer, setActiveImageIndex } = props;

    const manageViewer = (poc, index) => {
        setActivePoc(poc);
        setImageViewer(true);
        setActiveImageIndex(index)
    }

    return (
        <div className='mb-3'>
            <Row className="mb-2">
                <Col sm="12">
                    <RHFTextField
                        id="description"
                        label="Description"
                        name="description"
                        isController={false}
                        defaultValue={pocdata?.description}
                        disabledField={true}
                    />
                </Col>
            </Row>
            <Row >
                <Col sm="6">
                    <Row className="mb-2">
                        <Col sm="12">
                            <RHFTextField
                                id="url"
                                label="URl"
                                name="url"
                                isController={false}
                                defaultValue={pocdata?.url}
                                disabledField={true}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="12">
                            <RHFTextField
                                id="parameters"
                                label="Parameters"
                                name="parameters"
                                isController={false}
                                defaultValue={pocdata?.parameters}
                                disabledField={true}

                            />
                        </Col>
                    </Row>
                </Col>
                <Col sm="6">
                    <RHFTextField
                        id="payload"
                        label="Payload"
                        name="payload"
                        multiline={true}
                        rows={5}
                        type="textarea"
                        isController={false}
                        defaultValue={pocdata?.payload}
                        disabledField={true}
                    />
                </Col>
            </Row>
            <Row>
                <div className='poc-images-detail'>
                    {pocdata?.images?.map((item, index) =>
                        <div className='poc-inner-images justify-content-center' key={item?.signedUrl}>
                            <img
                                alt="Sample"
                                src={item?.signedUrl}
                                onClick={() => manageViewer(pocdata, index)}
                            />
                        </div>
                    )}
                </div>
            </Row>
        </div>
    )
}

export default ReadOnlyPoc