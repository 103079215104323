import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { addNetwork, listAllNetwork, } from "helpers/BackendHelper"
import { ADD_NETWORK, GET_ALL_NETWORK } from "./actionTypes"
import { addNetworkFail, addNetworkSuccess, getAllNetworkFail, getAllNetworkSuccess, } from "./actions"
import { Toast } from "components/Common/Toaster"


/* Add New Network */
function* addNewNetwork({ payload }) {
    try {
        const response = yield call(addNetwork, payload)
        yield put(addNetworkSuccess(response))
        Toast.success(response.message)
    } catch (error) {
        yield put(addNetworkFail(error))
    }
}

/* Get All Network */
function* getAllNetworks({ payload }) {
    try {
        const response = yield call(listAllNetwork, payload)
        yield put(getAllNetworkSuccess(response))
    } catch (error) {
        yield put(getAllNetworkFail(error))
    }
}

export function* watchNetworks() {
    yield takeEvery(ADD_NETWORK, addNewNetwork)
    yield takeEvery(GET_ALL_NETWORK, getAllNetworks)
}

function* networkSaga() {
    yield all([fork(watchNetworks)])
}

export default networkSaga;
