import React, { Fragment } from "react";
import { Controller } from "react-hook-form";
import { FormFeedback, Input, Label } from "reactstrap";
import RHFDisableField from "./RHFDisableField";

const RHFTextField = ({ isController = true, ...props }) => {
  const {
    name,
    label,
    errorobj,
    control,
    defaultValue,
    multiline,
    rows,
    onChange,
    handleOnChange,
    isRequired = true,
    backgroundcolor = false,
    autoFocus = false,
    bsSize = "md",
    type = "text",
    disabledField = false,

    ...extraProps
  } = props;

  if (!isController) {
    return (
      <Fragment>
        <div className={label && "mb-2"}>
          {label && (
            <Label htmlFor="example-text-input" className="form-label">
              {label}
            </Label>
          )}
          {!disabledField ?
            <Input
              autoFocus={autoFocus}
              style={backgroundcolor ? { background: backgroundcolor, color: '#fff' } : null}
              type={type}
              {...props}
              onChange={(data) => {
                if (handleOnChange) {
                  handleOnChange(
                    data?.target?.value,
                    name
                  ); /* You must pass this function while isController is false -> else you will not get selected values */
                }
              }}
            />
            : <RHFDisableField fieldValue={defaultValue}></RHFDisableField>
          }
        </div>
      </Fragment>
    );
  }

  let isError = false;
  let errorMessage = "";
  let disabled = false;
  let multilineDetails = {};
  let someValue = "";

  if (errorobj && Object.prototype.hasOwnProperty.call(errorobj, name)) {
    isError = true;
    errorMessage = errorobj[name]?.message;
  }

  if (multiline && rows) {
    multilineDetails = { multiline: true, rows: `${rows}` };
  }

  disabled = props.disabled === undefined ? false : props.disabled;

  if (defaultValue !== undefined) {
    someValue = defaultValue;
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={someValue}
      render={({ field }) => (
        <Fragment>
          {label && <Label htmlFor="example-text-input" className="form-label">
            {label} {isRequired && <span className="text-danger">*</span>}
          </Label>
          }
          {!disabledField ?
            <Fragment>
              <Input
                autoComplete="off"
                {...field}
                {...extraProps}
                autoFocus={autoFocus}
                bsSize={bsSize}
                type={type}
                invalid={isError}
                disabled={disabled}
                onChange={(_) => {
                  if (_?.target?.type === "text") {
                    field.onChange(_.target.value);
                    return !onChange ? field.onChange(_.target.value) : onChange(_);
                  }
                  field.onChange(_.target.value);
                  return !onChange ? field.onChange(_.target.value) : onChange(_);
                }}
                {...multilineDetails}
              />
              {isError && (
                <FormFeedback type="invalid">{errorMessage}</FormFeedback>
              )}
            </Fragment>
            : <RHFDisableField fieldValue={field.value} bsSize={bsSize}></RHFDisableField>
          }
        </Fragment>
      )}
    />
  );
};

export default RHFTextField;
