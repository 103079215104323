

import { ADD_FINDING, UPDATE_FINDING, GET_ALL_CWE, GET_ALL_CVE, GET_ALL_FINDING, UPDATE_POC, DELETE_POC, CREATE_POC, ADD_COMMENT, APPROVE_REJECT_EXCEPTION, GET_FINDING_DETAILS, DELETE_FINDING, POC_IMAGE_UPLOAD, GET_ALL_SUBMISSIONS, GET_FINDING_LOGS, GET_CWE_TRENDS_BAR_CHART, GET_OWASP_WEB_BAR_CHART, GET_OWASP_API_BAR_CHART, GET_OWASP_MOBILE_BAR_CHART, GET_ALL_CVE_FROM_CWE, GET_CVE_DETAILS, GET_SUBMISSIONS_CHARTS, GET_ALL_OWASP, GET_OWASP_DETAILS } from "helpers/UrlHelper";
import { API_URL } from "./Services";

const baseURL = API_URL.FINDING;

export const Finding = {
    getAllCWE: {
        method: 'GET',
        url: GET_ALL_CWE,
        baseURL
    },
    getAllCVE: {
        method: 'GET',
        url: GET_ALL_CVE,
        baseURL
    },
    getAllOWASP: {
        method: 'GET',
        url: GET_ALL_OWASP,
        baseURL
    },
    getAllCVEFromCWE: {
        method: 'POST',
        url: GET_ALL_CVE_FROM_CWE,
        baseURL
    },
    getCVEDetails: {
        method: 'GET',
        url: GET_CVE_DETAILS,
        baseURL
    },
    getOWASPDetails: {
        method: 'GET',
        url: GET_OWASP_DETAILS,
        baseURL
    },
    addFinding: {
        method: 'POST',
        url: ADD_FINDING,
        baseURL
    },
    listAllFinding: {
        method: 'POST',
        url: GET_ALL_FINDING,
        baseURL
    },
    editFinding: {
        method: 'PUT',
        url: UPDATE_FINDING,
        baseURL
    },
    deleteFinding: {
        method: 'DELETE',
        url: DELETE_FINDING,
        baseURL
    },
    getFinding: {
        method: 'GET',
        url: GET_FINDING_DETAILS,
        baseURL
    },
    createPoc: {
        method: 'POST',
        url: CREATE_POC,
        baseURL
    },
    editPoc: {
        method: 'PUT',
        url: UPDATE_POC,
        baseURL
    },
    deletePoc: {
        method: 'DELETE',
        url: DELETE_POC,
        baseURL
    },
    addComment: {
        method: 'POST',
        url: ADD_COMMENT,
        baseURL
    },
    approveRejectException: {
        method: 'PUT',
        url: APPROVE_REJECT_EXCEPTION,
        baseURL
    },
    pocImageUpload: {
        method: 'POST',
        url: POC_IMAGE_UPLOAD,
        baseURL
    },
    listAllSubmissions: {
        method: 'POST',
        url: GET_ALL_SUBMISSIONS,
        baseURL
    },
    getSubmissionsCharts: {
        method: 'POST',
        url: GET_SUBMISSIONS_CHARTS,
        baseURL
    },
    getFindingLogs: {
        method: 'GET',
        url: GET_FINDING_LOGS,
        baseURL
    },
    getCweTrendsBarChart: {
        method: 'POST',
        url: GET_CWE_TRENDS_BAR_CHART,
        baseURL
    },
    getOwaspWebBarChart: {
        method: 'POST',
        url: GET_OWASP_WEB_BAR_CHART,
        baseURL
    },
    getOwaspApiBarChart: {
        method: 'POST',
        url: GET_OWASP_API_BAR_CHART,
        baseURL
    },
    getOwaspMobileBarChart: {
        method: 'POST',
        url: GET_OWASP_MOBILE_BAR_CHART,
        baseURL
    },

}