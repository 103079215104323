import React from 'react'
import ReactApexChart from 'react-apexcharts'

const BarChart = (props) => {

    const { seriesData = [], xAxisCategoryData = [], barColors = [], nameLables = [] } = props
    const series = [
        {
            data: seriesData,
        },
    ]
    const options = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 3,
                horizontal: true,
                distributed: true,
                barHeight: '85%',
                dataLabels: {
                    position: 'top'
                }
            },

        },
        dataLabels: {
            enabled: false,
            style: {
                colors: ['#B8BFC6']
            },
            // textAnchor: 'start',
            // formatter: function (val, opt) {
            //     return opt.w.config.labels[opt.dataPointIndex]
            // },
            // offsetX: 20
        },
        legend: {
            show: false
        },
        colors: barColors,
        labels: nameLables,
        grid: {
            borderColor: "#f1f1f1",
        },
        xaxis: {
            categories: xAxisCategoryData,
        },
        yaxis: {
        },
        tooltip: {
            shared: false,
            x: {
                formatter: function (val) {
                    return val
                }
            },
            y: {
                formatter: function (val) {
                    return Math.abs(val)
                },
                title: {
                    formatter: (seriesName, opt) => {
                        return opt.w.config.labels[opt.dataPointIndex]
                    },
                },
            }
        },

    }

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height="230"
        />
    )
}

export default BarChart
