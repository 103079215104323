import React, { useState } from 'react'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Col, Row } from 'reactstrap';
import RHFTextField from 'components/FormControls/RHFTextField';
import RHFDropZone from 'components/FormControls/RHFDropZone';
import RHFButton from 'components/FormControls/RHFButton';
import { createPoc, pocImageUpload } from 'helpers/BackendHelper';
import { useParams } from 'react-router-dom';
import { urlRegex } from 'constants/Constant';

const PocStepsAddEdit = (props) => {

    const { handleToggle, setFormData, editPocData } = props;
    const { findingId } = useParams()
    const [imageFiles, setImageFiles] = useState([])
    const [isApiCalling, setApiCalling] = useState(false);
    const [isPOCloading, setIsPOCloading] = useState(false);

    const PocStepsSchema = yup.object().shape({
        description: yup.string().trim().required("Description is required"),
        images: yup.array().min(1, 'Pocs are required'),
        url: yup.string().trim().transform((curr, orig) => orig === '' ? null : curr).nullable()
            .matches(urlRegex, 'Enter Correct URL')
    });

    const {
        handleSubmit: handleSubmitpocsteps,
        control: pocControl,
        setValue: pocSetvalue,
        reset: pocReset,
        formState: { errors: pocErrors },
    } = useForm({
        defaultValues: { id: new Date().getUTCMilliseconds() },
        mode: "onBlur",
        resolver: yupResolver(
            PocStepsSchema
        ),
    });

    const onSubmitPocSteps = async (data) => {
        const formData = new FormData();
        Object.entries(imageFiles).forEach(([key, value]) => {
            formData.append(`image (${key})`, value)
        });

        let newImages = []
        if (imageFiles?.length > 0) {
            setIsPOCloading(true)
            if (!isPOCloading) {
                await pocImageUpload(formData).then(resp => {
                    newImages = resp?.data?.map(x => x.url);
                    setTimeout(() => {
                        setFormData({ ...data, images: resp?.data });
                    }, 1000);
                }).finally(() => {
                    setIsPOCloading(false)
                });
            }
        }
        // setFormData({ ...data, images: base64Files });
        pocReset();
        if (findingId) {
            setApiCalling(true);
            if (!isApiCalling) {
                createPoc({ ...data, images: newImages, findingId: findingId }).then(() => {
                    setApiCalling(false);
                }).catch(() => {
                    setApiCalling(false);
                });
            }
        }
        // }
        handleToggle()
    };

    const getFileData = (files) => {
        setImageFiles(files?.files)
    }

    return (
        <div>
            <Row className="mb-2">
                <Col sm="12">
                    <RHFTextField
                        id="description"
                        label="Description"
                        name="description"
                        errorobj={pocErrors}
                        control={pocControl}
                        isController={true}
                    />
                </Col>
            </Row>
            <Row className="mb-2">
                <Col sm="6">
                    <Row className="mb-2">
                        <Col sm="12">
                            <RHFTextField
                                id="url"
                                label="URL"
                                name="url"
                                errorobj={pocErrors}
                                control={pocControl}
                                isController={true}
                                isRequired={false}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="12">
                            <RHFTextField
                                id="parameters"
                                label="Parameters"
                                name="parameters"
                                errorobj={pocErrors}
                                control={pocControl}
                                isController={true}
                                isRequired={false}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col sm="6">
                    <RHFTextField
                        id="payload"
                        label="Payload"
                        name="payload"
                        multiline={true}
                        rows={5}
                        type="textarea"
                        errorobj={pocErrors}
                        control={pocControl}
                        isController={true}
                        isRequired={false}
                    />

                </Col>
            </Row>
            <Row className="mb-2">
                <Col sm="12">
                    <RHFDropZone
                        name="images"
                        label="Upload Images"
                        errorobj={pocErrors}
                        control={pocControl}
                        pocSetvalue={pocSetvalue}
                        getFileData={getFileData}
                        editPocData={editPocData ? editPocData : null}
                        multiple={true}
                    />
                </Col>
            </Row>
            <div className="d-flex justify-content-end">
                <RHFButton
                    btnName="Cancel"
                    outline={true}
                    onClick={handleToggle}
                    className="mx-2"

                />
                {!isPOCloading ? <RHFButton
                    btnName="Submit"
                    type="submit"
                    onClick={handleSubmitpocsteps(onSubmitPocSteps)}
                    isLoading={isApiCalling}
                /> : <RHFButton
                    btnName="Loading..."
                    isLoading={isPOCloading}
                />
                }
            </div>
        </div>
    )
}

export default PocStepsAddEdit
