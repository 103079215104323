import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { addKickoff, editKickoff } from "helpers/BackendHelper"
import { addKickoffFail, addKickoffSuccess, updateKickoffFail, updateKickoffSuccess, } from "./actions"
import { ADD_KICK_OFF, UPDATE_KICK_OFF } from "./actionTypes"
import { Toast } from "components/Common/Toaster"


/* Add Kickoff */
function* addNewKickoff({ payload }) {

    try {
        const response = yield call(addKickoff, payload)
        yield put(addKickoffSuccess(response))
        Toast.success(response.message)
    } catch (error) {
        yield put(addKickoffFail(error))
    }
}

/* Update Kickoff */
function* updateKickoff({ payload }) {
    try {
        const response = yield call(editKickoff, payload)
        yield put(updateKickoffSuccess(response))
        Toast.success(response.message)
    } catch (error) {
        yield put(updateKickoffFail(error))
    }
}


export function* watchKickoff() {
    yield takeEvery(ADD_KICK_OFF, addNewKickoff)
    yield takeEvery(UPDATE_KICK_OFF, updateKickoff)
}

function* kickoffSaga() {
    yield all([fork(watchKickoff)])
}

export default kickoffSaga;
