

import { ADD_KICF_OFF, UPDATE_KICK_OFF, GET_KICKOFF_DETAIL } from "helpers/UrlHelper";
import { API_URL } from "./Services";

const baseURL = API_URL.KICKOFF;

export const KICKOFF = {
    addKickoff: {
        method: 'POST',
        url: ADD_KICF_OFF,
        baseURL
    },
    editKickoff: {
        method: 'POST',
        url: UPDATE_KICK_OFF,
        baseURL
    },
    getKickoffDetail: {
        method: 'GET',
        url: GET_KICKOFF_DETAIL,
        baseURL
    }
}