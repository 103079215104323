import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect, useHistory } from "react-router-dom";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  permission = true,
  showEmptyLayout = false,
  ...rest
}) => {
  let history = useHistory();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !localStorage.getItem("authUser")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        return (
          !showEmptyLayout ?
            <Layout>
              {
                permission ? <Component {...props} /> : history.goBack()
              }
            </Layout> :
            permission ? <Component {...props} /> : history.goBack()
        );
      }}
    />
  )
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;
