import { CHANGE_PASSWORD, CHANGE_PASSWORD_FAIL, CHANGE_PASSWORD_SUCCESS } from "./actionTypes"

export const changePassword = (payload) => {
    return {
        type: CHANGE_PASSWORD,
        payload,
    }
}

export const changePasswordSuccess = (data) => {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        payload: data
    }
}

export const changePasswordFail = (data) => {
    return {
        type: CHANGE_PASSWORD_FAIL,
        payload: data
    }
}