import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import defaultImage from "../../assets/images/Default-User-Image.jpeg";

// actions
import RHFFileUpload from "components/FormControls/RHFFileUpload";
import RHFTextField from "components/FormControls/RHFTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import RHFButton from "components/FormControls/RHFButton";
import DialogBox from "components/Modals/DialogBox";
import ChangePasswordForm from "./Profile/ChangePasswordForm";
import { isModulePermisssion } from "helpers/util";
import { ROLE_PERMISSIONS } from "constants/RolePermissions";
import { useEffect } from "react";
import { userUpdate } from "store/user/actions";
import RHFSwitch from "components/FormControls/RHFSwitch";
import { generateQRCode } from "store/auth/twoFA/actions";
import OtpInput from "react-otp-input";
import { verifyUpdatedOtp } from "store/auth/useProfileTwoFA/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { SUPPORTED_FORMATS, USER_ROLES } from "constants/Constant";
import PageLoader from "components/PageLoader";
import { getLoginUserDetail } from "store/actions";
import { editUser, getLoginUser, profileUpload } from "helpers/BackendHelper";
import { Toast } from "components/Common/Toaster";
import RHFMobileNo from "components/FormControls/RHFMobileNo";

const UserProfile = () => {
  //meta title
  document.title = "Profile | RiDiscovery";

  const dispatch = useDispatch()
  const history = useHistory()
  const { user } = useSelector(state => state.Login);
  const { isTwoFAEnabledLoading, TwoFAEnabled } = useSelector(state => state.UserProfileTwoFA);
  const { isLoading } = useSelector(state => state.User);
  const { qrCode } = useSelector(state => state?.TwoFA);
  const editUserProfileData = user?.user;
  const token = localStorage.getItem('authUser')
  const [isModelOpen, setIsModelOpen] = useState(false);
  const TWOFA = TwoFAEnabled ? TwoFAEnabled : user?.user['2FAEnabled']
  const [isActive, setIsActive] = useState((Boolean(TWOFA)));
  const [isTwoFAOpen, setIsTwoFAOpen] = useState(false)
  const [otp, setOtp] = useState('')
  const [pageLoader, setPageLoader] = useState(false)
  const [isMode, setIsMode] = useState({
    isChangePasswordMode: false,
    isDisable2FA: false
  });
  const [countries, setCountries] = useState('')

  useEffect(() => {
    setPageLoader(true)
    getLoginUser().then((resp) => {
      if (resp?.data) {
        setPageLoader(false)
      }
    }).catch(() => {
      setPageLoader(false)
    })
    setPageLoader(false)

  }, []);

  useEffect(() => {
    setIsActive((Boolean(TWOFA)))
  }, [TWOFA])

  const profileSchema = yup.object().shape({
    firstName: yup.string().trim().matches(/^[A-Za-z ]*$/, 'Please enter valid name').required('First Name is required').min(3).max(30),
    lastName: yup.string().trim().matches(/^[A-Za-z ]*$/, 'Please enter valid name').required('Last Name is required').min(3).max(30),
    email: yup.string().email().max(150).required('Email is required'),
    // phone: yup.string().trim().matches(phoneRegExp, 'Phone number is not valid').required('Phone Number is required').min(10, "Minimum 10 digits are required").max(10, "Phone number should not exceed 10 digits"),
    phone: yup.string().required('Phone Number is required')
      .test("Check country", "Invalid country code", function (value) {
        let contactWithoutSpecialCharacter = value?.match(/\d+/g)?.join("")
        const findCountry = countries.find(country => contactWithoutSpecialCharacter?.startsWith(country?.dialCode))
        if (!findCountry) {
          return false
        } else {
          return true
        }
      })
      .test('Check Number', "Invaild Phone number", function (value) {
        let contactWithoutSpecialCharacter = value?.match(/\d+/g)?.join("")
        const findCountry = countries.find(country => contactWithoutSpecialCharacter?.startsWith(country?.dialCode))
        if (findCountry) {
          const func = (str) => {
            let count = 0
            if (str !== null) {
              for (let i = 0; i < str.length; i++)
                if (str[i] === ".") {
                  count++
                }
              return count
            }
          }
          let stringLength = func(findCountry?.format)
          if (contactWithoutSpecialCharacter?.length < stringLength) {
            return false
          } else {
            return true
          }
        }
      }),
  });

  const profileImageSchema = yup.object().shape({
    userProfile: yup.mixed().required("Profile image is required").test("type", "Invalid file formate.", function (value) {
      if (!value) {
        return false
      } else if (typeof value === "string") {
        if (value?.toLowerCase().includes("jpeg") || value?.toLowerCase().includes("jpg") || value?.toLowerCase().includes("png") || value?.toLowerCase().includes("bmp") || value?.toLowerCase().includes("svg")) {
          return true
        }
      }
      else if (SUPPORTED_FORMATS.includes(value.type)) {
        return true
      } else {
        return false
      }
    })
  })

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(profileSchema),
  });

  const {
    handleSubmit: handleSubmit2,
    control: control2,
    setValue: setValue2,
    setError: setError2,
    clearErrors: clearErrors2,
    formState: { errors: errors2 },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(profileImageSchema),
  });

  const handleToggle = () => {
    setIsMode({
      isChangePasswordMode: false,
      isDisable2FA: false
    })
    setIsModelOpen(!isModelOpen)
  };

  useEffect(() => {
    if (editUserProfileData) {
      setValue('firstName', editUserProfileData?.firstName)
      setValue('lastName', editUserProfileData?.lastName)
      setValue('email', editUserProfileData?.email)
      setValue('phone', editUserProfileData?.phone)
    }
  }, [editUserProfileData]);

  const onSubmit = (data) => {
    const payload = {
      ...data,
      userId: editUserProfileData?.id
    }
    dispatch(userUpdate(payload));
    editUser(payload).then((res) => {
      Toast.success(res.message);
      dispatch(getLoginUserDetail());
    })
  };

  const handleSwitchChange = (val) => {
    if (isActive) {
      handleToggle()
      setIsMode({
        isDisable2FA: true
      })
    } else {
      setIsActive(val);
      setIsTwoFAOpen(true)
      dispatch(generateQRCode({ token: token }))
    }
  }

  const handleOTPDisable = () => {
    if (isActive) {
      setIsActive(false);
      const payload = {
        "twoFAEnabled": false,
      }
      dispatch(verifyUpdatedOtp(payload))

      setIsTwoFAOpen(false)
      handleToggle()
      setIsMode({
        isDisable2FA: true
      })
    }

  }

  const handleOtpSubmit = () => {
    if (isActive) {
      const payload = {
        "otp": otp,
        "twoFAEnabled": isActive,
        "secret": qrCode?.secret
      }
      dispatch(verifyUpdatedOtp(payload))
      setOtp('')
      if (!TwoFAEnabled) {
        setIsActive(false)
        setIsTwoFAOpen(false)
      } else {
        setIsActive(true)
      }
    }
  }

  const handleOtpChange = (otp) => {
    setOtp(otp);
  }

  const getFileData = (fileData) => {
    setValue2("userProfile", fileData?.file)
    handleSubmit2((fileData) => {
      const payload = {
        "fileName": fileData?.userProfile?.name,
        "image": fileData?.userProfile
      }
      if (!errors2?.userProfile && payload) {
        profileUpload(payload).then((res) => {
          Toast.success(res.message);
          dispatch(getLoginUserDetail());
        })
      }
    })()
  };


  const breadcrumbItems = [
    { path: "/", heading: "Home" },
    { path: "/profile", heading: "Profile" }
  ]

  const profileImage = user?.user?.profile;

  return (
    <React.Fragment>
      {pageLoader || isTwoFAEnabledLoading ?
        <PageLoader />
        :
        <div className="page-content">

          {/* Render Breadcrumb */}
          <Breadcrumb title="User Profile" breadcrumbItem={breadcrumbItems} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="user-profile">
                  <div className="d-flex mb-2">
                    <div className="ms-3">
                      <RHFFileUpload
                        name="userProfile"
                        control={control2}
                        errorobj={errors2}
                        isValidate={true}
                        setValue={setValue2}
                        setError={setError2}
                        clearErrors={clearErrors2}
                        getFileData={getFileData}
                        defaultImage={profileImage || defaultImage}
                        sizeValidation={true}
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center ms-3">
                      <div className="text-muted">
                        <h5>{editUserProfileData?.firstName} {editUserProfileData?.lastName}</h5>
                        <p className="mb-1">{editUserProfileData?.email}</p>
                        <p className="mb-0">{USER_ROLES[editUserProfileData?.roleId]}</p>
                      </div>
                    </div>
                    <div>
                      {isModulePermisssion(ROLE_PERMISSIONS?.CHANGE_PASSWORD) &&
                        <RHFButton btnName="Change Password" onClick={() => {
                          handleToggle()
                          setIsMode({ isChangePasswordMode: true })
                        }} />}

                      <DialogBox
                        isModelOpen={isModelOpen}
                        handleToggle={handleToggle}
                        modelSize="sm-100"
                        title={isMode?.isChangePasswordMode ? "Change your password" :
                          isMode?.isDisable2FA ? "Conformation" : ""}
                        actions={isMode?.isChangePasswordMode ? null : isMode?.isDisable2FA ?
                          <>
                            <RHFButton
                              btnName="Cancel"
                              outline={true}
                              onClick={handleToggle}
                            />
                            <RHFButton
                              btnName="Okay"
                              onClick={handleOTPDisable}
                              isLoading={isTwoFAEnabledLoading}
                            />
                          </> :
                          ""
                        }
                      >
                        {isMode?.isChangePasswordMode && <ChangePasswordForm handleToggle={handleToggle} />}
                        {isMode?.isDisable2FA &&
                          <div className="text-center">
                            <i className="mdi mdi-alert-circle-outline" style={{ fontSize: "5em", color: "orange" }} />
                            <h5>Are you sure to Disable Two Step Verification?</h5>
                            <p className="fs-5">You won't be able to revert this!</p>
                          </div>
                        }
                      </DialogBox>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <form
                className="form-horizontal"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row className="mb-3">
                  <Col sm="12">
                    <RHFTextField
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      errorobj={errors}
                      control={control}
                      isController={true}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm="12">
                    <RHFTextField
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      errorobj={errors}
                      control={control}
                      isController={true}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm="12">
                    <RHFTextField
                      id="email"
                      label="Email"
                      name="email"
                      errorobj={errors}
                      control={control}
                      isController={true}
                      disabledField={true}
                    />
                  </Col>
                </Row>
                {/* <Row className="mb-3">
                  <Col sm="12">
                    <RHFTextField
                      id="phone"
                      label="Mobile No."
                      name="phone"
                      errorobj={errors}
                      control={control}
                      isController={true}
                    />
                  </Col>
                </Row> */}
                <Row className="mb-3">
                  <Col sm="12">
                    <RHFMobileNo
                      id="phone"
                      label="Mobile No."
                      name="phone"
                      errorobj={errors}
                      control={control}
                      isController={true}
                      setCountries={setCountries}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-end">
                  <RHFButton
                    btnName="Cancel"
                    outline={true}
                    onClick={() => history.push('/dashboard')}
                    className="me-2"
                  />
                  <RHFButton
                    btnName="Save"
                    type="submit"
                    isLoading={isLoading}
                  />
                </div>
              </form>
              <hr />
              {isModulePermisssion(ROLE_PERMISSIONS?.UPDATE_2FA_MECHANISM) &&
                <>
                  <Row className="mb-3">
                    <Col sm="12">
                      <label htmlFor="small-radius-switch" className="form-label me-2">
                        Two Step-Verification
                      </label>
                      <RHFSwitch
                        name="twoFAEnabled"
                        checked={isActive}
                        isController={true}
                        errorobj={errors}
                        control={control}
                        onChange={handleSwitchChange}
                      />
                    </Col>
                  </Row>
                  {
                    isTwoFAOpen && qrCode?.QRCode &&
                    <>
                      <Row className="mb-3">
                        <Col sm="12">
                          <div className="mb-3 d-flex justify-content-lg-start">
                            <img alt='qr-code' src={qrCode?.QRCode} width="150" height="150" />
                            <OtpInput
                              onChange={handleOtpChange}
                              value={otp}
                              numInputs={6}
                              shouldAutoFocus={true}
                              inputStyle={{
                                border: "1px solid #adb5bd",
                                borderRadius: "8px",
                                width: "40px",
                                height: "40px",
                                fontSize: "12px",
                                marginRight: "10px",
                                color: "#000",
                                fontWeight: "400",
                                caretColor: "blue"
                              }}
                              focusStyle={{
                                border: "1px solid #868686",
                                outline: "none"
                              }}
                            />
                            <div className="d-flex align-items-center" >
                              {otp.length === 6 &&
                                <RHFButton
                                  btnName="Verify"
                                  type="submit"
                                  className="mx-2"
                                  onClick={handleOtpSubmit}
                                  isLoading={isTwoFAEnabledLoading}
                                />}
                            </div>
                          </div>

                        </Col>
                      </Row>
                    </>
                  }
                </>
              }
            </CardBody>
          </Card>
        </div>
      }
    </React.Fragment >
  )
}

export default UserProfile;