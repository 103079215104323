import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { GET_LOGIN_USER_DETAIL, GET_PERMISSION, LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN, VERIFY_TANENT } from "./actionTypes";
import { apiError, loginSuccess, loginFail, verifyTanentSuccess, verifyTanentFail, getPermissionSuccess, getPermissionFail } from "./actions";
import { ENV_CONFIG } from "constants/Constant";

import {
  getAllUserPermission,
  getLoginUser,
  postSocialLogin,
} from "../../../helpers/BackendHelper";

function* checkTenantExistOrNot({ payload }) {
  try {
    let response = payload?.response;
    if (response?.status && response?.isTenantAvailable) {
      const tenant_url = process.env.NODE_ENV === 'development' ?
        `http://${location.hostname}:3001` : `https://${response?.tenantName}.ridiscovery.com`;
      window.open(`${tenant_url}/tenant/login?email=${btoa(payload?.email)}`, '_self');
    }
    yield put(verifyTanentSuccess(response))
    if (response?.isEmailExist) {
      yield put(verifyTanentSuccess(response))
    } else {
      yield put(verifyTanentFail({ isTenantAvailable: true }))
    }
  } catch (error) {
    yield put(verifyTanentFail(error))
  }

}

function* loginUser({ payload: { user, history } }) {
  try {
    const response = user;
    if (response?.status && response?.user?.['2FAEnabled']) {
      history.push(`/verify-otp/${response?.user?.token}`);
    } else {
      if (response?.status) {
        if (response?.user?.tenantName) {
          // redirect user to his tenant  // nisl.ridiscovery.com/login
          localStorage.setItem('authUser', response?.user?.token);
          localStorage.setItem('roleId', response?.user?.roleId)
          history.push("/9rD4t2gsbEY2ycOnK1oLb0325HTFFTZIm6UnelCoiEgcFvfvN0")
        }
        else {
          // redirect user to  company registration page  // app.ridiscovery.com/register-company
          localStorage.setItem('authUser', response?.user?.token);
          // window.open(`${globalTanent}/register-company`, '_self');
          history.push("/onboard-company")
        }
        yield put(loginSuccess(response))
      }

    }
    // yield put(loginSuccess(response))
  } catch (error) {
    yield put(loginFail(error))
  }

}

function* loginUserDetails() {
  try {
    const response = yield call(getLoginUser);
    yield put(loginSuccess(response))
  } catch (error) {
    yield put(loginFail(response))
  }
}

function* logoutUser({ payload: { email, history } }) {
  try {
    localStorage.clear();
    // history.push(`/tenant/login?email=${btoa(email)}`)
    const tenantName = location?.host?.split('.')[0];

    if (location?.port === ENV_CONFIG?.PORT2 || (tenantName !== ENV_CONFIG?.APP_TENANT && location?.port !== ENV_CONFIG?.PORT1)) {
      history.push(`/tenant/login?email=${btoa(email)}`)
    } else {
      history.push('/login')
    }


  } catch (error) {
    yield put(apiError(error));
  }
}


/* Get Login user permission */
function* getLoginUserPermission({ payload }) {
  try {
    const response = yield call(getAllUserPermission, payload);
    if (response?.status) {
      const permissions = {}
      response?.permissions?.map(obj => {
        permissions[`${obj?.permissionSlug}`] = obj?.isAllowed
      })
      localStorage.setItem('role-permission', JSON.stringify(permissions))
      yield put(getPermissionSuccess(permissions))
    }
  } catch (error) {
    yield put(getPermissionFail(error))
  }

}


function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, data, type);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(VERIFY_TANENT, checkTenantExistOrNot);
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(GET_LOGIN_USER_DETAIL, loginUserDetails);
  yield takeEvery(GET_PERMISSION, getLoginUserPermission);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
