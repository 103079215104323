import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    Card,
    CardBody,
} from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getUserDetail } from 'helpers/BackendHelper';
import DataTable, { createTheme } from 'react-data-table-component';
import { useSelector } from 'react-redux';


const userData = () => {
    document.title = "User | RiDiscovery";
    const { userId } = useParams();
    const [userData, setUserData] = useState([]);
    const { layoutMode } = useSelector((state) => ({ layoutMode: state.Layout.layoutMode }));

    const lightCustomStyles = {
        headCells: {
            style: {
                backgroundColor: "#e9e9ef",
                fontSize: "15px"
            },
        },
    };
    const darkCustomStyles = {
        headCells: {
            style: {
                backgroundColor: "#2c302e",
                fontSize: "15px"
            },
        },
    }

    createTheme('solarized', {
        text: {
            primary: '#000000',
            secondary: '#000000',
            disabled: 'rgba(0, 0, 0, 0.38)'
        },
        background: {
            default: 'transparent',
        },
        divider: {
            default: 'rgba(0,0,0,.12)',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    });

    createTheme('dark', {
        text: {
            primary: '#6f767e',
            secondary: '#6f767e',
            disabled: 'rgba(0, 0, 0, 0.38)'
        },
        background: {
            default: 'transparent',
        },
        highlightOnHover: {
            default: 'rgba(0,0,0,.08)',
            text: '#6f767e',
        },
    });


    useEffect(() => {
        const params = {
            id: userId
        }
        if (userId) {
            getUserDetail({ ...params }).then(resp => {
                setUserData(resp?.user);
            })
        }
    }, []);

    const columns = [
        {
            name: "Mobile Number",
            cell: (row) => <span>{row?.phone ? row?.phone : "N/A"}</span>,
        },
        {
            name: "Role",
            cell: (row) => <span>{row?.roleId ? row?.roleId : "N/A"}</span>,

        },
        {
            name: "Category",
            cell: (row) => <span>{row?.category ? row?.category : "N/A"}</span>,
        },
        {
            name: "Comapany",
            cell: (row) => <span>{row?.companyName ? row?.companyName : "N/A"}</span>,
        },
        {
            name: "Start Date",
            cell: (row) => <span>{row?.startDate ? row?.startDate : "N/A"}</span>,
        },
        {
            name: "End Date",
            cell: (row) => <span>{row?.endDate ? row?.endDate : "N/A"}</span>,

        },
        {
            name: "Status",
            cell: (row) => <span>{row?.activated ? "Active" : "Deactive"}</span>,
        },
    ];

    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/user-list", heading: "User list" },
    { path: `/user-list/user/${userId}`, heading: `${userData?.firstName} ${userData?.lastName}` },
    ]

    return (
        <div className="page-content">
            <Breadcrumbs title="User List" breadcrumbItem={breadcrumbItems} />
            <Card>
                <CardBody>
                    <div className="d-flex mb-5">
                        <div className="ms-3">
                            <div className="profile-container">
                                <img
                                    src={userData?.image}
                                    alt="image"
                                    className="profileCover"
                                    name="userProfile"
                                />
                            </div>
                        </div>
                        <div className="flex-grow-1 align-self-center ms-3">
                            <div className="text-muted">
                                <h5>{userData?.firstName} {userData?.lastName}</h5>
                                <p className="mb-1">{userData?.email}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow-1 align-self-center ms-3">
                        <div className="text-muted">
                            <h5>{userData?.firstName} {userData?.lastName}</h5>
                            <p className="mb-1">{userData?.email}</p>
                        </div>
                    </div>

                    <div>
                        <DataTable
                            columns={columns}
                            data={userData ? [userData] : null}
                            theme={layoutMode === 'light' ? "solarized" : "dark"}
                            customStyles={layoutMode === 'light' ? lightCustomStyles : darkCustomStyles}
                        />
                    </div>
                </CardBody>
            </Card>
        </div >
    )
}

export default userData
