import React from 'react';
import { useLocation } from "react-router-dom";
import * as DOMPurify from 'dompurify';

export const isModulePermisssion = (slug) => {
    // const { permissions } = useSelector(state => state.Login);
    const permissions = JSON.parse(localStorage.getItem('role-permission'))


    return permissions && permissions[slug]
}

export const renderTimestamp = timestamp => {
    let prefix = "";
    const timeDiff = Math.round(
        (new Date().getTime() - new Date(timestamp).getTime()) / 60000
    );
    if (timeDiff < 1) {
        // less than one minute ago
        prefix = "just now";
    } else if (timeDiff < 60 && timeDiff >= 1) {
        // less than sixty minutes ago
        prefix = `${timeDiff} minute ago`;
    } else if (timeDiff < 24 * 60 && timeDiff >= 60) {
        // less than 24 hours 전
        prefix = `${Math.round(timeDiff / 60)} hours ago`;
    } else if (timeDiff < 31 * 24 * 60 && timeDiff > 24 * 60) {
        // less than 7 days 전
        prefix = `${Math.round(timeDiff / (60 * 24))} days ago`;
    }
    else if (timeDiff < 12 * 31 * 24 * 60 && timeDiff > 31 * 24 * 60) {
        // less than 7 days 전
        prefix = `${Math.round(timeDiff / (31 * 24 * 60))} month ago`;
    }
    else {
        // few month ago
        prefix = `few month ago`;
    }
    return prefix;
};


export const dmDebounce = (func) => {
    let timer;
    return function (...args) {
        const context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
        }, 500);
    };
};

export const inverseObject = (obj) => {
    var retobj = {};
    for (var key in obj) {
        retobj[obj[key]] = key;
    }
    return retobj;
}

export const sortableObject = (obj) => {
    return Object?.fromEntries(
        Object?.entries(obj).sort(([, a], [, b]) => a - b)
    );
}


export const applicationScore = (x) => {
    switch (true) {
        case (x == 0.0):
            return 'Informative'
        case (x >= 0.1 && x <= 3.9):
            return 'Low'
        case (x >= 4.0 && x <= 6.9):
            return 'Medium'
        case (x >= 7.0 && x <= 8.9):
            return 'High'
        case (x >= 9.0 && x <= 10.0):
            return 'Critical'
        default:
            break;
    }
}

export const TitleCase = (str) => {
    const s = str && str[0].toUpperCase() + str.substring(1);
    return s;

}

export const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const htmlSanitizer = (str, allowTag = {}) => {
    return DOMPurify.sanitize(str, allowTag)
}
