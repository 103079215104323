import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ResetPasswordSaga from "./auth/resetPassword/saga"
import ChangePasswordSaga from "./auth/changePassword/saga"
import TwoFASaga from "./auth/twoFA/saga"
import userProfileTwoFASaga from "./auth/useProfileTwoFA/saga"
import AgencySaga from "./company/agency/saga"
import UserSaga from "./user/saga"
import ApplicationSaga from "./application/saga"
import NetworkSaga from "./network/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
import chatSaga from "./chat/saga"
import invoiceSaga from "./invoices/saga"
import contactsSaga from "./contacts/saga";
import BuSaga from "./bu/saga"
import kickoffSaga from "./kickoff/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ResetPasswordSaga),
    fork(ChangePasswordSaga),
    fork(TwoFASaga),
    fork(userProfileTwoFASaga),
    fork(AgencySaga),
    fork(UserSaga),
    fork(ApplicationSaga),
    fork(NetworkSaga),
    fork(BuSaga),
    fork(kickoffSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(invoiceSaga),
    fork(contactsSaga)
  ])
}
