import React, { useEffect, useState } from 'react';
import { CKEditor } from 'ckeditor4-react';
import { Controller } from "react-hook-form";

const Editor = (props) => {
    const { name, errorobj, control, setValue, details, setSectionErrors } = props

    const [showEditor, setloadEditor] = useState(false);

    let errorMessage = "";

    if (errorobj && Object.prototype.hasOwnProperty.call(errorobj, name)) {
        errorMessage = errorobj[name]?.message;
    }

    useEffect(() => {
        if (errorMessage) {
            setSectionErrors(name, errorMessage)
        } else {
            setSectionErrors(name, '')
        }
    }, [errorMessage])

    useEffect(() => {
        if (details) {
            setValue(`${name}`, details?.[name] || '');
        }
    }, [details, name])

    useEffect(() => {
        setTimeout(() => {
            setloadEditor(true)
        }, [1000])
    }, [])

    return (
        <div>
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    return (
                        <React.Fragment>
                            {/* {(!isEditMode || field?.value) && */}
                            {showEditor &&
                                <CKEditor
                                    {...field}
                                    name={field?.name}
                                    onChange={(event) => field.onChange(event?.editor?.getData())}
                                    editorUrl="https://cdn.ckeditor.com/4.20.1/full/ckeditor.js"
                                    // initData={sectionData ? sectionData : 'Settings Data'}
                                    initData={field?.value}
                                    type="classic"
                                    onInstanceReady={() => { }}
                                />
                            }
                            {/* } */}
                        </React.Fragment>
                    )
                }}
            />
        </div>
    )
}

export default Editor