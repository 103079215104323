import {
    CHANGE_PASSWORD, CHANGE_PASSWORD_FAIL, CHANGE_PASSWORD_SUCCESS
} from "./actionTypes"

const initialState = {
    isLoading: false
}

const changePassword = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD:
            state = {
                ...state,
                isLoading: true,
            }
            break
        case CHANGE_PASSWORD_SUCCESS:
            state = {
                ...state,
                isLoading: false,
            }
            break
        case CHANGE_PASSWORD_FAIL:
            state = {
                ...state,
                isLoading: false,
            }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export default changePassword
