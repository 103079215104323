import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import RHFAutoCompleteSelect from "../../components/FormControls/RHFAutoCompleteSelect";
import RHFDatePicker from "../../components/FormControls/RHFDatePicker";
import RHFTextField from "../../components/FormControls/RHFTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import RHFButton from "components/FormControls/RHFButton";
import { useDispatch, useSelector } from "react-redux";
import RHFFileUpload from "components/FormControls/RHFFileUpload";
import defaultImage from "../../assets/images/Default-User-Image.jpeg";
import { isModulePermisssion } from "helpers/util";
import { ROLE_PERMISSIONS } from "constants/RolePermissions";
import { SUPPORTED_FORMATS, today } from "constants/Constant";
import { editUser, addUser } from "helpers/BackendHelper";
import { Toast } from "components/Common/Toaster";
import moment from 'moment/moment'
import { getLoginUserDetail } from "store/actions";
import RHFMobileNo from "components/FormControls/RHFMobileNo";

const UserAddEdit = (props) => {
  const { editUserData, companyList, roleList, setRefresh, role, handleToggle, setUserRoleList, allRoleList } = props;
  const isEditMode = editUserData ? true : false;
  const [isApiCalling, setApiCalling] = useState(false);
  const { user } = useSelector(state => state.Login);
  const [isCompanySelected, setIsComapnySelected] = useState(isModulePermisssion(ROLE_PERMISSIONS?.ADD_USER_AGENCY_DROPDOWN) ? false : true)
  const dispatch = useDispatch();
  const [countries, setCountries] = useState('')

  const userSchema = yup.object().shape({
    profile: yup.mixed().nullable()
      .test("type", "Invalid file formate.", function (value) {
        if (!value) {
          return true
        } else
          if (typeof value === "string") {
            if (value?.toLowerCase().includes("jpeg") || value?.toLowerCase().includes("jpg") || value?.toLowerCase().includes("png") || value?.toLowerCase().includes("bmp") || value?.toLowerCase().includes("svg")) {
              return true
            }
          }
          else if (SUPPORTED_FORMATS.includes(value.type)) {
            return true
          } else {
            return false
          }
      }),
    firstName: yup.string()
      .trim()
      .matches(/^[0-9a-zA-Z _-]*$/, 'Please enter valid name')
      .required("First Name is required"),
    lastName: yup.string()
      .trim()
      .matches(/^[0-9a-zA-Z _-]*$/, 'Please enter valid name')
      .required("Last Name is required"),
    companyName: isModulePermisssion(ROLE_PERMISSIONS?.ADD_USER_AGENCY_DROPDOWN) && !isEditMode && yup
      .object()
      .shape({ label: yup.string(), value: yup.string() })
      .nullable()
      .required("Select atleast one option"),
    phone: yup.string().required('Phone Number is required')
      .test("Check country", "Invalid country code", function (value) {
        let contactWithoutSpecialCharacter = value?.match(/\d+/g)?.join("")
        const findCountry = countries.find(country => contactWithoutSpecialCharacter?.startsWith(country?.dialCode))
        if (!findCountry) {
          return false
        } else {
          return true
        }
      })
      .test('Check Number', "Invaild Phone number", function (value) {
        let contactWithoutSpecialCharacter = value?.match(/\d+/g)?.join("")
        const findCountry = countries.find(country => contactWithoutSpecialCharacter?.startsWith(country?.dialCode))
        if (findCountry) {
          const func = (str) => {
            let count = 0
            if (str !== null) {
              for (let i = 0; i < str.length; i++)
                if (str[i] === ".") {
                  count++
                }
              return count
            }
          }
          let stringLength = func(findCountry?.format)
          if (contactWithoutSpecialCharacter?.length < stringLength) {
            return false
          } else {
            return true
          }
        }
      }),
    role: !isEditMode && yup
      .object()
      .shape({ label: yup.string(), value: yup.string() })
      .nullable()
      .required("Select atleast one option"),
    email: yup.string()
      .trim()
      .email()
      .max(150)
      .required("Email is required"),
    startDate: yup.date()
      .typeError("Date is required").test("startDate", "Start Date should be today's date or later.", function (value) {
        if (isEditMode) {
          if (moment(editUserData?.startDate).diff(today, 'days') <= 0) {
            return true
          } else if ((moment(value).diff(today, 'days') >= 0)) {
            return true
          }
        } else if (moment(value).diff(today, 'days') >= 0) {
          return true
        }
      }),
    endDate: yup.date().nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .test("endDate", "End Date should be greater than or equal to start date", function (value) {
        const { startDate } = this?.parent;
        if (value) {
          if (startDate) {
            if (moment(startDate).diff(value, 'days') <= 0) {
              if (moment(value).diff(today, 'days') >= 0) {
                return true
              }
            }
          } else {
            return false
          }
        } else {
          return true
        }
      }),
  })

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(userSchema),
  });

  useEffect(() => {
    if (isEditMode) {

      const selectedCompany = companyList?.find(o => o.value === editUserData?.companyId)
      const companyType = selectedCompany?.companyType

      if (companyType) {
        setIsComapnySelected(true)
      } else {
        setValue("role", "")
      }

      const roleData = [...role];
      const filterRoles = roleData.reduce((filtered, role) => {
        if (role.isAvailable.indexOf(companyType) > -1) {
          filtered.push({ value: role?.roleSlug, label: role?.roleName });
        }
        return filtered;
      }, []);
      setUserRoleList(filterRoles);
    }
  }, [])

  useEffect(() => {
    if (isEditMode) {
      setValue('profile', editUserData?.profile)
      setValue('firstName', editUserData?.firstName)
      setValue('lastName', editUserData?.lastName)
      setValue('email', editUserData?.email)
      setValue('startDate', editUserData?.startDate)
      setValue('endDate', editUserData?.endDate)
      setValue('phone', editUserData?.phone)
      setValue('companyName', companyList?.find(o => o.value === editUserData?.companyId))
      setValue('role', allRoleList?.find(o => o.value === editUserData?.roleId))
    }
    else {
      setValue(null)
    }
  }, [editUserData]);

  const onSubmit = async (data) => {
    const End_Date = moment(data?.endDate).format('YYYY-MM-DD')
    setApiCalling(true);
    if (!isApiCalling) {
      const formData = new FormData();
      formData.append('firstName', data?.firstName);
      formData.append('lastName', data?.lastName);
      formData.append("email", data?.email);
      formData.append("phone", data?.phone);
      formData.append("companyId", data?.companyName?.value);
      formData.append("roleId", data?.role?.value);
      formData.append("startDate", data?.startDate);
      formData.append('endDate', End_Date === "Invalid date" ? null : End_Date);
      formData.append('profile', data?.profile);
      isEditMode && formData.append('userId', editUserData?.id)
      const payload = formData
      if (isEditMode) {
        editUser(payload).then(resp => {
          Toast.success(resp?.message)
          if (editUserData.id === user.user.id) {
            dispatch(getLoginUserDetail());
          }
          setRefresh(prevValue => !prevValue)
          reset();
          handleToggle();
          setApiCalling(false);
        }).catch(() => {
          setApiCalling(false);
        });
      } else {
        addUser(payload).then(resp => {
          Toast.success(resp?.message)
          setRefresh(prevValue => !prevValue)
          reset();
          handleToggle();
          setApiCalling(false);
        }).catch(() => {
          setApiCalling(false);
        });
      }
    }
  };

  const handleOnChange = (data) => {
    const companyType = data?.companyType;
    if (companyType) {
      setIsComapnySelected(true)
    } else {
      setValue("role", "")
    }
    const roleData = [...role];
    const filterRoles = roleData.reduce((filtered, role) => {
      if (role.isAvailable.indexOf(companyType) > -1) {
        filtered.push({ value: role?.roleSlug, label: role?.roleName });
      }
      return filtered;
    }, []);
    setUserRoleList(filterRoles);
  }

  const getFileData = (file) => {
    setValue("profile", file?.file)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex mb-3 justify-content-center">
        <div className="">
          <RHFFileUpload
            name="profile"
            defaultImage={editUserData?.profile ? editUserData?.profile : defaultImage}
            errorobj={errors}
            setValue={setValue}
            getFileData={getFileData}
            setError={setError}
            clearErrors={clearErrors}
            isValidate={true}
            control={control}
          />
        </div>
      </div>
      <Row className="mb-3 mt-5">
        <Col sm="6">
          <RHFTextField
            id="firstName"
            label="First Name"
            name="firstName"
            errorobj={errors}
            control={control}
            isController={true}
          />
        </Col>
        <Col sm="6">
          <RHFTextField
            id="lastName"
            label="Last Name"
            name="lastName"
            errorobj={errors}
            control={control}
            isController={true}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col sm="6">
          <RHFTextField
            id="email"
            label="Email"
            name="email"
            errorobj={errors}
            control={control}
            isController={true}
            disabledField={isEditMode ? true : false}
          />
        </Col>

        <Col sm="6">
          <RHFMobileNo
            id="phone"
            label="Mobile Number"
            name="phone"
            errorobj={errors}
            control={control}
            isController={true}
            setCountries={setCountries}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        {isModulePermisssion(ROLE_PERMISSIONS?.ADD_USER_AGENCY_DROPDOWN) && <Col sm="6">
          <RHFAutoCompleteSelect
            id="companyName"
            label="Company/Agency"
            name="companyName"
            options={companyList && companyList}
            isMultiple={false}
            errorobj={errors}
            control={control}
            isController={true}
            disabledField={isEditMode ? true : false}
            handleOnChange={handleOnChange}
          />
        </Col>}
        <Col sm="6">
          {/* csm_manager   oam_manager*/}
          <RHFAutoCompleteSelect
            id="role"
            label="Role"
            name="role"
            options={isCompanySelected ? roleList : []}
            isMultiple={false}
            errorobj={errors}
            control={control}
            isController={true}
            disabledField={user?.user?.roleId === 'oam_manager' ?
              (user?.user?.roleId !== 'oam_manager' && isEditMode ? true : false) :
              (user?.user?.roleId !== 'csm_manager' && isEditMode ? true : false)}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col sm="6">
          <RHFDatePicker
            name="startDate"
            label="Start Date"
            errorobj={errors}
            control={control}
            isController={true}
            disabledField={editUserData && moment(editUserData?.startDate).diff(today, 'days') <= 0 && true}
            defaultValue={!isEditMode && moment(new Date()).format('YYYY-MM-DD')}
          />
        </Col>
        <Col sm="6">
          <RHFDatePicker
            name="endDate"
            label="End Date"
            errorobj={errors}
            control={control}
            isController={true}
            isRequired={false}
          />
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <RHFButton
          btnName="Cancel"
          outline={true}
          onClick={handleToggle}
          className="mx-2"
        />
        <RHFButton
          btnName="Submit"
          type="submit"
          isLoading={isApiCalling}
        />
      </div>
    </form>
  );
};

export default UserAddEdit;
