import { Toast } from 'components/Common/Toaster';
import RHFButton from 'components/FormControls/RHFButton';
import DialogBox from 'components/Modals/DialogBox';
import Table from 'components/Tables/Table';
import { USER_ROLES } from 'constants/Constant';
import { ROLE_PERMISSIONS } from 'constants/RolePermissions';
import { approveRejectUser } from 'helpers/BackendHelper';
import { USER } from 'helpers/Services/User';
import { isModulePermisssion } from 'helpers/util';
import React, { useState } from 'react'
import { Card, CardBody } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import defaultImage from "../../assets/images/Default-User-Image.jpeg";

const ApproveRejectUsers = () => {

    const [isRefresh, setRefresh] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isApiCalling, setApiCalling] = useState(false);
    const [isUserStatus, setIsUserStatus] = useState('pending')
    const [pendingUserId, setPendingUserId] = useState(null)

    const handleToggle = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleApproveRejectUser = () => {
        setApiCalling(true)
        if (!isApiCalling) {
            const payload = {
                status: isUserStatus,
                userId: pendingUserId
            }
            approveRejectUser(payload).then((resp) => {
                Toast.success(resp?.message)
                setApiCalling(false)
                setRefresh(prevValue => !prevValue)
            }).catch(() => {
                setApiCalling(false)
            })
        }
        handleToggle()
    }

    const columns = [
        {
            name: "Full Name",
            cell: (row) => (
                <div className="d-flex">
                    <img
                        src={row?.profile ? row?.profile : defaultImage}
                        className="rounded-circle me-2"
                        width="35px"
                        height="35px"
                    />
                    <span
                        className="align-self-center"

                    >
                        {row?.firstName} {row?.lastName}
                    </span>
                </div>
            ),
            isVisible: true,
            minWidth: "130px",
            width: "200px"
        },
        {
            name: "Email",
            cell: (row) => <span>{row?.email}</span>,
            isVisible: true,
            minWidth: "130px"
        },
        {
            name: "Role",
            cell: (row) => <span>{USER_ROLES[row?.roleId]}</span>,
            isVisible: true,
            minWidth: "130px",
            width: "170px"
        },

        {
            name: "Agency/Company",
            cell: (row) => <span>{row?.company?.companyName}</span>,
            isVisible: true,
            width: "170px",
            minWidth: "130px"
        },
        {
            name: "Start Date",
            cell: (row) => <span>{row?.startDate}</span>,
            isVisible: true,
            width: "130px",
            minWidth: "100px"

        },
        {
            name: "End Date",
            cell: (row) => <span>{row?.endDate ? row?.endDate : '-'}</span>,
            isVisible: true,
            width: "130px",
            minWidth: "100px"
        },
        {
            name: "Actions",
            minWidth: "130px",
            width: "160px",
            isVisible: true,
            cell: (row) => (
                <div className='d-flex'>
                    <RHFButton
                        className="me-1"
                        outline={true}
                        size="sm"
                        btnName="Approve"
                        type="button"
                        onClick={() => {
                            setPendingUserId(row?.id)
                            setIsUserStatus("approved")
                            handleToggle()
                        }}
                    // color="success"
                    />
                    <RHFButton
                        outline={true}
                        btnName="Reject"
                        size="sm"
                        type="button"
                        onClick={() => {
                            setPendingUserId(row?.id)
                            setIsUserStatus("rejected")
                            handleToggle()
                        }}
                    // color="danger"
                    />
                </div>
            )
        },
    ];

    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/approve-reject-users-list", heading: "User list" }]


    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Approve/Reject Users" breadcrumbItem={breadcrumbItems} />
                <Card>
                    {isModulePermisssion(ROLE_PERMISSIONS?.APPROVE_REJECT_USER) &&
                        <CardBody className="table-responsive">
                            <Table
                                columns={columns}
                                dataURL={USER.listAllPendingUsers}
                                isRefresh={isRefresh}
                            />
                        </CardBody>
                    }
                </Card>
            </div>

            <DialogBox
                isModelOpen={isModalOpen}
                handleToggle={handleToggle}
                modelSize="sm-100"
                title="Confirmation"
                actions={
                    <>
                        <RHFButton
                            btnName="Cancel"
                            outline={true}
                            onClick={handleToggle}
                        />
                        <RHFButton
                            btnName="Okay"
                            onClick={handleApproveRejectUser}
                            isLoading={isApiCalling}
                        />
                    </>
                }
            >
                <div className="text-center">
                    <i className="mdi mdi-alert-circle-outline" style={{ fontSize: "5em", color: "orange" }} />
                    <h5>Are you sure?</h5>
                    <p className="fs-5">You won't be able to revert this!</p>
                </div>
            </DialogBox>

        </React.Fragment >
    )
}

export default ApproveRejectUsers
