import React, { useEffect, useState } from 'react'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Col, Row } from 'reactstrap';
import RHFTextField from 'components/FormControls/RHFTextField';
import RHFDropZone from 'components/FormControls/RHFDropZone';
import RHFButton from 'components/FormControls/RHFButton';
import { editPoc, pocImageUpload } from 'helpers/BackendHelper';
import { urlRegex } from 'constants/Constant';

const EditablePoc = (props) => {

    const { editPocData, pocStepData, setPocStepData, setEditPocData } = props;
    const [imageFiles, setImageFiles] = useState([])
    const isEditMode = editPocData ? true : false;
    const [isApiCalling, setApiCalling] = useState(false);
    const [isPOCloading, setIsPOCloading] = useState(false);

    const PocStepsSchema = yup.object().shape({
        description: yup.string().trim().required("Description is required"),
        images: yup.array().min(1, 'Pocs are required'),
        url: yup.string().trim().transform((curr, orig) => orig === '' ? null : curr).nullable().matches(urlRegex, 'Enter correct url')
    });

    const {
        handleSubmit: handleEditpocsteps,
        control: editPocControl,
        setValue: editPocSetvalue,
        formState: { errors: editPocErrors },
    } = useForm({
        defaultValues: { id: new Date().getUTCMilliseconds() },
        mode: "onBlur",
        resolver: yupResolver(
            PocStepsSchema
        ),
    });

    const closeHandler = () => {
        setEditPocData(null)
    }

    const onEditPocSteps = async (data) => {
        const copyData = [...pocStepData];
        const elemIndex = copyData.findIndex((stepData) => stepData.id === editPocData.id);

        const formData = new FormData();
        Object.entries(imageFiles).forEach(([key, value]) => {
            formData.append(`image (${key})`, value)
        });

        var newImages = imageFiles?.length > 0 && imageFiles[0]?.signedUrl ? imageFiles?.map(x => x.url) : copyData[elemIndex]?.images?.map(x => x.url);

        if (imageFiles.length > 0 && !imageFiles[0]?.signedUrl) {
            await pocImageUpload(formData).then(resp => {
                newImages = resp?.data?.map(x => x.url);
                copyData[elemIndex] = { ...data, id: editPocData?.id, images: resp?.data }
            })
        } else {
            copyData[elemIndex] = { ...data }
        }
        setPocStepData(copyData);

        if (editPocData?.findingId) {
            setApiCalling(true);
            if (!isApiCalling) {
                editPoc({ ...data, images: newImages, pocId: editPocData?.id, findingId: editPocData?.findingId }).then(() => {
                    setApiCalling(false);
                }).catch(() => {
                    setApiCalling(false);
                });
            }
        }
        setEditPocData(null)
    };

    useEffect(() => {
        if (isEditMode) {
            editPocSetvalue('description', editPocData?.description);
            editPocSetvalue('url', editPocData?.url);
            editPocSetvalue('parameters', editPocData?.parameters);
            editPocSetvalue('payload', editPocData?.payload);
            editPocSetvalue('images', editPocData?.images);
        }
        else {
            editPocSetvalue(null)
        }
    }, [editPocData]);

    const getFileData = (files) => {
        setImageFiles(files?.files)
    }

    return (
        <div className='mb-3'>
            <Row className="mb-2">
                <Col sm="12">
                    <RHFTextField
                        id="description"
                        label="Description"
                        name="description"
                        errorobj={editPocErrors}
                        control={editPocControl}
                        isController={true}
                    />
                </Col>
            </Row>
            <Row >
                <Col sm="6">
                    <Row className="mb-2">
                        <Col sm="12">
                            <RHFTextField
                                id="url"
                                label="URl"
                                name="url"
                                errorobj={editPocErrors}
                                control={editPocControl}
                                isController={true}
                                isRequired={false}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="12">
                            <RHFTextField
                                id="parameters"
                                label="Parameters"
                                name="parameters"
                                errorobj={editPocErrors}
                                control={editPocControl}
                                isController={true}
                                isRequired={false}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col sm="6">
                    <RHFTextField
                        id="payload"
                        label="Payload"
                        name="payload"
                        multiline={true}
                        rows={5}
                        type="textarea"
                        errorobj={editPocErrors}
                        control={editPocControl}
                        isController={true}
                        isRequired={false}
                    />
                </Col>
            </Row>
            <Row className="mb-2">
                <Col sm="12">
                    <RHFDropZone
                        name="images"
                        label="Upload Images"
                        errorobj={editPocErrors}
                        control={editPocControl}
                        pocSetvalue={editPocSetvalue}
                        getFileData={getFileData}
                        editPocData={editPocData ? editPocData : null}
                        multiple={true}
                    />
                </Col>
            </Row>
            <Row>
                <div className="d-flex justify-content-end">
                    <RHFButton
                        btnName="Cancel"
                        outline={true}
                        onClick={closeHandler}
                        className="mx-2"

                    />
                    {!isPOCloading ? <RHFButton
                        btnName="Submit"
                        type="button"
                        onClick={handleEditpocsteps(onEditPocSteps)}
                        isLoading={isEditMode ? isApiCalling : ""}
                    /> : <RHFButton
                        btnName="Loading..."
                        isLoading={isPOCloading}
                    />
                    }
                </div>
            </Row>
            <hr></hr>
        </div>
    )
}

export default EditablePoc
