import { ADD_COMPANY, COMPANY_ON_BOARDING, EDIT_COMPANY, GET_ALL_COMPANY, GET_COMPANY_PROFILE_DETAIL } from "helpers/UrlHelper";
import { API_URL } from "./Services";

const baseURL = API_URL.COMPANY;

export const COMPANY = {
    addCompany: {
        method: 'POST',
        url: ADD_COMPANY,
        baseURL
    },
    editCompany: {
        method: 'PUT',
        url: EDIT_COMPANY,
        baseURL
    },
    listAllCompany: {
        method: 'POST',
        url: GET_ALL_COMPANY,
        baseURL
    },
    onBoardCompany: {
        method: 'POST',
        url: COMPANY_ON_BOARDING,
        baseURL
    },
    getCompanyProfileDetail: {
        method: 'GET',
        url: GET_COMPANY_PROFILE_DETAIL,
        baseURL
    }
}