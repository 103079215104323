
import {
    ADD_APPLICATION,
    DELETE_APPLICATION,
    GET_ALL_APPLICATION,
    UPDATE_APPLICATION,
    ADD_BU,
    UPDATE_BU,
    GET_ALL_BU,
    ACTIVE_DEACTIVE_BU,
    ADD_OR_REMOVE_APPLICATION_USER,
    GET_APPLICATION_DETAILS,
    GET_APPLICATION_SUBMISSION_DONUTCHART_DATA,
    GET_APPLICATION_SCORE_LINECHART_DATA,
    GET_BU_RISK_SUMMARY_LINECHART_DATA,
    UPDATE_APPLICATION_STATUS,
    ADD_REVALIDATE_APPLICATION,
    GET_APPLICATION_LOGS,
    GET_OVERVIEW_CHART_DATA,
    GET_APPLICATION_REPORT,
    GET_APPLICATION_CERTIFICATE,
    GET_APPLICATION_CERTIFICATE_LIST,
    GET_DOWNLOAD_APPLICATION_CERTIFICATE,
} from "helpers/UrlHelper";

import { API_URL } from "./Services";

const baseURL = API_URL.APPLICATION;

export const APPLICATION = {
    addApplication: {
        method: 'POST',
        url: ADD_APPLICATION,
        baseURL
    },
    editApplication: {
        method: 'PUT',
        url: UPDATE_APPLICATION,
        baseURL
    },
    deleteApplication: {
        method: 'DELETE',
        url: DELETE_APPLICATION,
        baseURL
    },
    getApplicationDetails: {
        method: 'GET',
        url: GET_APPLICATION_DETAILS,
        baseURL
    },
    listAllApplication: {
        method: 'POST',
        url: GET_ALL_APPLICATION,
        baseURL
    },
    addRemoveAppUser: {
        method: 'PUT',
        url: ADD_OR_REMOVE_APPLICATION_USER,
        baseURL
    },
    getApplicationSubmissionDonutChartData: {
        method: 'POST',
        url: GET_APPLICATION_SUBMISSION_DONUTCHART_DATA,
        baseURL
    },
    getApplicationScoreLineChartData: {
        method: 'POST',
        url: GET_APPLICATION_SCORE_LINECHART_DATA,
        baseURL
    },
    getBuRiskSummaryLineChartData: {
        method: 'POST',
        url: GET_BU_RISK_SUMMARY_LINECHART_DATA,
        baseURL
    },
    getOverViewChart: {
        method: 'POST',
        url: GET_OVERVIEW_CHART_DATA,
        baseURL
    },
    addBu: {
        method: 'POST',
        url: ADD_BU,
        baseURL: API_URL.COMPANY
    },
    editBu: {
        method: 'PUT',
        url: UPDATE_BU,
        baseURL: API_URL.COMPANY
    },
    listAllBu: {
        method: 'POST',
        url: GET_ALL_BU,
        baseURL: API_URL.COMPANY
    },
    activeDeactivebu: {
        method: 'PUT',
        url: ACTIVE_DEACTIVE_BU,
        baseURL: API_URL.COMPANY
    },
    updateApplicationStatus: {
        method: 'PUT',
        url: UPDATE_APPLICATION_STATUS,
        baseURL
    },
    addRevalidateApplication: {
        method: 'POST',
        url: ADD_REVALIDATE_APPLICATION,
        baseURL
    },
    getApplicationLogs: {
        method: 'GET',
        url: GET_APPLICATION_LOGS,
        baseURL
    },
    getApplicationReport: {
        method: 'GET',
        url: GET_APPLICATION_REPORT,
        baseURL
    },
    getApplicationCertificateReport: {
        method: 'POST',
        url: GET_APPLICATION_CERTIFICATE,
        baseURL
    },
    getApplicationCertificateList: {
        method: 'POST',
        url: GET_APPLICATION_CERTIFICATE_LIST,
        baseURL
    },
    getApplicationCertificateDownload: {
        method: 'GET',
        url: GET_DOWNLOAD_APPLICATION_CERTIFICATE,
        baseURL
    },
}