//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const DELETE_USER = "/delete/user"
export const UPDATE_USER = "/update/user"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"


/** Dm */

/* AUTH Routes */
export const RIGISTER_NEW_USER = "/auth/signup";
export const VERIFY_USER_EMAIL = "/auth/verifyEmail";
export const USER_LOGIN = "/auth/login";
export const RESET_PASSWORD = "/auth/resetPassword";
export const FORGET_PASSWORD = "/auth/resetPasswordEmail";
export const GENERATE_QR_CODE = "/auth/generate2faQrcode";
export const VERIFY_OTP = "/auth/verifyTotp";
export const VERIFY_TANENT = "/auth/verifyTenant";
export const VELIDATE_TANENT = "/auth/validateTenant";

/* USER Profile */
export const VERIFY_UPDATED_OTP = "/auth/update2fa";
export const CHANGE_PASSWORD = "/auth/updatePassword";

/* Company Routes */
export const COMPANY_ON_BOARDING = "/company/register";
export const ADD_COMPANY = "/company/add";
export const EDIT_COMPANY = "/company/edit";
export const GET_ALL_COMPANY = "/company/list";
export const GET_COMPANY_PROFILE_DETAIL = '/company/details'


/* Role Permission Routes */
export const GET_ROLE_PERMISSIONS = '/roles/getUserPermissions'
export const GET_ROLE = '/roles'


/* Users Routes */
export const ADD_USER = '/user/add'
export const USER_UPDATE = '/user/edit'
export const GET_ALL_USER = '/user/list'
export const GET_USER_BY_ROLE = '/user/getUserByRole'
export const ACTIVE_DEACTIVE_USER = '/user/updateStatus'
export const GET_LOGIN_USER_DETAILS = '/user/me'
export const USER_PROFILE_UPLOAD = '/user/getProfileUrl'
export const GET_SIGNED_URL = '/user/getSignedUrl'
export const GET_USER_TEAM = '/user/getteam'
export const GET_APP_MEMBER_BY_TYPE = '/user/getMembersByType'
export const GET_USER_DETAIL = '/user/detail'
export const GET_ALL_PENDING_USERS = '/user/pending/list'
export const APPROVE_REJECT_USER = '/user/approveReject'


/* BU Routes */

export const ADD_BU = '/company/bu/add'
export const UPDATE_BU = '/company/bu/edit'
export const GET_ALL_BU = '/company/bu/list'
export const ACTIVE_DEACTIVE_BU = '/company/bu/updateStatus'


/* Kick off Routes*/

export const ADD_KICF_OFF = '/kickoff/add'
export const UPDATE_KICK_OFF = '/kickoff/edit'
export const GET_KICKOFF_DETAIL = '/kickoff/'

/* Applications Routes */
export const ADD_APPLICATION = '/application/add'
export const UPDATE_APPLICATION = '/application/edit'
export const DELETE_APPLICATION = '/application/delete'
export const GET_ALL_APPLICATION = '/application/list'
export const ADD_OR_REMOVE_APPLICATION_USER = '/application/assignTeam'
export const GET_APPLICATION_DETAILS = '/application'
export const GET_APPLICATION_SUBMISSION_DONUTCHART_DATA = '/application/submissionChart'
export const GET_APPLICATION_SCORE_LINECHART_DATA = '/application/scoreChart'
export const GET_BU_RISK_SUMMARY_LINECHART_DATA = '/application/riskSummaryChart'
export const UPDATE_APPLICATION_STATUS = '/application/updateStatus'
export const ADD_REVALIDATE_APPLICATION = '/application/revalidation/add'
export const GET_APPLICATION_LOGS = '/application/logs'
export const GET_OVERVIEW_CHART_DATA = '/application/overviewChart'
export const GET_APPLICATION_REPORT = '/application/getApplicationReport'
export const GET_APPLICATION_CERTIFICATE = '/application/certificateData'
export const GET_APPLICATION_CERTIFICATE_LIST = '/application/certificates'
export const GET_DOWNLOAD_APPLICATION_CERTIFICATE = '/application/certificate/download'


/* Finding Routes */
export const GET_ALL_CWE = '/finding/cwe/list'
export const GET_ALL_CVE = '/finding/cve/list'
export const GET_ALL_CVE_FROM_CWE = '/finding/cwe/cve/list'
export const GET_ALL_OWASP = '/finding/owasp/list'
export const GET_CVE_DETAILS = '/finding/cve'
export const GET_OWASP_DETAILS = '/finding/owasp'
export const GET_ALL_FINDING = '/finding/list'

export const ADD_FINDING = '/finding/add'
export const UPDATE_FINDING = '/finding/edit'
export const DELETE_FINDING = '/finding/delete'
export const GET_FINDING_DETAILS = '/finding/details'

export const CREATE_POC = '/finding/poc/add'
export const UPDATE_POC = '/finding/poc/edit'
export const DELETE_POC = '/finding/poc/delete'
export const POC_IMAGE_UPLOAD = '/finding/poc/upload'
export const GET_ALL_SUBMISSIONS = '/finding/submissions'
export const GET_SUBMISSIONS_CHARTS = '/finding/submissions/charts'

export const ADD_COMMENT = '/finding/comment/add'
export const APPROVE_REJECT_EXCEPTION = '/finding/comment/exception/edit'
export const GET_FINDING_LOGS = '/finding/logs'
export const GET_CWE_TRENDS_BAR_CHART = '/finding/chart/cweTrends'
export const GET_OWASP_WEB_BAR_CHART = '/finding/chart/webOwasp'
export const GET_OWASP_API_BAR_CHART = '/finding/chart/apiOwasp'
export const GET_OWASP_MOBILE_BAR_CHART = '/finding/chart/mobileOwasp'
/* Network Routes */

export const ADD_NETWORK = '/network/add'
export const GET_ALL_NETWORK = '/network/list'
export const GET_NETWORK_SUBMISSION_DONUT_CHART_DATA = '/network/submissionChart'
export const GET_NETWORK_SCORE_LINECHART_DATA = '/network/scoreChart'
export const GET_NETWORK_LOGS = '/network/logs'
export const GET_OS_SUMMARY_CHART = '/network/osesSummary'
export const GET_CVE_TRENDS_CHART = '/network/topTenCve'

/* Network Finding Routes */

export const GET_All_NETWORK_FINDING = '/network/finding/list'
export const UPDATE_NETWORK_FINDING = '/network/finding/edit'
export const GET_NETWORK_FINDING_DETAILS = '/network/finding'
export const ADD_OR_REMOVE_NETWORK_USER = '/network/assignTeam'
export const GET_NETWORK_DETAILS = '/network/details'
export const ADD_NETWORK_FINDING = '/network/findings/add'
export const GET_NETWORK_FINDING_STATUS = '/network/finding/status'
export const GET_NETWORK_FINDING_LOGS = '/network/finding/logs'


/* Template Routes */
export const ADD_TEMPLATE = '/template/add'
export const UPDATE_TEMPLATE = '/template/edit'
export const GET_ALL_TEMPLATE = '/template/list'
export const DELETE_TEMPLATE = '/template/delete'
export const GET_TEMPLATE_DETAILS = '/template/detail'
export const DEFAULT_USER = '/template/setDefaultTemplate'
export const GENERATE_APPLICATION_REPORT = '/template/generateReport'


export const ADD_CERTIFICATE_TEMPLATE = '/certificate/add'
export const UPDATE_CERTIFICATE_TEMPLATE = '/certificate/edit'
export const DELETE_CERTIFICATE_TEMPLATE = '/certificate/delete'
export const GET_ALL_CERTIFICATE_TEMPLATE = '/certificate/list'
export const GET_CERTIFICATE_TEMPLATE_DETAILS = '/certificate/detail'
export const GENERATE_CERTIFICATE = '/certificate/generateReport'


/** Susbcription */
export const GET_SUBSCRIPTION_LIST = '/subscription/plan/list'
export const GET_CURRENT_SUBSCRIPTION = '/subscription/plan/activeplan'
export const UPDATE_SUBSCRIPTION_PLAN = '/subscription/plan/choose'


