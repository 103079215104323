import React, { useState, useEffect, useCallback } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import Table from 'components/Tables/Table';
import RHFTextField from 'components/FormControls/RHFTextField';
import ActionButtons from 'components/FormControls/ActionButtons';
import RHFButton from 'components/FormControls/RHFButton';

import ManageColumns from 'components/Common/ManageColumns';
import TextChip from 'components/Common/TextChip';
import { useHistory, useParams } from 'react-router-dom';
import { applicationScore, isModulePermisssion, TitleCase } from 'helpers/util';
import { ROLE_PERMISSIONS } from 'constants/RolePermissions';
import { Finding } from 'helpers/Services/Finding';
import { FINDING_SCORE_COLORS, CVSS, CVSS_TEXT_COLOR, FINDING_STATUS, FINDING_STATUS_COLOR, FINDING_STATUS_OVERVIEW, STATUS_COLOR } from 'constants/Constant';
import DialogBox from 'components/Modals/DialogBox';
import { deleteFinding } from 'helpers/BackendHelper';
import { Toast } from 'components/Common/Toaster';
import { useSelector } from 'react-redux';

const FindingPage = ({ applicationData }) => {
    // document.title = "Application-Overview | RiDiscovery";

    const history = useHistory();
    const { applicationId } = useParams();
    const [activeRow, setActiveRow] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [isRefresh, setRefresh] = useState(false);
    const [filterColumns, setFilterColumns] = useState([]);
    const [columnOptions, setColumnOption] = useState([]);
    const [isApiCalling, setApiCalling] = useState(false);

    const { user } = useSelector(state => state?.Login);

    const [filterFields, setFilterFields] = useState({
        'applicationId': applicationId,
        'search': ''
    });

    useEffect(() => {
        const columnFilter = [...columns];
        setColumnOption([...columns]);
        setFilterColumns(columnFilter.filter(o => o.isVisible && o));
    }, [applicationData])

    const getFilteredValues = (cols) => {
        setColumnOption(cols);
        setFilterColumns(cols.filter(o => o.isVisible && o));
    }

    const getCols = (cols) => {
        setColumnOption(cols);
    }

    const setFilterValues = (field) => {
        setFilterFields(prev => {
            return {
                ...prev,
                ...field
            }
        })
    }

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };

    const optimizedFn = useCallback(debounce((val) => setFilterValues({ search: val })), []);

    const previewHandler = (obj) => {
        history.push({ pathname: `/application/${applicationId}/finding/${obj?.id}/overview` })
    };


    const handleToggle = () => {
        setIsModelOpen(!isModelOpen);
    };

    const deleteHandler = (obj) => {
        setActiveRow(obj);
        handleToggle(true);
    };

    const handleDelete = () => {
        setApiCalling(true)
        if (!isApiCalling) {
            const payload = {
                "isDeleted": true,
                "findingId": activeRow?.id
            }
            deleteFinding(payload).then(resp => {
                setApiCalling(false)
                Toast.success(resp?.message)
                handleToggle();
                setRefresh(prevValue => !prevValue)
            }).catch(() => {
                setApiCalling(false);
            });
        }
    }

    const editHandler = (obj) => {
        history.push({ pathname: `/application/${applicationId}/edit-finding/${obj?.id}`, state: { editFindingData: obj, appData: applicationData } })
    };

    const handleActionClick = (payload, actionType) => {
        const actionMapper = {
            preview: previewHandler,
            edit: editHandler,
            delete: deleteHandler,
        };

        if (actionMapper[actionType]) {
            actionMapper[actionType](payload);
        }
    };

    const columns = [
        {
            id: 'id', //  @DM  - its required when sorting is true @DM
            width: '65px',
            name: "Id",

            cell: (row) =>
                row?.isEditable && !row?.status ?
                    <span>{row?.id}</span> :
                    <div className="table-cell-click" onClick={() => previewHandler(row)}>
                        <span>{row?.id}</span>
                    </div>,
            isVisible: true,
        },
        {
            id: 'title',
            name: "Title",
            minWidth: '15%',
            cell: (row) =>
                row?.isEditable && !row?.status ?
                    <span>{row?.findingTitle}</span> :
                    <div className="table-cell-click" onClick={() => previewHandler(row)}>
                        <span>{row?.findingTitle}</span>
                    </div >,
            isVisible: true,
        },
        {
            id: 'cweCategory',
            name: "CWE Category",
            minWidth: '20%',
            cell: (row) => <span>{row?.cwes[0]?.name || '-'}</span>,
            isVisible: true,
        },
        {
            id: 'severity',
            name: "Severity",
            width: '125px',
            cell: (row) =>
                <TextChip
                    text={row?.cvss ? row?.cvssName : applicationScore(+row?.severity) || "-"}
                    color={row?.cvss ? CVSS[row?.cvssName] : FINDING_SCORE_COLORS[(+row?.severity).toFixed(1)]}
                    textColor={row?.cvss ? CVSS_TEXT_COLOR[row?.cvssName] : CVSS_TEXT_COLOR[applicationScore(+row?.severity)]}
                />,
            isVisible: true,
        },
        {
            id: 'score',
            name: "Score",
            width: '80px',
            cell: (row) => <TextChip
                text={row.cvssScore || "-"}
                color={row?.cvss ? CVSS[row?.cvssName] : FINDING_SCORE_COLORS[(+row?.severity).toFixed(1)]}
                textColor={row?.cvss ? CVSS_TEXT_COLOR[row?.cvssName] : CVSS_TEXT_COLOR[applicationScore(+row?.severity)]}
            />,
            isVisible: true,
        },
        {
            id: 'status',
            name: "Status",
            center: "true",
            width: "85px",
            omit: !['oam_manager', 'csm_manager', 'sm_manager', 'executive', 'da_manager', 'developer', 'd_executive', 's_executive'].includes(user?.user?.roleId),
            cell: (row) =>
                <TextChip
                    text={TitleCase(row?.findingStatus)}
                    color='#E9E8EF'
                    // color={row?.findingStatus && FINDING_STATUS_COLOR[FINDING_STATUS?.find(o => o.value === row?.findingStatus)?.label]}
                    textColor={row?.findingStatus && FINDING_STATUS_COLOR[FINDING_STATUS?.find(o => o.value === row?.findingStatus)?.label]}
                />,
            isVisible: true,
        },
        {
            id: 'approveStatus',
            name: "Approval Status",
            width: "170px",
            center: "true",
            omit: !['oam_manager', 'csm_manager', 'sm_manager', 'executive', 'pentester', 's_executive'].includes(user?.user?.roleId),
            cell: (row) =>
                row?.isEditable && !row?.status ?
                    <TextChip
                        text={"Drafted"}
                        color='#E9E8EF'
                        textColor={"#3457D5"}
                    /> :
                    <TextChip
                        text={FINDING_STATUS_OVERVIEW?.find(o => o.value === row?.status)?.label || 'Pending approval'}
                        color='#E9E8EF'
                        textColor={row?.status && STATUS_COLOR[FINDING_STATUS_OVERVIEW?.find(o => o.value === row?.status)?.label] || '#000'}
                    />,
            isVisible: true,
        },
        {
            name: "Actions",
            width: "100px",
            isVisible: true,
            cell: (row) => {
                return (
                    <ActionButtons
                        preview={
                            Boolean(row?.isEditable) === false && {
                                handleClick: () => handleActionClick(row, 'preview'),
                                isPermission: ROLE_PERMISSIONS?.VIEW_FINDING,
                            }}
                        edit={
                            isModulePermisssion(ROLE_PERMISSIONS?.UPDATE_FINDING) && Boolean(row?.isEditable) &&
                            ['open', 'scheduled', 'in_progress'].includes(applicationData?.applicationStatus) && {
                                handleClick: () => handleActionClick(row, 'edit'),
                                // isPermission: ROLE_PERMISSIONS?.UPDATE_FINDING
                            }
                        }
                        delete={
                            isModulePermisssion(ROLE_PERMISSIONS?.DELETE_FINDING) &&
                            ['open', 'scheduled', 'in_progress'].includes(applicationData?.applicationStatus) && !row?.parentFindingId && {
                                handleClick: () => handleActionClick(row, 'delete'),
                                // isPermission: ROLE_PERMISSIONS?.DELETE_FINDING,
                            }}
                    />
                );
            },
        }
    ]

    return (
        <React.Fragment>
            <h5 className='mt-2 mb-2'>Finding</h5>
            <Card>
                <CardHeader>
                    <Row>
                        <Col xs={6} lg={3}>
                            <RHFTextField
                                id="search"
                                name="search"
                                placeholder="Search here"
                                isController={false}
                                handleOnChange={optimizedFn}
                            />
                        </Col>
                        <Col xs={6} lg={9}>
                            <div className="d-flex justify-content-end">
                                <div>
                                    <ManageColumns
                                        allColumns={columnOptions}
                                        getCols={getCols}
                                        getFilteredValues={getFilteredValues}
                                    />
                                </div>
                                {isModulePermisssion(ROLE_PERMISSIONS?.ADD_FINDING) && ['open', 'scheduled', 'in_progress'].includes(applicationData?.applicationStatus) &&
                                    <RHFButton
                                        className='ms-2'
                                        btnName="Add Finding"
                                        icon="plus"
                                        onClick={() => {
                                            history.push({ pathname: `/application/${applicationId}/add-finding`, state: { appData: applicationData } })
                                        }}
                                    />
                                }
                            </div>
                        </Col>
                    </Row>
                </CardHeader>
                {isModulePermisssion(ROLE_PERMISSIONS?.VIEW_FINDING) &&
                    <CardBody>
                        <Table
                            columns={filterColumns}
                            dataURL={filterColumns?.length > 0 ? Finding.listAllFinding : ""}
                            isRefresh={isRefresh}
                            filter={filterFields}
                        />
                    </CardBody>
                }
            </Card>
            <DialogBox
                isModelOpen={isModelOpen}
                handleToggle={handleToggle}
                modelSize="sm-100"
                title="Confirmation"
                actions={
                    <>
                        <RHFButton
                            btnName="Cancel"
                            outline={true}
                            onClick={handleToggle}
                        />
                        <RHFButton
                            btnName="Okay"
                            onClick={handleDelete}
                            isLoading={isApiCalling}
                        />
                    </>
                }
            >
                <div className="text-center">
                    <i className="mdi mdi-alert-circle-outline" style={{ fontSize: "5em", color: "orange" }} />
                    <h5>Are you sure?</h5>
                    <p className="fs-5">You won't be able to revert this!</p>
                </div>
            </DialogBox>
        </React.Fragment>
    )
}

export default FindingPage;