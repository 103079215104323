
export const ADD_KICK_OFF = "ADD_KICK_OFF"
export const ADD_KICK_OFF_SUCCESS = "ADD_KICK_OFF_SUCCESS"
export const ADD_KICK_OFF_FAIL = "ADD_KICK_OFF_FAIL"

export const UPDATE_KICK_OFF = "UPDATE_KICK_OFF"
export const UPDATE_KICK_OFF_SUCCESS = "UPDATE_KICK_OFF_SUCCESS"
export const UPDATE_KICK_OFF_FAIL = "UPDATE_KICK_OFF_FAIL"


