import { Toast } from "components/Common/Toaster"
import { verifyUpdatedOTP } from "helpers/BackendHelper"
import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { verifyUpdatedOtpFail, verifyUpdatedOtpSuccess } from "./actions"
import { VERIFY_UPDATED_OTP } from "./actionTypes"

function* verifyUpdated2FAOtp({ payload }) {
    try {
        const response = yield call(verifyUpdatedOTP, payload)
        yield put(verifyUpdatedOtpSuccess(response?.twoFAEnabled))
        Toast.success(response?.message)
    } catch (error) {
        yield put(verifyUpdatedOtpFail(error))
    }
}

export function* watchUpdatedOTP() {
    yield takeEvery(VERIFY_UPDATED_OTP, verifyUpdated2FAOtp)
}

function* userProfileTwoFASaga() {
    yield all([fork(watchUpdatedOTP)])
}

export default userProfileTwoFASaga
