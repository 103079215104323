import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Row, Col, Form } from "reactstrap";
//redux
import { useDispatch } from "react-redux";

import { withRouter, Link, useHistory, useLocation } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { ENV_CONFIG } from "constants/Constant";

// actions
import { loginUserAction } from "../../store/actions";

// import images
import logo from "../../assets/images/RiDiscovery_Icon.png";

//Import config
import RHFTextField from "components/FormControls/RHFTextField";
import RHFButton from "components/FormControls/RHFButton";
import RHFCheckbox from "components/FormControls/RHFCheckbox";
import { signIn } from "helpers/BackendHelper";


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const TanentLogin = (props) => {
  document.title = "Tanent Login | RiDiscovery"
  const [isRemember, setIsRemember] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isApiCalling, setApiCalling] = useState(false);

  const query = useQuery();

  const loginSchema = yup.object().shape({
    email: yup.string().email().max(150).required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    const tenantName = location?.host?.split('.')[0];
    if (location?.port === ENV_CONFIG?.PORT1 || (tenantName === ENV_CONFIG?.APP_TENANT && location?.port !== ENV_CONFIG?.PORT2)) {
      history.push('/login');
    } else {
      if (localStorage.getItem("authUser")) {
        history.push('/9rD4t2gsbEY2ycOnK1oLb0325HTFFTZIm6UnelCoiEgcFvfvN0');
      }
    }
  }, []);

  const handleCheckboxChange = (val) => {
    setIsRemember(val);
    setValue("isRemember", val);
  };

  const onLogin = (values) => {
    const payload = { ...values, isRemember };

    setApiCalling(true);
    if (!isApiCalling) {
      // dispatch(loginUserAction(payload, props.history));
      signIn(payload).then(resp => {
        setApiCalling(false);
        dispatch(loginUserAction(resp, props.history));
      }).catch(() => {
        setApiCalling(false);
      });
    }

  };

  return (
    <React.Fragment>
      <div className="auth-page">

        <Row className="g-0">
          <Col lg={4} md={5} className="col-xxl-3">
            <div className="auth-full-page-content d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5 text-center">
                    <span className="d-block auth-logo">
                      <img src={logo} alt="" height="35" />{" "}
                      <span className="logo-txt">RiDiscovery</span>
                    </span >
                  </div>
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      <h3 className="mb-0">Welcome Back !</h3>
                      <p className="text-muted mt-2">
                        Sign in to continue to RiDiscovery
                      </p>
                    </div>
                    <Form
                      className="custom-form mt-4 pt-2"
                      onSubmit={handleSubmit(onLogin)}
                    >
                      <div className="mb-3">
                        <RHFTextField
                          id="email"
                          label="Email"
                          name="email"
                          defaultValue={query.get('email') ? atob(query.get('email')) : ''}
                          errorobj={errors}
                          control={control}
                          isController={true}
                        />
                      </div>

                      <div className="mb-3">
                        <RHFTextField
                          id="password"
                          label="Password"
                          name="password"
                          type="password"
                          errorobj={errors}
                          control={control}
                          isController={true}
                        />
                      </div>

                      <div className="row mb-0">
                        <div className="col">
                          <div className="d-flex justify-content-between">
                            <div className="form-check">
                              <RHFCheckbox
                                name="isRemember"
                                label="Remember me"
                                checked={isRemember}
                                isController={false}
                                onChange={handleCheckboxChange} // mostly useful when isController === false
                              />
                            </div>
                            <div>
                              <p className="text-muted mb-0">
                                <Link
                                  to="/forgot-password"
                                  className="text-primary fw-semibold"
                                >
                                  Forgot Password ?
                                </Link>{" "}
                              </p>
                            </div>
                          </div>

                          <div className="mt-3 d-grid">
                            <RHFButton btnName="Log In" type="submit" isLoading={isApiCalling} />
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

      </div>
    </React.Fragment >
  );
};

export default withRouter(TanentLogin);
TanentLogin.propTypes = {
  history: PropTypes.object,
};

