import axios from "axios"
import { del, get, makeAPICall, post, put } from "./ApiHelper"
import { APPLICATION } from "./Services/Application"
import { AUTH } from "./Services/Auth"
import { CERTIFICATE_TEMPLATE } from "./Services/CertificateTemplate"
import { COMPANY } from "./Services/Company"
import { Finding } from "./Services/Finding"
import { KICKOFF } from "./Services/Kickoff"
import { NETWORK } from "./Services/Network"
import { ROLE } from "./Services/Role"
import { SUBSCRIPTION } from "./Services/Subscription"
import { TEMPLATE } from "./Services/Template"
import { USER } from "./Services/User"
import * as url from "./UrlHelper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// get contacts
export const getUsers = () => get(url.GET_USERS)

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user)

// update user
export const updateUser = user => put(url.UPDATE_USER, user)

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } })

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS)

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail)

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } })

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS)

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS)

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS)

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS)

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS)

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA)
export const getYearlyData = () => get(url.GET_YEARLY_DATA)
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA)

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS)

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  })
}
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  })
}

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  })
}

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  })
}


/** DM  API Execution */

/* AUTH  */
export const registerNewUser = (data) => makeAPICall({ option: AUTH.registerNewUser, data });
export const verifyUserEmail = (data) => makeAPICall({ option: AUTH.verifyUserEmail, data });
export const resetPassword = (data) => makeAPICall({ option: AUTH.resetPassword, data })
export const forgetPassword = (data) => makeAPICall({ option: AUTH.forgetPassword, data })
export const signIn = (data) => makeAPICall({ option: AUTH.signIn, data });
export const getQRCode = (data) => makeAPICall({ option: AUTH.getQRCode, data });
export const verifyOTP = (data) => makeAPICall({ option: AUTH.verifyOTP, data });
export const verifyTanent = (data) => makeAPICall({ option: AUTH.verifyTanent, data, config: { isAppTenantAPI: true } });
export const validateTanent = (data) => makeAPICall({ option: AUTH.validateTanent, data, config: { isAppTenantAPI: true } });


/* USER Profile */
export const verifyUpdatedOTP = (data) => makeAPICall({ option: AUTH.verifyUpdatedOTP, data });
export const updatePassword = (data) => makeAPICall({ option: AUTH.updatePassword, data });

/* Company */
export const onBoardCompany = (data) => makeAPICall({ option: COMPANY.onBoardCompany, data });
export const addCompany = (data) => makeAPICall({ option: COMPANY.addCompany, data, });
export const editCompany = (data) => makeAPICall({ option: COMPANY.editCompany, data, });
export const listAllCompany = (data) => makeAPICall({ option: COMPANY.listAllCompany, data });
export const getCompanyProfileDetail = (params) => makeAPICall({ option: COMPANY.getCompanyProfileDetail, params });

/* Role Permission */
export const getAllUserPermission = (params) => makeAPICall({ option: AUTH.getUserPermission, params });
export const getAllRole = () => makeAPICall({ option: ROLE.getRoles })

/* DashBoard - Risk-summary*/
export const getBuRiskSummaryLineChartData = (data) => makeAPICall({ option: APPLICATION.getBuRiskSummaryLineChartData, data })
export const getCWETrendsBarChartData = (data) => makeAPICall({ option: Finding.getCweTrendsBarChart, data })
export const getOverViewChartData = (data) => makeAPICall({ option: APPLICATION.getOverViewChart, data })
export const getOwaspWebBarChartData = (data) => makeAPICall({ option: Finding.getOwaspWebBarChart, data })
export const getOwaspApiBarChartData = (data) => makeAPICall({ option: Finding.getOwaspApiBarChart, data })
export const getOwaspMobileBarChartData = (data) => makeAPICall({ option: Finding.getOwaspMobileBarChart, data })

/* DAshboard - Asset-summary */
export const getOSesSummaryChartData = (data) => makeAPICall({ option: NETWORK.getOSesSummaryChart, data })
export const getCVETrendsChartData = (data) => makeAPICall({ option: NETWORK.getCVETrendsChart, data })

/* Users */
export const addUser = (data) => makeAPICall({ option: USER.addUser, data });
export const editUser = (data) => makeAPICall({ option: USER.editUser, data });
export const listAllUser = (data) => makeAPICall({ option: USER.listAllUser, data });
export const activeDeactiveUser = (data) => makeAPICall({ option: USER.activeDeactiveUser, data });
export const getLoginUser = () => makeAPICall({ option: USER.getLoginUser });
export const profileUpload = (data) => makeAPICall({
  option: USER.profileUpload, data, config: {
    headers: { 'Content-Type': 'multipart/form-data' },
  }
});
export const listAllPendingUsers = (data) => makeAPICall({ option: USER.listAllPendingUsers, data })
export const approveRejectUser = (data) => makeAPICall({ option: USER.approveRejectUser, data })

export const getSignedUrl = (data) => makeAPICall({ option: USER.getSignedUrl, data });
export const getUserTeam = (data) => makeAPICall({ option: USER.getUserTeam, data });
export const getAppMemberByType = (data) => makeAPICall({ option: USER.getAppMemberByType, data });
export const getUserDetail = (params) => makeAPICall({ option: USER.getUserDetail, params });
export const getUserListByRole = (data) => makeAPICall({ option: USER.getUserByRole, data });/////


/* BU */
export const addBu = (data) => makeAPICall({ option: APPLICATION.addBu, data });
export const editBu = (data) => makeAPICall({ option: APPLICATION.editBu, data });
export const listAllBu = (data) => makeAPICall({ option: APPLICATION.listAllBu, data });
export const activeDeactivebu = (data) => makeAPICall({ option: APPLICATION.activeDeactivebu, data });

/* Kickoff */
export const addKickoff = (data) => makeAPICall({ option: KICKOFF.addKickoff, data });
export const editKickoff = (data) => makeAPICall({ option: KICKOFF.editKickoff, data });
export const getKickoffDetail = (params) => makeAPICall({ option: KICKOFF.getKickoffDetail, params });

/* Applications */
export const addApplication = (data) => makeAPICall({ option: APPLICATION.addApplication, data });
export const editApplication = (data) => makeAPICall({ option: APPLICATION.editApplication, data });
export const deleteApplication = (data) => makeAPICall({ option: APPLICATION.deleteApplication, data });
export const listAllApplication = (data) => makeAPICall({ option: APPLICATION.listAllApplication, data });
export const addRemoveAppUser = (data) => makeAPICall({ option: APPLICATION.addRemoveAppUser, data });
export const getApplicationDetails = (params) => makeAPICall({ option: APPLICATION.getApplicationDetails, params });
export const updateApplicationStatus = (data) => makeAPICall({ option: APPLICATION.updateApplicationStatus, data });
export const getApplicationSubmissionDonutChartData = (data) => makeAPICall({ option: APPLICATION.getApplicationSubmissionDonutChartData, data })/////
export const getApplicationScoreLineChartData = (data) => makeAPICall({ option: APPLICATION.getApplicationScoreLineChartData, data });
export const addRevalidateApplication = (data) => makeAPICall({ option: APPLICATION.addRevalidateApplication, data });
export const getApplicationLogs = (params) => makeAPICall({ option: APPLICATION.getApplicationLogs, params });
// export const getApplicationReport = (params) => makeAPICall({ option: APPLICATION.getApplicationReport, params });
export const getApplicationReport = (params, token) => makeAPICall({ option: APPLICATION.getApplicationReport, params, config: { headers: { 'x-access-token': token } } });
export const getApplicationCertificateReport = (data, token) => makeAPICall({ option: APPLICATION.getApplicationCertificateReport, data, config: { headers: { 'x-access-token': token } } });
export const getApplicationCertificateList = (data) => makeAPICall({ option: APPLICATION.getApplicationCertificateList, data });
export const getApplicationCertificateDownload = (params) => makeAPICall({ option: APPLICATION.getApplicationCertificateDownload, params, config: { responseType: 'blob' } });


/* Network */
export const addNetwork = (data) => makeAPICall({
  option: NETWORK.addNetwork, data, config: { headers: { 'Content-Type': 'multipart/form-data' }, }
});
export const addRemoveNetworkUser = (data) => makeAPICall({ option: NETWORK.addRemoveNetworkUser, data });
export const listAllNetwork = (data) => makeAPICall({ option: NETWORK.listAllNetwork, data });
export const getNetworkFinding = (params) => makeAPICall({ option: NETWORK.getNetworkFinding, params });
export const editNetworkFindingDropdownData = (data) => makeAPICall({ option: NETWORK.editNetworkFinding, data })
export const getNetworkSubmissionDonutChartData = (data) => makeAPICall({ option: NETWORK.getNetworkSubmissionDonutChartData, data })
export const getNetworkScoreLineChartData = (data) => makeAPICall({ option: NETWORK.getNetworkScoreLineChartData, data })
export const getNetworkDetails = (params) => makeAPICall({ option: NETWORK.getNetworkDetails, params });
export const addNetworkFinding = (data) => makeAPICall({
  option: NETWORK.addNetworkFinding, data, config: { headers: { 'Content-Type': 'multipart/form-data' }, }
});
export const getNetworkFindingStatus = () => makeAPICall({ option: NETWORK.getNetworkFindingStatus });
export const getNetworkLogs = (params) => makeAPICall({ option: NETWORK.getNetworkLogs, params });
export const getNetworkFindingLogs = (params) => makeAPICall({ option: NETWORK.getNetworkFindingLogs, params });


/* Findings */
export const addFinding = (data) => makeAPICall({ option: Finding.addFinding, data });
export const editFinding = (data) => makeAPICall({ option: Finding.editFinding, data });
export const deleteFinding = (data) => makeAPICall({ option: Finding.deleteFinding, data });
export const getFinding = (params) => makeAPICall({ option: Finding.getFinding, params });
export const getAllCWEList = () => makeAPICall({ option: Finding?.getAllCWE })
export const getAllCVEList = () => makeAPICall({ option: Finding?.getAllCVE })
export const getAllOWASP = () => makeAPICall({ option: Finding?.getAllOWASP })
export const getAllCVEListFromCWE = (data) => makeAPICall({ option: Finding?.getAllCVEFromCWE, data })
export const getCVEDetails = (params) => makeAPICall({ option: Finding?.getCVEDetails, params })
export const getOWASPDetails = (params) => makeAPICall({ option: Finding?.getOWASPDetails, params })
export const getAllSubmissions = (data) => makeAPICall({ option: Finding?.listAllSubmissions, data })
export const getSubmissionsCharts = (data) => makeAPICall({ option: Finding?.getSubmissionsCharts, data })

export const createPoc = (data) => makeAPICall({ option: Finding.createPoc, data });
export const editPoc = (data) => makeAPICall({ option: Finding.editPoc, data });
export const deletePoc = (data) => makeAPICall({ option: Finding.deletePoc, data });
export const pocImageUpload = (data) => makeAPICall({
  option: Finding.pocImageUpload, data,
  config: {
    headers: { 'Content-Type': 'multipart/form-data' },
  }
});

export const addComment = (data) => makeAPICall({ option: Finding.addComment, data });
export const approveRejectException = (data) => makeAPICall({ option: Finding.approveRejectException, data });

export const getFindingLogs = (params) => makeAPICall({ option: Finding.getFindingLogs, params });


/* Templates */
export const addTemplate = (data) => makeAPICall({ option: TEMPLATE.addTemplate, data, config: { headers: { 'Content-Type': 'multipart/form-data' } } });
export const editTemplate = (data) => makeAPICall({ option: TEMPLATE.editTemplate, data });
export const deleteTemplate = (data) => makeAPICall({ option: TEMPLATE.deleteTemplate, data });
export const listAllTemplate = (data) => makeAPICall({ option: TEMPLATE.listAllTemplates, data });
export const getTemplateDetails = (params) => makeAPICall({ option: TEMPLATE.getTemplateDetails, params });
export const templateDefaultUser = (data) => makeAPICall({ option: TEMPLATE.defaultUser, data });
export const generateApplicationReport = (data) => makeAPICall({ option: TEMPLATE.generateApplicationReport, data, config: { responseType: 'blob' } });


export const addCertificateTemplate = (data) => makeAPICall({ option: CERTIFICATE_TEMPLATE.addTemplate, data, config: { headers: { 'Content-Type': 'multipart/form-data' } } });
export const editCertificateTemplate = (data) => makeAPICall({ option: CERTIFICATE_TEMPLATE.editTemplate, data });
export const deleteCertificateTemplate = (data) => makeAPICall({ option: CERTIFICATE_TEMPLATE.deleteTemplate, data });
export const listAllCertificateTemplate = (data) => makeAPICall({ option: CERTIFICATE_TEMPLATE.listAllTemplates, data });
export const getCertificateTemplateDetails = (params) => makeAPICall({ option: CERTIFICATE_TEMPLATE.getTemplateDetails, params });
export const generateCertificate = (data) => makeAPICall({ option: CERTIFICATE_TEMPLATE.generateCertificate, data });

/** Subscription */
export const getAllSubscriptions = (data) => makeAPICall({ option: SUBSCRIPTION.getAllSubscriptions, data, config: { isAppTenantAPI: true } });
export const getCurrentSubscription = (data) => makeAPICall({ option: SUBSCRIPTION.getCurrentSubscription, data });
export const updateSubscriptionplan = (data) => makeAPICall({ option: SUBSCRIPTION.updateSubscriptionplan, data });



export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
}
