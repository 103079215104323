
export const ADD_BU = "ADD_BU"
export const ADD_BU_SUCCESS = "ADD_BU_SUCCESS"
export const ADD_BU_FAIL = "ADD_BU_FAIL"

export const UPDATE_BU = "UPDATE_BU"
export const UPDATE_BU_SUCCESS = "UPDATE_BU_SUCCESS"
export const UPDATE_BU_FAIL = "UPDATE_BU_FAIL"

export const GET_ALL_BU = "GET_ALL_BU"
export const GET_ALL_BU_SUCCESS = "GET_ALL_BU_SUCCESS"
export const GET_ALL_BU_FAIL = "GET_ALL_BU_FAIL"


export const ACTIVE_DEACTIVE_BU = "ACTIVE_DEACTIVE_BU"
export const ACTIVE_DEACTIVE_BU_SUCCESS = "ACTIVE_DEACTIVE_BU_SUCCESS"
export const ACTIVE_DEACTIVE_BU_FAIL = "ACTIVE_DEACTIVE_BU_FAIL"
