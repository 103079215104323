import React, { Fragment } from "react";
import { Controller } from "react-hook-form";
import { FormFeedback, Label } from "reactstrap";
import PhoneInput from 'react-phone-input-2'
import RHFDisableField from "./RHFDisableField";
import 'react-phone-input-2/lib/style.css'

const RHFMobileNo = (props) => {
  const {
    name,
    setCountries,
    label,
    errorobj,
    isController,
    control,
    onChange,
    defaultValue,
    handleOnChange,
    isRequired = true,
    disabledField = false,
    bsSize = "md",
    ...extraProps
  } = props;

  if (!isController) {
    return (
      <Fragment>
        <div className={label && "mb-2"}>
          {label && (
            <Label htmlFor="example-text-input" className="form-label">
              {label}
            </Label>
          )}
          {!disabledField ?
            <PhoneInput
              {...props}
              country={"in"}
              excludeCountries={['us', 'kz']}
              onChange={(phone) => {
                if (handleOnChange) {
                  handleOnChange(phone, name);
                }
              }}
            />
            : <RHFDisableField fieldValue={defaultValue}></RHFDisableField>
          }
        </div>
      </Fragment>
    );
  }

  let isError = false;
  let errorMessage = "";
  let someValue = "";

  if (errorobj && Object.prototype.hasOwnProperty.call(errorobj, name)) {
    isError = true;
    errorMessage = errorobj[name]?.message;
  }


  if (defaultValue !== undefined) {
    someValue = defaultValue;
  }

  const borderStyle = errorMessage ? { borderColor: "#fd625e" } : null
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={someValue}
      render={({ field }) => (
        <Fragment>
          {label && <Label htmlFor="example-text-input" className="form-label">
            {label} {isRequired && <span className="text-danger">*</span>}
          </Label>
          }
          {!disabledField ?
            <Fragment>
              <PhoneInput
                {...field}
                {...extraProps}
                defaultMask='(...) ...-....'
                inputStyle={borderStyle}
                buttonStyle={borderStyle}
                priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                country={"in"}
                excludeCountries={['us', 'kz']}
                onChange={(phone, country, e, formattedValue) => field.onChange(formattedValue)}
                isValid={(phone, country, countries) => {
                  setCountries(countries)
                  return true
                }}
              />
              {isError && (
                <FormFeedback type="invalid">{errorMessage}</FormFeedback>
              )}
            </Fragment>
            : <RHFDisableField fieldValue={field.value} bsSize={bsSize}></RHFDisableField>
          }
        </Fragment>
      )}
    />
  );
};

export default RHFMobileNo;
