import React from 'react'
import ReactApexChart from 'react-apexcharts'

const DonutChart = (props) => {
    const { height, optionSeriesData = [], DonutChartTotal, donutColors = [], donutLabels = [] } = props
    const DONUT_CHART_SERIES = optionSeriesData
    const DONUT_CHART_OPTIONS = {
        labels: donutLabels,
        colors: donutColors,
        stroke: { width: 0 },
        legend: {
            show: donutLabels,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "10px",
            offsetX: 0,
            offsetY: -10,
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return parseInt((val * DonutChartTotal) / 100);
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '55%',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            color: "#B6BEC5"
                        },
                        value: {
                            show: true,
                            color: "#B6BEC5"
                        },
                        total: {
                            show: true,
                            label: "Total",
                            fontSize: "13px"
                        }

                    }
                }
            }
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        height: 240,
                        width: 200
                    },
                    legend: {
                        show: true,
                    },
                },
            },
        ],
    }

    return (

        <ReactApexChart
            options={DONUT_CHART_SERIES?.length ? DONUT_CHART_OPTIONS : {}}
            series={DONUT_CHART_SERIES || []}
            type="donut"
            height={height}
        />

    )
}

export default DonutChart
