
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

const CvssCalculator = (props) => {

    const { getCvssData, defualtCvss = '' } = props;

    const [sevirity, setSevirity] = useState({
        name: '',
        score: '',
        cvss: ''
    })

    useEffect(() => {
        var c = new window["CVSS"]("cvssboard", {
            onchange: () => {
                setSevirity(prev => ({
                    ...prev,
                    name: c.severityRating(c.get().score)?.name,
                    score: c?.get()?.score,
                    cvss: c?.get()?.vector,
                }));
                getCvssData({
                    name: c.severityRating(c.get().score)?.name,
                    score: c?.get()?.score,
                    cvss: c?.get()?.vector,
                });
            },
        })

        c.set(defualtCvss); // edit mode

    }, [])

    return (
        <Card className='cvss-card'>
            <CardBody>
                <CardTitle className='d-flex align-items-center cvssjs'>
                    CVSS 3.1
                    {sevirity?.name !== '?' && <span className={`ms-2 sevirity-name ${sevirity.name}`}>{sevirity.name}</span>}
                    {sevirity?.name !== '?' && <span className={`ms-2`}>{sevirity.cvss}</span>}
                    {sevirity?.score !== '?' && <span className={`ms-auto sevirity-score ${sevirity.name}`}>{sevirity.score}</span>}
                </CardTitle>
                <div id="cvssboard">

                </div>
            </CardBody>
        </Card>


    );
}

export default CvssCalculator;
