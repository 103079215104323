import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup';
import RHFTextField from 'components/FormControls/RHFTextField'
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import PageLoader from 'components/PageLoader';
import RHFButton from 'components/FormControls/RHFButton';
import { htmlTagRegex, nameRegex, SUPPORTED_FORMATS, urlRegex } from 'constants/Constant';
import defaultImage from '../../assets/images/Default-User-Image.jpeg'
import moment from 'moment/moment'
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { editCompany, getCompanyProfileDetail } from 'helpers/BackendHelper';
import { Toast } from 'components/Common/Toaster';
import RHFFileUpload from 'components/FormControls/RHFFileUpload';
import RHFInputTags from 'components/FormControls/RHFInputTags';

const CompanyProfile = () => {

    const [companyDetails, setCompanyDetails] = useState(null)
    const [pageLoader, setPageLoader] = useState(false);
    const history = useHistory()
    const [isApiCalling, setApiCalling] = useState(false);
    const { user } = useSelector(state => state.Login);
    const userID = user?.user?.companyId

    const CompanyProfileSchema = yup.object().shape({
        companyLogo: yup.mixed().required("Profile image is required").test("type", "Invalid file formate.", function (value) {
            if (!value) {
                return false
            } else if (typeof value === "string") {
                if (value?.toLowerCase().includes("jpeg") || value?.toLowerCase().includes("jpg") || value?.toLowerCase().includes("png") || value?.toLowerCase().includes("bmp") || value?.toLowerCase().includes("svg")) {
                    return true
                }
            }
            else if (SUPPORTED_FORMATS.includes(value.type)) {
                return true
            } else {
                return false
            }
        }),
        companyName: yup.string().trim().matches(nameRegex, "Please enter valid Comapany Name").required("Agency Name is required"),
        domain: yup.array().required("Domain is required").of(yup.string().test("domain-name", "Please enter valid Domain Name",
            function (value) {
                let isValidHtml = htmlTagRegex.test(value);
                let isValidUrl = urlRegex.test(value);
                if (isValidUrl && isValidHtml) {
                    return true;
                } else {
                    return false;
                }
            }))
            // .matches(urlRegex, 'Invalid domain'))
            .min(1, "Domain is required"),
    });

    useEffect(() => {
        if (userID) {
            const params = {
                id: userID
            }
            setPageLoader(true);
            getCompanyProfileDetail({ ...params }).then((resp) => {
                Toast.success(resp?.message)
                setCompanyDetails(resp?.data)
                setPageLoader(false);
            }).catch(() => {
                setPageLoader(false);
            })
        }
    }, [user])

    const {
        handleSubmit,
        control,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(CompanyProfileSchema),
    });

    useEffect(() => {
        const domain = [];
        companyDetails?.domains?.map(o => domain.push(o?.companyDomain))

        if (companyDetails) {
            setValue('companyLogo', companyDetails?.companyLogo)
            setValue('companyName', companyDetails?.companyName)
            setValue('companyType', companyDetails?.companyType ? companyDetails?.companyType : "None")
            setValue('domain', domain)
            setValue('createdAt', moment(companyDetails?.createdAt).format('YYYY-MM-DD'))
        }
    }, [companyDetails]);

    const onSubmit = (data) => {
        setApiCalling(true);
        if (!isApiCalling) {
            const formData = new FormData();
            formData.append('companyLogo', data?.companyLogo);
            formData.append('companyName', data?.companyName);
            formData.append('companyType', data?.companyType);
            formData.append('domain', JSON.stringify((data?.domain)));
            formData.append('companyId', userID)
            const payload = formData

            editCompany(payload).then(resp => {
                Toast.success(resp?.message)
                history.push(`/dashboard`)
                setApiCalling(false);
                reset();
            }).catch(() => {
                setApiCalling(false);
            });

        }
    }

    const getFileData = (fileData) => {
        setValue("companyLogo", fileData?.file)
    };


    const breadcrumbItems = [
        { path: "/", heading: "Home" },
        { path: "/company-profile", heading: "Company Profile" }
    ]

    return (
        <React.Fragment>
            {pageLoader ?
                <PageLoader />
                : <div className="page-content">
                    <Breadcrumb title="Company Profile" breadcrumbItem={breadcrumbItems} />
                    <form
                        className="form-horizontal"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Row className='mb-3'>
                            <Col lg={4}>
                                <div className="d-flex mb-3 justify-content-center">
                                    <RHFFileUpload
                                        name="companyLogo"
                                        control={control}
                                        errorobj={errors}
                                        isValidate={true}
                                        setValue={setValue}
                                        getFileData={getFileData}
                                        setError={setError}
                                        clearErrors={clearErrors}
                                        defaultImage={companyDetails ? companyDetails?.companyLogo : defaultImage}
                                    />
                                </div>

                            </Col>
                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <div className="app-detail-wrapper">
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-title">Company Name :</span>
                                                        <span className="value-text">
                                                            <RHFTextField
                                                                id="companyName"
                                                                name="companyName"
                                                                errorobj={errors}
                                                                control={control}
                                                                isController={true}
                                                                bsSize="sm"
                                                                disabledField={true}
                                                            />
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-title">Company Type:</span>
                                                        <span className="value-text">
                                                            <RHFTextField
                                                                id="companyType"
                                                                name="companyType"
                                                                errorobj={errors}
                                                                control={control}
                                                                isController={true}
                                                                bsSize="sm"
                                                                disabledField={true}
                                                            />
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-title">Domains :</span>
                                                        <span className="value-text">
                                                            <RHFInputTags
                                                                id="domain"
                                                                name="domain"
                                                                errorobj={errors}
                                                                control={control}
                                                                isController={true}
                                                                bsSize="sm"
                                                            />
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-title">Company Created Date :</span>
                                                        <span className="value-text">
                                                            <RHFTextField
                                                                id="createdAt"
                                                                name="createdAt"
                                                                errorobj={errors}
                                                                control={control}
                                                                isController={true}
                                                                bsSize="sm"
                                                                disabledField={true}
                                                            />
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end mt-3">
                                            <RHFButton
                                                btnName="Cancel"
                                                outline={true}
                                                onClick={() => history.push(`/dashboard`)}
                                                className="me-2"
                                            />
                                            <RHFButton
                                                btnName="Save"
                                                type="submit"
                                                isLoading={isApiCalling}
                                            />
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </form>
                </div>
            }
        </React.Fragment >
    )
}

export default CompanyProfile
