import React, { useEffect, useState } from "react"
import { Row, Col, Form } from "reactstrap"

// Formik Validation
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

// action
import { apiError } from "../../store/actions";

//redux
import { useDispatch } from "react-redux";

import { Link, useHistory } from "react-router-dom";

// import images
import RHFButton from "components/FormControls/RHFButton";
import RHFTextField from "components/FormControls/RHFTextField";
import logo from "../../assets/images/RiDiscovery_Icon.png";
import { registerNewUser } from "helpers/BackendHelper";
import { Toast } from "components/Common/Toaster";
import RHFMobileNo from "components/FormControls/RHFMobileNo";

const Register = () => {
  //meta title
  document.title = "Register | RiDiscovery";

  const dispatch = useDispatch();
  const history = useHistory();
  const [isApiCalling, setApiCalling] = useState(false);
  const [countries, setCountries] = useState('')

  const registerSchema = yup.object().shape({
    firstName: yup.string().trim().matches(/^[A-Za-z ]*$/, 'Please enter valid name').required('First Name is required').min(3).max(30),
    lastName: yup.string().trim().matches(/^[A-Za-z ]*$/, 'Please enter valid name').required('Last Name is required').min(3).max(30),
    email: yup.string().email().max(150).required('Email is required'),
    phoneNo: yup.string().required('Phone Number is required')
      .test("Check country", "Invalid country code", function (value) {
        let contactWithoutSpecialCharacter = value?.match(/\d+/g)?.join("")
        const findCountry = countries.find(country => contactWithoutSpecialCharacter?.startsWith(country?.dialCode))
        if (!findCountry) {
          return false
        } else {
          return true
        }
      })
      .test('Check Number', "Invaild Phone number", function (value) {
        let contactWithoutSpecialCharacter = value?.match(/\d+/g)?.join("")
        const findCountry = countries.find(country => contactWithoutSpecialCharacter?.startsWith(country?.dialCode))
        if (findCountry) {
          const func = (str) => {
            let count = 0
            if (str !== null) {
              for (let i = 0; i < str.length; i++)
                if (str[i] === ".") {
                  count++
                }
              return count
            }
          }
          let stringLength = func(findCountry?.format)
          if (contactWithoutSpecialCharacter?.length < stringLength) {
            return false
          } else {
            return true
          }
        }
      }),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(registerSchema),
  });

  const onRegister = (values) => {
    setApiCalling(true);
    if (!isApiCalling) {
      registerNewUser(values).then(resp => {
        if (resp?.status) {
          Toast.success(resp.message)
          history.push('/login')
          reset();
        }
        setApiCalling(false);
      }).catch(() => {
        setApiCalling(false);
      });
    }

  }

  useEffect(() => {
    dispatch(apiError(""));
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="auth-page">

        <Row className="g-0">
          <Col lg={4} md={5} className="col-xxl-3">
            <div className="auth-full-page-content d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5 text-center">
                    <span className="d-block auth-logo">
                      <img src={logo} alt="" height="35" />{" "}
                      <span className="logo-txt">RiDiscovery</span>
                    </span>
                  </div>
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      <h3 className="mb-0">Register Account</h3>
                      <p className="text-muted mt-2">
                        Get your free RiDiscovery account now.
                      </p>
                    </div>

                    <Form
                      className="needs-validation custom-form mt-4 pt-2"
                      onSubmit={handleSubmit(onRegister)}
                    >
                      <div className="mb-3">
                        <RHFTextField
                          id="firstName"
                          label="First Name"
                          name="firstName"
                          errorobj={errors}
                          control={control}
                          isController={true}
                        />
                      </div>
                      <div className="mb-3">
                        <RHFTextField
                          id="lastName"
                          label="Last Name"
                          name="lastName"
                          errorobj={errors}
                          control={control}
                          isController={true}
                        />
                      </div>
                      <div className="mb-3">
                        <RHFTextField
                          id="email"
                          label="Email"
                          name="email"
                          errorobj={errors}
                          control={control}
                          isController={true}
                        />
                      </div>

                      <div className="mb-3">
                        <RHFMobileNo
                          id="phoneNo"
                          label="Phone No"
                          name="phoneNo"
                          errorobj={errors}
                          control={control}
                          isController={true}
                          setCountries={setCountries}
                        />
                      </div>

                      <div className="mb-0">
                        <RHFButton
                          className="btn btn-primary w-100 waves-effect waves-light"
                          btnName="Register"
                          type="submit"
                          isLoading={isApiCalling}
                        />
                      </div>
                    </Form>

                    <div className="mt-3 text-center">
                      <p className="text-muted mb-0">
                        Already have an account ?{" "}
                        <Link
                          to="/login"
                          className="text-primary fw-semibold"
                        >
                          {" "}
                          Login{" "}
                        </Link>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

      </div>
    </React.Fragment >
  );
};

export default Register;
