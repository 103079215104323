import React, { useEffect, useState } from "react";
import RHFAutoCompleteSelect from "components/FormControls/RHFAutoCompleteSelect";
import RHFDatePicker from "components/FormControls/RHFDatePicker";
import { Card, CardBody, CardHeader, Col, Label, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import RHFButton from "../../components/FormControls/RHFButton";
import RHFSwitch from "../../components/FormControls/RHFSwitch";
import DialogBox from "../../components/Modals/DialogBox";
import Table from "../../components/Tables/Table";
import UserAddEdit from "./UserAddEdit";
import ActionButtons from "components/FormControls/ActionButtons";
import { USER } from "helpers/Services/User";
import { useSelector } from "react-redux";
import { isModulePermisssion } from "helpers/util";
import { ROLE_PERMISSIONS } from "constants/RolePermissions";
import { USER_ROLES } from "constants/Constant";
import defaultImage from "../../assets/images/Default-User-Image.jpeg";
import { activeDeactiveUser, getAllRole, listAllCompany } from "helpers/BackendHelper";
import { Toast } from "components/Common/Toaster";

const StatusData = [
  {
    value: true,
    label: "Active",
  },
  { value: false, label: "De-Active" },
];

const UserList = () => {
  document.title = "Role Management | RiDiscovery";

  const [isModelOpen, setIsModelOpen] = useState(false);
  const [editUserData, setEditUserData] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);
  const [allRoleList, setAllRoleList] = useState([]);
  const [filterRoleList, setFilterRoleList] = useState([]);
  const [role, setRole] = useState([])
  const [filterFields, setFilterData] = useState();
  const [isRefresh, setRefresh] = useState(false);
  const isAgencyDropDown = isModulePermisssion(ROLE_PERMISSIONS?.AGENCY_DROPDOWN);
  const [filterColumns, setFilterColumns] = useState([]);
  const { user } = useSelector(state => state.Login);

  useEffect(() => {
    const columnFilter = [...columns];
    setFilterColumns(columnFilter.filter(o => o.isVisible && o));
  }, [user])

  const handleToggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const handleSwitchChange = (value, row) => {
    const payload = {
      userId: row?.id,
      activated: !row?.activated
    }
    activeDeactiveUser({ ...payload }).then(resp => {
      Toast.success(resp?.message)
      setRefresh(prevValue => !prevValue)
    })
  };

  const editHandler = (obj) => {
    handleToggle();
    setEditUserData(obj);
  };

  const handleActionClick = (payload, actionType) => {
    const actionMapper = {
      edit: editHandler,
    };
    if (actionMapper[actionType]) {
      actionMapper[actionType](payload);
    }
  };

  useEffect(() => {
    const payload = {
      "type": [
        "development_agency_external",
        "security_agency_external",
        "security_agency_internal",
        "development_agency_internal",
        "main"
      ]
    }
    Promise.all([
      listAllCompany(payload),
      getAllRole()
    ]).then(([companies, roles]) => {
      setCompanyList(companies?.data?.map(cmp => ({ value: cmp?.id, label: cmp?.companyName, companyType: cmp?.companyType })));
      setUserRoleList(roles?.data?.userRoles?.map(role => ({ value: role?.roleSlug, label: role?.roleName })));
      setRole(roles?.data?.allRoles)
      setAllRoleList(roles?.data?.allRoles?.map(role => ({ value: role?.roleSlug, label: role?.roleName })));
      if (['sm_manager', 's_executive'].includes(user?.user?.roleId)) {
        setFilterRoleList((roles?.data?.allRoles?.filter(role => role?.roleSlug === "pentester" || role?.roleSlug === "sm_manager" || role?.roleSlug === "s_executive").map(role => ({ value: role?.roleSlug, label: role?.roleName }))))
      } else if (['da_manager', 'd_executive'].includes(user?.user?.roleId)) {
        setFilterRoleList((roles?.data?.allRoles?.filter(role => role?.roleSlug === "developer" || role?.roleSlug === "da_manager" || role?.roleSlug === "d_executive").map(role => ({ value: role?.roleSlug, label: role?.roleName }))))
      } else {
        setFilterRoleList((roles?.data?.allRoles?.map(role => ({ value: role?.roleSlug, label: role?.roleName }))))
      }
    })
  }, []);

  const handleOnChange = (data, name) => {
    const value = data;
    setFilterData((prevValue) => {
      const prev = {
        ...prevValue,
      };
      if (value === undefined || value === "") {
        delete prev[name];
      } else {
        prev[name] = value;
      }
      return prev;
    });
  };

  const columns = [
    {
      name: "Full Name",
      cell: (row) => (
        <div className="d-flex">
          <img
            src={row?.profile ? row?.profile : defaultImage}
            className="rounded-circle me-2"
            width="35px"
            height="35px"
          />
          <span
            className="align-self-center"

          >
            {row?.firstName} {row?.lastName}
          </span>
        </div>
      ),
      isVisible: true,
      minWidth: "130px"
    },
    {
      name: "Email",
      cell: (row) => <span>{row?.email}</span>,
      isVisible: true,
      minWidth: "130px"
    },
    {
      name: "Role",
      cell: (row) => <span>{USER_ROLES[row?.roleId]}</span>,
      isVisible: true,
      minWidth: "130px"
    },

    {
      name: "Agency/Company",
      cell: (row) => <span>{row?.company?.companyName}</span>,
      isVisible: true,
      minWidth: "130px"
    },
    {
      name: "Start Date",
      cell: (row) => <span>{row?.startDate}</span>,
      isVisible: true,
      width: "150px",
      minWidth: "100px"

    },
    {
      name: "End Date",
      cell: (row) => <span>{row?.endDate ? row?.endDate : '-'}</span>,
      isVisible: true,
      width: "150px",
      minWidth: "100px"
    },
    {
      name: "Status",
      cell: (row) => (
        user?.user?.id === row?.id || row?.roleId === 'oam_manager' ? ""
          : <RHFSwitch
            name="activated" // isActive
            isController={false}
            checked={row?.activated}
            rowData={row}
            onChange={(val, row) => handleSwitchChange(val, row)}
          />
      ),
      isVisible: isModulePermisssion(ROLE_PERMISSIONS?.DEACTIVATE_USER) === false ? false : true,
      width: "120px",
      minWidth: "100px"
    },
    {
      name: "Actions",
      minWidth: "130px",
      width: "100px",
      cell: (row) => {
        return (
          (['csm_manager'].includes(user?.user?.roleId) && row?.roleId === 'oam_manager') ?
            "" : <ActionButtons
              edit={
                !['executive', 'd_executive', 's_executive'].includes(user?.user?.roleId) &&
                {
                  handleClick: () => handleActionClick(row, 'edit'),
                }}
            />
        );
      },
      isVisible: true,
    },
  ];

  const breadcrumbItems = [{ path: "/", heading: "Home" },
  { path: "/user-list", heading: "User list" }]

  return (
    <React.Fragment>

      <div className="page-content">
        <Breadcrumbs title="Role Management" breadcrumbItem={breadcrumbItems} />
        <Card>
          <CardHeader>
            <Row>
              {isAgencyDropDown &&
                <Col md="2">
                  <RHFAutoCompleteSelect
                    id="agencyId"
                    label="Agency"
                    name="agencyId"
                    options={companyList}
                    isMultiple={false}
                    isController={false}
                    handleOnChange={handleOnChange}
                  />
                </Col>
              }
              <Col md="2">
                <RHFAutoCompleteSelect
                  id="roleId"
                  label="Role"
                  name="roleId"
                  options={filterRoleList}
                  isMultiple={false}
                  isController={false}
                  handleOnChange={handleOnChange}
                />
              </Col>
              <Col md="2">
                <RHFAutoCompleteSelect
                  id="activated"
                  label="Status"
                  name="activated"
                  options={StatusData}
                  isMultiple={false}
                  isController={false}
                  handleOnChange={handleOnChange}
                />
              </Col>
              <Col md="2">
                <RHFDatePicker
                  name="startDate"
                  label="Start Date"
                  isController={false}
                  handleOnChange={handleOnChange}
                />
              </Col>
              <Col md="2">
                <RHFDatePicker
                  name="endDate"
                  label="End Date"
                  isController={false}
                  handleOnChange={handleOnChange}
                />
              </Col>

              <Col>
                <Label htmlFor="example-date-input" className="form-label">&nbsp;</Label>
                <div className="col d-flex justify-content-end align-items-end">
                  {!['executive', 'd_executive', 's_executive'].includes(user?.user?.roleId) &&
                    <RHFButton
                      btnName="Add User"
                      icon="plus"
                      onClick={() => {
                        handleToggle();
                        setEditUserData(null);
                      }}
                    />
                  }
                  <DialogBox
                    isModelOpen={isModelOpen}
                    handleToggle={handleToggle}
                    modelSize="lg"
                    title={editUserData === null ? "New User" : "Edit User"}
                    actions={null}
                  >
                    <UserAddEdit
                      editUserData={editUserData}
                      handleToggle={handleToggle}
                      setRefresh={setRefresh}
                      companyList={companyList}
                      roleList={userRoleList}
                      setUserRoleList={setUserRoleList}
                      allRoleList={allRoleList}
                      role={role}
                    />
                  </DialogBox>
                </div>
              </Col>
            </Row>
          </CardHeader>
          {isModulePermisssion(ROLE_PERMISSIONS?.VIEW_USERS_AGENCY_LIST) &&
            <CardBody className="table-responsive">
              <Table
                columns={filterColumns}
                dataURL={USER.listAllUser}
                isRefresh={isRefresh}
                filter={filterFields}
                fixedHeader={false}
              />
            </CardBody>
          }
        </Card>
      </div>

    </React.Fragment >
  );
};

export default UserList;
