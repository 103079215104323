


import { ADD_TEMPLATE, DEFAULT_USER, DELETE_TEMPLATE, GENERATE_APPLICATION_REPORT, GET_ALL_TEMPLATE, GET_TEMPLATE_DETAILS, UPDATE_TEMPLATE } from "helpers/UrlHelper";
import { API_URL } from "./Services";

const baseURL = API_URL.TEMPLATE;

export const TEMPLATE = {
    addTemplate: {
        method: 'POST',
        url: ADD_TEMPLATE,
        baseURL
    },
    editTemplate: {
        method: 'PUT',
        url: UPDATE_TEMPLATE,
        baseURL
    },
    deleteTemplate: {
        method: 'DELETE',
        url: DELETE_TEMPLATE,
        baseURL
    },
    listAllTemplates: {
        method: 'POST',
        url: GET_ALL_TEMPLATE,
        baseURL
    },
    getTemplateDetails: {
        method: 'GET',
        url: GET_TEMPLATE_DETAILS,
        baseURL
    },
    defaultUser: {
        method: 'PUT',
        url: DEFAULT_USER,
        baseURL
    },
    generateApplicationReport: {
        method: 'POST',
        url: GENERATE_APPLICATION_REPORT,
        baseURL
    },
}