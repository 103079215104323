import React, { useEffect, useState } from "react";
import ReportingTemplates from "./ReportingTemplates";
import CertificateTemplates from "./Certificate/CertificateTemplates";
import Tabs from "components/Tab/Tabs";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const navLinkData = [
  {
    tabNo: "reporting-template",
    tabName: "Reporting Template",
    tabPath: "/reporting-template",
    isVisible: true,
  },
  {
    tabNo: "certificate-template",
    tabName: "Certificate Template",
    tabPath: "/certificate-template",
    isVisible: true,
  }
]


const TemplatesList = () => {
  document.title = "Template | RiDiscovery";

  const { permissions } = useSelector(state => state.Login);
  let location = useLocation()
  const history = useHistory()
  let tabValue = location.pathname
  const [filterColumns, setFilterColumns] = useState([]);
  const [activeTab, setactiveTab] = useState('');

  let columnFilter = [...navLinkData];

  useEffect(() => {
    const filteredTabs = columnFilter.filter(o => o.isVisible && o);
    setFilterColumns(filteredTabs);
    setactiveTab(filteredTabs.length > 0 ? filteredTabs[0]?.tabNo : '')
  }, [permissions])

  const toggle = (tab) => {
    setactiveTab(tab);
    history.push(`/${tab}`)
  };

  return (
    <div className="page-content1">
      <div className='page-title-box'>
        <Tabs
          navLinkData={filterColumns}
          activeTab={activeTab}
          setactiveTab={setactiveTab}
          toggle={toggle}
        >
          {tabValue === "/reporting-template" && <ReportingTemplates />}
          {tabValue === "/certificate-template" && <CertificateTemplates />}
        </Tabs>
      </div>
    </div>
  )
};

export default TemplatesList;