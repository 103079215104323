import React from 'react'
import ReactApexChart from 'react-apexcharts'

const LineChart = (props) => {
    const { height, seriesTitle, seriesData, lineColor = [] } = props

    const LINE_CHART_SERIES = [
        {
            name: seriesTitle,
            data: seriesData
        },
    ]
    const LINE_CHART_OPTIONS = {
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: "smooth",
        },
        colors: lineColor,
        chart: {
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: false,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                }
            }
        },
        zoom: {
            enabled: false
        },
        xaxis: {
            type: 'datetime',
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val.toFixed(0);
                },
            },
            title: {
                text: 'Finding'
            },
        },
        markers: {
            size: 0,
            style: 'hollow',
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy'
            },
            y: {
                formatter: function (val) {
                    return val.toFixed(2)
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100]
            }
        },
    }
    return (
        <ReactApexChart
            options={LINE_CHART_OPTIONS}
            series={LINE_CHART_SERIES}
            type="area"
            height={height}
        />
    )
}

export default LineChart
