


import { ADD_CERTIFICATE_TEMPLATE, DELETE_CERTIFICATE_TEMPLATE, GENERATE_CERTIFICATE, GET_ALL_CERTIFICATE_TEMPLATE, GET_CERTIFICATE_TEMPLATE_DETAILS, UPDATE_CERTIFICATE_TEMPLATE } from "helpers/UrlHelper";
import { API_URL } from "./Services";

const baseURL = API_URL.CERTIFICATE_TEMPLATE;

export const CERTIFICATE_TEMPLATE = {
    addTemplate: {
        method: 'POST',
        url: ADD_CERTIFICATE_TEMPLATE,
        baseURL
    },
    editTemplate: {
        method: 'PUT',
        url: UPDATE_CERTIFICATE_TEMPLATE,
        baseURL
    },
    deleteTemplate: {
        method: 'DELETE',
        url: DELETE_CERTIFICATE_TEMPLATE,
        baseURL
    },
    listAllTemplates: {
        method: 'POST',
        url: GET_ALL_CERTIFICATE_TEMPLATE,
        baseURL
    },
    getTemplateDetails: {
        method: 'GET',
        url: GET_CERTIFICATE_TEMPLATE_DETAILS,
        baseURL
    },
    generateCertificate: {
        method: 'POST',
        url: GENERATE_CERTIFICATE,
        baseURL
    }
}