import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import RHFTextField from 'components/FormControls/RHFTextField';
import { Col, Row } from 'reactstrap';
import RHFAutoCompleteSelect from 'components/FormControls/RHFAutoCompleteSelect';
import RHFDatePicker from 'components/FormControls/RHFDatePicker';
import RHFButton from 'components/FormControls/RHFButton';
import RHFUpload from 'components/FormControls/RHFUpload';
import { isModulePermisssion } from 'helpers/util';
import { ROLE_PERMISSIONS } from 'constants/RolePermissions';
import { useSelector } from 'react-redux';
import { QUARTER_TYPE } from 'constants/Constant';
import { addNetwork } from 'helpers/BackendHelper';
import { Toast } from 'components/Common/Toaster';
import moment from 'moment/moment'

const NetworkAddEdit = (props) => {
    const { handleToggle, setRefresh } = props
    const { bussinessUnit } = useSelector(state => state?.bu);
    const today = new Date();
    today.setHours(0, 0, 0, 0)

    const RESULT_TYPE = [
        (isModulePermisssion(ROLE_PERMISSIONS?.IMPORT_NMAP_RESULTS) && {
            value: "Nmap",
            label: "Nmap"
        }),
        (isModulePermisssion(ROLE_PERMISSIONS?.IMPORT_NESSUS_RESULTS) && {
            value: "Nessus",
            label: "Nessus"
        }),
    ]
    var filteredResult = RESULT_TYPE.filter(Boolean);

    const [selectedOption, setSelectedOption] = useState(RESULT_TYPE)
    const [flag, setFlag] = useState(false)
    const [isApiCalling, setApiCalling] = useState(false);

    const networkSchema = yup.object().shape({
        title: yup
            .string().trim().matches(/^[0-9a-zA-Z _-]*$/, 'Please enter valid title')
            .required("Title is required"),
        quarterType: yup
            .object()
            .shape({ label: yup.string(), value: yup.string() })
            .nullable()
            .required("Select atleast one option"),
        startDate: yup.date()
            .typeError("Start date is required")
            .test("startDate", "Start Date should be today's date or later.", function (value) {
                if (moment(value).diff(today, 'days') >= 0) {
                    return true
                }
            }),
        endDate: yup.date().typeError("End date is required")
            .transform((curr, orig) => orig === '' ? null : curr)
            .test("endDate", "End Date should be greater than or equal to start date", function (value) {
                const { startDate } = this?.parent;
                if (value) {
                    if (startDate) {
                        if (moment(startDate).diff(value, 'days') <= 0) {
                            if (moment(value).diff(today, 'days') >= 0) {
                                return true
                            }
                        }
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            }),
        nmap:
            isModulePermisssion(ROLE_PERMISSIONS?.IMPORT_NMAP_RESULTS) &&
            flag && selectedOption?.value === "Nmap" &&
            yup.mixed()
                .required("Nmap is required").test(
                    "fileFormat",
                    "Unsupported File Format",
                    value => value && ["text/xml"].includes(value.type)
                ),

        nessus:
            isModulePermisssion(ROLE_PERMISSIONS?.IMPORT_NESSUS_RESULTS) &&
            flag && selectedOption?.value === "Nessus" &&
            yup.mixed()
                .required("Nessus is required").test(
                    "fileFormat",
                    "Unsupported File Format",
                    value => value && ["text/csv"].includes(value.type)
                ),
    },)

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
        reset,
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(networkSchema),
    });

    const onSubmit = (data) => {
        setApiCalling(true);
        if (!isApiCalling) {
            const formData = new FormData();
            formData.append('title', data?.title);
            formData.append('quarterType', data?.quarterType?.value);
            formData.append('startDate', data?.startDate);
            formData.append('endDate', data?.endDate);
            formData.append('buTags', JSON.stringify(data?.buTags?.map(o => +o.value)));
            formData.append('nmap', data?.nmap);
            formData.append('nessus', data?.nessus);
            const payload = formData
            if (payload) {
                addNetwork(payload).then(resp => {
                    Toast.success(resp?.message)
                    setRefresh(prevValue => !prevValue)
                    reset()
                    handleToggle()
                    setApiCalling(false);
                }).catch(() => {
                    setApiCalling(false);
                });
            }
        }
    };

    const fileRef = useRef(null);
    const fileRef2 = useRef(null)

    const getFileData = () => {
    };

    const handleOnChange = (selectedValue) => {
        setFlag(true)
        setSelectedOption(selectedValue)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
                <Col sm="12">
                    <RHFTextField
                        id="title"
                        label="Title"
                        name="title"
                        errorobj={errors}
                        control={control}
                        isController={true}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm="12">
                    <RHFAutoCompleteSelect
                        id="quarterType"
                        label="Type"
                        name="quarterType"
                        options={QUARTER_TYPE}
                        isMultiple={false}
                        errorobj={errors}
                        control={control}
                        isController={true}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm="6">
                    <RHFDatePicker
                        name="startDate"
                        label="Start Date"
                        errorobj={errors}
                        control={control}
                        isController={true}
                        defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                    />
                </Col>
                <Col sm="6">
                    <RHFDatePicker
                        name="endDate"
                        label="End Date"
                        errorobj={errors}
                        control={control}
                        isController={true}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm="12">
                    <RHFAutoCompleteSelect
                        id="buTags"
                        label="BU Tag"
                        name="buTags"
                        options={bussinessUnit ? bussinessUnit?.data?.map((bu) => ({ label: bu?.name, value: bu?.id })) : []}
                        isMultiple={true}
                        errorobj={errors}
                        control={control}
                        isController={true}
                        isRequired={false}
                    />
                </Col>
            </Row>
            {(isModulePermisssion(ROLE_PERMISSIONS?.IMPORT_NMAP_RESULTS) || isModulePermisssion(ROLE_PERMISSIONS?.IMPORT_NESSUS_RESULTS)) &&
                <Row className="mb-3">
                    <Col sm="12">
                        <RHFAutoCompleteSelect
                            id="fileType"
                            label="Upload Nmap / Nessus"
                            name="fileType"
                            options={filteredResult}
                            isMultiple={false}
                            errorobj={errors}
                            control={control}
                            isController={true}
                            isRequired={false}
                            handleOnChange={handleOnChange}
                            inputNote="Please keep this field blank to create empty network "
                        />
                    </Col>
                </Row>}
            {
                flag && selectedOption?.value === "Nmap" && <Row className="mb-3">
                    <Col sm="12" >
                        <RHFUpload
                            inputRef={fileRef}
                            name="nmap"
                            id="nmap"
                            label="Import Nmap Results"
                            getFileData={getFileData}
                            setValue={setValue}
                            errorobj={errors}
                            control={control}
                            isController={true}
                            isValidate={true} />

                    </Col>
                </Row>
            }
            {
                flag && selectedOption?.value === "Nessus" &&
                <Row className="mb-3">
                    <Col sm="12" >
                        <RHFUpload
                            inputRef={fileRef2}
                            name="nessus"
                            id="nessus"
                            label="Import Nessus Results"
                            getFileData={getFileData}
                            setValue={setValue}
                            errorobj={errors}
                            control={control}
                            isController={true}
                            isValidate={true}
                        />
                    </Col>
                </Row>
            }
            <div className="d-flex justify-content-end">
                <RHFButton
                    className="mx-2"
                    btnName="Cancel"
                    outline={true}
                    onClick={handleToggle}
                />
                <RHFButton
                    btnName="Save"
                    type="submit"
                    isLoading={isApiCalling}
                />
            </div>
        </form >
    )
}

export default NetworkAddEdit
