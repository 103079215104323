import RHFButton from 'components/FormControls/RHFButton';
import RHFTextField from 'components/FormControls/RHFTextField';
import React from 'react'
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { changePassword } from 'store/auth/changePassword/actions';
import { useDispatch, useSelector } from 'react-redux';


const ChangePasswordForm = (props) => {
    document.title = "Change Password | RiDiscovery"
    const { handleToggle } = props
    const dispatch = useDispatch()
    const { isLoading } = useSelector(state => state.ChangePassword);


    const changePasswordSchema = yup.object().shape({
        oldPassword: yup.string().required("Old Password is required"),
        newPassword: yup.string().required("New Password is required").matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character required.")
            .test("matchPassword", "Old Password and New Passwordd can not be same", function (value) {
                const { oldPassword } = this?.parent;
                if (value) {
                    if (value !== oldPassword) return true
                } else return false
            }),
        confirmPassword: yup.string().required("Confirm Password is required").oneOf([yup.ref('newPassword'), null], 'New Passwords and Confirm Password must match'),
    })
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(changePasswordSchema),
    });

    const onSubmit = (values) => {
        const payload = {
            "oldPassword": values?.oldPassword,
            "newPassword": values?.newPassword
        }
        dispatch(changePassword(payload));
        handleToggle()

    }


    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Row className="mb-3">
                <Col>
                    <RHFTextField
                        id="oldPassword"
                        label="Old Password"
                        name="oldPassword"
                        type="password"
                        errorobj={errors}
                        control={control}
                        isController={true}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <RHFTextField
                        id="newPassword"
                        label="New Password"
                        name="newPassword"
                        type="password"
                        errorobj={errors}
                        control={control}
                        isController={true}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <RHFTextField
                        id="confirmPassword"
                        label="Confirm Password"
                        name="confirmPassword"
                        type="password"
                        errorobj={errors}
                        control={control}
                        isController={true}
                    />
                </Col>
            </Row>
            <Row>
                <div className="d-flex justify-content-end">
                    <RHFButton
                        btnName="Not Now"
                        outline={true}
                        onClick={handleToggle}
                        className="mx-2"
                    />
                    <RHFButton
                        btnName="Update"
                        type="submit"
                        isLoading={isLoading}
                    />

                </div>
            </Row>
        </form>

    )
}

export default ChangePasswordForm
