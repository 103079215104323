export const REGISTER_USER = "register_user"
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull"
export const REGISTER_USER_FAILED = "register_user_failed"

export const EMAIL_VERIFY = "EMAIL_VERIFY"
export const EMAIL_VERIFY_SUCCESSFUL = "EMAIL_VERIFY_SUCCESSFUL"
export const EMAIL_VERIFY_FAILED = "EMAIL_VERIFY_FAILED"

export const REGISTER_COMPANY = "REGISTER_COMPANY"
export const REGISTER_COMPANY_SUCCESS = "REGISTER_COMPANY_SUCCESS"
export const REGISTER_COMPANY_FAIL = "REGISTER_COMPANY_FAIL"