import { ADD_NETWORK, ADD_NETWORK_FAIL, ADD_NETWORK_SUCCESS, GET_ALL_NETWORK, GET_ALL_NETWORK_FAIL, GET_ALL_NETWORK_SUCCESS } from "./actionTypes"

export const addNetwork = (payload) => {
    return {
        type: ADD_NETWORK,
        payload
    }
}

export const addNetworkSuccess = resp => {
    return {
        type: ADD_NETWORK_SUCCESS,
        payload: resp,

    }
}

export const addNetworkFail = (data) => {
    return {
        type: ADD_NETWORK_FAIL,
        payload: data
    }
}



export const getAllNetwork = (payload) => {
    return {
        type: GET_ALL_NETWORK,
        payload,
    }
}

export const getAllNetworkSuccess = (data) => {
    return {
        type: GET_ALL_NETWORK_SUCCESS,
        payload: data
    }
}

export const getAllNetworkFail = (data) => {
    return {
        type: GET_ALL_NETWORK_FAIL,
        payload: data
    }
}