import React, { useEffect, useState } from 'react'
import Breadcrumb from 'components/Common/Breadcrumb';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import LineChart from 'components/Common/Graphs/LineChart'
import DonutChart from 'components/Common/Graphs/DonutChart'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import Finding from '../Finding'
import { getNetworkDetails, getNetworkLogs, getNetworkScoreLineChartData, getNetworkSubmissionDonutChartData } from 'helpers/BackendHelper';
import PageLoader from 'components/PageLoader';
import UploadNetworkDetails from '../Finding/UploadNetworkDetails';
import { DONUT_SEVERITY_COLORS, DONUT_SEVERITY_LABELS, NETWORK_LOGS, APPLICATION_STATUS } from 'constants/Constant';
import moment from 'moment';
import defaultImage from 'assets/images/Default-User-Image.jpeg'
import { isModulePermisssion } from 'helpers/util';
import { ROLE_PERMISSIONS } from 'constants/RolePermissions';

const NetworkOverview = () => {
    document.title = "Network-Overview | RiDiscovery";

    const { networkId } = useParams();
    const [networkData, setnetworkData] = useState(null)
    const [submissionDonutChartData, setSubmissionDonutChartData] = useState([])
    const [DonutChartTotalSubmissions, setDonutChartTotalSubmissions] = useState(null)
    const [scoreLineChartData, setScoreLineChartData] = useState([])
    const [pageLoader, setPageLoader] = useState(false);
    const [networkLogs, setNetworkLogs] = useState([]);

    useEffect(() => {
        if (networkId) {
            const payload = { networkId: networkId }
            setPageLoader(true);
            Promise.all([
                getNetworkSubmissionDonutChartData(payload),
                getNetworkScoreLineChartData(payload)
            ]).then(([DonutChartResp, lineChartResp]) => {
                const DonutChartData = Object.values(DonutChartResp?.data);
                DonutChartData.forEach((field) => DonutChartResp?.data[field]);
                setSubmissionDonutChartData(DonutChartData)
                setDonutChartTotalSubmissions(DonutChartResp?.submmisions)
                const data = lineChartResp?.data?.map(item => [item.createdOn, item.avgScore]);
                setScoreLineChartData(data)
                setPageLoader(false);
            })
        }
    }, [])

    useEffect(() => {
        const params = {
            id: networkId
        }
        if (networkId) {
            getNetworkDetails({ ...params }).then(resp => {
                setnetworkData(resp?.data);
            })
        }
    }, []);


    useEffect(() => {
        if (networkId) {
            getNetworkLogs({ id: networkId }).then(resp => {
                setNetworkLogs(resp?.data);
            })
        }
    }, [networkId])


    const renderText = (log) => {
        let finalText = '';
        if ([NETWORK_LOGS.add_network]?.includes(log?.activityName)) {
            finalText = <span>has <b>added</b> network.</span>
        }
        else if ([NETWORK_LOGS.update_network]?.includes(log?.activityName)) {
            finalText = <span>has <b>uploaded</b> network file.</span>
        }
        else if ([NETWORK_LOGS.d_assign, NETWORK_LOGS.p_assign, NETWORK_LOGS.dm_assign, NETWORK_LOGS.sm_assign]?.includes(log?.activityName)) {
            finalText = <span>has assigned <b>{log?.user?.firstName} {log?.user?.lastName}</b> into network.</span>
        } else if ([NETWORK_LOGS.d_unassign, NETWORK_LOGS.p_unassign, NETWORK_LOGS.dm_unassign, NETWORK_LOGS.sm_unassign]?.includes(log?.activityName)) {
            finalText = <span>has unassigned <b>{log?.user?.firstName} {log?.user?.lastName}</b> from network.</span>
        }
        return finalText;
    }

    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/networks", heading: "Network" },
    { path: `/network/${networkId}/network-overview`, heading: `Network Overview` },
        // { path: `/network/${networkId}/network-overview`, heading: `Network Overview (${networkData?.title})` },
    ]
    return (
        <React.Fragment>
            {pageLoader ?
                <PageLoader />
                :
                <div className="page-content">
                    <Breadcrumb
                        badgeTitle={APPLICATION_STATUS[networkData?.networkStatus]}
                        title={networkData?.title}
                        breadcrumbItem={breadcrumbItems}

                    />
                    <div>
                        {networkData && networkData?.totalFindings > 0 ?
                            <Row className='g-3'>
                                <Col lg={6}>
                                    <Card className="m-0 h-100">
                                        <CardHeader>
                                            <h4 className="card-title mb-0">Finding by Severity</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <DonutChart
                                                optionSeriesData={submissionDonutChartData}
                                                DonutChartTotal={DonutChartTotalSubmissions}
                                                donutColors={DONUT_SEVERITY_COLORS}
                                                donutLabels={DONUT_SEVERITY_LABELS}
                                                height="300"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card className="m-0 h-100">
                                        <CardHeader>
                                            <h4 className="card-title mb-0">Finding Timeline</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <LineChart
                                                seriesTitle='Score Board'
                                                seriesData={scoreLineChartData}
                                                height="300"
                                                lineColor={["#3957d5"]}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row> :
                            null}

                        {networkData?.totalFindings > 0 ?
                            <Finding networkData={networkData} /> :
                            isModulePermisssion(ROLE_PERMISSIONS?.IMPORT_NMAP_RESULTS) &&
                            isModulePermisssion(ROLE_PERMISSIONS?.IMPORT_NESSUS_RESULTS) &&
                            <UploadNetworkDetails />
                        }

                        {/** Logs section */}
                        {networkLogs?.length > 0 &&
                            <Card className="g-3">
                                <CardHeader tag="h6">
                                    Network Logs
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <div className='audit-logs'>
                                                {networkLogs?.map(log => (
                                                    <div className="logs-wrapper" key={log?.id}>
                                                        <div className='log-avatar'>
                                                            <img src={log?.createdByUser?.profile ? log?.createdByUser?.profile : defaultImage} alt="" className="rounded-circle" width="50" height="50" />
                                                        </div>
                                                        <div className='log-details-wrapper'>
                                                            <div className='log-details'>
                                                                <h6 className='m-0 p-0'>
                                                                    {`${log?.createdByUser?.firstName} ${log?.createdByUser?.lastName}`}
                                                                </h6>
                                                                <span className='ms-1'>
                                                                    {renderText(log)}
                                                                </span>

                                                                <span className='ms-auto log-date-time'>{moment(log?.createdAt).format('Do MMM YYYY h:mm a')}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        }
                    </div>

                </div >
            }
        </React.Fragment >
    )
}

export default NetworkOverview
