import { VERIFY_UPDATED_OTP, VERIFY_UPDATED_OTP_FAIL, VERIFY_UPDATED_OTP_SUCCESS } from "./actionTypes"

const initialState = {
    isTwoFAEnabledLoading: false,
    TwoFAEnabled: false,
}

const userProfileTwoFA = (state = initialState, action) => {
    switch (action.type) {

        case VERIFY_UPDATED_OTP:
            state = {
                ...state,
                isTwoFAEnabledLoading: true,
            }
            break
        case VERIFY_UPDATED_OTP_SUCCESS:
            state = {
                ...state,
                isTwoFAEnabledLoading: false,
                TwoFAEnabled: action?.payload,
            }
            break
        case VERIFY_UPDATED_OTP_FAIL:
            state = {
                ...state,
                isTwoFAEnabledLoading: false,
                TwoFAEnabled: false,
            }
            break

        default:
            state = { ...state }
            break
    }
    return state
}

export default userProfileTwoFA
