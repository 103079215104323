import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import ActionButtons from 'components/FormControls/ActionButtons';
import RHFButton from 'components/FormControls/RHFButton';
import DialogBox from 'components/Modals/DialogBox';
import Table from 'components/Tables/Table';
import Breadcrumbs from "components/Common/Breadcrumb";
import { CERTIFICATE_TEMPLATE } from 'helpers/Services/CertificateTemplate';
import { ROLE_PERMISSIONS } from 'constants/RolePermissions';
import { deleteCertificateTemplate } from 'helpers/BackendHelper';
import { dmDebounce, isModulePermisssion } from 'helpers/util';
import RHFTextField from 'components/FormControls/RHFTextField';
import { Toast } from 'components/Common/Toaster';



const CertificateTemplates = () => {
    document.title = "Certificate Template | RiDiscovery";
    const [filterFields, setFilterFields] = useState({
        'search': null,
    });
    const [activeRow, setActiveRow] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [isRefresh, setRefresh] = useState(false);
    const [isApiCalling, setApiCalling] = useState(false);
    const history = useHistory();

    const handleToggle = () => {
        setIsModelOpen(!isModelOpen);
    };


    const setFilterValues = (field) => {
        setFilterFields(prev => {
            return {
                ...prev,
                ...field
            }
        })
    }

    const optimizedFn = useCallback(dmDebounce((val) => setFilterValues({ search: val })), []);

    const deleteHandler = (obj) => {
        setActiveRow(obj);
        handleToggle(true);
    };

    const handleDelete = () => {
        setApiCalling(true);
        if (!isApiCalling) {
            const payload = {
                "isDeleted": true,
                "certificateId": activeRow?.id
            }
            deleteCertificateTemplate(payload).then(resp => {
                Toast.success(resp?.message)
                setRefresh(prevValue => !prevValue)
                setApiCalling(false);
                handleToggle();
            }).catch(() => {
                setApiCalling(false);
            });
        }
    }

    const editHandler = (row) => {
        history.push({ pathname: `/certificate-template/edit/${row?.id}` })
    };

    const handleActionClick = (payload, actionType) => {
        const actionMapper = {
            edit: editHandler,
            delete: deleteHandler
        };

        if (actionMapper[actionType]) {
            actionMapper[actionType](payload);
        }
    };


    const columns = [
        {
            name: "Title",
            cell: (row) => <span>{row?.title}</span>,
            minWidth: "120px"
        },
        {
            name: "Date",
            cell: (row) => <span>{row?.date}</span>,
            minWidth: "120px"
        },
        {
            name: "Actions",
            width: "10%",
            omit: !isModulePermisssion(ROLE_PERMISSIONS?.UPDATE_CERTIFICATE_TEMPLATE) || !isModulePermisssion(ROLE_PERMISSIONS?.DELETE_CERTIFICATE_TEMPLATE),
            minWidth: "120px",
            cell: (row) => {
                return (
                    <>
                        {!row?.isAdmin ? <ActionButtons
                            edit={{
                                handleClick: () => handleActionClick(row, 'edit'),
                                isPermission: ROLE_PERMISSIONS?.UPDATE_CERTIFICATE_TEMPLATE,
                            }}
                            delete={{
                                handleClick: () => handleActionClick(row, 'delete'),
                                isPermission: ROLE_PERMISSIONS?.DELETE_CERTIFICATE_TEMPLATE,
                            }}
                        /> : null}
                    </>
                );
            },
        }
    ];

    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/certificate-template", heading: "Certificate Templates" }]

    return (
        <>
            <Breadcrumbs title="Certificate Templates" breadcrumbItem={breadcrumbItems} />
            <Card>
                <CardHeader>
                    <Row>
                        <Col md="3">
                            <RHFTextField
                                id="search"
                                name="search"
                                placeholder="Search here"
                                isController={false}
                                handleOnChange={optimizedFn}
                            />
                        </Col>
                        {isModulePermisssion(ROLE_PERMISSIONS.ADD_CERTIFICATE_TEMPLATE) &&
                            <Col>
                                <div className="col d-flex justify-content-end">
                                    <RHFButton
                                        btnName="Add Certificate"
                                        icon="plus"
                                        onClick={() => {
                                            history.push('/certificate-template/add')
                                        }}
                                    />
                                </div>
                            </Col>
                        }
                    </Row>
                </CardHeader>

                <CardBody className="table-responsive">
                    <Table
                        columns={columns}
                        dataURL={CERTIFICATE_TEMPLATE.listAllTemplates}
                        isRefresh={isRefresh}
                        filter={filterFields}
                    />
                </CardBody>
            </Card>

            <DialogBox
                isModelOpen={isModelOpen}
                handleToggle={handleToggle}
                modelSize="sm-100"
                title="Confirmation"
                actions={
                    <>
                        <RHFButton
                            btnName="Cancel"
                            outline={true}
                            onClick={handleToggle}
                        />
                        <RHFButton
                            btnName="Okay"
                            onClick={handleDelete}
                            isLoading={isApiCalling}
                        />
                    </>
                }
            >
                <div className="text-center">
                    <i className="mdi mdi-alert-circle-outline" style={{ fontSize: "5em", color: "orange" }} />
                    <h5>Are you sure?</h5>
                    <p className="fs-5">You won't be able to revert this!</p>
                </div>
            </DialogBox>
        </>

    )
}

export default CertificateTemplates;
