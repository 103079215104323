import {
    ADD_BU,
    ADD_BU_FAIL,
    ADD_BU_SUCCESS,

    GET_ALL_BU,
    GET_ALL_BU_FAIL,
    GET_ALL_BU_SUCCESS,

    UPDATE_BU,
    UPDATE_BU_FAIL,
    UPDATE_BU_SUCCESS,

    ACTIVE_DEACTIVE_BU,
    ACTIVE_DEACTIVE_BU_SUCCESS,
    ACTIVE_DEACTIVE_BU_FAIL,

} from "./actionTypes"

export const addBu = (payload) => {
    return {
        type: ADD_BU,
        payload,
    }
}

export const addBuSuccess = (data) => {
    return {
        type: ADD_BU_SUCCESS,
        payload: data
    }
}

export const addBuFail = (data) => {
    return {
        type: ADD_BU_FAIL,
        payload: data
    }
}

export const updateBu = (payload) => {
    return {
        type: UPDATE_BU,
        payload,
    }
}

export const updateBuSuccess = (data) => {
    return {
        type: UPDATE_BU_SUCCESS,
        payload: data
    }
}

export const updateBuFail = (data) => {
    return {
        type: UPDATE_BU_FAIL,
        payload: data
    }
}

export const getAllBu = (payload) => {
    return {
        type: GET_ALL_BU,
        payload,
    }
}

export const getAllBuSuccess = (data) => {
    return {
        type: GET_ALL_BU_SUCCESS,
        payload: data
    }
}

export const getAllBuFail = (data) => {
    return {
        type: GET_ALL_BU_FAIL,
        payload: data
    }
}


export const activeDeactiveBu = (payload) => {
    return {
        type: ACTIVE_DEACTIVE_BU,
        payload,
    }
}

export const activeDeactiveBuSuccess = (data) => {
    return {
        type: ACTIVE_DEACTIVE_BU_SUCCESS,
        payload: data
    }
}

export const activeDeactiveBuFail = (data) => {
    return {
        type: ACTIVE_DEACTIVE_BU_FAIL,
        payload: data
    }
}


