import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import ResetPassword from "./auth/resetPassword/reducer"
import ChangePassword from "./auth/changePassword/reducer"
import TwoFA from "./auth/twoFA/reducer"
import Agency from "./company/agency/reducer"
import User from "./user/reducer"
import Application from "./application/reducer"
import Network from "./network/reducer"
import Profile from "./auth/profile/reducer"
import bu from "./bu/reducer"
import Kickoff from "./kickoff/reducers"
import UserProfileTwoFA from "./auth/useProfileTwoFA/reducer"

//Calendar
import calendar from "./calendar/reducer"

//chat
import chat from "./chat/reducer"

//invoices
import invoices from "./invoices/reducer"

//contacts
import contacts from "./contacts/reducer"

const appReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  ResetPassword,
  ChangePassword,
  TwoFA,
  UserProfileTwoFA,
  Agency,
  User,
  Application,
  Network,
  bu,
  Kickoff,
  Profile,
  calendar,
  chat,
  invoices,
  contacts,
})

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer
