import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Breadcrumb from 'components/Common/Breadcrumb';
import { Card, CardBody, Col, Row } from 'reactstrap';
import RHFAutoCompleteSelect from 'components/FormControls/RHFAutoCompleteSelect';
import RHFDatePicker from 'components/FormControls/RHFDatePicker';
import RHFButton from 'components/FormControls/RHFButton';
import RHFTextField from 'components/FormControls/RHFTextField';
import { APPLICATION_ASSESSMENT_TYPE, APPLICATION_TYPES, APPLICATION_ASSET_TYPE, today, nameRegex } from 'constants/Constant';
import { addApplication, addRevalidateApplication, editApplication, getApplicationDetails, getUserListByRole, getUserTeam, listAllApplication, listAllBu, listAllTemplate } from 'helpers/BackendHelper';
import { Toast } from 'components/Common/Toaster';
import PageLoader from 'components/PageLoader';
import moment from 'moment/moment'

const ApplicationAddEdit = () => {
    const [applicationMembers, setApplicationMembers] = useState(null);
    const [businessUnits, setAllBusinessUnit] = useState([]);
    const [templateData, setAllTempalateData] = useState([]);
    const [applicationDetails, setApplicationDetails] = useState(null);
    const [showAppDropdown, setShowAppDropdown] = useState(false);
    const [appList, setAppList] = useState([]);
    const [isApiCalling, setApiCalling] = useState(false);
    const { applicationId } = useParams()
    const [pageLoader, setPageLoader] = useState(false);

    const [penDevUsers, setPenDevUsers] = useState({
        pentester: [],
        developer: []
    });

    let history = useHistory()
    const isEditMode = applicationId
    document.title = `${(isEditMode) ? "Edit Application" : "Add Application"} | RiDiscovery`;
    const applicationSchema = yup.object().shape({
        applicationName: yup
            .string().trim().matches(nameRegex, 'Please enter valid name')
            .required("Application Name is required"),
        applicationType: yup
            .object()
            .shape({ label: yup.string(), value: yup.string() })
            .nullable()
            .required("Select atleast one option"),
        assessmentType: yup
            .object()
            .shape({ label: yup.string(), value: yup.string() })
            .nullable()
            .required("Select atleast one option"),

        application: showAppDropdown && yup
            .object()
            .shape({ label: yup.string(), value: yup.string() })
            .nullable()
            .required("Select atleast one option"),

        startDate: yup.date().nullable()
            .transform((curr, orig) => orig === '' ? null : curr)
            .typeError("Date is required")
            .test("startDate", 'Start date should not be from the past', function (value) {
                if (value) {
                    if (isEditMode) {
                        if (moment(applicationDetails?.startDate).diff(today, 'days') <= 0) {
                            return true
                        } else if ((moment(value).diff(today, 'days') >= 0)) {
                            return true
                        }
                    } else if (moment(value).diff(today, 'days') >= 0) {
                        return true
                    }
                } else {
                    return true
                }
            })
    });

    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(applicationSchema),
    });

    useEffect(() => {
        const rolePayload = {
            "roleIds": ["da_manager", "sm_manager", "developer", 'pentester']
        }
        setPageLoader(true)
        Promise.all([
            getUserListByRole(rolePayload),
            listAllBu(),
            listAllTemplate()
        ]).then(([roleResp, buResp, templateResp]) => {
            const members = roleResp?.data?.reduce(function (r, a) {
                r[a?.roleId] = r[a?.roleId] || [];
                r[a?.roleId].push({ label: `${a?.firstName} ${a?.lastName}`, value: +a?.id, companyId: a?.companyId });
                return r;
            }, Object.create(null));
            const buOptions = buResp?.data?.filter((bu) => bu?.isActive === true).map((bu) => ({ label: bu?.name, value: bu?.id }));
            const templateOptions = templateResp?.data?.map((template) => ({ label: template?.name, value: template?.id, isDefault: template?.isDefault }))
            setApplicationMembers(members);
            setAllBusinessUnit(buOptions);
            setAllTempalateData(templateOptions)
            setPageLoader(false)
        })
    }, []);

    useEffect(() => {
        if (templateData && !isEditMode) {
            setValue('reportingTemplate', templateData?.find(o => o.isDefault === true))
        }
    }, [templateData])


    useEffect(() => {
        if (isEditMode) {
            const params = {
                id: applicationId
            }
            getApplicationDetails({ ...params }).then(resp => {
                setApplicationDetails(resp?.data);
            })
        }
    }, [applicationId]);

    const autofillForm = (data) => {
        if (!data) {
            setApplicationDetails(null);
        } else {
            const params = {
                id: data?.id
            }
            getApplicationDetails({ ...params }).then(resp => {
                delete resp?.data?.startDate;
                setApplicationDetails(resp?.data);
            })
        }
    }

    const handleAutoComplete = (data, name) => {
        setValue(name, data);
    }

    const handleAssesmentType = (data) => {
        if ([APPLICATION_ASSESSMENT_TYPE[1].value, APPLICATION_ASSESSMENT_TYPE[3].value].includes(data?.value)) {
            setShowAppDropdown(true);
            listAllApplication().then(resp => {
                const appListing = [];
                resp?.data?.filter(o => o?.applicationStatus === 'completed')?.map(item => appListing.push({ label: `${item?.penId} (${item?.applicationName})`, value: item?.id, ...item }));
                setAppList(appListing)

            })
        } else {
            setShowAppDropdown(false);
            const assessmentType = getValues('assessmentType');
            reset()
            setValue('assessmentType', assessmentType);
        }
    }

    const handleOnChange = (data, name, type, action) => {
        const payload = {
            "roleIds": [type],
            "companyIds": data.map(o => o.companyId)
        }
        getUserTeam(payload).then(resp => {
            const list = resp?.data?.map(o => ({ label: `${o?.firstName} ${o?.lastName}`, value: +o?.id, companyId: o?.companyId }))
            const obj = {};
            obj[`${type}`] = data?.length > 0 ? list : [];
            setPenDevUsers(prevUser => ({
                ...prevUser,
                ...obj
            }))

            if (action?.action === 'remove-value') {
                let assignUsers = type === 'pentester' ? getValues('securityTeam') : getValues('developmentTeam');
                setValue(type === 'pentester' ? 'securityTeam' : 'developmentTeam', assignUsers?.filter(a => a.companyId !== action?.removedValue?.companyId))
            }
        })
    }

    const onSubmit = (values) => {

        setApiCalling(true);
        if (!isApiCalling) {
            const payload = {
                applicationName: values?.applicationName,
                applicationType: values?.applicationType?.value,
                assessmentType: values?.assessmentType?.value,

                developmentManager: values?.developmentManager?.map(o => +o.value),
                developmentTeam: values?.developmentTeam?.map(o => +o.value),
                securityManager: values?.securityManager?.map(o => +o.value),
                securityTeam: values?.securityTeam?.map(o => +o.value),

                startDate: values?.startDate,
                businessUnit: values?.businessUnit?.map(o => +o.value),
                reportingTemplate: values?.reportingTemplate?.value,
                assetType: values?.assetType?.value,

                parentPenId: showAppDropdown ? +values?.application?.value || null : null
            }

            if (isEditMode) {
                editApplication({ ...payload, applicationStatus: applicationDetails?.applicationStatus, applicationId: applicationDetails?.id }).then(resp => {
                    history.push({ pathname: '/applications' })
                    Toast.success(resp?.message)
                    setApiCalling(false);
                }).catch(() => {
                    setApiCalling(false);
                });
            } else {

                if (showAppDropdown) {
                    addRevalidateApplication(payload).then(resp => {
                        if (resp?.status) {
                            Toast.success(resp?.message)
                            const appID = resp?.data?.id
                            history.push({ pathname: `/application/${appID}/add/kickoff`, state: { objData: resp?.data } })
                            setApiCalling(false);
                        }
                    }).catch(() => {
                        setApiCalling(false);
                    });
                } else {
                    addApplication(payload, history).then(resp => {
                        if (resp?.status) {
                            Toast.success(resp?.message)
                            const appID = resp?.data?.id
                            history.push({ pathname: `/application/${appID}/add/kickoff`, state: { objData: resp?.data } })
                            setApiCalling(false);
                        }
                    }).catch(() => {
                        setApiCalling(false);
                    });
                }
            }
            reset();
        }
    };

    useEffect(() => {
        if (isEditMode || showAppDropdown) {
            setValue('applicationName', applicationDetails?.applicationName)
            setValue('applicationType', APPLICATION_TYPES?.find(o => o.value === applicationDetails?.applicationType))
            isEditMode && setValue('assessmentType', APPLICATION_ASSESSMENT_TYPE?.find(o => o.value === applicationDetails?.assessmentType))

            setValue('securityManager', applicationMembers?.sm_manager?.filter((data) => applicationDetails?.securityManager?.map(o => o?.userId).includes(data.value)))
            setValue('developmentManager', applicationMembers?.da_manager?.filter((data) => applicationDetails?.developmentManager?.map(o => o?.userId).includes(data.value)))
            setValue('securityTeam', applicationMembers?.pentester?.filter((data) => applicationDetails?.securityTeam?.map(o => o?.userId).includes(data.value)))
            setValue('developmentTeam', applicationMembers?.developer?.filter((data) => applicationDetails?.developmentTeam?.map(o => o?.userId).includes(data.value)))

            setValue('businessUnit', businessUnits?.filter((data) => applicationDetails?.businessUnit?.map(o => o?.unitId).includes(data.value)))
            setValue('startDate', applicationDetails?.startDate)
            setValue('reportingTemplate', templateData?.find(o => o.value === applicationDetails?.reportingTemplate))
            setValue('assetType', APPLICATION_ASSET_TYPE?.find(o => o.value === applicationDetails?.assetType))

            applicationDetails && handleOnChange(applicationDetails?.securityManager?.map(o => o.user), 'name', 'pentester')
            applicationDetails && handleOnChange(applicationDetails?.developmentManager?.map(o => o.user), 'name', 'developer')
        }
        else {
            setValue(null)
        }
    }, [applicationDetails, applicationMembers]);

    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/applications", heading: "Application" },
    { path: `/application/add`, heading: (!isEditMode) ? "Add Application" : "Edit Application" }]

    return (
        <React.Fragment>
            {pageLoader ?
                <PageLoader />
                :
                <div className="page-content">
                    <Breadcrumb title={!isEditMode ? "Add Application Details" : "Edit Application Details"} breadcrumbItem={breadcrumbItems} />
                    <Card>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Row className="mb-3">
                                    <Col sm={6}>
                                        <RHFAutoCompleteSelect
                                            id="assessmentType"
                                            label="Assessment Type"
                                            name="assessmentType"
                                            options={APPLICATION_ASSESSMENT_TYPE}
                                            isMultiple={false}
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            disabledField={isEditMode}
                                            handleOnChange={handleAssesmentType}
                                        />
                                    </Col>
                                    {showAppDropdown && !isEditMode &&
                                        <Col sm={6}>
                                            <RHFAutoCompleteSelect
                                                id="application"
                                                label="Applications"
                                                name="application"
                                                options={appList && appList}
                                                isMultiple={false}
                                                errorobj={errors}
                                                control={control}
                                                isController={true}
                                                handleOnChange={autofillForm}
                                            />
                                        </Col>
                                    }
                                </Row>

                                <Row className="mb-3">
                                    <Col sm="6">
                                        <RHFTextField
                                            id="applicationName"
                                            label="Application Name"
                                            name="applicationName"
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            disabledField={showAppDropdown || [APPLICATION_ASSESSMENT_TYPE[1].value, APPLICATION_ASSESSMENT_TYPE[3].value].includes(applicationDetails?.assessmentType) ? true : false}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <RHFAutoCompleteSelect
                                            id="applicationType"
                                            label="Application Type"
                                            name="applicationType"
                                            options={APPLICATION_TYPES}
                                            isMultiple={false}
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            handleOnChange={handleAutoComplete}
                                            disabledField={isEditMode || showAppDropdown || [APPLICATION_ASSESSMENT_TYPE[1].value, APPLICATION_ASSESSMENT_TYPE[3].value].includes(applicationDetails?.assessmentType) ? true : false}
                                        />
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col sm="6">
                                        <RHFAutoCompleteSelect
                                            id="securityManager"
                                            label="Security Manager"
                                            name="securityManager"
                                            options={applicationMembers && applicationMembers?.sm_manager || []}
                                            isMultiple={true}
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            isRequired={false}
                                            handleOnChange={(data, name, action) => handleOnChange(data, name, 'pentester', action)}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <RHFAutoCompleteSelect
                                            id="developmentManager"
                                            label="Development Manager"
                                            name="developmentManager"
                                            options={applicationMembers && applicationMembers?.da_manager || []}
                                            isMultiple={true}
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            isRequired={false}
                                            handleOnChange={(data, name, action) => handleOnChange(data, name, 'developer', action)}
                                        />
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col sm="6">
                                        <RHFAutoCompleteSelect
                                            id="securityTeam"
                                            label="Security Team"
                                            name="securityTeam"
                                            // options={applicationMembers ? applicationMembers?.pentester : []}
                                            options={penDevUsers && penDevUsers?.pentester || []}
                                            isMultiple={true}
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            isRequired={false}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <RHFAutoCompleteSelect
                                            id="developmentTeam"
                                            label="Development Team"
                                            name="developmentTeam"
                                            // options={applicationMembers ? applicationMembers?.developer : []}
                                            options={penDevUsers && penDevUsers?.developer || []}
                                            isMultiple={true}
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            isRequired={false}
                                        />
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col sm="6">
                                        <RHFAutoCompleteSelect
                                            id="businessUnit"
                                            label="Bussiness Unit"
                                            name="businessUnit"
                                            options={businessUnits ? businessUnits : []}
                                            isMultiple={true}
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            isRequired={false}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <RHFAutoCompleteSelect
                                            id="assetType"
                                            label="Asset Type"
                                            name="assetType"
                                            options={APPLICATION_ASSET_TYPE}
                                            isMultiple={false}
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            isRequired={false}
                                        />
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col sm="6">
                                        <RHFAutoCompleteSelect
                                            id="reportingTemplate"
                                            label="Templates"
                                            name="reportingTemplate"
                                            options={templateData ? templateData : []}
                                            isMultiple={false}
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            isRequired={false}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <RHFDatePicker
                                            name="startDate"
                                            label="Start Date"
                                            errorobj={errors}
                                            control={control}
                                            isController={true}
                                            isRequired={false}
                                            disabledField={applicationDetails?.startDate && moment(applicationDetails?.startDate).diff(today, 'days') <= 0 && true}
                                        />
                                    </Col>
                                </Row>
                                <div className="d-flex justify-content-end">
                                    <RHFButton
                                        className="mx-2"
                                        btnName="Cancel"
                                        outline={true}
                                        onClick={() => history.push('/applications')}
                                    />
                                    <RHFButton
                                        btnName="Save"
                                        type="submit"
                                        isLoading={isApiCalling}
                                    />
                                </div>

                            </form>
                        </CardBody>
                    </Card>

                </div >
            }
        </React.Fragment >
    )
}

export default ApplicationAddEdit;
