const pageSectionStyle = { borderTop: "2px solid black", opacity: 1 };

export default function PageSection({ title, pageId, index, marginLeft, marginRight, children, header = null, pageHeader = true, hasTitle = true, isReport = true }) {
    return (
        <div className="page" id={pageId}
            style={{
                // paddingTop: pageHeader ? "80px" : "0px",
                minHeight: pageHeader && "calc(297mm - 105px)"
            }}>
            {!header &&
                <>
                    {hasTitle && <>
                        <h3 className="text-capitalize text-dark" id={pageId}>{(index || index === 0) && `${index + 1}.`} {title}</h3>
                        {isReport && <hr style={pageSectionStyle} />}
                    </>}
                </>
            }
            {header}
            {children}
        </div >
    )
}