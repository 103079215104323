import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";
import { updateApplicationStatus } from "helpers/BackendHelper";
import { Toast } from "./Toaster";
import ApplicationStatus from "components/ApplicationStatus";

const Breadcrumb = ({ title, breadcrumbItem, badgeTitle, dropdown = null, setRefreshAPI }) => {
  const lastCrumb = breadcrumbItem?.slice(-1)[0];
  const handleOnChange = (val) => {
    updateApplicationStatus({
      "applicationStatus": val?.id,
      "applicationId": dropdown?.id
    }).then(() => {
      Toast.success('Application status changed successfully');
      setRefreshAPI();
    })
  }

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{title} {" "}
            {badgeTitle &&
              (
                // <span className="badge-soft-info text-secondary badge fs-6 px-2 mx-2">{badgeTitle}</span>
                <span className="textChip" style={{ backgroundColor: "#2D31FA", color: "#fff", fontSize: "13px" }}>
                  {badgeTitle}
                </span>
              )}
          </h4>


          {dropdown?.selected &&
            <span className="me-auto">
              <ApplicationStatus
                heading={dropdown?.selected}
                isSearchable={false}
                menuItems={dropdown?.dropdownArr}
                handleClick={(item) => handleOnChange(item)}
                dropdown={dropdown}
              />
            </span>
          }

          <div className="page-title-right">
            {breadcrumbItem?.length > 0 &&
              <ol className="breadcrumb m-0">
                {breadcrumbItem?.map((breadcrumb, index) => (
                  <BreadcrumbItem key={index}>
                    <Link className={(breadcrumb === lastCrumb) ? "inactive" : ""} to={breadcrumb?.path || ""} >
                      <span className={(breadcrumb === lastCrumb) ? "text-secondary" : ""}>{breadcrumb?.heading}
                      </span>
                    </Link>
                  </BreadcrumbItem>
                ))}
              </ol>
            }
          </div>
        </div>
      </Col>
    </Row>
  );
};

Breadcrumb.propTypes = {
  title: PropTypes.string,
};

export default Breadcrumb;