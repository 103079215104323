import React, { useState } from 'react';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";


const ImageViewer = (props) => {

    const {
        images, isOpenViewer, activeImageIndex, toggle = () => { }
    } = props;

    const [option, setOption] = useState({
        photoIndex: activeImageIndex,
        isOpen: isOpenViewer
    })

    return (
        option?.isOpen ?
            <Lightbox
                mainSrc={images[option?.photoIndex]}
                nextSrc={images[(option?.photoIndex + 1) % images.length]}
                prevSrc={images[(option?.photoIndex + images.length - 1) % images.length]}
                onCloseRequest={() => {
                    setOption({
                        photoIndex: 0,
                        isOpen: false
                    });
                    toggle();
                }}
                onMovePrevRequest={() =>
                    setOption(prevState => ({ ...prevState, photoIndex: (prevState?.photoIndex + images.length - 1) % images.length }))
                }
                onMoveNextRequest={() =>
                    setOption(prevState => ({ ...prevState, photoIndex: (prevState?.photoIndex + 1) % images.length }))
                }
            /> : ''

    )
}

export default ImageViewer;