export const GET_ALL_APPLICATION = "GET_ALL_APPLICATION"
export const GET_ALL_APPLICATION_SUCCESS = "GET_ALL_APPLICATION_SUCCESS"
export const GET_ALL_APPLICATION_FAIL = "GET_ALL_APPLICATION_FAIL"

export const ADD_APPLICATION = "ADD_APPLICATION"
export const ADD_APPLICATION_SUCCESS = "ADD_APPLICATION_SUCCESS"
export const ADD_APPLICATION_FAIL = "ADD_APPLICATION_FAIL"

export const UPDATE_APPLICATION = "UPDATE_APPLICATION"
export const UPDATE_APPLICATION_SUCCESS = "UPDATE_APPLICATION_SUCCESS"
export const UPDATE_APPLICATION_FAIL = "UPDATE_APPLICATION_FAIL"

export const DELETE_APPLICATION = "DELETE_APPLICATION"
export const DELETE_APPLICATION_SUCCESS = "DELETE_APPLICATION_SUCCESS"
export const DELETE_APPLICATION_FAIL = "DELETE_APPLICATION_FAIL"


