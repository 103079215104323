import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { Toast } from "components/Common/Toaster"
// Login Redux States
import { addUser, editUser, listAllUser, activeDeactiveUser, profileUpload, getSignedUrl } from "helpers/BackendHelper"
import { ACTIVE_DEACTIVE_USER, ADD_USER, GET_ALL_USER, GET_SIGNED_URL, USER_PROFILE_UPLOAD, USER_UPDATE } from "./actionTypes"
import {
  activeDectiveUserFail, activeDectiveUserSuccess, addUserFail, addUserSuccess,
  getAllUserFail, getAllUserSuccess, getSignedUrlFail, getSignedUrlSuccess, userProfileUploadFail, userProfileUploadSuccess, userUpdateFail, userUpdateSuccess
} from "./actions"


/* Add User */
function* addNewUser({ payload }) {
  try {
    const response = yield call(addUser, payload)
    yield put(addUserSuccess(response))
    Toast.success(response.message)
  } catch (error) {
    yield put(addUserFail(error))
  }
}

/* Update User */
function* userUpdate({ payload }) {
  try {
    const response = yield call(editUser, payload)
    yield put(userUpdateSuccess(response))
    Toast.success(response.message)
  } catch (error) {
    yield put(userUpdateFail(error))
  }
}

/* Get All User */
function* getAllUSers({ payload }) {
  try {
    const response = yield call(listAllUser, payload)
    yield put(getAllUserSuccess(response))
  } catch (error) {
    yield put(getAllUserFail(error))
  }
}

/* ACtive - Deactive User */
function* activeDeactiveUserStatus({ payload }) {
  try {
    const response = yield call(activeDeactiveUser, payload)
    yield put(activeDectiveUserSuccess(response))
    Toast.success(response.message)
  } catch (error) {
    yield put(activeDectiveUserFail(error))
  }
}

function* uploadProfile({ payload }) {
  try {
    const response = yield call(profileUpload, payload)
    yield put(userProfileUploadSuccess(response?.data))
  } catch (error) {
    yield put(userProfileUploadFail(error))
  }
}
function* getSignedUrlData({ payload }) {
  try {
    const response = yield call(getSignedUrl, payload)
    yield put(getSignedUrlSuccess(response?.data))
  } catch (error) {
    yield put(getSignedUrlFail(error))
  }
}


export function* watchUsers() {
  yield takeEvery(ADD_USER, addNewUser)
  yield takeEvery(USER_UPDATE, userUpdate)
  yield takeEvery(GET_ALL_USER, getAllUSers)
  yield takeEvery(ACTIVE_DEACTIVE_USER, activeDeactiveUserStatus)
  yield takeEvery(USER_PROFILE_UPLOAD, uploadProfile)
  yield takeEvery(GET_SIGNED_URL, getSignedUrlData)

}

function* userSaga() {
  yield all([fork(watchUsers)])
}

export default userSaga;

