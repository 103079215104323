import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// BU Redux States
import { activeDeactivebu, addBu, editBu, listAllBu } from "helpers/BackendHelper"
import { ADD_BU, UPDATE_BU, GET_ALL_BU, ACTIVE_DEACTIVE_BU } from "./actionTypes"
import { activeDeactiveBuFail, activeDeactiveBuSuccess, addBuFail, addBuSuccess, getAllBuFail, getAllBuSuccess, updateBuFail, updateBuSuccess, } from "./actions"
import { Toast } from "components/Common/Toaster"


/* Add BU */
function* addNewBu({ payload }) {
    try {
        const response = yield call(addBu, payload)
        yield put(addBuSuccess(response))
        Toast.success(response.message)
    } catch (error) {
        yield put(addBuFail(error))
    }
}

/* Update BU */
function* updateBu({ payload }) {
    try {
        const response = yield call(editBu, payload)
        yield put(updateBuSuccess(response))
        Toast.success(response.message)
    } catch (error) {
        yield put(updateBuFail(error))
    }
}

/* Get All BU */
function* getAllBu({ payload }) {
    try {
        const response = yield call(listAllBu, payload)
        yield put(getAllBuSuccess(response))
    } catch (error) {
        yield put(getAllBuFail(error))
    }
}

/* ACtive - Deactive BU */
function* activeDeactiveBuStatus({ payload }) {
    try {
        const response = yield call(activeDeactivebu, payload)
        yield put(activeDeactiveBuSuccess(response))
        Toast.success(response.message)
    } catch (error) {
        yield put(activeDeactiveBuFail(error))
    }
}



export function* watchBu() {
    yield takeEvery(ADD_BU, addNewBu)
    yield takeEvery(UPDATE_BU, updateBu)
    yield takeEvery(GET_ALL_BU, getAllBu)
    yield takeEvery(ACTIVE_DEACTIVE_BU, activeDeactiveBuStatus)
}

function* BuSaga() {
    yield all([fork(watchBu)])
}

export default BuSaga;
