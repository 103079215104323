export const VERIFY_TANENT = "VERIFY_TANENT"
export const VERIFY_TANENT_SUCCESS = "VERIFY_TANENT_SUCCESS"
export const VERIFY_TANENT_FAIL = "VERIFY_TANENT_FAIL"

export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const LOGOUT_USER_FAIL = "LOGOUT_USER_FAIL"

export const GET_PERMISSION = "GET_PERMISSION"
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS"
export const GET_PERMISSION_FAIL = "GET_PERMISSION_FAIL"

export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

export const GET_LOGIN_USER_DETAIL = "GET_LOGIN_USER_DETAIL"

export const RESET_USER = "RESET_USER"

export const SET_USER_DETAIL_NOW = "SET_USER_DETAIL_NOW"
