import React, { useState, useEffect } from 'react';
import FeatherIcon from "feather-icons-react";
import { Popover, PopoverBody, UncontrolledTooltip } from 'reactstrap';
import RHFTextField from 'components/FormControls/RHFTextField';
import DefaultUser from 'assets/images/Default-User-Image.jpeg';
import { makeAPICall } from 'helpers/ApiHelper';
import { APPLICATION } from 'helpers/Services/Application';
import './AvtarGroup.scss';
import { getAppMemberByType } from 'helpers/BackendHelper';
import { NETWORK } from 'helpers/Services/Network';
import { Toast } from 'components/Common/Toaster';

const AvtarGroup = (props) => {
    const { allUsers = [], assignedUsers = [], max = 3, type = null, appId = null, popUpId, requestType = 'application', assignRemovePermission = true, isDetailPage = false, getAssignedUsers } = props;

    // const [allUsersCopy, setAllUsers] = useState(allUsers);
    // const [avtarUsers, setAvtarUsers] = useState(allUsers);

    const [flag, setFlag] = useState(false);

    const [allUsersCopy, setAllUsers] = useState({
        pentester: [],
        developer: [],
        da_manager: allUsers?.da_manager || [],
        sm_manager: allUsers?.sm_manager || []

    });
    const [avtarUsers, setAvtarUsers] = useState({
        pentester: [],
        developer: [],
        da_manager: allUsers?.da_manager || [],
        sm_manager: allUsers?.sm_manager || []
    });


    const [assignedUsersCopy, setAssignUsers] = useState(assignedUsers);

    useEffect(() => {
        setAssignUsers(assignedUsers)
    }, [assignedUsers])

    const [dropdownOpen, setDropdownOpen] = useState(false);


    useEffect(() => {
        if (dropdownOpen) {
            const obj = {};
            obj[type] = allUsersCopy?.[type]?.map(o => assignedUsersCopy?.map(assignUser => assignUser?.id).includes(o?.id) ? ({ ...o, isAssigned: true }) : ({ ...o, isAssigned: false }))
            setAvtarUsers(prevData => ({ ...prevData, ...obj }));
            setAllUsers(prevData => ({ ...prevData, ...obj }))
            setFlag(false);
        }
    }, [dropdownOpen, flag])

    const toggle = () => {
        setDropdownOpen((prevState) => !prevState);
        setAvtarUsers(allUsers)

        if (!dropdownOpen) {
            if (['pentester', 'developer'].includes(type)) {

                const payload = {
                    "type": type,
                    "requestType": requestType
                }

                if (requestType === 'application') {
                    payload['applicationId'] = appId;
                } else {
                    payload['networkId'] = appId;
                }

                getAppMemberByType(payload).then(resp => {
                    setFlag(true);
                    const list = resp?.data?.map(o => ({ user_name: `${o?.firstName} ${o?.lastName}`, ...o }))
                    const obj = {};
                    obj[`${type}`] = resp?.data?.length > 0 ? list : [];
                    setAllUsers(prevUser => ({
                        ...prevUser,
                        ...obj
                    }))
                    setAvtarUsers(prevUser => ({
                        ...prevUser,
                        ...obj
                    }))
                })
            }
        }

    };

    const handleSearch = (val) => {
        const obj = {};
        obj[type] = allUsersCopy[type].filter(o => o?.user_name?.toLowerCase().indexOf(val.toLowerCase()) > -1); // allUsers
        setAvtarUsers(prevUser => ({ ...prevUser, ...obj }))
    }

    const manageAppUsers = (user) => {

        if (assignRemovePermission) {
            let URL
            if (appId) {
                let payload = {
                    "assignedUserId": null,
                    "removedUserId": null,
                    "team": user?.roleId,
                    // "applicationId": appId
                }

                if (requestType === 'application') {
                    payload['applicationId'] = appId;
                    URL = APPLICATION.addRemoveAppUser
                } else {
                    payload['networkId'] = appId;
                    URL = NETWORK.addRemoveNetworkUser
                }

                if (user?.isAssigned) payload = { ...payload, removedUserId: user?.id }
                else payload = { ...payload, assignedUserId: user?.id }

                makeAPICall({ option: URL, data: payload }).then((resp) => {
                    Toast.success(resp?.message)
                    let data = [...assignedUsersCopy];
                    const userObj = data.find(o => o?.id === user?.id);
                    if (userObj) {
                        data = data.filter(o => o.id !== userObj?.id);
                    }
                    else {
                        data.push({ ...user, isAssigned: true })
                    }
                    setAssignUsers(data);
                    if (isDetailPage) {
                        getAssignedUsers(data)
                    }
                })
                toggle();
            }
        } else {
            Toast.error('Opps! Permission Denied')
        }
    }

    return (
        <div className="avatars d-flex flex-row-reverse justify-content-end">
            {assignRemovePermission ?
                <div key='add-user' className="avatar" id={`popover_${popUpId}`} onClick={() => toggle()}>
                    <FeatherIcon icon='user-plus' className="rounded-circle avatar-sm user-add cursor-pointer" />{" "}
                </div>
                :

                assignedUsersCopy?.length > max &&
                <div key='add-user' className="avatar" id={`popover_${popUpId}`} onClick={() => toggle()}>
                    <FeatherIcon icon='eye' className="rounded-circle avatar-sm user-add cursor-pointer" />
                    <span className='user-count badge rounded-pill bg-danger'>{assignedUsersCopy?.length - max}+</span>
                </div>
            }

            <Popover
                placement="right"
                isOpen={dropdownOpen}
                // target={`popover_${popUpId}`}
                // target={assignRemovePermission ? `popover_${popUpId}` : null}
                target={assignRemovePermission || assignedUsersCopy?.length > max ? `popover_${popUpId}` : null}
                toggle={toggle}
                trigger="legacy"
            >
                <PopoverBody>
                    <RHFTextField
                        isController={false}
                        name="Search"
                        placeholder="Search"
                        className='form-control-sm'
                        handleOnChange={handleSearch}
                    />
                    <div className='avatar-wrapper'>
                        {

                            assignRemovePermission ?
                                avtarUsers?.[type]?.length > 0 ? avtarUsers?.[type]?.map(user => {
                                    return (
                                        <div className='avatar-row' key={user?.id} onClick={() => manageAppUsers(user)}>
                                            <img
                                                src={user?.profile || DefaultUser}
                                                className={`${user?.isAssigned && 'active'}`}
                                                alt="asignee"
                                            />
                                            <div className='avatar-info ms-2'>
                                                <p className={`${user?.isAssigned && 'fw-bold text-primary'}`}>
                                                    {user?.user_name}
                                                </p>
                                                <p className='font-size-10'>
                                                    {user?.company?.companyName}
                                                </p>

                                            </div>
                                        </div>
                                    )
                                }) : <span className='fs-6'>No Member's Found</span>
                                :

                                avtarUsers?.[type]?.length > 0 ? avtarUsers?.[type]?.filter((user) => user?.isAssigned)?.map(user => {
                                    return (
                                        <div className='avatar-row' key={user?.id}>
                                            <img
                                                src={user?.image || DefaultUser}
                                                className={`${user?.isAssigned && 'active'}`}
                                                alt="asignee"
                                            />
                                            <div className='avatar-info ms-2'>
                                                <p className={` ${user?.isAssigned && 'fw-bold text-primary'}`}>{user?.user_name}
                                                </p>
                                                <p className='font-size-10'>
                                                    {user?.company?.companyName}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                }) : <span className='fs-6'>No Member's Found</span>
                        }



                    </div>
                </PopoverBody>
            </Popover>

            {assignedUsersCopy?.slice(0, max)?.map((avtar, index) => {
                return (
                    <React.Fragment key={avtar?.id}>
                        <div className="avatar" id={`tooltip_${avtar?.id}`}>
                            <img src={avtar?.profile || DefaultUser} alt="assigned-users" className="rounded-circle avatar-sm" />
                        </div>
                        <UncontrolledTooltip
                            placement="left"
                            target={`tooltip_${avtar?.id}`}
                        >
                            <span>{`${avtar?.user_name}`}</span><br />
                            <span>{`${avtar?.company?.companyName}`}</span>
                        </UncontrolledTooltip>
                    </React.Fragment>
                )
            })}
        </div>
    )
}


export default React.memo(AvtarGroup);