import React, { useState, useEffect } from 'react'
import RHFButton from 'components/FormControls/RHFButton'
import * as yup from "yup";
import { Card, CardBody, CardHeader, CardText, Col, Row } from 'reactstrap'
import Breadcrumb from 'components/Common/Breadcrumb'
import { useParams } from 'react-router-dom'
import { CVSS, CVSS_TEXT_COLOR, FINDING_LOGS, FINDING_SCORE_COLORS, FINDING_STATUS, FINDING_STATUS_OVERVIEW } from 'constants/Constant'
import RHFTextField from 'components/FormControls/RHFTextField'
import { addComment, approveRejectException, editFinding, getApplicationDetails, getFinding, getFindingLogs } from 'helpers/BackendHelper'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import RHFCheckbox from 'components/FormControls/RHFCheckbox'
import { applicationScore, htmlSanitizer, isModulePermisssion, renderTimestamp, TitleCase } from 'helpers/util'
import defaultImage from "assets/images/Default-User-Image.jpeg";
import { ROLE_PERMISSIONS } from 'constants/RolePermissions'
import RHFAutoCompleteSelect from 'components/FormControls/RHFAutoCompleteSelect'
import TextChip from 'components/Common/TextChip'
import { useSelector } from 'react-redux'
import FeatherIcon from 'feather-icons-react';

import { Toast } from 'components/Common/Toaster'
import ImageViewer from 'components/ImageViewer'
import PageLoader from 'components/PageLoader'
import moment from 'moment';


const FindingOverview = () => {
    document.title = "Finding-Overview | RiDiscovery";
    const { findingId, applicationId } = useParams();
    const [isException, setException] = useState(false);
    const [commentList, setCommentList] = useState([]);
    const [findingDetails, setFindingDetails] = useState(null);
    const [showTextBox, setShowTextBox] = useState(false);
    const [correctionText, setCorrectionText] = useState('');
    const [applicationData, setApplicationData] = useState([])
    const [isOpenViewer, setImageViewer] = useState(false);
    const [activePoc, setActivePoc] = useState(null);
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const { user } = useSelector(state => state?.Login);
    const [pageLoader, setPageLoader] = useState(false);
    const [findingLogs, setFindingLogs] = useState([]);
    const [isApiCalling, setApiCalling] = useState(false);
    const [disableApprovalText, setDisableApprovalText] = useState('');

    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/applications", heading: "Application" },
    { path: `/application/${applicationId}/overview`, heading: `${applicationData.penId || 'Application Overview'}` },
    { path: `/application/${applicationId}/finding/${findingId}/overview`, heading: `Finding Overview` }]

    const isApproveRejectPermission = isModulePermisssion(ROLE_PERMISSIONS.APPLICATION_APPROVE_REJECT_EXCEPTIONS);

    const commentSchema = yup.object().shape({
        comment: yup.string().trim().max(200, 'Comment should be less then 200 charecters').required('Comment is required')
    });
    const correctionSchema = yup.object().shape({
        correctionDescription: yup.string().trim().max(200, 'Correction should be less then 200 charecters').required('Correction is required')
    });

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(commentSchema),
    });

    const {
        handleSubmit: handleSubmit2,
        control: control2,
        formState: { errors: errors2 },
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(correctionSchema),
    });

    const apiCalls = () => {
        const params = {
            findingId: findingId
        }
        setPageLoader(true);
        getFinding({ ...params }).then(resp => {
            setFindingDetails(resp?.data);
            setCommentList(resp?.data?.comments.sort((a, b) => Number(b.isException) - Number(a.isException)));
            setPageLoader(false);
        })
        const applicationParams = {
            id: applicationId
        }
        if (applicationId) {
            getApplicationDetails({ ...applicationParams }).then(resp => {
                setApplicationData(resp?.data);
            })

            getFindingLogs({ id: findingId }).then(resp => {
                setFindingLogs(resp?.data);
            })
        }
    }

    useEffect(() => {
        apiCalls();
    }, []);

    useEffect(() => {
        if (FINDING_STATUS_OVERVIEW[2].value === findingDetails?.status && findingDetails) {
            setShowTextBox(true);
            setCorrectionText(findingDetails?.correctionDescription)
        }
    }, [[FINDING_STATUS_OVERVIEW[2].value].includes(findingDetails?.status), findingDetails])

    const handleSwitchChange = (val) => {
        setException(val);
    };

    const onSubmit = (values) => {
        setApiCalling(true)
        if (!isApiCalling) {
            const data = {
                comment: values?.comment,
                isException,
                "findingId": findingId,
                "findingType": "application"
            }
            addComment(data).then(resp => {
                setApiCalling(false);
                // setCommentList((prevData) => [resp?.data, ...prevData])
                let newCommentList = [resp?.data, ...commentList]
                let sortedCommentsExceptionWise = newCommentList.sort((a, b) => Number(b.isException) - Number(a.isException))
                setCommentList(sortedCommentsExceptionWise)
                getFindingLogs({ id: findingId }).then(response => {
                    setFindingLogs(response?.data);
                })
                Toast.success(resp?.message)
                reset()
            }).catch(() => {
                setApiCalling(false);
            });
        }
    }

    const correctionSubmitHandler = (val) => {
        setApiCalling(true);
        editFinding({
            "status": FINDING_STATUS_OVERVIEW[2].value,
            "correctionDescription": val?.correctionDescription,
            "findingId": findingId,
            "applicationId": applicationId,
            "type": 'status'
        }).then(() => {
            setShowTextBox(false);
            setApiCalling(false)
            Toast.success('Correction reported successfully.')
        }).catch(() => {
            setApiCalling(false)
            Toast.error('Correction reported Failed.')
        })
    }

    const takeAction = ({ obj, action }) => {
        const payload = {
            "commentId": obj?.id,
            "isException": obj?.isException,
            "status": action,
            "findingId": findingId
        }
        approveRejectException(payload).then(resp => {
            Toast.success(resp?.message);
            apiCalls();
        });
    }

    const hanldeStatus = (val) => {
        if (val) {

            const payload = {
                "findingId": findingId,
                "applicationId": applicationId
            }
            if (FINDING_STATUS?.map(o => o.value)?.includes(val)) {
                payload.findingStatus = val;
                payload.type = 'findingStatus';
            } else {
                payload.status = val
                payload.type = 'status';
            }

            if ([FINDING_STATUS_OVERVIEW[0].value, FINDING_STATUS_OVERVIEW[1].value].includes(val)) {
                setDisableApprovalText(FINDING_STATUS_OVERVIEW?.find(o => o.value === val)?.label);
            }

            if (val === FINDING_STATUS_OVERVIEW[2].value) {
                // correction
                setShowTextBox(true);
            } else {
                setShowTextBox(false);
            }

            if (val !== FINDING_STATUS_OVERVIEW[2].value) {
                editFinding(payload).then(() => {
                    Toast.success('Status has been changed.')
                })
            };
        }
    }

    const manageViewer = (poc, index) => {
        setActivePoc(poc);
        setImageViewer(true);
        setActiveImageIndex(index)
    }

    const toggle = () => {
        setImageViewer(!isOpenViewer);
    }

    const renderText = (log) => {
        let finalText = '';

        if ([FINDING_LOGS.finding_create]?.includes(log?.activityName)) {
            finalText = <span>has <b>created</b> finding.</span>
        }
        if ([FINDING_LOGS.finding_update]?.includes(log?.activityName)) {
            finalText = <span>has <b>updated</b> finding.</span>
        }
        else if ([FINDING_LOGS.add_comment]?.includes(log?.activityName)) {
            finalText = <span>has added <b>comment.</b></span>
        }
        else if ([FINDING_LOGS.add_poc]?.includes(log?.activityName)) {
            finalText = <span>has added <b>POCs.</b></span>
        }
        else if ([FINDING_LOGS.delete_poc]?.includes(log?.activityName)) {
            finalText = <span>has deleted <b>POCs.</b></span>
        }
        else if ([FINDING_LOGS.status_update, FINDING_LOGS.finding_status_update]?.includes(log?.activityName)) {
            finalText = <span>has updated status.</span>
        }
        else if ([FINDING_LOGS.exception_approved]?.includes(log?.activityName)) {
            finalText = <span>has<b> Approved Exeption.</b></span>
        }
        else if ([FINDING_LOGS.exception_rejected]?.includes(log?.activityName)) {
            finalText = <span>has<b> Rejected Exeption.</b></span>
        }

        return finalText;
    }




    return (
        <React.Fragment>
            {pageLoader ?
                <PageLoader />
                :
                <div className="page-content">
                    <Breadcrumb title={findingDetails?.findingTitle} breadcrumbItem={breadcrumbItems} />
                    <Row className='g-3'>
                        <Col lg={9}>
                            <Card>
                                <CardBody>
                                    {findingDetails?.description && <Card className="mb-3 border-0">
                                        <CardHeader className="py-2 rounded-0 card-header-bg">
                                            <h5 className="card-title m-0">Description</h5>
                                        </CardHeader>
                                        <CardBody className="card-body p-2 mx-2">
                                            {/* <CardText dangerouslySetInnerHTML={{ __html: findingDetails?.description }} /> */}
                                            <CardText dangerouslySetInnerHTML={{ __html: htmlSanitizer(findingDetails?.description, { ALLOWED_TAGS: ['p', 'b', 'em', 'li', 'ol', 'ul', 'u', 'strong', 'br'] }) }} />
                                        </CardBody>
                                    </Card>}
                                    {findingDetails?.impect && < Card className="mb-3 border-0">
                                        <CardHeader className="py-2 rounded-0 card-header-bg">
                                            <h5 className="card-title m-0">Impact</h5>
                                        </CardHeader>
                                        <CardBody className="card-body p-2 mx-2">
                                            <CardText dangerouslySetInnerHTML={{ __html: htmlSanitizer(findingDetails?.impect, { ALLOWED_TAGS: ['p', 'b', 'em', 'li', 'ol', 'ul', 'u', 'strong', 'br'] }) }} />
                                        </CardBody>
                                    </Card>}
                                    {findingDetails?.pocs?.length > 0 && <Card className="mb-3 border-0">
                                        <CardHeader className="py-2 rounded-0 card-header-bg">
                                            <h5 className="card-title m-0">Proof of Concept</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Card className='mb-2 border-0'>
                                                {findingDetails?.pocs?.map((poc, index) =>
                                                    <div key={poc?.id}>
                                                        <CardHeader className="py-2 rounded-0 card-header-bg d-flex align-items-center mb-2">
                                                            <h6 className='text-left mb-0 mx-1'>
                                                                <b>Step {index + 1}:</b></h6>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Row className="mb-2">
                                                                <Col sm="12">
                                                                    <RHFTextField
                                                                        id="description"
                                                                        label="Description"
                                                                        name="description"
                                                                        isController={false}
                                                                        defaultValue={poc?.description}
                                                                        disabledField={true}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row >
                                                                {(poc?.url || poc?.parameters) && <Col sm="6">
                                                                    {poc?.url && <Row className="mb-2">
                                                                        <Col sm="12">
                                                                            <RHFTextField
                                                                                id="url"
                                                                                label="URl"
                                                                                name="url"
                                                                                isController={false}
                                                                                defaultValue={poc?.url}
                                                                                disabledField={true}
                                                                            />
                                                                        </Col>
                                                                    </Row>}
                                                                    {poc?.parameters && <Row className="mb-2">
                                                                        <Col sm="12">
                                                                            <RHFTextField
                                                                                id="parameters"
                                                                                label="Parameters"
                                                                                name="parameters"
                                                                                isController={false}
                                                                                defaultValue={poc?.parameters}
                                                                                disabledField={true}

                                                                            />
                                                                        </Col>
                                                                    </Row>}
                                                                </Col>}
                                                                {poc?.payload && <Col sm="6">
                                                                    <RHFTextField
                                                                        id="payload"
                                                                        label="Payload"
                                                                        name="payload"
                                                                        multiline={true}
                                                                        rows={3}
                                                                        type="textarea"
                                                                        isController={false}
                                                                        defaultValue={poc?.payload}
                                                                        disabledField={true}
                                                                    />
                                                                </Col>}
                                                            </Row>
                                                            <Row>
                                                                <div className='poc-images-detail'>
                                                                    {poc?.images?.map((item, index) =>
                                                                        <div className='poc-inner-images' key={item?.url}>
                                                                            <img
                                                                                alt="Sample"
                                                                                src={item?.url}
                                                                                onClick={() => manageViewer(poc, index)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Row>
                                                        </CardBody>
                                                        <hr></hr>
                                                    </div>)}
                                            </Card>
                                        </CardBody>
                                    </Card>}
                                    {findingDetails?.remediation && <Card className="mb-3 border-0">
                                        <CardHeader className="py-2 rounded-0 card-header-bg">
                                            <h5 className="card-title m-0">Remediation</h5>
                                        </CardHeader>
                                        <CardBody className="card-body p-2 mx-2">
                                            <CardText dangerouslySetInnerHTML={{ __html: htmlSanitizer(findingDetails?.remediation, { ALLOWED_TAGS: ['p', 'b', 'em', 'li', 'ol', 'ul', 'u', 'strong', 'br'] }) }} />
                                        </CardBody>
                                    </Card>}
                                    {findingDetails?.reference && <Card className="mb-3 border-0">
                                        <CardHeader className="py-2 rounded-0 card-header-bg">
                                            <h5 className="card-title m-0">Reference</h5>
                                        </CardHeader>
                                        <CardBody className="card-body p-2 mx-2">
                                            <CardText dangerouslySetInnerHTML={{ __html: htmlSanitizer(findingDetails?.reference, { ALLOWED_TAGS: ['p', 'b', 'em', 'li', 'ol', 'ul', 'u', 'strong', 'br'] }) }} />
                                        </CardBody>
                                    </Card>}
                                    {findingDetails?.infectedURL?.length > 0 && <Card className="mb-3 border-0">
                                        <CardHeader className="py-2 rounded-0 card-header-bg">
                                            <h5 className="card-title m-0">URL</h5>
                                        </CardHeader>
                                        {findingDetails?.infectedURL?.map((u, i) =>
                                            <CardBody className="card-body p-2 mx-2" key={i}>
                                                <CardText> {u}</CardText>
                                            </CardBody>
                                        )}
                                    </Card>}
                                </CardBody>
                            </Card>
                            {commentList?.length > 0 ?
                                !(findingDetails?.isEditable && !findingDetails?.status) && <Card>
                                    <CardHeader tag="h6">
                                        Comments
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <div className='comments'>
                                                    {commentList?.map(comment => (
                                                        <div className="d-flex justify-content-between align-items-center" key={comment?.id}>
                                                            <div className="comment text-justify">
                                                                <div className='d-flex align-items-baseline'>
                                                                    <div>
                                                                        <img src={comment?.user?.profile ? comment?.user?.profile : defaultImage} alt="" className="rounded-circle" width="50" height="50" />
                                                                    </div>
                                                                    <h6 className='ms-2'>{`${comment?.user?.firstName} ${comment?.user?.lastName}`}</h6>
                                                                    <span className='ms-2'>{renderTimestamp(comment?.createdAt)}</span>
                                                                    {(comment?.isException && comment?.status === "pending") && <span className="ms-2 badge bg-danger">exception</span>}
                                                                    {(comment?.isException && comment?.status === "approved") && <span className="ms-2 badge bg-success">approved</span>}
                                                                    {(comment?.isException && comment?.status === "rejected") && <span className="ms-2 badge bg-warning">Rejected</span>}
                                                                </div>
                                                                <p className='fs-6'>{comment?.comment}</p>
                                                            </div>
                                                            {(isApproveRejectPermission && comment?.isException && comment?.status === 'pending') &&
                                                                <div className="comment-action">
                                                                    <RHFButton
                                                                        btnName="Approve"
                                                                        className="mx-1 btn-sm"
                                                                        outline={true}
                                                                        onClick={() => takeAction({ obj: comment, action: 'approved' })}
                                                                    />
                                                                    <RHFButton
                                                                        btnName="Reject"
                                                                        className="mx-1 btn-sm"
                                                                        outline={false}
                                                                        onClick={() => takeAction({ obj: comment, action: 'rejected' })}
                                                                    />
                                                                    {/* <RHFButton
                                                                    btnName="Correction"
                                                                    type="submit"
                                                                    className="mx-2"
                                                                    outline={true}
                                                                /> */}
                                                                </div>
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <hr></hr> */}
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <Row className="mb-3">
                                                <Col>
                                                    <RHFTextField
                                                        id="comment"
                                                        label="Comment"
                                                        name="comment"
                                                        multiline={true}
                                                        rows={3}
                                                        type="textarea"
                                                        errorobj={errors}
                                                        control={control}
                                                        isController={true}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                {isModulePermisssion(ROLE_PERMISSIONS.APPLICATION_GENERATE_EXCEPTIONS) && !['completed'].includes(applicationData?.applicationStatus) && findingDetails?.findingStatus !== 'close' &&
                                                    <Col>
                                                        <div className="form-check">
                                                            <RHFCheckbox
                                                                name="exception"
                                                                label="Raise Exception ?"
                                                                checked={isException}
                                                                isController={false}
                                                                onChange={handleSwitchChange}
                                                            />
                                                        </div>
                                                    </Col>
                                                }
                                                {isModulePermisssion(ROLE_PERMISSIONS.ADD_COMMENTS_ON_FINDING) &&
                                                    <Col>
                                                        <div className='d-flex justify-content-end'>
                                                            <RHFButton
                                                                btnName="Save"
                                                                type="submit"
                                                                isLoading={isApiCalling}
                                                            />
                                                        </div>
                                                    </Col>
                                                }
                                            </Row>
                                        </form>
                                    </CardBody>
                                </Card>
                                :
                                !(findingDetails?.isEditable && !findingDetails?.status) && <Card>
                                    <CardBody>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <Row className="mb-3">
                                                <Col>
                                                    <RHFTextField
                                                        id="comment"
                                                        label="Comment"
                                                        name="comment"
                                                        multiline={true}
                                                        rows={3}
                                                        type="textarea"
                                                        errorobj={errors}
                                                        control={control}
                                                        isController={true}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                {isModulePermisssion(ROLE_PERMISSIONS.APPLICATION_GENERATE_EXCEPTIONS) && !['completed'].includes(applicationData?.applicationStatus) && findingDetails?.findingStatus !== 'close' &&
                                                    <Col>
                                                        <div className="form-check">
                                                            <RHFCheckbox
                                                                name="exception"
                                                                label="Raise Exception ?"
                                                                checked={isException}
                                                                isController={false}
                                                                onChange={handleSwitchChange}
                                                            />
                                                        </div>
                                                    </Col>
                                                }
                                                <Col>
                                                    <div className='d-flex justify-content-end'>
                                                        <RHFButton
                                                            btnName="Save"
                                                            type="submit"
                                                            isLoading={isApiCalling}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </form>
                                    </CardBody>
                                </Card>}
                            {/** Logs section */}
                            {findingLogs?.length > 0 &&
                                <Card>
                                    <CardHeader tag="h6">
                                        Finding Logs
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <div className='audit-logs'>
                                                    {findingLogs?.map(log => (
                                                        <div className="logs-wrapper" key={log?.id}>
                                                            <div className='log-avatar'>
                                                                <img src={log?.createdByUser?.profile ? log?.createdByUser?.profile : defaultImage} alt="" className="rounded-circle" width="50" height="50" />
                                                            </div>
                                                            <div className='log-details-wrapper'>
                                                                <div className='log-details'>
                                                                    <h6 className='m-0 p-0'>
                                                                        {`${log?.createdByUser?.firstName} ${log?.createdByUser?.lastName}`}
                                                                    </h6>
                                                                    <span className='ms-1'>
                                                                        {renderText(log)}
                                                                    </span>

                                                                    <span className='ms-auto log-date-time'>{moment(log?.createdAt).format('Do MMM YYYY h:mm a')}</span>
                                                                </div>
                                                                {[FINDING_LOGS.status_update, FINDING_LOGS.finding_status_update].includes(log?.activityName) &&
                                                                    <div className='log-status'>
                                                                        <p className='fs-6 mb-0'>
                                                                            <span className='prev-status'>{
                                                                                log?.activityName === FINDING_LOGS.status_update ?
                                                                                    FINDING_STATUS_OVERVIEW.find(o => +o.value === +log?.prevValue)?.label :
                                                                                    FINDING_STATUS.find(o => o.value === log?.prevValue)?.label
                                                                            }</span>
                                                                            <span className=''>
                                                                                <FeatherIcon
                                                                                    icon="arrow-right"
                                                                                    size="22"
                                                                                    className="actionBtn"
                                                                                /> </span>
                                                                            <span className='new-status fw-bold text-primary'>{
                                                                                log?.activityName === FINDING_LOGS.status_update ?
                                                                                    FINDING_STATUS_OVERVIEW.find(o => +o.value === +log?.newValue)?.label :
                                                                                    FINDING_STATUS.find(o => o.value === log?.newValue)?.label
                                                                            }</span>
                                                                        </p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            }

                        </Col>
                        <Col lg={3}>
                            <Card>
                                <CardBody>
                                    <div className="app-detail-wrapper">
                                        {['sm_manager'].includes(user?.user?.roleId) && ['revalidation', 'reassessment'].includes(findingDetails?.assessmentType) ?
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-title-finding">Finding Status: </span>
                                                        <p className='flex-grow-1 mb-0'>
                                                            <RHFAutoCompleteSelect
                                                                id="status"
                                                                name="status"
                                                                placeholder="Select Status"
                                                                options={FINDING_STATUS}
                                                                isMultiple={false}
                                                                isController={false}
                                                                handleOnChange={hanldeStatus}
                                                                defaultValue={findingDetails && FINDING_STATUS?.find(o => o.value === findingDetails?.findingStatus)}
                                                            />
                                                        </p>
                                                    </p>
                                                </div>
                                            </div>
                                            :
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-title-finding">Status: </span>
                                                        <span className="value-text">{TitleCase(FINDING_STATUS?.find(o => o.value === findingDetails?.findingStatus)?.label)}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        }



                                        {isModulePermisssion(ROLE_PERMISSIONS.APPROVE_REJECT_CORRECTION_FINDING) &&
                                            ['open', 'scheduled', 'in_progress'].includes(applicationData?.applicationStatus) &&
                                            !(findingDetails?.isEditable && !findingDetails?.status) && <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-title-finding">Approval Status: </span>
                                                        <p className='flex-grow-1 mb-0'>
                                                            <RHFAutoCompleteSelect
                                                                id="status"
                                                                name="status"
                                                                placeholder="Select Status"
                                                                options={FINDING_STATUS_OVERVIEW}
                                                                isMultiple={false}
                                                                isController={false}
                                                                handleOnChange={hanldeStatus}
                                                                defaultValue={findingDetails && FINDING_STATUS_OVERVIEW?.find(o => o.value === findingDetails?.status)}
                                                                disabledText={
                                                                    findingDetails && FINDING_STATUS_OVERVIEW?.find(o => o.value === findingDetails?.status)?.label ||
                                                                    disableApprovalText
                                                                }
                                                            />
                                                        </p>
                                                    </p>
                                                </div>
                                            </div>
                                        }

                                        {isModulePermisssion(ROLE_PERMISSIONS.APPROVE_REJECT_CORRECTION_FINDING) &&
                                            ['open', 'scheduled', 'in_progress'].includes(applicationData?.applicationStatus) &&
                                            showTextBox &&
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <form onSubmit={handleSubmit2(correctionSubmitHandler)}>
                                                        <RHFTextField
                                                            id="correctionDescription"
                                                            name="correctionDescription"
                                                            placeholder="What correction you want?"
                                                            isController={true}
                                                            control={control2}
                                                            errorobj={errors2}
                                                            multiline={true}
                                                            rows={3}
                                                            type="textarea"
                                                            // handleOnChange={handleText}
                                                            defaultValue={correctionText}
                                                        />
                                                        <div className='d-flex justify-content-end mt-2'>
                                                            <RHFButton
                                                                btnName="Save"
                                                                type="submit"
                                                                isLoading={isApiCalling}
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        }

                                        {/* {['sm_manager'].includes(user?.user?.roleId) && findingDetails?.assessmentType === 'revalidation' &&
                                    <div className='app-detail'>
                                        <div className='value-wrapper'>
                                            <p className='value-row'>
                                                <span className="value-title-finding">Finding Status :</span>
                                                <p className='flex-grow-1 mb-0'>
                                                    <RHFAutoCompleteSelect
                                                        id="status"
                                                        name="status"
                                                        placeholder="Select Status"
                                                        options={FINDING_STATUS}
                                                        isMultiple={false}
                                                        isController={false}
                                                        handleOnChange={hanldeStatus}
                                                    />
                                                </p>
                                            </p>
                                        </div>
                                    </div>
                                } */}

                                        <div className='app-detail'>
                                            <div className='value-wrapper'>
                                                <p className='value-row'>
                                                    <span className="value-title-finding">Severity</span>
                                                    <span className='value-text mb-1' >
                                                        <TextChip
                                                            text={findingDetails?.cvss ? findingDetails?.cvssName : applicationScore(+findingDetails?.severity)}
                                                            color={findingDetails?.cvss ? CVSS[findingDetails?.cvssName] : FINDING_SCORE_COLORS[(+findingDetails?.severity).toFixed(1)]}
                                                            textColor={findingDetails?.cvss ? CVSS_TEXT_COLOR[findingDetails?.cvssName] : CVSS_TEXT_COLOR[applicationScore(+findingDetails?.severity)]}
                                                        />
                                                    </span>
                                                </p>
                                                <p className='value-row'>
                                                    <span className="value-title-finding">Score</span>
                                                    <span className="value-text mb-1">
                                                        <TextChip
                                                            text={findingDetails?.cvssScore}
                                                            color={findingDetails?.cvss ? CVSS[findingDetails?.cvssName] : FINDING_SCORE_COLORS[(+findingDetails?.severity).toFixed(1)]}
                                                            textColor={findingDetails?.cvss ? CVSS_TEXT_COLOR[findingDetails?.cvssName] : CVSS_TEXT_COLOR[applicationScore(+findingDetails?.severity)]}
                                                        />
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        {findingDetails?.cvss &&
                                            <div className='app-detail'>
                                                <div className='value-wrapper'>
                                                    <p className='value-row'>
                                                        <span className="value-title-finding">CVSS</span>
                                                        <span className="value-text">{findingDetails?.cvss}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                        {findingDetails?.cves?.length > 0 && <div className='app-detail'>
                                            <div className='value-wrapper'>
                                                <p className='value-row'>
                                                    <span className="value-title-finding">CVE</span>
                                                    <span className='value-text' >
                                                        {findingDetails?.cves.map((cve, index) =>
                                                            <div key={index} className="badge badge-soft-primary bg-soft-primary text-reset p-1 me-2" >
                                                                {cve?.name}
                                                            </div>
                                                        )}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>}
                                        {findingDetails?.cwes?.length > 0 && <div className='app-detail'>
                                            <div className='value-wrapper'>
                                                <p className='value-row'>
                                                    <span className="value-title-finding">CWE</span>
                                                    <span className="value-text badge badge-soft-primary bg-soft-primary text-reset p-1 mb-0">{findingDetails?.cwes[0]?.name}</span>
                                                </p>
                                            </div>
                                        </div>}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {
                        isOpenViewer && <ImageViewer
                            images={activePoc?.images?.map(a => a?.url)}
                            isOpenViewer={isOpenViewer}
                            activeImageIndex={activeImageIndex}
                            toggle={toggle}
                        />
                    }
                </div >
            }
        </React.Fragment >
    )
}

export default FindingOverview;
