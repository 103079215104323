
// export const urlRegex = /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
export const urlRegex = /^(?:(?:https?):\/\/)?(?!0\.0\.0\.0$)(?:(?:(?:1?\d\d?|2[0-4]\d|25[0-5])(?:\.(?!$))?){4}|(?:[a-zA-Z\d]\.|[a-zA-Z\d](?:(?![-.]{2})[a-zA-Z\d-]){0,63}?[a-zA-Z\d]\.){1,63}?[a-z]{2,63})(?:[:/].*)?$/

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const nameRegex = /^[0-9a-zA-Z ._-]*$/
export const alphaRegex = /^[a-z]*$/
export const htmlTagRegex = /^(?!.*<[^>]+>).*/

export const uriRegex = /\w+:(\/?\/?)[^\s]+/
export const ipAddressRegex = /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/


export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'image/bmp', 'image/svg+xml'];

export const today = new Date();
today.setHours(0, 0, 0, 0)

export const APPLICATION_TYPES = [
    { label: "Web", value: "web" },
    { label: "Mobile", value: "mobile" },
    { label: "API", value: "api" },
]

export const APPLICATION_STATUS = {
    open: "Open",
    scheduled: "Scheduled",
    in_progress: "In Progress",
    action_required: "Action Required",
    in_review: "In Review",
    completed: "Completed"
}

export const APPLICATION_ASSESSMENT_TYPE = [
    { label: "Initial", value: "initial" },
    { label: "Revalidation", value: "revalidation" },
    { label: "Limited", value: "limited" },
    { label: "Reassessment", value: "reassessment" }
]

export const APPLICATION_ASSET_TYPE = [
    { label: "Internal", value: "internal" },
    { label: "Infranet", value: "infranet" },
    { label: "External", value: "external" }
]


export const FINDING_STATUS = [{
    value: "open",
    label: "Open",
}, {
    value: "close",
    label: "Close",
}]

export const NETWORK_FINDING_TYPES = [
    {
        value: "true_vulnerability",
        label: "True Vulnerability",
    },
    {
        value: "action_required",
        label: "Action Required",
    },
    {
        value: "open",
        label: "Open",
    },
    {
        value: "closed",
        label: "Closed",
    },
    {
        value: "do_not_scan",
        label: "Do Not Scan",
    },
    {
        value: "false_positive",
        label: "False Positive",
    }
]

export const NETWORK_ASSET_TYPE = [{
    value: "internal",
    label: "Internal"
},
{
    value: "infranet",
    label: "Infranet"
},
{
    value: "external",
    label: "External"
}]



export const QUARTER_TYPE = [
    {
        value: "Q1",
        label: "Q1"
    },
    {
        value: "Q2",
        label: "Q2"
    },
    {
        value: "Q3",
        label: "Q3"
    },
    {
        value: "Q4",
        label: "Q4"
    }

]


export const FINDING_STATUS_OVERVIEW = [{
    value: 2,
    label: "Approve",
}, {
    value: 3,
    label: "Reject",
},
{
    value: 4,
    label: "Correction",
}]



export const USER_ROLES = {
    "oam_manager": "OAM Manager",
    "csm_manager": "CSM Manager",
    "da_manager": "Development Manager",
    "sm_manager": "Security Manager",
    "developer": "Developer",
    "pentester": "Pentester",
    "executive": "Executive",
    "d_executive": "DA Executive",
    "s_executive": "SA Executive"
}


export const CVSS = {
    None: '#a2d572',
    // Low: '#00E7A8',
    Low: 'var(--bs-success)',
    Medium: '#FEDF00',
    High: '#FB7700',
    Critical: '#F11605',
    Informative: '#3457D5'
}

export const NETWORK_SEVERITY = [
    { label: "Informative", value: 0 },
    { label: "Low", value: 2 },
    { label: "Medium", value: 5.5 },
    { label: "High", value: 8 },
    { label: "Critical", value: 9.5 },
]

export const FINDING_SEVERITY = [
    ...NETWORK_SEVERITY,
    { label: "CVSS 3.1", value: 10 }
]

export const CVSS_TEXT_COLOR = {
    None: '#000',
    Low: '#000',
    Medium: '#000',
    High: '#000',
    Critical: '#fff',
    Informative: '#fff'
}

export const DONUT_SEVERITY_COLORS = [CVSS["Informative"], CVSS["Low"], CVSS["Medium"], CVSS["High"], CVSS["Critical"]];
export const DONUT_SEVERITY_LABELS = ["Informative", "Low", "Medium", "High", "Critical"]
export const DONUT_DASHBOARD_COLORS = { pending: '#F7E602', inProgress: '#F28A02', resolved: '#8AC926' }

// export const STATUS_COLOR = {
//     Approve: "#E6FBEB", Reject: "#FCECEB", Correction: "#FDF3E6"
// }
export const STATUS_COLOR = {
    Approve: "var(--bs-success)", Reject: "#EF0107", Correction: "#F17701"
}

export const STATUS_TEXT_COLOR = {
    Approve: "#fff", Reject: "#000", Correction: "#000"
}

export const FINDING_STATUS_COLOR = {
    Open: "#3457D5", Close: "#EF0107"
}

export const FINDING_STATUS_TEXT_COLOR = {
    Open: "#fff", Close: "#fff"
}

/** Logs */

export const APPLICATION_LOGS = {
    create: "create",
    update: "update",
    dm_unassign: "dm_unassign",
    sm_unassign: "sm_unassign",
    d_unassign: "d_unassign",
    p_unassign: "p_unassign",
    dm_assign: "dm_assign",
    sm_assign: "sm_assign",
    d_assign: "d_assign",
    p_assign: "p_assign",
    update_status: "update_status",
    download_report: "download_report",
    download_certificate: "download_certificate",
    generate_certificate: "generate_certificate"
}

export const FINDING_LOGS = {
    finding_create: "finding_create",
    finding_update: "finding_update",
    status_update: "status_update",
    finding_status_update: "finding_status_update",
    add_comment: "add_comment",
    add_poc: "add_poc",
    delete_poc: "delete_poc",
    exception_approved: "exception_approved",
    exception_rejected: "exception_rejected"
}

export const NETWORK_LOGS = {
    add_network: "add_network",
    update_network: "update_network",
    dm_unassign: "dm_unassign",
    sm_unassign: "sm_unassign",
    d_unassign: "d_unassign",
    p_unassign: "p_unassign",
    dm_assign: "dm_assign",
    sm_assign: "sm_assign",
    d_assign: "d_assign",
    p_assign: "p_assign",
}

export const NETWORK_FINDING_LOGS = {
    update_assetType: "update_assetType",
    update_findingType: "update_findingType",
    update_severity: "update_severity",
    add_comment: "add_comment",
    exception_approved: "exception_approved",
    exception_rejected: "exception_rejected"
}

export const TemplateFields = {
    confidentiality: "Confidentiality",
    disclaimers: "Disclaimer",
    documentAuthorization: "Document Authorizations",
    tableOfContents: "Table of Contents",
    purpose: "Purpose",
    scope: "Scope",
    assesmentInformation: "Assessment Information",
    summary: "Summary",
    vulenerabilityIndex: "Vulnerability Index",
    poc: "Poc",
    appendices: "Appendices",
    appendixBUsedTools: "Appendix B.Used tools",
    checklist: "Checklist"
}
export const TEMPLATE_PARAMETER_FEILDS = [
    "${applicationName}",
    "${lastModifyBy}",
    "${lastModifyDate}",
    "${lastReviewDate}",
    "${applicationType}",
    "${assessmentType}",
    "${reportDate}",
    "${publishDate}",
    "${reportId}",
    "${assessmentId}",
    "${environment}",
    "${inScope}",
    "${outOfScope}",
    "${serverDetails}",
    "${applicationDetails}",
    "${userDetails}",
]

export const CERTIFICATE_PARAMETER_FEILD = [
    "${applicationName}",
    "${siteURL}",
    "${testSiteURL}",
    "${assessmentType}",
    "${publishDate}",
    "${initialReportDate}",
    "${firstRevalidationDate}",
    "${secondRevalidationDate}",
    "${thirdRevalidationDate}",
    "${certificateNo}",
    "${companyOwner}",
    "${applicationOwner}",
    "${reviewedBy}",
    "${Vulnerability}",
    "${auditeeName}",
    "${appendixA}"
]

export const ENV_CONFIG = {
    PORT1: "3000", // app.ridiscovery.com // global tenenat
    PORT2: "3001", // nisl.ridiscovery.com // main tenenat
    APP_TENANT: "app",
};

export const COMPANY_SCORE_COLORS =
{
    '10.0': "#3457D5",
    '9.9': "#2A63DD",
    '9.8': "#1F6FE6",
    '9.7': "#157AEE",
    '9.6': "#0A86F7",
    '9.5': "#0092FF",
    '9.4': "#019AFF",
    '9.3': "#009EFF",
    '9.2': "#00A9FF",
    '9.1': "#00B5FF",
    '9.0': "#00C0FF",
    '8.9': "#08C8FF",
    '8.8': "#00C6F2",
    '8.7': "#00CCE6",
    '8.6': "#00D2D9",
    '8.5': "#00D8CD",
    '8.4': "#00DEC0",
    '8.3': "#00E4B4",
    '8.2': "#05E9B9",
    '8.1': "#00E7A8",
    '8.0': "#00E99D",
    '7.9': "#00EC91",
    '7.8': "#00EF86",
    '7.7': "#00F27A",
    '7.6': "#00F46E",
    '7.5': "#00FF49",
    '7.4': "#0BFF46",
    '7.3': "#17FF44",
    '7.2': "#22FE41",
    '7.1': "#2DFE3E",
    '7.0': "#39FE3B",
    '6.9': "#44FE39",
    '6.8': "#50FE36",
    '6.7': "#5BFE33",
    '6.6': "#66FD30",
    '6.5': "#72FD2E",
    '6.4': "#7DFF2C",
    '6.3': "#7DFF2C",
    '6.2': "#8AFC21",
    '6.1': "#97FC17",
    '6.0': "#A4FB0D",
    '5.9': "#B2FB11",
    '5.8': "#B2FB11",
    '5.7': "#BFFA02",
    '5.6': "#CDF801",
    '5.5': "#DAF600",
    '5.4': "#DAF600",
    '5.3': "#E7F500",
    '5.2': "#F3F400",
    '5.1': "#FFF200",
    '5.0': "#FCEE29",
    '4.9': "#FEE700",
    '4.8': "#FEE700",
    '4.7': "#FEDF00",
    '4.6': "#FEDF00",
    '4.5': "#FED500",
    '4.4': "#FED500",
    '4.3': "#FFCD05",
    '4.2': "#FFCD05",
    '4.1': "#FFC70A",
    '4.0': "#FFC70A",
    '3.9': "#FEBF10",
    '3.8': "#FEBF10",
    '3.7': "#FDB813",
    '3.6': "#FDB813",
    '3.5': "#FCB315",
    '3.4': "#FCB315",
    '3.3': "#FBAE17",
    '3.2': "#FBAE17",
    '3.1': "#FBA919",
    '3.0': "#FBA919",
    '2.9': "#FAA41A",
    '2.8': "#FAA533",
    '2.7': "#FAA533",
    '2.6': "#FAA542",
    '2.5': "#FF9F10",
    '2.4': "#FE9208",
    '2.3': "#FD8500",
    '2.2': "#FD8500",
    '2.1': "#FB7700",
    '2.0': "#F96900",
    '1.9': "#F96900",
    '1.8': "#F75700",
    '1.7': "#F54600",
    '1.6': "#F54600",
    '1.5': "#F43A01",
    '1.4': "#F43A01",
    '1.3': "#F33102",
    '1.2': "#F33102",
    '1.1': "#F22703",
    '1.0': "#F22703",
    '0.9': "#F11D04",
    '0.8': "#F11D04",
    '0.7': "#F11605",
    '0.6': "#F11605",
    '0.5': "#F00E06",
    '0.4': "#F00E06",
    '0.3': "#F00806",
    '0.2': "#F00806",
    '0.1': "#EF0107",
    '0.0': "#3457D5",
}

export const FINDING_SCORE_COLORS =
{
    '0.0': "#3457D5",
    '0.1': "#3457D5",
    '0.2': "#2A63DD",
    '0.3': "#1F6FE6",
    '0.4': "#157AEE",
    '0.5': "#0A86F7",
    '0.6': "#0092FF",
    '0.7': "#019AFF",
    '0.8': "#009EFF",
    '0.9': "#00A9FF",
    '1.0': "#00B5FF",
    '1.1': "#00C0FF",
    '1.2': "#08C8FF",
    '1.3': "#00C6F2",
    '1.4': "#00CCE6",
    '1.5': "#00D2D9",
    '1.6': "#00D8CD",
    '1.7': "#00DEC0",
    '1.8': "#00E4B4",
    '1.9': "#05E9B9",
    '2.0': "#00E7A8",
    '2.1': "#00E99D",
    '2.2': "#00EC91",
    '2.3': "#00EF86",
    '2.4': "#00F27A",
    '2.5': "#00F46E",
    '2.6': "#00FF49",
    '2.7': "#0BFF46",
    '2.8': "#17FF44",
    '2.9': "#22FE41",
    '3.0': "#2DFE3E",
    '3.1': "#39FE3B",
    '3.2': "#44FE39",
    '3.3': "#50FE36",
    '3.4': "#5BFE33",
    '3.5': "#66FD30",
    '3.6': "#72FD2E",
    '3.7': "#7DFF2C",
    '3.8': "#7DFF2C",
    '3.9': "#8AFC21",
    '4.0': "#97FC17",
    '4.1': "#A4FB0D",
    '4.2': "#B2FB11",
    '4.3': "#B2FB11",
    '4.4': "#BFFA02",
    '4.5': "#CDF801",
    '4.6': "#DAF600",
    '4.7': "#DAF600",
    '4.8': "#E7F500",
    '4.9': "#F3F400",
    '5.0': "#FFF200",
    '5.1': "#FCEE29",
    '5.2': "#FEE700",
    '5.3': "#FEE700",
    '5.4': "#FEDF00",
    '5.5': "#FEDF00",
    '5.6': "#FED500",
    '5.7': "#FED500",
    '5.8': "#FFCD05",
    '5.9': "#FFCD05",
    '6.0': "#FFC70A",
    '6.1': "#FFC70A",
    '6.2': "#FEBF10",
    '6.3': "#FEBF10",
    '6.4': "#FDB813",
    '6.5': "#FDB813",
    '6.6': "#FCB315",
    '6.7': "#FCB315",
    '6.8': "#FBAE17",
    '6.9': "#FBAE17",
    '7.0': "#FBA919",
    '7.1': "#FBA919",
    '7.2': "#FAA41A",
    '7.3': "#FAA533",
    '7.4': "#FAA533",
    '7.5': "#FAA542",
    '7.6': "#FF9F10",
    '7.7': "#FE9208",
    '7.8': "#FD8500",
    '7.9': "#FD8500",
    '8.0': "#FB7700",
    '8.1': "#F96900",
    '8.2': "#F96900",
    '8.3': "#F75700",
    '8.4': "#F54600",
    '8.5': "#F54600",
    '8.6': "#F43A01",
    '8.7': "#F43A01",
    '8.8': "#F33102",
    '8.9': "#F33102",
    '9.0': "#F22703",
    '9.1': "#F22703",
    '9.2': "#F11D04",
    '9.3': "#F11D04",
    '9.4': "#F11605",
    '9.5': "#F11605",
    '9.6': "#F00E06",
    '9.7': "#F00E06",
    '9.8': "#F00806",
    '9.9': "#F00806",
    '10.0': "#EF0107",
}




















































































