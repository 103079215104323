import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Application from './Application';
import Network from './Network';
import Tabs from 'components/Tab/Tabs';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isModulePermisssion } from 'helpers/util';
import { ROLE_PERMISSIONS } from 'constants/RolePermissions';

const ApplicationNetwork = () => {
  document.title = "Assessment | RiDiscovery";
  const navLinkData = [
    {
      tabNo: "applications",
      tabName: "Application",
      tabPath: "/applications",
      isVisible: isModulePermisssion(ROLE_PERMISSIONS.APPLICATION) ? true : false,
    },
    {
      tabNo: "networks",
      tabName: "Network",
      tabPath: "/networks",
      isVisible: isModulePermisssion(ROLE_PERMISSIONS.NETWORK) ? true : false,
    }
  ]

  const { permissions } = useSelector(state => state.Login);

  let location = useLocation()
  const history = useHistory()
  let tabValue = location.pathname
  const [filterColumns, setFilterColumns] = useState([]);
  const [activeTab, setactiveTab] = useState('');

  let columnFilter = [...navLinkData];

  useEffect(() => {
    const filteredTabs = columnFilter.filter(o => o.isVisible && o);
    setFilterColumns(filteredTabs);
    setactiveTab(filteredTabs.length > 0 ? filteredTabs[0]?.tabNo : '')
  }, [permissions])

  const toggle = (tab) => {
    setactiveTab(tab);
    history.push(`/${tab}`)
  };
  return (
    <div className="page-content1">
      <div className='page-title-box'>
        <Tabs
          navLinkData={filterColumns}
          activeTab={activeTab}
          setactiveTab={setactiveTab}
          toggle={toggle}
        >
          {tabValue === "/applications" && <Application />}
          {tabValue === "/networks" && <Network />}
        </Tabs>
      </div>
    </div>
  )
}

export default ApplicationNetwork;