import {
  ADD_APPLICATION,
  ADD_APPLICATION_SUCCESS,
  ADD_APPLICATION_FAIL,

  UPDATE_APPLICATION,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION_FAIL,

  DELETE_APPLICATION,
  DELETE_APPLICATION_SUCCESS,
  DELETE_APPLICATION_FAIL,

  GET_ALL_APPLICATION,
  GET_ALL_APPLICATION_SUCCESS,
  GET_ALL_APPLICATION_FAIL,


} from "./actionTypes"

export const addApplication = (data, history) => {
  return {
    type: ADD_APPLICATION,
    payload: { data, history },
  }
}

export const addApplicationSuccess = resp => {
  return {
    type: ADD_APPLICATION_SUCCESS,
    payload: resp,

  }
}

export const addApplicationFail = (data) => {
  return {
    type: ADD_APPLICATION_FAIL,
    payload: data
  }
}

export const updateApplication = (payload) => {
  return {
    type: UPDATE_APPLICATION,
    payload,
  }
}

export const updateApplicationSuccess = (data) => {
  return {
    type: UPDATE_APPLICATION_SUCCESS,
    payload: data
  }
}

export const updateApplicationFail = (data) => {
  return {
    type: UPDATE_APPLICATION_FAIL,
    payload: data
  }
}


export const deleteApplication = (payload) => {
  return {
    type: DELETE_APPLICATION,
    payload,
  }
}

export const deleteApplicationSuccess = (data) => {
  return {
    type: DELETE_APPLICATION_SUCCESS,
    payload: data
  }
}

export const deleteApplicationFail = (data) => {
  return {
    type: DELETE_APPLICATION_FAIL,
    payload: data
  }
}


export const getAllApplication = (payload) => {
  return {
    type: GET_ALL_APPLICATION,
    payload,
  }
}

export const getAllApplicationSuccess = (data) => {
  return {
    type: GET_ALL_APPLICATION_SUCCESS,
    payload: data
  }
}

export const getAllApplicationFail = (data) => {
  return {
    type: GET_ALL_APPLICATION_FAIL,
    payload: data
  }
}


