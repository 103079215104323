import { VERIFY_UPDATED_OTP, VERIFY_UPDATED_OTP_FAIL, VERIFY_UPDATED_OTP_SUCCESS } from "./actionTypes"

export const verifyUpdatedOtp = (payload) => {
    return {
        type: VERIFY_UPDATED_OTP,
        payload
    }
}

export const verifyUpdatedOtpSuccess = (data) => {
    return {
        type: VERIFY_UPDATED_OTP_SUCCESS,
        payload: data
    }
}

export const verifyUpdatedOtpFail = (data) => {
    return {
        type: VERIFY_UPDATED_OTP_FAIL,
        payload: data
    }
}