
import { ADD_NETWORK, ADD_NETWORK_FINDING, ADD_OR_REMOVE_NETWORK_USER, GET_ALL_NETWORK, GET_All_NETWORK_FINDING, GET_CVE_TRENDS_CHART, GET_NETWORK_DETAILS, GET_NETWORK_FINDING_DETAILS, GET_NETWORK_FINDING_LOGS, GET_NETWORK_FINDING_STATUS, GET_NETWORK_LOGS, GET_NETWORK_SCORE_LINECHART_DATA, GET_NETWORK_SUBMISSION_DONUT_CHART_DATA, GET_OS_SUMMARY_CHART, UPDATE_NETWORK_FINDING } from "helpers/UrlHelper";

import { API_URL } from "./Services";

const baseURL = API_URL.NETWORK;

export const NETWORK = {
    addNetwork: {
        method: 'POST',
        url: ADD_NETWORK,
        baseURL
    },
    listAllNetwork: {
        method: 'POST',
        url: GET_ALL_NETWORK,
        baseURL
    },
    listAllNetworkFinding: {
        method: 'POST',
        url: GET_All_NETWORK_FINDING,
        baseURL
    },
    editNetworkFinding: {
        method: 'PUT',
        url: UPDATE_NETWORK_FINDING,
        baseURL
    },
    getNetworkFinding: {
        method: 'GET',
        url: GET_NETWORK_FINDING_DETAILS,
        baseURL
    },
    addRemoveNetworkUser: {
        method: 'PUT',
        url: ADD_OR_REMOVE_NETWORK_USER,
        baseURL
    },
    getNetworkSubmissionDonutChartData: {
        method: 'POST',
        url: GET_NETWORK_SUBMISSION_DONUT_CHART_DATA,
        baseURL
    },
    getNetworkScoreLineChartData: {
        method: 'POST',
        url: GET_NETWORK_SCORE_LINECHART_DATA,
        baseURL
    },
    getNetworkDetails: {
        method: 'GET',
        url: GET_NETWORK_DETAILS,
        baseURL
    },
    addNetworkFinding: {
        method: 'POST',
        url: ADD_NETWORK_FINDING,
        baseURL
    },
    getNetworkFindingStatus: {
        method: 'GET',
        url: GET_NETWORK_FINDING_STATUS,
        baseURL
    },
    getNetworkLogs: {
        method: 'GET',
        url: GET_NETWORK_LOGS,
        baseURL
    },
    getNetworkFindingLogs: {
        method: 'GET',
        url: GET_NETWORK_FINDING_LOGS,
        baseURL
    },
    getOSesSummaryChart: {
        method: 'POST',
        url: GET_OS_SUMMARY_CHART,
        baseURL
    },
    getCVETrendsChart: {
        method: 'POST',
        url: GET_CVE_TRENDS_CHART,
        baseURL
    }
}