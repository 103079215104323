import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { updatePassword } from "helpers/BackendHelper"
import { CHANGE_PASSWORD } from "./actionTypes"
import { changePasswordFail, changePasswordSuccess } from "./actions"
import { Toast } from "components/Common/Toaster"

/* Change Password */
function* changeUserPassword({ payload }) {
    try {
        const response = yield call(updatePassword, payload)
        yield put(changePasswordSuccess(response))
        Toast.success(response.message)
    } catch (error) {
        yield put(changePasswordFail(error))
    }
}

export function* watchChangePassword() {
    yield takeEvery(CHANGE_PASSWORD, changeUserPassword)
}

function* changePasswordSaga() {
    yield all([fork(watchChangePassword)])
}

export default changePasswordSaga
