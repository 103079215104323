import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form } from "reactstrap";
import { Link } from "react-router-dom";
import * as yup from "yup";
import logo from "../../assets/images/RiDiscovery_Icon.png";
import RHFTextField from "components/FormControls/RHFTextField";
import RHFButton from "components/FormControls/RHFButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { forgetPassword } from "helpers/BackendHelper";
import { Toast } from "components/Common/Toaster";

const ForgetPasswordPage = () => {

  //meta title
  document.title = "Forget Password | RiDiscovery ";

  const [isApiCalling, setApiCalling] = useState(false);
  const ForgetPasswordSchema = yup.object().shape({
    email: yup.string().email().max(150).required('Email is required')
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(ForgetPasswordSchema)
  });


  const handleForgetPassword = (values) => {
    const payload = { email: values?.email };
    setApiCalling(true);
    if (!isApiCalling) {
      forgetPassword(payload).then(resp => {
        setApiCalling(false);
        if (resp?.status) {
          Toast.success(resp.message)
          reset();
        }
      }).catch(() => {
        setApiCalling(false);
      });
    }
  };


  return (
    <React.Fragment>
      <div className="auth-page">

        <Row className="g-0">
          <Col lg={4} md={5} className="col-xxl-3">
            <div className="auth-full-page-content d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5 text-center">
                    <span className="d-block auth-logo">
                      <img src={logo} alt="" height="35" />
                      <span className="logo-txt">RiDiscovery</span>
                    </span>
                  </div>
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      <h3 className="mb-0">Forget Password</h3>
                      <p className="text-muted mt-2">Forget Password with RiDiscovery.</p>
                    </div>

                    <Form
                      className="custom-form mt-4"
                      onSubmit={handleSubmit(handleForgetPassword)}
                    >
                      <div className="mb-3">
                        <RHFTextField
                          id="email"
                          label="Email"
                          name="email"
                          errorobj={errors}
                          control={control}
                          isController={true}
                        />
                      </div>

                      <Row className="mb-3">
                        <Col>
                          <div className="mt-3 d-grid">
                            <RHFButton btnName="Forget Password" type="submit" isLoading={isApiCalling} />
                          </div>
                        </Col>
                      </Row>

                    </Form>

                    <div className="mt-4 text-center">
                      <p className="text-muted mb-0">Remember It ?
                        <Link to="/tenant/login" className="text-primary fw-semibold"> Login</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

      </div>
    </React.Fragment >
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default ForgetPasswordPage;