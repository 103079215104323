import React from 'react';

const TextChip = (props) => {
    const {
        color,
        text = '',
        textColor = ''
    } = props;
    return (
        <span className="textChip" style={{ backgroundColor: color, color: textColor }}>
            {text}
        </span>
    )
}

export default TextChip