import {
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,

  ACTIVE_DEACTIVE_USER,
  ACTIVE_DEACTIVE_USER_SUCCESS,
  ACTIVE_DEACTIVE_USER_FAIL,

  GET_ALL_USER,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_FAIL,

  USER_UPDATE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,

  USER_PROFILE_UPLOAD,
  USER_PROFILE_UPLOAD_SUCCESS,
  USER_PROFILE_UPLOAD_FAIL,
  GET_SIGNED_URL,
  GET_SIGNED_URL_SUCCESS,
  GET_SIGNED_URL_FAIL,

} from "./actionTypes"

export const addUser = (payload) => {
  return {
    type: ADD_USER,
    payload,
  }
}

export const addUserSuccess = (data) => {
  return {
    type: ADD_USER_SUCCESS,
    payload: data
  }
}

export const addUserFail = (data) => {
  return {
    type: ADD_USER_FAIL,
    payload: data
  }
}

export const userUpdate = (payload) => {
  return {
    type: USER_UPDATE,
    payload,
  }
}

export const userUpdateSuccess = (data) => {
  return {
    type: USER_UPDATE_SUCCESS,
    payload: data
  }
}

export const userUpdateFail = (data) => {
  return {
    type: USER_UPDATE_FAIL,
    payload: data
  }
}

export const getAllUser = (payload) => {
  return {
    type: GET_ALL_USER,
    payload,
  }
}

export const getAllUserSuccess = (data) => {
  return {
    type: GET_ALL_USER_SUCCESS,
    payload: data
  }
}

export const getAllUserFail = (data) => {
  return {
    type: GET_ALL_USER_FAIL,
    payload: data
  }
}


export const activeDectiveUser = (payload) => {
  return {
    type: ACTIVE_DEACTIVE_USER,
    payload,
  }
}

export const activeDectiveUserSuccess = (data) => {
  return {
    type: ACTIVE_DEACTIVE_USER_SUCCESS,
    payload: data
  }
}

export const activeDectiveUserFail = (data) => {
  return {
    type: ACTIVE_DEACTIVE_USER_FAIL,
    payload: data
  }
}

export const userProfileUpload = (payload) => {
  return {
    type: USER_PROFILE_UPLOAD,
    payload,
  }
}

export const userProfileUploadSuccess = (data) => {
  return {
    type: USER_PROFILE_UPLOAD_SUCCESS,
    payload: data
  }
}

export const userProfileUploadFail = (data) => {
  return {
    type: USER_PROFILE_UPLOAD_FAIL,
    payload: data
  }
}

export const getSignedUrl = (payload) => {
  return {
    type: GET_SIGNED_URL,
    payload,
  }
}

export const getSignedUrlSuccess = (data) => {
  return {
    type: GET_SIGNED_URL_SUCCESS,
    payload: data
  }
}

export const getSignedUrlFail = (data) => {
  return {
    type: GET_SIGNED_URL_FAIL,
    payload: data
  }
}