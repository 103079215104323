import TextChip from 'components/Common/TextChip';
import RHFAutoCompleteSelect from 'components/FormControls/RHFAutoCompleteSelect';
import RHFButton from 'components/FormControls/RHFButton';
import Table from 'components/Tables/Table';
import { ROLE_PERMISSIONS } from 'constants/RolePermissions';
import { Finding } from 'helpers/Services/Finding';
import { applicationScore, isModulePermisssion, TitleCase } from 'helpers/util';
import React, { useEffect, useRef, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { CVSS, CVSS_TEXT_COLOR, FINDING_SCORE_COLORS, FINDING_STATUS, FINDING_STATUS_COLOR, FINDING_STATUS_OVERVIEW, STATUS_COLOR } from 'constants/Constant';
import { listAllApplication, getUserTeam, getSubmissionsCharts } from 'helpers/BackendHelper';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { addDays } from 'date-fns';
import moment from 'moment/moment'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DonutChart from 'components/Common/Graphs/DonutChart';
import LineChart from 'components/Common/Graphs/LineChart';
import PageLoader from 'components/PageLoader';
import TimelineButton from 'components/FormControls/TimelineButton';

const Submission = () => {
    document.title = "Submissions | RiDiscovery";
    const history = useHistory();
    const [filterFields, setFilterData] = useState({
        'pentesterId': null,
        'status': null,
        'applicationId': null,
        'from': null,
        'to': null
    });
    const [timelineData, setTimelineData] = useState({})
    const [isTimelineModalOpen, setIsTimelineModalOpen] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [applicationList, setApplicationList] = useState([])
    const [pentesterlist, setPentesterList] = useState([])
    const { user } = useSelector(state => state?.Login)
    const [timeline, setTimeline] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection'
        }
    ]);
    const ref = useRef();


    const [submissionChartData, setSubmissionChartData] = useState({
        submissionChartDataKeyName: null,
        submissionChartDataCount: null,
        submissionChartDataCountTotal: null,
        submissionChartAverageScore: null
    })

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setIsTimelineModalOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    const handleOnChange = (data, name) => {
        const value = data;
        setFilterData((prevValue) => {
            const prev = {
                ...prevValue,
            };
            if (value === undefined || value === "") {
                delete prev[name];
            } else {
                prev[name] = value;
            }
            return prev;
        });
    };

    const handleTimelineChange = () => {
        setFilterData((prevValue) => {
            const prev = {
                ...prevValue,
            };

            const fromDate = moment(timelineData?.startDate).format('YYYY-MM-DD')
            const endDate = moment(timelineData?.endDate).format('YYYY-MM-DD')

            prev['from'] = fromDate;
            prev['to'] = endDate;
            return prev;
        });

        setTimeline([{
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection'
        }])
        setIsTimelineModalOpen(false);
    }
    const clearAllFilters = () => {
        setFilterData({ 'pentesterId': null, 'status': null, 'applicationId': null, 'from': null, 'to': null });
        setTimeline([{
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection'
        }])
        setIsTimelineModalOpen(false);
    }


    const appPreviewHandler = (obj) => {
        history.push({ pathname: `/application/${obj?.applicationId}/overview`, state: { objData: obj } })
    }

    const findingPreviewHandler = (obj) => {
        history.push({ pathname: `/application/${obj?.applicationId}/finding/${obj?.id}/overview` })
    }
    const columns = [
        {
            name: "Pen Id",
            width: "120px",
            cell: (row) =>
                <div className="table-cell-click" onClick={() => appPreviewHandler(row)}>
                    <span>{row?.application?.penId ? row?.application?.penId : "-"}</span>
                </div>,
        },
        {
            name: "Application Name",
            cell: (row) =>
                <div className="table-cell-click" onClick={() => appPreviewHandler(row)}>
                    <span>{row?.application?.applicationName ? row?.application?.applicationName : "-"}</span>
                </div>,
        },
        {
            name: "Application Type",
            cell: (row) => <span>{row?.application?.applicationType}</span>,
        },
        {
            name: "Finding Id",
            width: "120px",
            cell: (row) =>
                <div className="table-cell-click" onClick={() => findingPreviewHandler(row)}>
                    <span>{row?.id ? row?.id : '-'}</span>
                </div>,
        },
        {
            name: "Finding Title",
            cell: (row) =>
                <div className="table-cell-click" onClick={() => findingPreviewHandler(row)}>
                    <span>{row?.findingTitle ? row?.findingTitle : "-"}</span>
                </div>,
        },
        {
            name: "Score",
            width: "130px",
            cell: (row) => <TextChip
                text={row.cvssScore}
                color={row?.cvss ? CVSS[row?.cvssName] : FINDING_SCORE_COLORS[(+row?.severity).toFixed(1)] || "#E9E8EF"}
                textColor={row?.cvss ? CVSS_TEXT_COLOR[row?.cvssName] : CVSS_TEXT_COLOR[applicationScore(+row?.severity)]}
            />,
        },
        {
            name: "Severity",
            width: "130px",
            cell: (row) =>
                <TextChip
                    text={row?.cvss ? row?.cvssName : applicationScore(+row?.severity)}
                    color={row?.cvss ? CVSS[row?.cvssName] : FINDING_SCORE_COLORS[(+row?.severity).toFixed(1)] || "#E9E8EF"}
                    textColor={row?.cvss ? CVSS_TEXT_COLOR[row?.cvssName] : CVSS_TEXT_COLOR[applicationScore(+row?.severity)]}
                />,
        },
        {
            name: "Status",
            width: "130px",
            omit: !['oam_manager', 'csm_manager', 'sm_manager', 'executive', 'd_executive', 's_executive'].includes(user?.user?.roleId),
            cell: (row) =>
                <div className='d-flex gap-1'>
                    <TextChip
                        text={TitleCase(row?.findingStatus) || "N/A"}
                        color='#E9E8EF'
                        textColor={row?.findingStatus && FINDING_STATUS_COLOR[FINDING_STATUS?.find(o => o.value === row?.findingStatus)?.label] || "#000"}
                    />
                </div>
            ,
        },
        {
            name: "Approval Status",
            cell: (row) =>
                <div className='d-flex gap-1'>
                    <TextChip
                        text={FINDING_STATUS_OVERVIEW?.find(o => o.value === +row?.status)?.label}
                        color='#E9E8EF'
                        textColor={STATUS_COLOR[FINDING_STATUS_OVERVIEW?.find(o => o.value === +row?.status)?.label]}
                    />
                </div>
            ,
        },
    ];

    useEffect(() => {
        const payload = {
            "roleIds": ["pentester"],
        }
        if (user?.user?.roleId !== "oam_manager") {
            payload['companyIds'] = user?.user?.companyId
        }

        setPageLoader(true);
        Promise.all([
            listAllApplication(),
            getUserTeam(payload),
            getSubmissionsCharts(filterFields)
        ]).then(([application, pentesterlist, submissionsChartsResp]) => {
            setApplicationList(application?.data?.map(app => ({ value: app?.id, label: app?.applicationName })));
            setPentesterList(pentesterlist?.data?.map(o => ({ label: `${o?.firstName} ${o?.lastName}`, value: +o?.id, companyId: o?.companyId })))
            setPageLoader(false);
            const submissionCount = Object.values(submissionsChartsResp?.pieData).slice(0, -1)
            const submissionCountKeyName = Object.keys(submissionsChartsResp?.pieData).slice(0, -1)
            const submissionAvgScore = submissionsChartsResp?.scoreData?.map(item => [item.date, item.averageScore]);
            setSubmissionChartData({
                submissionChartDataKeyName: submissionCountKeyName,
                submissionChartDataCount: submissionCount,
                submissionChartDataCountTotal: submissionsChartsResp?.pieData?.total,
                submissionChartAverageScore: submissionAvgScore
            })

        })
    }, [filterFields])


    const breadcrumbItems = [{ path: "/", heading: "Home" },
    { path: "/submissions", heading: "Submissions" }]

    const toggle = () => {
        setIsTimelineModalOpen(!isTimelineModalOpen);
    }

    const dummyToggle = () => {

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Submissions" breadcrumbItem={breadcrumbItems} />
                <Row className='mb-3'>
                    {!['pentester'].includes(user?.user?.roleId) &&
                        <Col md="2">
                            <RHFAutoCompleteSelect
                                id="pentesterId"
                                label="Pentester"
                                name="pentesterId"
                                options={pentesterlist}
                                isMultiple={false}
                                isController={false}
                                handleOnChange={handleOnChange}
                                value={!filterFields?.pentesterId ? null : pentesterlist.find(o => o?.value === filterFields?.pentesterId)}
                            />
                        </Col>
                    }
                    <Col md="2">
                        <RHFAutoCompleteSelect
                            id="status"
                            label="Status"
                            name="status"
                            options={FINDING_STATUS_OVERVIEW}
                            isMultiple={false}
                            isController={false}
                            handleOnChange={handleOnChange}
                            value={!filterFields?.status ? null : pentesterlist.find(o => o?.value === filterFields?.status)}
                        />
                    </Col>
                    <Col md="2">
                        <RHFAutoCompleteSelect
                            id="applicationId"
                            label="Application"
                            name="applicationId"
                            options={applicationList}
                            isMultiple={false}
                            isController={false}
                            handleOnChange={handleOnChange}
                            value={!filterFields?.applicationId ? null : applicationList.find(o => o?.value === filterFields?.applicationId)}
                        />
                    </Col>
                    <Col md="2">
                        <Label htmlFor="select-input" className="form-label">&nbsp;</Label>
                        <div ref={ref}>
                            <TimelineButton
                                toggle={toggle}
                                timeline={timeline}
                                setTimeline={setTimeline}
                                setTimelineData={setTimelineData}
                                handleTimelineChange={handleTimelineChange}
                                isTimelineModalOpen={isTimelineModalOpen}
                            />
                        </div>
                    </Col>
                    <Col >
                        <Label htmlFor="select-input" className="form-label">&nbsp;</Label>
                        <div className='d-flex justify-content-end'>
                            <div className='me-2'>
                                <RHFButton
                                    btnName="Clear Filter"
                                    outline={true}
                                    icon="filter"
                                    onClick={() => clearAllFilters()}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

                {pageLoader ?
                    <PageLoader /> :
                    <>
                        <Row className='mb-3 g-3'>
                            <Col lg={6}>
                                <Card className="m-0 h-100">
                                    <CardHeader>
                                        <h4 className="card-title mb-0">Finding Ratio</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <DonutChart
                                            optionSeriesData={submissionChartData?.submissionChartDataCount}
                                            donutColors={["var(--bs-success)", "#EF0107", "#FD8500"]}
                                            donutLabels={["Approved", "Rejected", "Correction"]}
                                            DonutChartTotal={submissionChartData?.submissionChartDataCountTotal}
                                            height="300"
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card className="m-0 h-100">
                                    <CardHeader>
                                        <h4 className="card-title mb-0">Finding by Timeline</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <LineChart
                                            seriesTitle='Score Board'
                                            seriesData={submissionChartData?.submissionChartAverageScore}
                                            height="300"
                                            lineColor={["#2D31FA"]}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            {isModulePermisssion(ROLE_PERMISSIONS?.ALL_SUBMISSIONS) &&
                                <CardBody className="table-responsive">
                                    <Table
                                        columns={columns}
                                        dataURL={Finding.listAllSubmissions}
                                        filter={filterFields}
                                        description
                                    />
                                </CardBody>
                            }
                        </Card>
                    </>}
            </div>
        </React.Fragment >
    )
}

export default Submission
