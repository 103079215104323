import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu } from 'reactstrap';
import RHFButton from 'components/FormControls/RHFButton';
import DialogBox from './Modals/DialogBox';


const ApplicationStatus = (props) => {
    const { menuItems = [], handleClick, heading, filterFields, dropdown, ...args } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [menuItemList, setMenuItemList] = useState(menuItems);
    const [isModelOpen, setModel] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const manageHandleClick = (item) => {
        // handleClick(item);
        setModel(true);
        setSelectedItem(item)
    }

    const toggle = () => {
        setDropdownOpen((prevState) => !prevState);
        setMenuItemList(menuItems)
    };

    const handleModelToggle = () => {
        setModel(!isModelOpen);
        setSelectedItem(null);
    }

    const handleSuccess = () => {
        handleClick(selectedItem);
        handleModelToggle();
    }

    return (
        <>
            <div className='ms-2'>
                <RHFButton
                    btnName={heading}
                    icon='chevron-down'
                    iconPlacement='end'
                    onClick={toggle}
                    className='btn-sm btn-secondary rounded-5'
                    outline={true}
                    color='#fff'
                />
            </div>

            {dropdown?.isVisible && dropdown?.dropdownArr?.length > 0 &&
                <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="up" className='ms-2'>
                    <DropdownMenu {...args} className='p-1' style={{ maxHeight: "200px", overflowY: "auto", }}>
                        <div className='column-list py-1'>
                            {menuItemList?.map(item => {
                                return (
                                    <DropdownItem className='font-size-14 px-2 py-0' onClick={() => manageHandleClick(item)} key={item?.id} active={item?.id === filterFields?.buId}>
                                        {item?.name}
                                    </DropdownItem>
                                )
                            })}
                        </div>
                    </DropdownMenu>
                </Dropdown>
            }


            <DialogBox
                isModelOpen={isModelOpen}
                handleToggle={handleModelToggle}
                modelSize="sm-100"
                title="Confirmation"
                actions={
                    <>
                        <RHFButton
                            btnName="Cancel"
                            outline={true}
                            onClick={handleModelToggle}
                        />
                        <RHFButton
                            btnName="Okay"
                            onClick={handleSuccess}
                        />
                    </>
                }
            >
                <div className="text-center">
                    <i className="mdi mdi-alert-circle-outline" style={{ fontSize: "5em", color: "orange" }} />
                    <h5>Are you sure?</h5>
                    <p className="fs-5">You won't be able to revert this!</p>
                </div>
            </DialogBox>

        </>
    )
}

export default ApplicationStatus